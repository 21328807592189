@import '../../assets/scss/vars.scss'; 

#presto-side-nav {
    margin-left: 10px;
    margin-top: 65px;
    h2 {
        margin-right: 26%;
        font-size: 34px;
        @media only screen and (min-width: $mobileView) and (max-width: 996px) {
            margin-right: 18%;
        }
        @media only screen and (min-width: 997px) and (max-width: 1050px) {
            margin-right: 20%;
        }

        @media only screen and (min-width: 1051px) and (max-width: 1150px) {
            margin-right: 22%;
        }
        @media only screen and (min-width: 1151px) and (max-width: 1200px) {
            margin-right: 24%;
        }
    }
    ul {
        position: relative;
        .divider {
            // position: absolute;
            // right: 26%;
            // top: -20px;
            // margin-right: 10px;
            margin-top: 0;
            margin-bottom: 0px;
            hr {
                width: 240px;
                margin-right: 12px;
            }
        }
        li {
            margin-right: 26%;
            margin-top: 16px;
            @media only screen and (min-width: $mobileView) and (max-width: 996px) {
                margin-right: 18%;
            }
            @media only screen and (min-width: 997px) and (max-width: 1050px) {
                margin-right: 20%;
            }

            @media only screen and (min-width: 1051px) and (max-width: 1150px) {
                margin-right: 22%;
            }
            @media only screen and (min-width: 1151px) and (max-width: 1200px) {
                margin-right: 24%;
            }
        }
    }
}