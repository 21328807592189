@import '../../assets/scss/vars';

.transitpass-add {
  .option-already-added {
    font-size: 16px;
    line-height: 24px;
    margin: $spacing-1 0 0;
  }

  .selectbox {
    vertical-align: top;

    .mobileSelect {
      color: inherit;
    }
  }
}

.guest-login-add-pass {
  margin-top: 16px;
  @media only screen and (max-width: $mobileView) {
    margin-top: 0;
    padding: 16px;
  }
}

.transitPass-wrapper {
  width: auto;
  // margin-left: 25px;
  margin-bottom: 20px;

  // @media only screen and (max-width: $mobileView) {
  //   margin-left: 20px;
  //   margin-right: 20px;
  // }
  .hide {
    display: none;
  }

  .accordion-section {
    margin-right: 0;

    .accordion-heading {
      // outline: none;
      padding: 15px;
      margin-bottom: 15px;

      h2 {
        margin-top: 0;
        margin-bottom: 0;

        @media only screen and (max-width: $mobileView) {
          text-align: left;
        }
      }
    }
  }

  .content-error-block {
    margin-top: 8px;

    .content-error-block-title {
      @media only screen and (max-width: $mobileView) {
        line-height: 25px;
      }
    }

    .content-error-block-text-margin {
      margin-left: 0px;

      .pause-deadline {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid #e8e8e8;
        padding-top: 14px;
        padding-bottom: 14px;

        @media only screen and (max-width: $mobileView) {
          display: block;
        }

        #content1::after {
          @media only screen and (max-width: $mobileView) {
            content: '\a';
            white-space: pre;
          }
        }

        .payment-Attempts {
          font-family: 'AvenirNextLTPro-Demi';
        }
      }

      .update-attempts {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid #e8e8e8;
        padding-top: 14px;
        padding-bottom: 14px;

        @media only screen and (max-width: $mobileView) {
          display: block;
        }

        #content1::after {
          @media only screen and (max-width: $mobileView) {
            content: '\a';
            white-space: pre;
          }
        }

        .payment-Attempts {
          font-family: 'AvenirNextLTPro-Demi';
        }
      }

      .buttonClass {
        justify-content: end;
      }
    }
  }

  .warning {
    margin-top: 40px;

    .warning-Block {
      margin-left: 0px;

      .pause-deadline {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid #e8e8e8;
        padding-top: 14px;
        padding-bottom: 14px;
        margin-top: 15px;

        @media only screen and (max-width: $mobileView) {
          display: block;
        }

        #content1::after {
          @media only screen and (max-width: $mobileView) {
            content: '\a';
            white-space: pre;
          }
        }

        .payment-Attempts {
          font-family: 'AvenirNextLTPro-Demi';
        }
      }

      .update-attempts {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid #e8e8e8;
        padding-top: 14px;
        padding-bottom: 14px;

        @media only screen and (max-width: $mobileView) {
          display: block;
        }

        #content1::after {
          @media only screen and (max-width: $mobileView) {
            content: '\a';
            white-space: pre;
          }
        }

        .payment-Attempts {
          font-family: 'AvenirNextLTPro-Demi';
        }
      }

      .buttonClass {
        justify-content: end;
      }
    }
  }
}

@media only screen and (min-width: 920px) {
  .transitPass-wrapper {
    width: 624px;
  }
}
