@import '../../assets/scss/vars.scss';
.contentPageLayout-main {
  .contentPageLayout-sidebar {
    display: none;
    width: 40%;
    margin-right: 6%;
    @media only screen and (min-width: $mobileView) {
      display: flex;
      width: 40%;
      position: fixed;
      z-index: 100;
      left: 0;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-right: 0px;
    }
  }
}
.navigation {
  display: none;

  @media only screen and (min-width: $mobileView) {
    display: inline-block;
    vertical-align: top;
    //  width: 100%;
    margin-left: auto;
    margin-right: 20%;
    text-align: -webkit-right;

    .navMenu {
      margin: 40px 0 0;
      color: $black;
      // margin-top: 5.1rem;
      text-align: -moz-right;

      h2 {
        text-align: right;
        font-weight: 700;
        line-height: 2rem;
        font-size: 1.375rem;
        padding: 0 0 1rem 0;
        border-bottom: 1px solid #7d7d7d;
        width: fit-content;
        margin-bottom: 1.625rem;
      }

      .nav__sub-section {
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 2.5rem;
        margin-left: auto;
        text-align: right;
        list-style: none;
      }

      // .divider {
      //   padding: 0;

      //   hr {
      //     height: 1px;
      //     border: 0;
      //     border-top: 1px solid $secondary20;
      //     width: 224px;
      //   }
      // }

      ul {
        text-align: right;
        padding: 0;
        margin-top: 0;
        margin-bottom: 2.5rem;

        li {
          font-family: 'AvenirNextLTPro-Regular', sans-serif;
          font-size: 0.875rem;
          list-style: none;
          line-height: 1.3125rem;
          margin-bottom: 0.875rem;
          position: relative;
          clear: both;

          &.active ::after {
            content: '';
            position: absolute;
            background-color: #8bb8e8;
            height: 100%;
            width: 0.25rem;
            margin-left: 0.5rem;
          }
          &.heading-active ::after {
            content: '';
            position: absolute;
            background-color: #8bb8e8;
            height: 1.75rem;
            width: 0.25rem;
            margin-left: 0.5rem;
          }
          a {
            text-decoration: none;
            font-family: inherit;
          }
        }

        .nav__sub-section-title__link {
          font-size: 1.125rem;
          position: relative;
        }
      }
    }
  }
}
