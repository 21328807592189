@import '../../assets/scss/vars';

.autorenew {
  .header-subTitle,
  .header-subTitle-bold {
    top: 0;

    @media only screen and (min-width: $mobileView) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .header-subTitle {
    font-family: $regularFont;
  }

  .header-subTitle-bold {
    margin-bottom: $spacing-3;
  }

  .success-block {
    .checkoutGrid-wrapper {
      border-top: 2px solid $secondary20;
    }
  }

  .checkoutGrid {
    border-bottom: none;
    padding: 24px;
    @media only screen and (max-width: $mobileView) {
      padding: 16px 16px 0;
    }

    .checkoutGridItem {
      border: none;
      // padding: $spacing-1 0 0;
      .checkoutGridItem-title {
        margin-bottom: 0;
      }

      .checkoutGridItem-value-block {
        margin: 2px 0 0 auto;

        @media only screen and (min-width: $mobileView) {
          margin: 6px 0 0 auto;
        }
      }

      .checkoutGridItem-data-block {
        @media only screen and (min-width: $mobileView) {
          margin-left: 8px;
        }
      }

      .checkoutGridItem-logo-block {
        padding-left: 0px;
        img {
          @media only screen and (min-width: $mobileView) {
            align-self: center;
            top: 0px;
          }
        }
      }

      .checkoutGridItem-date {
        margin-bottom: 0;
      }
    }

    .checkoutGridItem-disable-border {
      padding-bottom: 0;
    }
  }

  .autorenew-agreement {
    padding: $spacing-1;
    background-color: $white;
    border: 1px solid $secondary10;
    border-top: 0;
    font-family: $regularFont;
    font-size: 14px;
    line-height: 21px;

    @media only screen and (min-width: $mobileView) {
      padding: 0px $spacing-2 $spacing-2 $spacing-11;
    }

    &-title {
      padding-top: $spacing-1;
      font-family: $boldFont;
      font-size: 16px;
      border-top: 2px solid $secondary20;
    }

    &-note {
      padding-top: 8px;

      @media only screen and (min-width: $mobileView) {
        padding-right: 160px;
      }
    }

    &-success {
      font-family: $regularFont;
      font-size: 14px;
      line-height: 21px;
      padding: $spacing-base 0 16px;
      border-bottom: 2px solid $secondary20;

      @media only screen and (min-width: $mobileView) {
        padding: 0 0 $spacing-2 $spacing-7;
      }
    }
  }

  .checkout-total.successModifier {
    align-items: baseline;
    display: flex;
    font-size: 16px;
    padding: $spacing-1 0 0;
    text-align: left;
    line-height: 24px;

    @media only screen and (min-width: $mobileView) {
      display: block;
      font-size: 16px;
      padding-top: $spacing-1;
      text-align: right;
    }

    span {
      font-size: 20px;
      position: relative;
      top: 2px;
      padding-left: $spacing-base;

      @media only screen and (min-width: $mobileView) {
        font-size: 22px;
        padding-left: $spacing-4;
      }
    }
  }

  .success-block {
    .manageRenew {
      span {
        @media only screen and (min-width: $mobileView) {
          font-size: 16px;
          top: 0;
          padding-left: 4px;
        }
      }
    }
    .successModifier {
      .checkoutGrid-info-wrapper {
        padding: 0;
      }
    }
  }

  .content-error-block {
    margin: $spacing-1 0 0 0;
  }

  .checkout-terms-block {
    border-top: 1px solid $secondary20;
    padding-top: $spacing-1;
    padding-bottom: $spacing-1;
  }

  .checkout-payment-total {
    align-items: baseline;
    display: flex;
    border-top: 1px solid $secondary20;
    padding-top: $spacing-1;
    padding-bottom: $spacing-base;
    font-family: $boldFont;
    font-size: 14px;

    @media only screen and (min-width: $mobileView) {
      display: block;
      font-size: 16px;
      padding-top: 20px;
      text-align: right;
    }

    span {
      font-size: 20px;
      margin-left: auto;
      margin-right: 0;
      position: relative;
      top: 2px;
      padding-left: $spacing-base;

      @media only screen and (min-width: $mobileView) {
        font-size: 22px;
        padding-left: $spacing-4;
      }
    }
  }

  .checkout-subtext {
    font-size: 14px;
    font-family: $regularFont;
    text-align: center;
    padding: 0 0 $spacing-1;

    @media only screen and (min-width: $mobileView) {
      padding: 0 50px $spacing-1;
    }
  }

  .cardBlockComponent-top-margin {
    margin-top: $spacing-1;
  }

  .note {
    margin-top: $spacing-1;
    margin-bottom: $spacing-1;
  }

  .header-title {
    // margin-bottom: 8px;
    &.centered {
      @media only screen and (min-width: $mobileView) {
        padding-bottom: 0;
      }
    }
  }
}

.set-autoRenew--steps {
  padding-top: 20px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 14px;
  }
}

.autorenew2-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  flex-direction: row-reverse;

  @media only screen and (max-width: $mobileView) {
    display: block;
    margin-top: 24px;
    margin: 0;

    #autorenew2-printicon {
      display: none;
    }
  }

  .error-autorenew {
    margin-bottom: 15px;
  }
}
