@import '../../../assets/scss/vars';

.transaction {
  background: $white;
  width: auto;
  position: relative;
  margin-right: 64px;
  margin-left: $spacing-2;

  &:after {
    content: '';
    display: block;
    width: 2px;
    background-color: #6e6e6e;
    margin: 0 auto;
    height: 100%;
    position: absolute;
    right: -35px;
    top: 0;

    @media only screen and (min-device-width: 360px) and (max-device-width: 768px) {
      height: 100%;
    }
  }

  &:first-child {
    &:after {
      top: 40px;
      @media only screen and (min-device-width: 360px) and (max-device-width: 768px) {
        top: 30px;
      }
    }
  }
  &:last-child {
    &:after {
      height: 40px;
      @media only screen and (min-device-width: 360px) and (max-device-width: 768px) {
        height: 30px;
      }
    }
  }
  &-pointer {
    width: 8px;
    height: 8px;
    background-color: $secondary60;
    border-radius: 50%;
    position: absolute;
    top: 24px;
    right: -38px;
    @media only screen and (min-width: $mobileView) {
      top: 38px;
    }
  }

  &:last-child {
    border: none;
    .transaction-head {
      border: none;
    }
    .transaction-discount {
      border-bottom: none;
      border-top: 1px solid $secondary10;
    }
  }

  &:first-child:nth-last-child(1) {
    .transaction-pointer {
      display: none;
    }
    &:after {
      display: none;
    }
  }

  &-head {
    position: relative;
    display: flex;
    border-bottom: 1px solid $secondary10;
    padding: 10px 0;
  }

  &-right {
    display: flex;
    width: 100%;
  }

  &-title {
    line-height: 24px;
    .link {
      text-align: left;
      @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          color: #191919;
        }
    }
  }

  &-subTitle {
    font-family: $regularFont;
    line-height: 21px;
    font-size: 14px;
    // width: 100px;
    @media only screen and (min-device-width: 360px) and (max-device-width: 468px) {
      width: 100px;
    }
  }

  &-agencyTitle {
    @media only screen and (min-device-width: 360px) and (max-device-width: 768px) {
      font-family: $regularFont;
      line-height: 21px;
      font-size: 14px;
    }
  }

  &-remove {
    font-family: $regularFont;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
  }

  &-block {
    margin-bottom: $spacing-base;
    flex: 1 auto auto;
    @media only screen and (min-width: $mobileView) {
      align-items: center;
      display: flex;
      margin-bottom: 0;
    }
  }

  &-value-block {
    text-align: right;
    @media only screen and (min-width: $mobileView) {
      display: flex;
      align-items: center;
    }
  }

  &-logo-block {
    display: none;
    @media only screen and (min-width: $mobileView) {
      padding-right: $spacing-3;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 60px;
    }
  }

  &-logo {
    width: 64px;
  }
}
.transaction-discount {
  border-bottom: 1px solid $secondary10;
}
.transaction-discount-value {
  text-align: right;
  font-size: 14px;
  font-family: $regularFont;
  line-height: 1.5em;
  padding: 4px;
}
