@import '../../assets/scss/vars.scss';
.selectBox-wrapper {
  margin-bottom: 16px;
  position: relative;

  .selectBox {
    border: none;
    color: $black;
    font-family: $regularFont;
    font-size: 16px;
    text-align: left;
    width: 224px;
    padding: 12px 0 12px 16px;
    background-color: $secondary10;
    border-bottom: 2px solid $black;
    line-height: 24px;
    outline: none;
    &:focus {
      outline: 1px solid #191919;
    }
    img {
      width: 24px;
      float: right;
      margin-right: 8px;
    }
    img.up {
      transform: rotate(180deg);
    }
  }
  .inactive {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}
.change-card-dropdown {
  visibility: visible;
  opacity: 1;
  display: block;
  position: absolute;
  width: 224px;
  height: 520px;
  overflow-y: auto;
  overflow-x: hidden;
  .oneitem {
    padding: 16px;
    background-color: $secondary10;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $secondary20;
    }
    .card-information {
      margin-left: 8px;
      text-align: left;

      .card-holder {
        font-size: $fontSize-14;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 140px;
      }
      .card-small-text {
        font-size: 12px;
        font-family: $regularFont;
        line-height: 16px;
      }
    }
  }
}
.selected-card {
  margin-bottom: 16px;
  text-align: left;

  .big-text {
    font-size: 22px;
    line-height: 32px;
  }
  .small-text {
    line-height: 21px;
    font-size: $fontSize-14;
  }
  .small-text-top,
  .small-text-bottom {
    line-height: 21px;
    font-size: 14px;
  }
  .small-text-top {
    font-family: $regularFont;
  }
  .small-text-bottom {
    padding-top: 55px;
    padding-bottom: 40px;
  }
  .amex,
  .interac,
  .master,
  .visa {
    height: 140px;
    padding: 16px 24px 0px 24px;
  }
  .amex,
  .interac,
  .master {
    color: $black;
  }
  .visa,
  .presto,
  .anonymous,.CRDNC {
    color: $white;
  }
  .presto, .CRDNC {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 140px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: $black;

    .card-logo {
      width: 100%;
      text-align: right;
    }

    img {
      width: 94px;
    }
    .gw-card-title{
      display:flex;
      justify-content: space-between;
      align-items: center;
      img{
        width:27px;
        height:24px
      }
    }
    .custom-image-css{
      justify-content: flex-end;
    }
  }
  .anonymous {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 140px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: $black;

    .card-logo {
      width: 100%;
      text-align: right;
    }

    img {
      width: 94px;
    }
  }
  .interac {
    background: url('../../assets/images/switch-interac.svg') no-repeat;
  }
  .amex {
    background: url('../../assets/images/switch-amex.svg') no-repeat;
  }
  .master {
    background: url('../../assets/images/switch-master.svg') no-repeat;
  }
  .visa {
    background: url('../../assets/images/switch-visa.svg') no-repeat;
  }
  .contactless {
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    .normal-font {
      font-family: $regularFont;
    }
    .bold-font {
      font-family: $boldFont;
    }
  }
}

.cardSwitcher-noCard {
  width: 100%;
  height: auto;
}
