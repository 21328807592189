@import '../../assets/scss/vars.scss';

#presto-page-layout {
  // .contentPageLayout-content {
  //   margin: 56px 10px 40px 10px;
  //   width: 100%;
  //   @media only screen and (min-width: $mobileView) {
  //     width: 50%;
  //     margin: 56px 135px 40px 0px;
  //   }
  // }
  // .side-menu {
  //   .selectBox-wrapper,
  //   .selected-card {
  //     margin-right: 26%;
  //   }
  // }
  .side-menu {
    .selected-card {
      float: right;
      width: 224px;
      .presto {
        height: 140px;
      }
    }
  }
  .welcome-msg {
    h2 {
      margin-right: 0px;
      .isSmall {
        // width: 240px;
        // float: right;
      }
    }
  }
}
