@import '../../assets/scss/vars.scss';
.activatecard-whole-wrapper {
  padding-top: 16px;
  background-color: #f3f3f3;
  // @media only screen and (min-width: $mobileView) {
  //   width: 777px;
  // }
  .activatecard-title {
    font-size: 45px;
    padding-top: 20px;
    text-align: center;
    @media only screen and (max-width: $mobileView) {
      // width: 60%;
      // padding-left: 77px;
      padding-top: 0px;
      font-size: 30px;
    }
  }
  .new-error-block {
    padding: 12px 0 12px 12px;
    border: 1px solid #e8e8e8;
    border-left: 4px solid #d63d3d;
    background-color: #fff;
    margin-bottom: 16px;
    .new-error-block-title {
      color: $feedbackError;
      font-size: 16px;
      font-family: $boldFont;
      padding-left: 34px;
      line-height: 32px;
      margin-bottom: $spacing-1;
      background: url('../../assets/images/error.svg') no-repeat left top;
      background-size: 24px;
      background-position: 0 5px;
      .content-hyperlink {
        a {
          color: $feedbackError;
        }
      }
    }
  }
  .activatecard-step1 {
    width: 97%;
    // padding-left: 65px;
    @media only screen and (max-width: $mobileView) {
      // padding-left: 20px;
      width: 100%;
    }
    .activatecard-description {
      font-family: $regularFont;
      padding-bottom: 20px;
      line-height: 24px;
      text-align: center;
    }
    .activatecard-note-wrapper {
      border: 1px solid $secondary10;
      border-left: 4px solid #8bb8e8;
      margin-bottom: 20px;
      .activatecard-note {
        margin: 20px;
      }
    }
    .activate-card-textinput {
      display: flex;
      @media only screen and (max-width: $mobileView) {
        width: initial;
        display: block;
      }
      .activate-cardtext-wrapper {
        width: 100%;
        .activatecard-card-text {
          margin-top: -25px;
          padding-bottom: 14px;
          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }
      }
      .activatecard-pin-text {
        margin-left: 1rem;
        width: 100%;
        .activatecard-pin-field {
          margin-top: -25px;
          padding-bottom: 14px;
          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }
        @media only screen and (max-width: $mobileView) {
          margin-left: 0;
        }
      }
      .helper-text-activate-card {
        font-size: 14px;
        margin-left: 13px;
      }
    }
    .activatecard-buttons {
      float: right;
      padding: 5px;
      padding-right: 0px;
      margin-right: -9px;
      display: flex;
      flex-direction: row-reverse;
      @media only screen and (max-width: $mobileView) {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        padding-bottom: 10px;
        margin-right: 0;
      }
      #activatecard-activatebutton {
        padding: 10px;
        @media only screen and (max-width: $mobileView) {
          padding: 1px;
        }
      }
      #activatecard-cancelbutton {
        // display: inline-block;
        padding: 10px;
        @media only screen and (max-width: $mobileView) {
          padding: 0px;
        }
      }
    }
  }

  .activatecard-print {
    display: inline-block;
  }
  .activation-success {
    width: 95%;
    padding-left: 20px;
    .activatecard-success-content {
      font-family: $regularFont;
      padding-top: 16px;
    }
    @media only screen and (max-width: $mobileView) {
      width: 73%;
      padding-left: 56px;
    }
  }
  .activatecard-termsandconditions-text {
    font-family: $regularFont;
    padding-top: 100px;
    text-align: center;
    word-wrap: break-word;
    a {
      font-family: $regularFont;
    }
    @media only screen and (max-width: $mobileView) {
      padding: 15px;
    }
  }

  .activatecard-print {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    @media only screen and (max-width: $mobileView) {
      display: block;
    }
    .print-icon {
      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
    .error-print-icon {
      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
    .activatecard-print-image {
      img {
        width: 30px;
        height: 30px;
      }
    }
    .manage-button {
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }
  .show-error {
    padding-left: 49px;
    width: 84%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 39px;
    }
    .Error-block {
      @media print {
        -webkit-print-color-adjust: exact;
      }
      border: 1px solid $secondary10;
      border-left: 4px solid $feedbackError;
      padding-left: 13px;
      background-color: white;
      .error-note {
        margin: 20px;

        .activatecard-error {
          color: $feedbackError;
          display: flex;
          padding-bottom: 14px;
          .activate {
            margin-left: -10px;
            padding-bottom: 10px;
          }
          .card-not-found {
            margin-top: 14px;
            font-size: 24px;
          }
        }
        .activatecard-content {
          font-family: $regularFont;
          margin-left: -8px;
        }
      }
    }
  }
}
