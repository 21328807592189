@import '../../assets/scss/vars';
@import '../../assets/scss/mixins';

.checkout {
  max-width: 784px;
  padding: 32px 16px 48px 16px;
  margin: 0 auto;

  .checkout-step1-grid {
    .checkoutGrid-wrapper {
      .checkoutGridItem-block {
        display: block;
      }

      .checkoutGridItem-logo-block {
        padding-top: 6px;
      }
    }
  }

  .header-title {
    padding-bottom: 0px;
    // margin-bottom: 40px;
  }
  .header-subtitle {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-bottom: $spacing-4;
  }
  .h2-title {
    margin-top: 0px;
    margin-bottom: $spacing-4;
    text-align: center;
  }

  h2 {
    @include fontSize-18;
    font-family: $regularFont;
    //text-align: center;
  }

  .checkout-text {
    margin-top: 29px;
  }

  .checkout-terms-block {
    border-top: 1px solid $secondary20;
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .checkout-payment-total {
    border-top: 1px solid $secondary20;
    padding-top: 16px;
    padding-bottom: 8px;
    text-align: right;
    font-family: $boldFont;

    @media only screen and (min-width: $mobileView) {
      padding-top: 20px;
    }

    span {
      font-size: 22px;
      position: relative;
      top: 2px;

      @media only screen and (min-width: $mobileView) {
        padding-left: 24px;
      }
    }
  }

  .checkout-textInput {
    max-width: 360px;

    > div {
      margin-top: 16px;
    }
  }

  .checkout-subtext {
    font-size: 14px;
    font-family: $regularFont;
    line-height: 21px;
    text-align: center;
    padding: 0 0 16px;

    @media only screen and (min-width: $mobileView) {
      padding: 0 50px 16px;
    }
  }

  .success-block {
    .success-context-text {
      margin-left: 50px;
    }
  }
  .ticket-error {
    margin-left: 150px;
  }
  .shipping-add-details {
    padding: 10px 0px 25px 0px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    margin-bottom: 18px;

    .shipping-header {
      font-size: 22px;
      line-height: 32px;
      padding: 0px 21px 12px 21px;
      border-bottom: 1px solid #d1d1d1;
    }

    .shipping-block {
      font-family: 'AvenirNextLTPro-Regular';
      padding: 12px 21px 0px 21px;
      line-height: 24px;
    }

    .shipping-street {
      font-family: 'AvenirNextLTPro-Regular';
      padding: 18px 21px 0px 21px;
      line-height: 24px;
      p {
        margin: 0;
      }
    }
  }
  .emailaddress-checkout {
    margin-bottom: 32px;
    .cardBlockComponent {
      .cardBlockComponent-title {
        font-size: 22px;
        line-height: 32px;
        font-family: $boldFont;
      }
      .cardBlockComponent-content {
        padding: 16px 24px 11px;
       
        .payment-radio {
          .radioInput {
            @media only screen and (max-width: 320px) {
              label {
                width: 36px;
                height: 16px;
              }
              input[type='radio']:checked + label {
                width: 16px;
                height: 6px;
              }
            }
            @media only screen and (min-width: 321px) and (max-width: 767px) {
              label {
                width: 32px;
                height: 16px;
              }
              input[type='radio']:checked + label {
                width: 16px;
                height: 8px;
              }
            }
            @media only screen and (min-width: 768px) and (max-width: 920px) {
              label {
                width: 16px;
                height: 16px;
              }
              input[type='radio']:checked + label {
                width: 6px;
                height: 6px;
              }
            }
          }
        }
        .payment-radio-fr {
          margin-bottom: 16px;
          margin-left: 4px;
          .radioInput {
            @media only screen and (max-width: 320px) {
              label {
                width: 46px;
                height: 15px;
              }
              input[type='radio']:checked + label {
                width: 18px;
                height: 6px;
              }
            }
            @media only screen and (min-width: 321px) and (max-width: 767px) {
              label {
                width: 36px;
                height: 15px;
              }
              input[type='radio']:checked + label {
                width: 16px;
                height: 7px;
              }
            }
            @media only screen and (min-width: 768px) and (max-width: 920px) {
              label {
                width: 16px;
                height: 15px;
              }
              input[type='radio']:checked + label {
                width: 6px;
                height: 6px;
              }
            }
          }
        }
        .checkout-textInput {
          margin-top: 32px;
        }
        .checkout-terms-block {
          padding-top: 20px;
          padding-bottom: 20px;
        }
        .checkout-payment-total {
          padding-bottom: 17px;
        }
      }
      .checkout-subtext {
        padding-bottom: 8px;
      }
    }
  }
}

.checkout-transactionInfoGrid {
  background-color: $white;
  padding: 0 16px;
  margin-top: 16px;
  font-size: 14px;

  @media only screen and (min-width: $mobileView) {
    font-size: 16px;
    padding: 0 24px;
  }

  .checkout-transactionInfoGrid-row {
    padding: 16px 0;
    border-bottom: 1px solid $secondary20;
    display: flex;

    &:last-child {
      border: none;
    }

    & > div {
      line-height: 24px;

      &:first-child {
        width: 40%;
        font-family: $regularFont;
      }

      &:last-child {
        width: 60%;
        text-align: right;
      }
    }
  }
}

// .shipping-add-details {
//   padding: 10px 0px 25px 0px;
//   background-color: #fff;
//   border: 1px solid #e8e8e8;
//   margin-bottom: 18px;

//   .shipping-header {
//     font-size: 18px;
//     padding: 0px 21px 12px 21px;
//     border-bottom: 1px solid #d1d1d1;
//   }

//   .shipping-block {
//     font-family: 'AvenirNextLTPro-Regular';
//     padding: 12px 21px 0px 21px;
//   }

//   .shipping-street {
//     font-family: 'AvenirNextLTPro-Regular';
//     padding: 18px 21px 0px 21px;

//     p {
//       margin: 0;
//     }
//   }
// }

.checkout-transactionInfoGrid {
  background-color: $white;
  padding: 0 16px;
  margin-top: 16px;
  font-size: 14px;

  @media only screen and (min-width: $mobileView) {
    font-size: 16px;
    padding: 0 24px;
  }

  .checkout-shippingDetails {
    padding: 16px 0;
    border-bottom: 1px solid #d1d1d1;
    display: flex;

    & > div {
      line-height: 24px;

      &:first-child {
        width: 40%;
        font-family: $regularFont;
      }

      &:last-child {
        width: 60%;
        text-align: right;
      }
    }

    p {
      margin: 0px;
      font-family: 'AvenirNextLTPro-Regular';

      &:first-child {
        font-family: 'AvenirNextLTPro-Demi';
      }
    }
  }

  .checkout-transactionInfoGrid-row {
    padding: 16px 0;
    border-bottom: 1px solid $secondary20;
    display: flex;

    &:last-child {
      border: none;
    }

    & > div {
      line-height: 24px;

      &:first-child {
        width: 40%;
        font-family: $regularFont;
      }

      &:last-child {
        width: 60%;
        text-align: right;
      }
    }
  }
}
