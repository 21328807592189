@import '../../assets/scss/vars';
.dateRange {
  &-clear {
    text-align: center;
    margin-bottom: $spacing-3;
    margin-top: -16px;
    display: block;
    width: 100%;

    @media only screen and (min-width: $mobileView) {
      position: absolute;
      display: inline-block;
      width: auto;
      left: $spacing-3;
      bottom: $spacing-2;
      text-decoration: underline;
      margin: 0;
    }
  }
  &-datepicker-tx {
    @media only screen and (min-width: $mobileView) {
      display: flex;
    }
    .date-format {
      padding-left: 16px;
    }
    .datePickerInput-label {
      text-transform: none;
    }
  }
}
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__year-read-view--down-arrow::before {
  content: none !important;
}
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  top: 4px;
}

.presto-datepicker-mat {
  .MuiInput-root {
    width: 300px;
    height: 45px;
    background-color: #e8e8e8;
    color: #191919;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }

  .MuiSelect-select.MuiSelect-select {
    padding-left: 10px;
  }
}

.dateRange-datepicker-tx {
  .react-datepicker__month-dropdown {
    top: 50px;
    left: 24px;
    width: 45%;
  }

  .react-datepicker__year-dropdown {
    top: 50px;
    left: 50%;
    width: 45%;
  }
}
