@import '../../assets/scss/vars.scss';

.update-personal-info {
  margin-top: 50px;

  @media only screen and (max-width: $mobileView) {
    margin-top: 0px;
  }
}

.cityinput-wrapper {
  width: 49%;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
  }
}

.provinceinput-wrapper {
  width: 40%;
  margin-left: 1rem;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
    margin-left: unset;
  }
}

.countryinput-wrapper {
  width: 37%;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
  }
}

.postalinput-wrapper {
  width: 37%;
  margin-left: 1rem;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
    margin-left: unset;
  }
}

.required-textcontent {
  margin-bottom: 0;
}

.personal-info-divider {
  margin-top: 20px;
  border-bottom: 3px solid #f3f3f3;
}

.info-btn-flex {
  display: flex;
  padding-top: 20px;
  flex-direction: row-reverse;

  @media only screen and (max-width: $mobileView) {
    display: block;
  }
}

.btn-receiveMail-empty {
  @media only screen and (min-width: $mobileView) {
    padding-top: 40px;
  }
}

.info-btn-padding {
  padding-left: 20px;

  @media only screen and (max-width: $mobileView) {
    padding-left: 0px;
    padding-top: 10px;
  }
}

.info-checkbox-padding {
  padding-top: 10px;

  .checkboxComponent-label {
    font-family: $regularFont;
  }
}

.postal-code-desc {
  font-family: $regularFont;
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: -10px;

  @media only screen and (max-width: $mobileView) {
    margin-bottom: 0px;
  }
}

.province-margin-perInfo {
  margin-top: 29px;
}