@import '../../assets/scss/vars';

.footer {
  height: auto;
  padding: 24px 0;
  margin: 0;
  background-color: $white;
  color: $black;
  width: 100%;

  @media only screen and (min-width: $mobileView) {
    // height: 120px;
    padding: 32px 0 24px;
  }
}

.footer-logo {
  width: 73px;
  height: auto;
}

.footer-center {
  // padding: 0 20px;
  display: flex;
  margin: 0 auto;
  max-width: 952px;
  flex-direction: column;
  height: auto;
  align-items: baseline;
  width: auto;

  @media only screen and (min-width: $mobileView) {
    flex-direction: row;
    align-items: center;
    width: 944px;
  }

  img {
    margin-bottom: auto;
  }

  .footer-links {
    flex-direction: column;
    margin-left: 0;
    width: 100%;

    @media only screen and (min-width: $mobileView) {
      flex-direction: row;
      margin-left: 242px;
      display: flex;
      width: auto;
    }
    @media only screen and (max-width: $mobileView) {
      margin-top: 8px;
    }

    ul {
      flex: 1;
      margin: 0;
      padding: 8px 0;
      text-decoration: none;
      list-style: none;
      min-width: 224px;
      line-height: 21px;

      @media only screen and (min-width: $mobileView) {
        padding: 0 8px;
      }
      @media only screen and (max-width: $mobileView) {
        padding-top: 0;
        padding-bottom: 16px;
      }

      li:first-of-type {
        font-family: $boldFont;
        line-height: 24px;
      }

      .category-link {
        a {
          text-decoration: none;
          font-family: $regularFont;
          font-size: 14px;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }

    .social-networks {
      padding: 0 8px;
      display: flex;
      flex-direction: row;
      min-width: 126px;
      justify-content: center;

      @media only screen and (min-width: $mobileView) {
        flex-direction: column;
        justify-content: unset;
        margin-top: 28px;
      }

      .network {
        display: flex;
        padding: 4px 0;
      }
    }
  }
}

.footer-column-title {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
  @media only screen and (min-width: $mobileView) {
    padding-left: 8px;
    margin-top: 4px;
  }
}
