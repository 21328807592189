@import '../../assets/scss/vars';

.full-width-wrapper {
  background-color: $secondary5;
  flex: 1 1 auto;

  .presto-fullwidth-navbar-with-padding {
    position: relative;
    padding-top: 80px;

  }

  .main-content {
    padding-top: 56px;
  }

  .google-wallet-page-main-content {
    background: #ffffff !important;
    padding-bottom: 100px;

    @media only screen and (max-width: $mobileView) {
      padding-bottom: 24px;
    }
  }
}