@import '../../assets/scss/vars.scss';
.security-alerts {
  p {
    margin: 0;
  }
  .password-info-title {
    font-family: $boldFont;
  }
  .success-msg-content {
    margin-top: $spacing-1;
  }
  @media only screen and (min-width: $mobileView) {
    .text-field {
      width: 247px;
      display: inline-block;
    }
    .confirm-new-password,
    .new-password {
      margin-left: 0;
      margin-right: 0;
      vertical-align: top;
    }
    .new-password {
      margin-right: 14px;
    }
  }
  .header-title {
    margin-top: 0;
    padding-bottom: $spacing-1;
    @media only screen and (min-width: $mobileView) {
      padding-bottom: $spacing-2;
    }
  }
  &.editSecurity-wrapper {
    margin: 0;
  }
  .content-error-block,
  .success-block {
    margin-top: $spacing-base;
  }
}

.security-alerts-modal {
  &.MuiDialogContent-root:first-child {
    padding-top: 0;
  }
}
