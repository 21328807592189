@import '../../assets/scss/vars';

.card-item {
  width: 100%;
  display: flex;
  flex-direction: column;

  .card-border {
    display: block;
    border-top: 1px solid $secondary20;
    margin: 0 0 $spacing-2;
    @media only screen and (min-width: $mobileView) {
      margin:  $spacing-1 0 $spacing-3
    }
  }

  &:first-child{
    .card-border {
      display: none;
  }
  }

  .card-item-wrapper{
    display:  flex;
    flex-direction: column-reverse;
    @media only screen and (min-width: $mobileView) {
      flex-direction: row;
    }
    
    .card-item-content {
      flex: 2;
      display: flex;
      flex-direction: column;
      width: auto;

      .heading {
        font-family: $boldFont;
        font-size: $fontSize-18;
        font-weight: bold;
        margin: 0;
        padding: 0;
      }

      .description {
        font-family: $regularFont;
        font-size: $fontSize-1;
        line-height: 24px;
        padding-right: $spacing-base;
      }
    }
    
    .item-image {
      display: flex;
      height: auto;
      flex: 1;
      margin-bottom: $spacing-1;
      @media only screen and (min-width: $mobileView) {
        margin: 0;
      }
  
      img {
        width: auto;
        align-self: center;
        margin: 0 auto;
        max-width: 200px;
        height: auto;
      }
    }
  }
}

