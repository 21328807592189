@import '../../assets/scss/vars.scss';

.autoload-success-wrapper {
  width: auto;
  margin: 0;
  @media only screen and (min-width: $mobileView) {
    width: 624px;
  }
  .autoload-header {
    margin-bottom: 32px;
  }

  .success-block-title {
    font-size: 22px;
  }
}

.autoload-success {
  width: auto;
  margin: 0;

  .setup-section {
    padding: 0;
    margin-top: 28px;
  }

  .section-details-amount.no-top-border {
    border-top: none;
  }

  .autoload-description {
    font-family: $regularFont;
    font-size: 14px;
    margin-top: 16px;
    line-height: 21px;
  }

  .amount {
    font-size: 16px !important;
  }
}
