@import '../../assets/scss/vars.scss';

.content-wrapper-signin {
  width: 544px;
  .content-title {
    display: flex;
    line-height: 32px;

    .tag-new {
      background-color: $mediumGreen;
      padding: 5px 10px;
      font-size: $fontSize-base;
      margin-left: 10px;
    }

    .signin-textfield {
      .text-field-label {
        line-height: 21px;
      }
      @media screen and (max-width: $mobileView) {
        margin-top: 32px;
      }
    }
  }

  // ::-ms-reveal {
  //   display: none;
  // }

  .media-cards {
    margin-top: 10px;

    img {
      padding: 0;
    }
  }

  .signIn-forgot {
    margin-top: 13px;
    margin-bottom: 16px;
    line-height: 24px;
    font-family: $regularFont;
    @media screen and (max-width: $mobileView) {
      margin-bottom: 32px;
    }
  }

  .signIn-remember {
    display: flex;
    align-items: center;
    line-height: 24px;

    input {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      appearance: none;
      border: 1px solid $secondary60;
      border-radius: 2px;
      padding: 0;

      &:checked {
        border-color: $black;
        background: url('../../assets/images/Selected@3x.png') -11px center no-repeat;
        background-size: 42px;
      }
      &:checked::-ms-check {
        display: none;
      }
    }
  }

  .signIn-button-policy {
    position: absolute;
    line-height: 24px;
    font-family: $regularFont;
    font-size: 16px;
    padding: 64px 24px 40px 0;

    @media screen and (max-width: $mobileView) {
      bottom: 16px;
      padding-top: 0;
    }
  }
  .button-signIn {
    // width: 96px;
    // margin-right: 24px;
    height: 48px;
    @media screen and (max-width: $mobileView) {
      width: 100%;
    }
  }

  @media screen and (max-width: $mobileView) {
    .signIn-button {
      width: 100%;
    }
  }
}
