@import '../../../assets/scss/vars';

.header-title {
  display: block;
  font-size: 32px;
  line-height: 48px;
  font-weight: normal;
  margin-bottom: $spacing-1;
  @media only screen and (min-width: $mobileView) {
    font-size: 48px;
    line-height: 72px;
  }

  &.centered {
    text-align: center;
    margin-top: $spacing-2;
    @media only screen and (min-width: $mobileView) {
      margin-top: $spacing-4;
      padding-bottom: $spacing-1;
    }
  }

  &.smaller-font {
    font-size: 26px;
    margin-top: $spacing-2;
    line-height: 40px;
    margin-bottom: 0;
    @media only screen and (min-width: $mobileView) {
      padding-bottom: $spacing-2;
      font-size: 32px;
      line-height: 48px;
    }
  }
}

.personalInformation {
  .header-title {
    margin-top: 40px;
  }
}
