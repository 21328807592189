@import '../../assets/scss/vars.scss';

.thirdPartySystemErrorWarning {
  &-refNumber {
    font-family: $boldFont;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-bottom: $spacing-3;
    @media only screen and (min-width: $mobileView) {
      margin-bottom: 0;
      position: relative;
      top: -32px;
    }
  }
}
