@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.transfer-card-block {
  margin-top: 16px;
  padding: 0px 0px 20px 0px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
  }

  .bold-content {
    font-weight: bold;
  }

  .transfer-card-details-header {
    font-size: 18px;
    padding: 20px 20px 21px 20px;
    border-bottom: 2px solid #d1d1d1;
    background-color: white;
  }

  .transfer-card-details-descr {
    font-family: 'AvenirNextLTPro-Regular';
    padding: 20px 20px 0px 20px;
    background-color: white;
  }

  .transfer-card-details {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    background-color: white;

    .card-nickname {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-number {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-balance {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-autoload {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-autorenew {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .card-transitpass {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;

      p:last-child {
        margin-bottom: 0;
      }

      b {
        flex-basis: 55%;
      }
    }

    .card-faretype {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }
  }

  .checkout-terms-block {
    padding-left: 18px;
    border-top: none;
    background-color: white;
    margin-bottom: 16px;
  }
}
