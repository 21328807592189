@import '../../assets/scss/vars';

$offset: 187;
$duration: 1.4s;

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-modalBackdrop;
  background-color: rgba(25, 25, 25, 0.96);
}

.payment-modal-bg {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-modalBackdrop;
  background-color: rgb(250, 250, 250);;
  
  .spinner-wrapper{
    .path{
      stroke: #191919;
  }
}
  .spinner-text{
    color: #191919;
  }
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .spinner {
    animation: rotator $duration linear infinite;
  }

  .path {
    stroke: $white;
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
  }
}

.spinner-icon {
  text-align: center;
}

.spinner-text {
  font-size: 32px;
  margin-top: $spacing-3;
  color: $white;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
