@import '../../assets/scss/vars.scss';

.mediaImageList {
  img {
    margin-right: $spacing-1;
    padding-bottom: $spacing-1;
  }

  &-isSmall img {
    margin-right: $spacing-base;
    padding-bottom: 0;
  }
}
