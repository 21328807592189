@import '../../assets/scss/vars';
.autoload-grid {
  font-family: $boldFont;
  padding-top: 24px;
  padding-bottom: 24px;
  @media only screen and (min-width: $mobileView) {
    padding-bottom: 24px;
  }

  .section-left,
  .section-right {
    text-align: center;
    & > div {
      font-size: 18px;
      @media only screen and (min-width: $mobileView) {
        font-size: 16px;
      }
    }
    @media only screen and (min-width: $mobileView) {
      display: inline-block;
      width: 49%;
    }
    .section-amount {
      margin-top: 8px;
      font-size: 26px;
      line-height: 48px;
      @media only screen and (min-width: $mobileView) {
        font-size: 32px;
      }
    }
  }

  .section-left {
    padding-bottom: 32px;
    border-bottom: 1px solid $secondary30;
    @media only screen and (min-width: $mobileView) {
      border-right: 1px solid $secondary30;
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  .section-right {
    padding-top: 32px;
    padding-bottom: 48px;
    @media only screen and (min-width: $mobileView) {
      padding-top: 0;
      padding-bottom: 0px;
    }
  }
}
