@import '../../assets/scss/vars.scss';

.main {
  max-width: 784px;
  margin: 80px auto 116px auto;
  background-color: $white;
}

.main-wrapper {
  display: flex;
}

.main-header-logo {
  width: 143px;
}

.main-header {
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  position: relative;

  .header-close-icon {
    position: absolute;
    right: 10px;
    display: none;

    @media only screen and (min-width: $mobileView) {
      display: block;
    }
  }
  .card-left {
    font-size: 20px;
    color: #fff;
    display: flex;

    .card-left-arrow {
      margin-left: 20px;
      img {
        width: 32px;
        align-self: center;
        display: block;
      }
    }

    .card-header-text {
      margin-left: 12px;
      align-self: center;
    }
  }
  .signin-header-logo {
    text-decoration: none;
    h1 {
      color: #fff;
      font-size: 22px;
      font-weight: 500;
    }
  }
}
.mobile-selected-header {
  justify-content: unset;
}

@media screen and (max-width: $mobileView) {
  .main {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
  }

  .main-wrapper {
    flex: 1;
  }
}
