@import '../../assets/scss/vars.scss';

.overlay-wrapper {
  width: 500px;
  &.hide {
    display: none;
  }
  .signin-main {
    background-color: $white;
    .main-header {
      justify-content: space-between;
      @media (max-width: 768px) {
        height: 6rem;
      }
      h2 {
        color: #fff;
        font-size: 2rem;
        margin-left: 1.5rem;
        @media (max-width: 768px) {
          font-size: 1.625rem;
        }
      }
      .header-close-icon {
        position: relative;
        display: block;
        cursor: pointer;
        margin-right: 1.875rem;
        @media (max-width: 768px) {
          margin-right: 1.25rem;
        }
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    .main-wrapper {
      display: block;
      max-height: 719px;
      @media (max-width: 768px) {
        max-height: 70vh;
      }

      .signin-paragraph {
        padding-left: 1.5rem;
        padding-right: 0;
        background-color: #e8e8e8;
        font-size: 1rem;
        font-family: $regularFont;
        position: relative;
        width: auto;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: #1a1a1a;
        line-height: 2rem;
        @media (max-width: 768px) {
          padding: 0.5rem 1rem 0.5rem 1rem;
          color: #1a1a1a;
          line-height: 1.75rem;
        }
      }
      .bodyText-paragraph {
        margin-top: 0.75rem;
        font-size: 1rem;
        line-height: 1.5rem;
        padding-top: 0.75rem;
        color: #191919;
        padding-left: 1.5rem;
        padding-right: 0;
        padding-bottom: 0.5rem;
        font-family: $regularFont;
        margin-bottom: 0;
        @media (max-width: 768px) {
          margin: 0;
          padding: 1rem 1rem 0.5rem 1rem;
          font-size: 1.125rem;
          line-height: 1.75rem;
        }
      }
      .content-error-block {
        margin: 0 24px 16px 24px;
        .content-error-block-title {
          font-size: 1.375rem;
          line-height: 2rem;
          background: url('../../assets/images/Failed.svg') no-repeat left top;
        }
        .content-error-block-text-margin {
          margin-left: 0;
          margin-bottom: 0.5rem;
          font-size: 0.875rem;
          line-height: 1.3125rem;
          color: #1e1e1e;
        }
      }
      .popup-button {
        display: flex;
        justify-content: flex-end;
        margin: 32px 24px 24px;
        button {
          width: auto;
          min-width: 96px;
          background-color: #191919;
          color: #fff;
          font-size: 1rem;
          padding: 1rem;
          border: none;
          text-transform: uppercase;
          cursor: pointer;
          outline: none;
          @media (max-width: 768px) {
            width: 100%;
            min-width: 125px;
          }
        }
      }
      iframe {
        height: 30rem;
        overflow-y: hidden;
        width: 100%;
        border: 0;
        margin-top: -1rem;
        @media (max-width: 768px) {
          height: 520px;
        }
      }
      .tabBorder {
        margin: 20px 0px;
        .tab {
          display: flex;
          justify-content: space-between;
          // align-items: center;
          background-color: #fff;
          cursor: pointer;

          button:focus {
            outline: 2px solid #000;
          }

          .card {
            //flex-basis: 45%;
            // border: 1px solid #bababa;
            // padding-top: 14px;
            // padding-left: 12px;
            // padding-bottom: 15px;
            background-color: #f3f3f3;
            border-bottom: 1px solid #000;
            text-align: left;
            width: 244px;
            .card-image {
              margin-top: 5px;

              img {
                margin-right: 8px;
                padding-bottom: 0;
              }
              .mediaImage {
                width: 33px;
              }
            }
            .card-info-wrapper {
              border: 1px solid #bababa;
              padding-top: 14px;
              padding-left: 12px;
              padding-bottom: 15px;
              border-bottom: 0px;
              @media only screen and (max-width: 404px) {
                border: none;
              }
            }
            @media only screen and (max-width: $mobileView) {
              width: 44%;
              margin: 0;
              // padding-left: 16px;
            }
            @media only screen and (max-width: 404px) {
              border-left: 1px solid #bababa;
              border-right: 1px solid #bababa;
              border-top: 1px solid #bababa;
            }
            .card-header {
              line-height: 24px;
              color: #191919;
              @supports (-webkit-touch-callout: none) {
                /* CSS specific to iOS devices */
                color: #191919;
              }
            }
          }
          .active {
            background-color: #fff;
            color: black;
            border: 1px solid black;
            border-bottom: 0;
            .card-info-wrapper {
              border: 0px;
            }
          }

          .middleBorder {
            border-bottom: 1px solid #000;
            width: 14px;
            align-self: flex-end;
            @media only screen and (max-width: $mobileView) {
              width: 14px;
              flex: 1;
            }
          }
        }

        .tabcontent {
          padding-top: 1.5rem;
          border: 1px solid #000;
          border-top: none;
          @media (max-width: $mobileView) {
            border: 0;
          }
        }

        .tabcontentInterac {
          padding-top: 1.5rem;
          border: 1px solid #000;
          border-top: none;
          @media (max-width: $mobileView) {
            border: 0;
          }
        }
      }
    }
    .content-container {
      padding: 0 24px 109px 24px;
      position: relative;
      width: 544px;
      box-sizing: border-box;

      @media (max-width: $mobileView) {
        width: 100%;
        padding: 0 0px 138px;
      }

      .content-title {
        display: flex;
        font-size: 22px;
        line-height: 32px;
        margin-top: 24px;

        h2 {
          margin-bottom: 0;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 32px;
          @media (max-width: $mobileView) {
            font-size: 20px;
          }
        }
        @media (max-width: $mobileView) {
          padding-left: 16px;
          padding-top: 16px;
        }
      }
      .content-subtext {
        @media only screen and (max-width: $mobileView) {
          margin-left: 16px;
          margin-right: 16px;
        }
      }
    }
  }
}
