@import '../../assets/scss/vars.scss';
.signin-mobile {
  background-color: $mediumGreen;
}

.top-nav-unauthenticated {
  width: 100%;
  position: fixed;
  background-color: $black;
  z-index: $zindex-topNavigation;

  .header {
    width: 1024px;
    .header-nav-links + div {
      li:nth-last-child(1) {
        a {
          margin-right: 0px;
        }
      }
    }
  }

  .nav-french-align {
    a {
      margin: 10px 12px;
    }
  }
}

// .header-nav-links {
//   margin-top: 6px;
// }
