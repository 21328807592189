@import '../../assets/scss/vars.scss';

.guest-signin-modal {
  .MuiDialog-paperWidthSm {
    @media screen and (min-width: $mobileView) {
      width: 544px;
    }
  }

  .MuiDialog-paperScrollPaper {
    @media screen and (min-width: $mobileView) {
      max-height: calc(100% - 120px);
    }
  }
}

.guest-signin-wrapper {
  background-color: #fff;

  .guest-flow-header {
    background-color: #191919;
    height: 70px;
    display: flex;
    position: relative;
    align-items: center;
    .presto-title {
      font-size: 22px;
      line-height: 32px;
      color: #fff;
      margin: 0 auto;
    }

    .presto-close {
      background: url('../../assets/images/CloseIcon.svg');
      width: 24px;
      height: 24px;
      position: absolute;
      right: 0;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 28px;
      cursor: pointer;
    }

    .guest-flow-backText {
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      font-family: $boldFont;
    }
  }
  .guest-flow-content {
    padding: 24px 24px 40px;
  }

  .guest-flow-content-title {
    font-size: 22px;
    line-height: 32px;
  }
  .guest-flow-content-subtext {
    font-size: 16px;
    line-height: 24px;
    margin: 0px 0 32px;
    font-family: $regularFont;
  }

  .guest-flow-signIn-forgot {
    margin-top: 16px;
    margin-bottom: 16px;
    line-height: 24px;
    font-family: $regularFont;
    @media screen and (max-width: $mobileView) {
      margin-bottom: 32px;
    }
  }

  .guest-flow-imagelist {
    padding: 16px 0;

    .mediaImageList {
      .mediaImage {
        width: 27px;
      }

      img {
        padding-bottom: 0;
        margin-right: 8px;
      }
    }
  }

  .guest-flow-signIn-remember {
    display: flex;
    align-items: center;
    line-height: 24px;

    input {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      appearance: none;
      border: 1px solid $secondary60;
      border-radius: 2px;
      padding: 0;

      &:checked {
        border-color: $black;
        background: url('../../assets/images/Selected@3x.png') -11px center no-repeat;
        background-size: 42px;
      }
      &:checked::-ms-check {
        display: none;
      }
    }
  }

  .guest-flow-button-signIn {
    width: 100%;
    margin-top: 24px;

    button {
      width: 100%;
    }

    .guest-flow-checkout-button {
      margin-top: 8px;
    }
  }

  .guest-flow-or-text {
    margin-top: 8px;
    h2 {
      font-size: 16px;
      line-height: 24px;
      display: grid;
      text-align: center;
      grid-template-columns: 1fr auto 1fr;
      gap: 1rem;
      margin: 0;
    }

    h2::before,
    h2::after {
      content: '';
      align-self: center;
      border-top: 1px solid #f3f3f3;
    }
  }

  .guest-flow-signup-link {
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-family: $regularFont;
  }

  .guest-flow-signIn-policy {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
    font-family: $regularFont;
  }
}
