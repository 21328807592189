@import '../../assets/scss/vars';

.wrapper-steps {
  width: 100%;

  &.two-steps {
    width: 234px;
    margin: 0 auto;

    @media screen and (min-width: $mobileView) {
      width: 400px;
    }
  }

  &.three-steps {
    width: 345px;
    margin: 0 auto;

    @media screen and (min-width: $mobileView) {
      width: 490px;
    }
  }
}

.unlink-card-wrapper-steps {
  width: 100%;

  &.two-steps {
    width: 250px;
    margin: 0 auto;

    @media screen and (min-width: $mobileView) {
      width: 311px;
    }
  }

  &.three-steps {
    width: 345px;
    margin: 0 auto;
  }
}

.steps {
  padding-left: 0;
  overflow: hidden;
  display: flex;
  width: 100%;

  li {
    list-style-type: none;
    flex-basis: 100%;
    position: relative;
    text-align: center;
    border-color: $black;
    font-size: 14px;
    font-family: $regularFont;
    color: $secondary60;
    font-weight: 600;

    &.active {
      color: $black;
    }

    .step-number {
      position: absolute;
      color: $black;
      top: 13px;
      width: 100%;
      font-size: 22px;
      z-index: $zindex-stepTrackerCircle;
      font-weight: 600;
      color: $secondary60;

      svg {
        position: relative;
        top: -4px;
      }
    }

    &:before {
      content: ' ';
      line-height: 48px;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      border: 1px solid $secondary30;
      display: block;
      text-align: center;
      margin: 0 auto 10px;
      background-color: $secondary20;
      position: relative;
      z-index: $zindex-stepTrackerCircle;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $secondary30;
      top: 24px;
      right: -45%;
    }

    &:last-child {
      &:after {
        content: none;
      }

      &.done {
        font-family: $boldFont;
      }
    }

    &.active,
    &.done {
      .step-number {
        color: $white;
      }

      &:before {
        border-color: $black;
        background-color: $black;
      }
    }

    &.done:after {
      background-color: $black;
    }

    &.active {
      font-family: $boldFont;

      &:after {
        background-color: $secondary30;
      }
    }
  }

  @media screen and (max-width: $mobileView) {
    li {
      span {
        display: none;
      }
    }
  }
}