@import '../../assets/scss/vars';

.contactlessTransactionHistory {
  &-grid {
    margin-top: $spacing-1;
  }

  .no-connection {
    background-color: $white;

    .connection-title {
      padding: 64px 16px;

      .title {
        font-size: 22px;
        text-align: center;
        line-height: 32px;
        margin-bottom: 8px;
        margin-top: 0;
        color: $black;
      }

      p {
        padding: 0;
        margin-bottom: 0;
      }
    }

    p {
      padding: 40px 16px;
      text-align: center;
      line-height: 24px;
      color: $secondary30;
    }
  }

  .no-transactions {
    background-color: $white;
    padding: $spacing-3 $spacing-1;
    text-align: center;

    img {
      width: 48px;
    }
  }

  &-buttons {
    display: flex;
    margin-top: $spacing-2;
  }

  &-buttons-filters {
    @media only screen and (min-width: $mobileView) {
      margin-left: auto;
    }
  }

  &-buttons-expand {
    margin-left: auto;

    @media only screen and (min-width: $mobileView) {
      margin-left: $spacing-2;
    }
  }

  &-unpaidFare-error {
    position: relative;
  }

  &-unpaidFare-total {
    position: absolute;
    right: $spacing-2;
    top: $spacing-2;
    font-size: 22px;
    font-family: $boldFont;
    line-height: $spacing-3;
    color: $errorAmount;
  }

  &-unpaidFare-text {
    line-height: $spacing-2;
  }

  &-unpaidFare-link {
    padding: $spacing-1 0 $spacing-base;
  }

  &-activeFilters {
    background-color: $white;
    text-align: center;
    line-height: 24px;
    padding: $spacing-base 0;
    border: 1px solid $black;
    position: relative;
    margin-top: $spacing-1;

    &-text {
      line-height: 24px;
      margin: 0;
    }

    &-clear {
      line-height: 21px;
      font-size: 14px;
      text-decoration: underline;

      @media only screen and (min-width: $mobileView) {
        position: absolute;
        right: $spacing-2;
        top: 10px;
      }
    }
  }

  &-error-text {
    font-family: $regularFont;
    margin-bottom: 7px;
  }
}
.content-error-block-text {
  font-size: 16px;
  color: #191919;
  margin: 24px 0;

}