@import '../../assets/scss/vars';

.copyright {
    width: 100%;
    background-color: $black;
    color: $white;
}

.copyright-center {
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    width: auto;
    margin: 0 auto;
    max-width: 952px;

    @media only screen and (min-width: $mobileView) {
        width: 100%;
    }
}

.footer-logo {
    width: 73px;
    height: auto;
}

.copyright-text {
    margin-left: auto;
    font-family: $regularFont;
    font-size: 12px;
    padding-right: 15px;
}