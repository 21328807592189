@import '../../assets/scss/vars.scss';

.newMainPageContent .new-mainContent {
  #rlc-card {
    .bodyText__headline {
      font-size: 48px;
      margin: 32px 0px 16px 0px;
      line-height: 72px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 0px;
        margin-top: 2rem;
      }
    }
  }
}

.balanceBlock-component {
  // width: 37rem;
  // border: 2px solid #e8e8e8;
  // margin-left: 20px;
  padding: 16px 32px 24px 40px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
    margin-top: 10px;
  }
  @media print {
    padding-left: 0px;
  }
}

.report-lost-card {
  @media only screen and (max-width: $mobileView) {
    // margin-left: 16px;
    // margin-right: 16px;
  }

  h1 {
    @media only screen and (max-width: $mobileView) {
      margin-left: 16px;
    }
  }

  .lost-card-note {
    margin-top: 16px;

    .note {
      padding-left: 0px;
      padding-bottom: 24px;
      border-left: 5px solid #8bb8e8;
      margin-top: 8px;

      @media only screen and (max-width: $mobileView) {
        line-height: 1.6;
        font-size: 16px;
        border-left: 7px solid #8bb8e8;
      }

      span {
        display: flex;
        align-items: flex-start;

        img {
          padding-right: 12px;
          padding-left: 20px;

          @media only screen and (max-width: $mobileView) {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .deactivate-card-option {
    background-color: white;
    // border: 2px solid #e8e8e8;
    margin-top: 16px;

    @media only screen and (max-width: $mobileView) {
      background-color: transparent;
    }

    .deactivate-card-header {
      font-size: 22px;
      padding: 16px 89px 16px 24px;
      border-bottom: 2px solid #d1d1d1;
      line-height: 32px;

      @media only screen and (max-width: $mobileView) {
        font-size: 28px;
        border: 2px solid #e8e8e8;
        background-color: white;
      }
    }

    .deactivate-card-para {
      font-family: 'AvenirNextLTPro-Regular';

      @media only screen and (max-width: $mobileView) {
        padding: 18px 16px 18px 16px;
        font-size: 23px;
        line-height: 1.6;
        border: 2px solid #e8e8e8;
        background-color: white;
      }

      .deactivate-card-subPara {
        font-size: 16px;
        padding: 16px 24px 0 24px;
        line-height: 24px;

        p {
          margin-bottom: 0;
        }
      }
    }

    .error-block {
      margin: 24px 24px 0px 24px;
      .content-error-block {
        padding: 14px 14px 0px 14px;
        margin-bottom: 0px;
        .content-error-block-title {
          font-size: 16px;

          @media only screen and (max-width: $mobileView) {
            font-size: 18px;
          }
        }
      }
      @media only screen and (max-width: $mobileView) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    .card-radio-list {
      padding: 24px;
      fieldset {
        margin: 0px;
        padding: 0px;
      }
      @media only screen and (max-width: $mobileView) {
        padding: 16px 0px 18px 0px;
      }

      .active {
        border-left: 7px solid #191919;
      }

      .radio-card-another {
        border: 1px solid #e8e8e8;
        padding: 24px 24px 24px 30px;
        display: flex;

        &.active {
          // border-left: 7px solid #191919;
          padding: 24px 24px 24px 23px;
          //  border-block-start: 0px;
          // border-block-end: 0px;
        }

        @media only screen and (max-width: $mobileView) {
          // border-left: none;
          // border-right: none;
          background-color: white;
          padding: 24px 24px 24px 30px;
        }

        .radioInput {
          margin-top: 26px;

          @media only screen and (max-width: $mobileView) {
            margin-top: 12px;
          }
        }

        .new-card-content {
          padding-left: 28px;
          font-size: 16px;

          .content-title {
            font-size: 16px;
            line-height: 24px;

            // @media only screen and (max-width: $mobileView) {
            //   line-height: 1.6;
            //   font-size: 23px;
            // }
          }

          .content-subtitle {
            margin-top: 4px;
            font-family: 'AvenirNextLTPro-Regular';

            @media only screen and (max-width: $mobileView) {
              line-height: 21px;
              font-size: 14px;
            }
          }
        }
      }
      .radio-card-another-lost {
        padding-right: 24px;
      }

      .radio-card-new {
        border: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        padding: 24px 24px 24px 30px;
        display: flex;
        line-height: 21px;

        &.active {
          //  border-left: 7px solid #191919;
          border-bottom: 1px solid #e8e8e8;
          padding: 24px 24px 24px 23px;
          // border-block-start: 0px;
          // border-block-end: 0px;
        }

        @media only screen and (max-width: $mobileView) {
          // border-left: none;
          // border-right: none;
          background-color: white;
          padding: 24px 24px 24px 30px;
        }

        .radioInput {
          margin-top: 15px;

          @media only screen and (max-width: $mobileView) {
            margin-top: 12px;
          }
        }

        .new-card-content {
          padding-left: 28px;
          font-size: 16px;

          .content-title {
            font-size: 16px;
            line-height: 24px;

            // @media only screen and (max-width: $mobileView) {
            //   line-height: 1.6;
            //   font-size: 23px;
            // }
          }

          .content-subtitle {
            margin-top: 4px;
            font-family: 'AvenirNextLTPro-Regular';

            @media only screen and (max-width: $mobileView) {
              line-height: 21px;
              font-size: 14px;
            }
          }
        }
      }
      .radio-card-new-lost {
        padding-right: 24px;
        .radioInput {
          margin-top: 25px;
        }
        // .new-card-content {
        //   .content-title {
        //     line-height: 21px;
        //   }
        // }
      }

      .radio-buy-new-card {
        &.active {
          border-bottom: 1px solid #e8e8e8;
        }
      }

      .radio-card-existing {
        border: 1px solid #e8e8e8;
        padding: 24px 28px 24px 30px;
        display: flex;
        line-height: 21px;

        &.active {
          //  border-left: 7px solid #191919;
          padding: 24px 24px 24px 23px;
          // border-block-end: 0px;
        }

        @media only screen and (max-width: $mobileView) {
          // border-left: none;
          // border-right: none;
          background-color: white;
          border-top: none;
          padding: 24px 24px 24px 30px;
        }

        .radioInput {
          margin-top: 24px;

          @media only screen and (max-width: $mobileView) {
            margin-top: 12px;
          }
        }

        .existing-card-content {
          padding-left: 28px;
          font-size: 16px;

          @media only screen and (max-width: $mobileView) {
            padding-right: 0;
          }

          .content-title {
            font-size: 16px;
            line-height: 24px;

            // @media only screen and (max-width: $mobileView) {
            //   line-height: 1.6;
            //   font-size: 23px;
            // }
          }

          .content-subtitle {
            margin-top: 4px;
            font-family: 'AvenirNextLTPro-Regular';

            @media only screen and (max-width: $mobileView) {
              line-height: 21px;
              font-size: 14px;
            }
          }
        }
      }
      .radio-card-existing-lost {
        padding-right: 24px;

        .radioInput {
          margin-top: 37px;
        }
      }

      .radio-card-deactive {
        border: 1px solid #e8e8e8;
        padding: 24px 28px 24px 30px;
        display: flex;

        &.active {
          // border-left: 7px solid #191919;
          padding: 24px 24px 24px 23px;
          // border-block-start: 0px;
        }

        @media only screen and (max-width: $mobileView) {
          border-top: 2px solid #e8e8e8;
          border-bottom: 2px solid #e8e8e8;
          background-color: white;
          padding: 24px 24px 24px 30px;
        }

        .radioInput {
          margin-top: 16px;

          @media only screen and (max-width: $mobileView) {
            margin-top: 12px;
          }
        }

        .deactivate-card-content {
          padding-left: 28px;
          font-size: 16px;

          .content-title {
            font-size: 16px;
            line-height: 24px;

            // @media only screen and (max-width: $mobileView) {
            //   line-height: 1.6;
            //   font-size: 23px;
            // }
          }

          .content-subtitle {
            margin-top: 4px;
            font-family: 'AvenirNextLTPro-Regular';

            @media only screen and (max-width: $mobileView) {
              line-height: 21px;
              font-size: 14px;
            }
          }
        }
      }
      .radio-card-deactive-lost {
        padding-right: 24px;
        .radioInput {
          margin-top: 22px;
        }
        .deactive-card-content {
          .content-title {
            line-height: 21px;
          }
        }
      }
    }

    .buttonClass {
      justify-content: end;

      @media only screen and (max-width: $mobileView) {
        margin-top: 25px;
      }
    }
  }
}

.disabled-option-rlc {
  label {
    background-color: #e8e8e8;
  }
}

.fieldset-hide {
  border: none;

  .legend-hide {
    display: none;
  }
}
