@import '../../assets/scss/vars';

.inlineError {
  font-size: 14px;
  color: $feedbackError;
  background: url('../../assets/images/error.svg') no-repeat 13px 2px;
  background-size: 16px;
  padding-left: 37px;
  padding-top: 2px;
  font-family: $boldFont;
  line-height: 21px;
  margin-top: 6px;
}
