@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.lost-card-details {
  margin-top: 16px;
  background-color: white;
  padding: 20px 0px 20px 0px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
  }

  .bold-content {
    font-weight: bold;
  }

  .card-details-header {
    font-size: 18px;
    padding: 0px 20px 21px 20px;
    border-bottom: 2px solid #d1d1d1;
  }

  .card-details-content {
    padding-left: 20px;
    padding-right: 20px;

    .card-number {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-nickname {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-balance {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-autoload {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-autorenew {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .card-transitpass {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;

      p:last-child {
        margin-bottom: 0;
      }

      b {
        flex-basis: 55%;
      }
    }

    .card-faretype {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }
  }
}

.lost-card-details-block {
  margin-top: 16px;
  padding: 0px 0px 20px 0px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
  }

  .bold-content {
    font-weight: bold;
  }

  .card-details-header {
    background-color: white;
    font-size: 18px;
    padding: 20px 20px 21px 20px;
    border-bottom: 2px solid #d1d1d1;
  }

  .card-details-descr {
    background-color: white;
    font-family: 'AvenirNextLTPro-Regular';
    padding: 20px 20px 0px 20px;
  }

  .card-select-block {
    .card-details-dropdown {
      display: flex;
      padding: 20px 20px 20px 20px;
      background-color: white;
      // margin-bottom: 16px;
      @media only screen and (max-width: $mobileView) {
        width: initial;
        display: block;
      }

      .card-detail {
        width: 16rem;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }

        .card-details-field {
          margin-top: 5px;

          @media only screen and (max-width: $mobileView) {
          }
        }
      }
    }

    .selected-card-detail {
      padding-left: 20px;
      padding-right: 20px;
      background-color: white;
      margin-bottom: 16px;
      .presto-card {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }

      .card-balance {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }

      .card-autoload {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }

      .card-autorenew {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;

        p:last-child {
          margin-bottom: 0;
        }
      }

      .transit-pass {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;

        p:last-child {
          margin-bottom: 0;
        }
      }

      .card-fareType {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
