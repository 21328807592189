@import '../../assets/scss/vars';
.three-column {
  background-color: white;
  width: 944px;
  margin: 0 auto;
  margin-top: -16px;
  grid-column-gap: 15px;
  padding-top: 40px;
  padding-bottom: 55px;
  @media only screen and (max-width: $mobileView) {
    width: unset;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
  }
  .imageparagraph2-etickets {
    height: 330px;
    @media only screen and (max-width: $mobileView) {
      height: auto;
    }
  }
  .column {
    flex: unset;
    width: 224px;
    @media only screen and (max-width: $mobileView) {
      width: auto;
    }
  }
}
.three-column-french {
  background-color: white;
  width: 944px;
  margin: 0 auto;
  margin-top: -16px;
  grid-column-gap: 15px;
  padding-top: 40px;
  padding-bottom: 55px;
  @media only screen and (max-width: $mobileView) {
    width: 88%;
    padding-left: 22px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 22px;
  }
  .imageparagraph2-etickets {
    height: 350px;
    @media only screen and (max-width: $mobileView) {
      height: auto;
    }
  }
  .column {
    flex: unset;
    width: 224px;
    @media only screen and (max-width: $mobileView) {
      width: auto;
    }
  }
}
.Third-column-Hello {
  //padding-left: 155px;
  //margin-top: -16px;
  grid-column-gap: 16px;
  padding-top: 24px;
  width: 944px;
  margin: 0 auto;
  .column {
    max-width: unset;
  }
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
    width: unset;
    &.row {
      display: block !important;
    }
    .row {
      display: block !important;
    }
  }
}

.threecolumn-wrapper {
  background-color: white;
}
