@import '../../assets/scss/vars.scss';

.notifications {
  &-emptyMsg {
    .cardBlockComponent-content {
      font-size: 18px;
      font-family: $boldFont;
    }
  }

  &-collapsibleNotification {
    margin-top: $spacing-1;
    background-color: $white;
    padding: $spacing-1 $spacing-1 $spacing-2;
    border-left: 4px solid $mediumCyanBlue;

    @media only screen and (min-width: $mobileView) {
      padding: $spacing-2;
    }

    &-header {
      display: flex;
      font-size: 20px;
      line-height: 32px;
      justify-content: space-between;

      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px 0px 16px 0px;

    +.notifications-collapsibleNotification {
      margin-top: 0;
    }

    .h2-title {
      line-height: 32px;
      margin: 0px;
    }
  }

  &-headerToggleBtn {
    border: none;
    background-color: transparent;
    font-family: $boldFont;
    font-size: 16px;
    cursor: pointer;
    line-height: 19px;

    >img,
    >span {
      vertical-align: middle;

      @media only screen and (max-width: $mobileView) {
        vertical-align: bottom;
      }
    }
  }

  &-collapsibleContent {
    margin-top: $spacing-1;
    font-family: $regularFont;
    line-height: 19px;

    .notifications-userEmail {
      display: block;
      font-family: $boldFont;
      font-size: 20px;
      line-height: 32px;
      margin: $spacing-1 0 $spacing-2;

      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
      }
    }
  }
    .green-left-border {
      border-left: 4px solid #328633;
    }
}