@import '../../assets/scss/vars.scss';

.header-search-mobile {
  .search-input-mobile {
    background-color: $white;
    padding: $spacing-1;
    line-height: 24px;
    margin-left: $spacing-base;
    border: none;
    font-family: $regularFont;
    font-size: 16px;
    color: $secondary80;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $secondary60;
    }
  }

  button.search {
    filter: invert(1);
    float: right;
    height: 57px;
  }
}
