@import '../../assets/scss/vars';

.headline {
  color: #000;
  font-family: AvenirNextLTPro-Regular;
  line-height: 2.5rem;
}

.bodyText__headline {
  font-family: $boldFont;
  font-size: 2rem;
  line-height: 3rem;
  color: #191919;
}

.instruction__headline {
  font-family: $boldFont;
  font-size: 2rem;
  text-align: left;
  line-height: 3rem;
  margin-bottom: 4.5rem;
  margin-top: 3.5rem;
}

#contactless_available_heading {
  .availableNow__headline {
    text-align: left;
    font-family: $boldFont;
    font-size: 1.375rem;
    line-height: 2rem;
    color: #191919;
  }

  +.accordion_demo_Section {
    .accordionContent {
      .row {
        .column {
          max-width: none;
        }
      }
    }
  }
}

.newMainPageContent .new-mainContent {
  .contactless-transaction-history-header {
    #rlc-card {
      .bodyText__headline {
        font-size: 32px;
        line-height: 48px;

        @media screen and (max-width: $mobileView) {
          font-size: 32px;
        }
      }
    }
  }
}

.new-mainContent {
  #delete-heading-success {
    @media only screen and (min-width: $mobileView) {
      margin-top: 32px;
      margin-bottom: 32px;

      .bodyText__headline {
        margin-right: 0px;
        text-align: center;
      }
    }

    @media only screen and (max-width: $mobileView) {
      .bodyText__headline {
        margin: 32px 0px 32px 10px;
      }
    }
  }

  #delete-heading {
    .bodyText__headline {
      margin-bottom: 32px;
    }

    @media only screen and (max-width: $mobileView) {

      .bodyText__headline {
        margin-top: 32px;
        margin-left: 10px;

      }
    }
  }

}

.contactless_support_headline {
  font-family: AvenirNextLTPro-Bold;
  font-size: 2rem;
  line-height: unset;
  color: #191919;
  text-align: center;
  margin: 0px;

  @media only screen and (max-width: 768px) {
    margin: 0 1.5rem;
  }
}

.bodyText__headline {
  margin-right: 1.5rem;
}

@media (max-width: 768px) {
  #page-title__headline {
    margin-bottom: 1.5rem;
  }

  #page-title__headline_home {
    .page-title__headline_home {
      margin: 0px;
    }
  }
}

.page-title__headline {
  display: none;

  @media only screen and (max-width: 768px) {
    margin: 0;
    display: block;
    font-size: 1.375rem;
    line-height: 2rem;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    color: #191919;
    background-color: transparent;
  }
}

.page-title__headline::after {
  content: '';
  border-bottom: 0.0625rem solid #000;
  position: absolute;
  bottom: 0;
  height: 0.0625rem;
  width: 10rem;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.page-title__headline_home {
  display: none;

  @media only screen and (max-width: 768px) {
    margin: 0;
    display: block;
    font-size: 1.375rem;
    line-height: 2rem;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    color: #191919;
    background-color: #f3f3f3;
  }
}

.page-title__headline_home::after {
  content: '';
  border-bottom: 0.0625rem solid #000;
  position: absolute;
  bottom: 0;
  height: 0.0625rem;
  width: 10rem;
  margin: 0 auto;
  left: 0;
  right: 0;
}

@media (max-width: 768px) {
  .headline {
    margin: 0 1.5rem;
  }

  .bodyText__headline {
    margin-top: 4rem;
  }

  .availableNow__headline {
    margin-top: 0.83em;
    margin-bottom: 0.83em;
  }
}

@media (min-width: 768px) {
  .headline {
    line-height: 4.5rem;
  }

  .bodyText__headline {
    font-size: 3rem;
    line-height: 4.5rem;
  }

  .instruction__headline {
    margin-top: 5rem;
    font-size: 3rem;
  }

  .availableNow__headline {
    font-size: 1.375rem;
    line-height: 2rem;
  }

  .contactless_support_headline {
    font-size: 3.75rem;
    line-height: 5.625rem;
    margin: 40px 20% 0 0;
    text-align: left;
  }

  .bodyText__headline {
    margin-right: 20%;
  }
}

.full-width-wrapper .main-content {
  padding-top: 0;

  .tc_headline {
    padding: 40px 15px;
    margin: 0;
    text-align: center;
    color: #181818;
    line-height: 2.0625rem;
    font-family: $regularFont;
    font-size: 1.875rem;
    font-weight: 400;
  }
}

.prestoheading {
  text-align: center;
  color: #181818;
  font-weight: lighter;
  font-family: $regularFont;
  display: block;
  padding: 20px 0 10px;
  font-size: 4.8rem;
  line-height: 1.1;
  background: #fafafa;

  @media only screen and (max-width: $mobileView) {
    font-size: 2rem;
    line-height: 1.2em;
    width: 65%;
    margin: 70px !important;
  }
}

.main-content {
  background: #f3f3f3 !important;

  // // .privacyheading {
  // // padding-bottom: 48px;
  // // padding-top: 20px;
  // // margin-bottom: 0 !important;
  // // font-size: 3rem;
  // // font-weight: lighter;
  // // color: #181818;
  // // font-family: $boldFont;
  // // text-align: center;
  // // line-height: 72px;

  // // @media only screen and (max-width: $mobileView) {
  // //   padding-top: 32px;
  // //   padding-bottom: 32px;
  // //   font-size: 32px;
  // //   line-height: 48px;
  // // }
  // }

  .create-account {
    padding-bottom: 24px;
    padding-top: 0px;
    margin-bottom: 0 !important;
    font-size: 3rem !important;
    font-weight: lighter;
    color: #181818;
    font-family: $boldFont;
    text-align: center;
    line-height: 72px;

    @media only screen and (max-width: $mobileView) {
      padding-top: 24px;
      padding-bottom: 24px;
      font-size: 2rem !important;
      line-height: 48px;
    }
  }
}

#accessibility-header-id {
  background-color: white;
  border-bottom: 2px solid #dfdede;

  .accessibility-heading {
    font-family: $boldFont;
    font-size: 48px;
    margin-top: 0;
    // padding: 56px 45px 96px 175px;
    padding-top: 56px;
    padding-bottom: 96px;
    // margin-bottom: 48px;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
      padding-left: 22px;
      margin: 0px;
      font-size: 32px;
      line-height: 48px;
      padding-top: 32px;
      padding-bottom: 32px;
      width: unset;
    }
  }
}

// .heading-content {
//   padding-bottom: 30px;
//   padding-top: 20px;
//   margin-bottom: 0 !important;
//   font-size: 3rem !important;
//   font-weight: lighter;
//   color: #181818;
//   font-family: $boldFont;
//   text-align: center;
//   line-height: 1.1;

//   @media only screen and (max-width: $mobileView) {
//     padding-top: 40px;
//   }
// }

.Headings-imagewith-para {
  margin: 0 auto;
  font-size: 26px;
  font-weight: lighter;
  color: #191919;
  font-family: $boldFont;
  //  text-align: left;
  line-height: 40px;
  // padding-left: 170px;
  width: 944px;
  padding-top: 48px;
  padding-bottom: 24px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-bottom: 24px;
    margin: 0 16px;
    font-size: 22px;
    line-height: 32px;
    width: unset;
  }
}

.Headings-imagewith-para-white {
  background-color: #ffffff;
  padding-top: 48px;
  padding-bottom: 24px;
  font-size: 26px;
  font-weight: lighter;
  color: #191919;
  font-family: $boldFont;
  text-align: left;
  line-height: 40px;
  padding-left: 170px;
  margin-bottom: 0;

  @media only screen and (max-width: $mobileView) {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 22px;
    font-size: 22px;
    line-height: 32px;
  }
}

#report-lost-card-heading-component-id {
  background-color: #fff;
  padding-left: 170px;
  border-bottom: 2px solid #dfdede;

  @media only screen and (max-width: $mobileView) {
    padding-left: 20px;
    padding-bottom: 32px;
    padding-top: 32px;
  }
}

.report-lost-card-heading-component {
  font-family: $boldFont;
  font-size: 48px;
  line-height: 72px;
  background-color: #fff;
  margin-top: 0px;
  padding-top: 56px;
  width: 80%;
  margin-bottom: 40px;

  @media only screen and (max-width: $mobileView) {
    font-size: 32px;
    line-height: 48px;
    margin-left: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    width: 96%;
  }
}

#paper-forms-header {
  background-color: #ffffff;
  border-bottom: 2px solid #dadada;
}

.paper-forms-heading {
  background-color: #ffffff;
  margin-top: 0;
  margin-bottom: 42px;
  padding-top: 56px;
  padding-bottom: 96px;
  //  padding-left: 178px;
  // border-bottom: 2px solid #dadada;
  font-family: $boldFont;
  height: 72px;
  color: #191919;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 72px;
  width: 944px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
    font-size: 32px;
    line-height: 48px;
    width: unset;
  }
}

#get_to_know_heading_ID {
  margin: 81px auto 33px;
  width: 944px;

  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 40px;
    margin-bottom: 24px;
    width: unset;
  }

  .get_to_know_header {
    font-family: $boldFont;
    font-size: 32px;
    margin-bottom: 33px;
    line-height: 48px;

    @media only screen and (max-width: $mobileView) {
      text-align: center;
      font-size: 26px;
      line-height: 40px;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 24px;
    }
  }
}

#redeem-voucher-header {
  .bodyText__headline {
    margin-top: 32px;
    margin-bottom: 20px;

    @media only screen and (max-width: $mobileView) {
      margin: 2rem 0 0;
    }
  }

  @media only screen and (max-width: $mobileView) {
    margin-left: 0;
  }
}

#transit-usage-report-heading {
  min-width: 110%;

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    min-width: 100%;
  }

  .headline {
    margin-bottom: 15px;
  }

  .bodyText__headline {
    margin-top: 2rem;
  }
}

.sitemap-heading-content~.null-class .row {
  .column:first-child {
    // margin-top: 0px;
    max-width: 40%;

    @media only screen and (max-width: $mobileView) {
      max-width: none;
      margin-top: auto;
      margin-bottom: 24px;
    }
  }

  .column {
    margin-top: 48px;
    max-width: 60%;
    margin-bottom: 32px;

    @media only screen and (max-width: $mobileView) {
      max-width: 88%;
      margin-top: 24px;
    }
  }
}

#contactus-heading {
  margin-top: -16px;

  // text-align: center;
  // margin-left: 55px;
  @media only screen and (max-width: $mobileView) {
    text-align: start;
    margin-top: -35px;
    margin-bottom: 24px;
    // margin-left: 20px;
    // margin-top: 32px;
  }

  .headline {
    font-size: 32px;
    margin-right: 11%;
    line-height: 48px;

    @media only screen and (max-width: $mobileView) {
      margin: 0;
      font-size: 22px;
      line-height: 38px;
    }
  }
}

#commercial-opportunities-heading {
  width: 50%;

  // margin-left: 35%;
  @media only screen and (max-width: $mobileView) {
    margin-bottom: 24px;
    margin-left: 0px;
    width: auto;
  }

  .headline {
    font-size: 32px;
    line-height: 48px;
    margin-top: 0;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
      // margin-top: 32px;
      // text-align: center;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

#presto-ttc-heading-id {
  border-bottom: 1px solid #d1d1d1;
  background-color: white;

  .presto-ttc-heading {
    font-family: $boldFont;
    font-size: 48px;
    line-height: 72px;
    margin-top: 0;
    padding-top: 56px;
    width: 944px;
    margin: 0 auto;
    padding-bottom: 96px;

    @media only screen and (max-width: 768px) {
      margin: 0px;
      line-height: 48px;
      font-size: 32px;
      width: unset;
      padding: 32px 16px;
    }
  }
}

.Using-Presto-Header {
  font-family: $boldFont;
  font-size: 26px;
  width: 88%;
  line-height: 40px;
  margin-top: 0px;

  @media only screen and (max-width: $mobileView) {
    margin-left: 0px;
    width: 100%;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 24px;
  }
}

#enjoymore-heading-wrapper {
  background-color: #ffffff;

  .new-to-presto-enjoymorediscounts {
    background-color: #fff;
    margin-bottom: 0;
    font-family: $boldFont;
    padding-top: 48px;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      //padding-left: 30px;
      margin-left: 16px;
      margin-right: 16px;
      font-size: 22px;
      padding-top: 24px;
      padding-bottom: 24px;
      width: unset;
    }
  }
}

.paper-heading {
  height: 40px;
  width: 264px;
  color: #191919;
  font-family: $boldFont;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: 0;

  @media only screen and (max-width: $mobileView) {
    margin: 0px;
    height: auto;
    font-size: 22px;
    line-height: 32px;
  }
}

.mail-heading {
  height: 40px;
  width: 264px;
  color: #191919;
  font-family: $boldFont;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: 0;

  @media only screen and (max-width: $mobileView) {
    margin: 0px;
    height: auto;
    padding-bottom: 24px;
    font-size: 22px;
    line-height: 32px;
  }
}

.register-heading {
  height: 40px;
  width: 264px;
  color: #191919;
  font-family: $boldFont;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: 0;

  @media only screen and (max-width: $mobileView) {
    height: auto;
    margin: 0px;
    padding-bottom: 24px;
    font-size: 22px;
    line-height: 32px;
    width: unset;
  }
}

.setup-heading {
  height: 40px;
  width: 264px;
  color: #191919;
  font-family: $boldFont;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: 0;

  @media only screen and (max-width: $mobileView) {
    width: unset;
    height: auto;
    margin: 0px;
    // padding-bottom: 130px;
    // width: 165%;
    font-size: 22px;
    line-height: 32px;
  }
}

.defective-heading {
  height: 40px;
  width: 264px;
  color: #191919;
  font-family: $boldFont;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: 0;

  @media only screen and (max-width: $mobileView) {
    height: auto;
    margin: 0px;
    padding-bottom: 24px;
    width: unset;
  }
}

.contactless-terms-conditions-heading {
  font-family: $boldFont;
  font-size: 48px;
  color: #191919;
  line-height: 3rem;
  text-align: center;

  @media only screen and (max-width: $mobileView) {
    margin-bottom: 30px;
  }
}

#privacy-header {
  background-color: #ffffff;
  border-bottom: 2px solid #dadada;

  .privacyheading {
    background-color: #ffffff;
    margin-top: 0;
    margin-bottom: 42px;
    padding-top: 56px;
    padding-bottom: 96px;
    //  padding-left: 178px;
    // border-bottom: 2px solid #dadada;
    font-family: $boldFont;
    height: 72px;
    color: #191919;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 72px;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      padding-top: 32px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 32px;
      font-size: 32px;
      line-height: 48px;
      width: unset;
    }
  }
}

#terms-and-conditions-header {
  background-color: #ffffff;
  padding-top: 56px;
  padding-bottom: 96px;
  border-bottom: 1px solid #d1d1d1;

  @media only screen and (max-width: $mobileView) {
    padding: 32px 16px 32px 16px;
  }

  .terms-and-conditions-heading {
    font-size: 48px;
    line-height: 72px;
    font-family: $boldFont;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      margin: 0px;
      font-size: 32px;
      line-height: 48px;
      width: 100%;
    }
  }
}

.Hello-free-heading {
  font-size: 32px;
  line-height: 48px;
  text-align: left;
  color: #191919;
  margin-top: 48px;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  width: 944px;
  font-family: $boldFont;

  @media only screen and (max-width: $mobileView) {
    font-size: 26px;
    line-height: 40px;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 16px;
    margin-right: 16px;
    width: 98%;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    font-family: $boldFont;
  }
}

#get-presto-ready-heading {
  background-color: #fff;
}

#presto-heading-component {
  background-color: #fff;
  padding-top: 56px;
  padding-bottom: 96px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.presto-heading-title {
  font-size: 48px;
  line-height: 72px;
  font-family: $boldFont;
  width: 944px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    font-size: 32px;
    line-height: 48px;
    width: unset;
    margin: 0 16px;
  }
}

#new_presto_devices_heading_id {
  background-color: #ffffff;
  border-bottom: 1px solid #d1d1d1;

  .new_presto_devices_heading {
    font-family: $boldFont;
    font-size: 48px;
    line-height: 72px;
    padding-top: 56px;
    padding-bottom: 96px;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      padding: 32px 16px;
      width: unset;
    }
  }
}