@import '../../assets/scss/vars.scss';
.accordian-component-main {
  .main-link {
    margin-right: 20%;
    float: right;
    width: auto;
    text-align: right;
    margin-bottom: 0.0625rem;
    color: #191919;
    @media only screen and (max-width: 768px) {
      margin: 0 1.5rem 2.5rem 0;
    }
  }
  a:hover {
    text-decoration: none;
  }
  .accordiancomponent {
    .accordionSection {
      margin: 5rem 20% 0 0;
      border: 0;
      @media only screen and (max-width: 768px) {
        margin-right: 0;
      }

      &.PRESTO_Contactless_Support {
        margin-top: 0;
      }

      &.Additional_Support {
        margin-top: 0;
      }

      .section-link {
        margin-top: 5rem;
        text-decoration: none;
        line-height: 1.5rem;
        white-space: nowrap;
        @media only screen and (max-width: 768px) {
          margin: 0 1.5rem;
        }
      }
      .section-link::before {
        content: ' ';
        border: solid #1a1a1a;
        border-width: 0 0.125rem 0.125rem 0;
        display: inline-block;
        padding: 0.25rem;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        cursor: pointer;
        left: 0;
        margin-right: 10px;
      }
      .accordionSection-heading {
        font-size: 3.75rem;
        margin-top: 13px;
        text-align: left;
        line-height: 5.625rem;
        color: #191919;
        font-family: 'AvenirNextLTPro-Bold';
        font-weight: bold;
        @media only screen and (max-width: 768px) {
          font-size: 1.375rem;
          margin-right: 0;
          text-align: left;
          margin: 0 1.5rem;
          line-height: unset;
          margin-top: 13px;
        }
      }
      .accordian-content {
        //float: right;
        @media only screen and (max-width: 768px) {
          margin: 0;
          padding: 0 1.5rem;
        }
        &.support {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .accordionSection-heading-subtitle {
            font-size: 2rem;
            line-height: normal;
            margin-top: 0;
            margin-bottom: 1rem;
            @media only screen and (max-width: 768px) {
              margin: 0 0 1rem 0;
              font-size: 22px;
              line-height: 2.5rem;
            }
          }
        }
        .accordionSection-SubTitleDesc {
          margin-bottom: 2rem;
          font-size: 1.125rem;
          margin-top: 1rem;
          font-family: 'AvenirNextLTPro-Regular';
          line-height: 2rem;
          @media only screen and (max-width: 768px) {
            margin: 0 0 1rem;
            font-size: 1rem;
            line-height: 1.75rem;
            font-family: 'AvenirNextLTPro-Regular';
            margin-bottom: 1.5rem;
          }
        }
        .accordionSection-image {
          padding-left: 10%;
          @media only screen and (max-width: 768px) {
            padding-left: 0;
            //margin: 0 1.5rem;
          }
          img {
            float: right;
            height: 9.3125rem;
            object-fit: contain;
            @media only screen and (max-width: 768px) {
              height: 5.5rem;
              width: auto;
            }
          }
        }
      }
    }
  }
}
.view-all-link {
  float: right;
  margin-right: 1.5rem;
  color: #191919;
  text-decoration: underline;
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .view-all-link {
    margin-bottom: 0.0625rem;
    margin-right: 20%;
    display: inline-block;
    width: auto;
    text-align: right;
  }
}

#Additional_Support{
  .accordionSection-image
  {
    margin-top: 17px;
    img{
      height: 9.3125rem;
      object-fit: contain;
      margin-left: 39%;
      margin-top: 14%;
      float: none;
    }
  }
}

    