@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.search-results {
  width: 100%;
  margin: 0 auto;
  padding: 0px $spacing-5 $spacing-1;

  @media only screen and (min-width: 1200px) {
    max-width: 1178px;
  }

  h1.header-title.centered {
    margin-top: 0;
  }

  .header-search-results {
    margin-top: 50px;
    margin-bottom: 35px;
    font-size: 48px;
    color: #181818;
    font-family: $regularFont;
    font-weight: lighter;
    text-align: center;
  }

  .subHeader {
    @include fontSize-20;
    font-family: $boldFont;
    margin-bottom: 0;

    @media only screen and (min-width: $mobileView) {
      @include fontSize-26;
    }
  }

  .result-number {
    @include fontSize-16;
    margin-bottom: $spacing-base;
    font-family: $regularFont;

    @media only screen and (min-width: $mobileView) {
      @include fontSize-18;
    }
  }

  .result-link {
    @include fontSize-14;
  }

  .cardBlockComponent-title {
    border-bottom-width: 2px;
  }

  .cardBlockComponent-content {
    a {
      display: block;
      margin-top: $spacing-1;
    }
  }

  .no-results {
    @include fontSize-18;
    font-family: $boldFont;
    text-align: center;

    @media only screen and (min-width: $mobileView) {
      @include fontSize-26;
    }
  }
}
