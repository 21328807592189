@import '../../assets/scss/vars.scss';

.add-card-2 {
  @media only screen and (min-width: $mobileView) {
    width: 624px;
  }
  .add-card-content-text {
    text-align: center;
    display: block!important;
  }
}
