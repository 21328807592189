@import '../../assets/scss/vars.scss';

.top-nav-anonymous {
  width: 100%;
  position: fixed;
  background-color: $black;
  z-index: $zindex-topNavigation;

  .header {
    width: 1024px;
  }
}

.dropdownmenu-mobile-anonymous {
  background-color: $white;
  list-style: none;
  padding: 0;
  opacity: 1;
  visibility: visible;
  max-width: 100%;
  position: relative;
  display: block;
  margin-left: 72px;
  li.anonymous-card {
    margin-left: 0 !important;
    padding-left: 24px;
    font-family: $boldFont;
    font-size: 18px;
    color: #191919;

    .icon-button img {
      position: absolute;
      right: 0;
      margin-right: $spacing-1;
      width: 32px;
      height: 32px;
      top: 10px;
    }
  }
  .card-id {
    font-family: $boldFont !important;
    font-size: 18px !important;
    color: #191919;
    padding-top: 16px;
  }
  .dropdown-third-level {
    padding-left: 0;
  }
  .third-level-link {
    margin-left: 0 !important;
    a {
      font-family: $regularFont !important;
      font-size: 14px !important;
    }
  }
}
