@import '../../assets/scss/vars.scss';

.content-container-card {
  padding: 24px;
  position: relative;
  width: 500px;

  ::-ms-reveal {
    display: none;
  }

  .prestocard-text-field {
    .text-field-label {
      font-size: 14px;
      line-height: 21px;
    }
    @media only screen and (max-width: $mobileView) {
      padding-bottom: 10px;
    }
  }

  .content-title {
    display: flex;
    font-size: 22px;
    line-height: 32px;
  }

  .content-subtext {
    @media only screen and (max-width: $mobileView) {
      margin: 16px 0 40px 0;
    }
  }

  .button-wrapper {
    margin-top: 29px;
    @media only screen and (max-width: $mobileView) {
      margin-top: 37px;
    }
  }

  .presto-card-image {
    margin-top: 24px;
    margin-bottom: 117px;
    @media only screen and (max-width: $mobileView) {
      margin-right: 0;
      margin-bottom: 16px;
      margin-top: 32px;
    }
    img {
      width: 280px;
      height: 139px;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }
  .presto-card-button {
    // width: 96px;
    // margin-right: 24px;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
}
