@import '../../assets/scss/vars';

.inline-button-wrapper {
  margin-top: 16px;

  @media only screen and (min-width: $mobileView) {
    margin-top: 24px;
  }

  .inline-button {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
    color: $white;
    border: none;
    background-color: $black;
    font-family: $boldFont;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: auto;

    @media only screen and (min-width: $mobileView) {
      width: auto;
    }

    &:hover {
      cursor: pointer;
      background-color: $secondary80;
    }
  }
}
