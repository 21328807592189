@import '../../assets/scss/vars.scss';
.sitemap-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 15px;
  margin: 0 auto;
  width: 944px;
  @media only screen and (max-width: $mobileView) {
    grid-template-columns: auto;
    margin-left: 16px;
    margin-top: 32px;
    width: 90%;
  }
  h2 {
    margin-bottom: 26px;
    margin-top: 48px;
    font-size: 22px;
    line-height: 32px;
    @media only screen and (max-width: $mobileView) {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }
  ul {
    padding-left: 0px;
    li {
      list-style-type: none;
      font-size: 16px;
      line-height: 32px;
      .external-image {
        display: none;
      }
    }
  }
  .sitemap-links-list-1 {
    width: 64%;
  }
  .sitemap-links-list-2 {
    width: 54%;
  }
}
.extra-bottom-margin {
  margin-bottom: 80px;
  @media only screen and (max-width: $mobileView) {
    margin-bottom: 64px;
  }
}
