@import '../../assets/scss/vars';

.transactionHistory {
  &-grid {
    margin-top: $spacing-1;
  }

  .no-transactions {
    background-color: $white;
    padding: $spacing-3 $spacing-1;
    text-align: center;

    img {
      width: 48px;
    }

    p {
      line-height: 24px;
      color: $black;
    }
  }

  &-buttons {
    display: flex;
    margin-top: $spacing-2;
  }

  &-buttons-filters {
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      color: #191919;
    }
    @media only screen and (min-width: $mobileView) {
      margin-left: auto;
    }
  }

  &-buttons-expand {
    margin-left: auto;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      color: #191919;
    }
    @media only screen and (min-width: $mobileView) {
      margin-left: $spacing-2;
    }
  }

  &-activeFilters {
    background-color: $white;
    text-align: center;
    line-height: 24px;
    padding: $spacing-base 0;
    border: 1px solid $black;
    position: relative;
    margin-top: $spacing-1;

    &-text {
      line-height: 24px;
      margin: 0;
    }

    &-clear {
      line-height: 21px;
      font-size: 14px;
      text-decoration: underline;
      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        color: #191919;
      }
      @media only screen and (min-width: $mobileView) {
        position: absolute;
        right: $spacing-2;
        top: 10px;
      }
    }
  }
}

.Tx-history-export {
  .print-button {
    order: 0;
  }
}
