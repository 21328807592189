@import '../../assets/scss/vars.scss';

.accountSecurity-wrapper {
  background-color: $white;
  padding: $spacing-1 $spacing-1 $spacing-2;
  margin-bottom: $spacing-2;
  margin-top: 16px;
  @media only screen and (min-width: $mobileView) {
    padding: $spacing-2;
  }
  span {
    font-family: $regularFont;
    display: block;
    margin-bottom: $spacing-1;
    @media only screen and (min-width: $mobileView) {
      margin-bottom: $spacing-2;
    }
  }
}

.editSecurity-wrapper {
  margin-bottom: $spacing-2;
  margin-top: $spacing-base;
  .security-item {
    border-top: 1px solid $secondary20;
    padding-bottom: $spacing-2;
    padding-top: $spacing-2;
    &:first-of-type {
      border: none;
      padding-top: 0;
    }
    .text-field-input {
      border-bottom: 1px solid $black;
    }
    .label {
      font-family: $boldFont;
    }
  }
  .button-wrapper {
    margin-top: 0;
  }
}
