@import '../../assets/scss/vars';
@import '../../assets/scss/mixins';

.shipping-addr-block {
  padding: 18px 0px 24px 0px;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-bottom: 0;

  .shipping-header {
    font-size: 23px;
    padding: 0px 51px 21px 24px;
    border-bottom: 2px solid #d1d1d1;

    @media only screen and (max-width: $mobileView) {
      padding: 0px 21px 21px 15px;
    }
  }

  .formContent {
    display: flex;
    padding: 12px 30px 0px 30px;

    @media only screen and (max-width: $mobileView) {
      width: initial;
      display: block;
      padding: 0;
    }

    .shipping-curr-addr {
      width: 360px;
      padding-top: 15px;
      padding-left: 32px;

      .radioInput label {
        display: block;
        width: 28px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #6e6e6e;
        margin-top: 4px;

        @media only screen and (max-width: $mobileView) {
          display: block;
          width: 26px;
          height: 17px;
          border-radius: 50%;
          border: 1px solid #6e6e6e;
          margin-top: 4px;
        }
      }

      .radioInput input[type='radio']:checked + label {
        width: 11px;
        height: 7px;
        border: 7px solid #191919;
        margin-top: 4px;

        @media only screen and (max-width: $mobileView) {
          width: 9px;
          height: 6px;
          border: 7px solid #191919;
          margin-top: 6px;
        }
      }

      .radioInput-label {
        padding-right: 40px;
        font-size: 18px;

        @media only screen and (max-width: $mobileView) {
          padding-right: 0;
          font-size: 21px;
          padding-left: 6px;
        }
      }

      .addressLine {
        width: 252px;
        margin-left: 32px;
        margin-top: 16px;

        @media only screen and (max-width: $mobileView) {
          width: auto;
          margin-left: 36px;
          margin-top: 28px;
        }
      }

      .addressLineFR {
        margin-top: 36px;
        @media only screen and (max-width: $mobileView) {
          margin-top: 28px;
        }
      }

      .shipping-curr-details {
        padding-left: 32px;
        padding-top: 10px;
        padding-right: 40px;
        font-family: 'AvenirNextLTPro-Regular';
        font-size: 18px;

        p {
          margin: 0;
        }

        @media only screen and (max-width: $mobileView) {
          padding-top: 18px;
          padding-left: 36px;
          font-size: 21px;
        }
      }

      @media only screen and (max-width: $mobileView) {
        width: auto;
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 21px;
      }
    }

    .shipping-diff-addr {
      width: 360px;
      padding-top: 15px;

      @media only screen and (max-width: $mobileView) {
        width: auto;
        display: block;
        padding-top: 2.5rem;
        padding-left: 15px;
        padding-right: 15px;
      }

      .radioInput label {
        display: block;
        width: 32px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #6e6e6e;
        margin-top: 4px;

        @media only screen and (max-width: $mobileView) {
          display: block;
          width: 32px;
          height: 18px;
          border-radius: 50%;
          border: 1px solid #6e6e6e;
          margin-top: 4px;
        }
      }

      .radioInput input[type='radio']:checked + label {
        width: 11px;
        height: 6px;
        border: 7px solid #191919;
        margin-top: 4px;

        @media only screen and (max-width: $mobileView) {
          width: 9px;
          height: 6px;
          border: 7px solid #191919;
          margin-top: 6px;
        }
      }

      .radioInput-label {
        padding-right: 40px;
        font-size: 18px;

        @media only screen and (max-width: $mobileView) {
          padding-right: 0;
          font-size: 21px;
          padding-left: 6px;
        }
      }

      .addressLine {
        width: 252px;
        margin-top: 16px;
        margin-left: 32px;

        @media only screen and (max-width: $mobileView) {
          width: auto;
          margin-left: 32px;
          margin-top: 28px;
        }
      }

      .shipping-diff-details {
        padding-left: 32px;
        padding-top: 10px;
        padding-right: 40px;
        font-family: 'AvenirNextLTPro-Regular';
        font-size: 18px;

        @media only screen and (max-width: $mobileView) {
          width: auto;
          padding-top: 18px;
          padding-left: 36px;
          font-size: 21px;
        }
      }
    }
  }

  .shipping-details-header {
    font-size: 22px;
    line-height: 32px;
    padding-left: 24px;
    padding-bottom: 14px;
    border-bottom: 1px solid #d1d1d1;
  }

  .shipping-title {
    font-family: 'AvenirNextLTPro-Regular';
    padding: 24px 0px 24px 24px;
    font-size: 18px;
    line-height: 28px;
  }

  .required {
    font-family: 'AvenirNextLTPro-Regular';
    padding-left: 24px;
    font-size: 14px;
    line-height: 21px;
  }
}

.shipping-details-content {
  .shipping-details-form {
    background-color: white;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 48px;
    border: 1px solid #e8e8e8;
    border-top: 0;
  }

  .shipping-username {
    display: flex;
    // width: 450px;
    margin: 0rem 0rem 32px 0rem;

    @media only screen and (max-width: $mobileView) {
      width: initial;
      display: block;
    }

    .first-name {
      width: 280px;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }
      .first-name-label {
        font-size: 14px;
        line-height: 21px;
      }
      .first-name-field {
        margin-top: -25px;

        @media only screen and (max-width: $mobileView) {
          margin-top: -18px;
        }
      }
    }

    .last-name {
      width: 304px;
      margin-left: 1rem;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }
      .last-name-label {
        font-size: 14px;
        line-height: 21px;
      }
      .last-name-field {
        margin-top: -25px;

        @media only screen and (max-width: $mobileView) {
          margin-top: -18px;
        }
      }

      @media only screen and (max-width: $mobileView) {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }

  .shipping-address {
    display: flex;

    // width: 450px;
    @media only screen and (max-width: $mobileView) {
      width: initial;
      display: block;
    }

    .street-add {
      width: 440px;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }
      .street-add-label {
        font-size: 14px;
        line-height: 21px;
      }
      .street-add-field {
        margin-top: -25px;

        @media only screen and (max-width: $mobileView) {
          margin-top: -18px;
        }
      }
    }

    .apartment {
      margin-left: 1rem;
      width: 144px;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }
      .apartment-label {
        font-size: 14px;
        line-height: 21px;
      }
      .apartment-field {
        margin-top: -25px;

        @media only screen and (max-width: $mobileView) {
          margin-top: -18px;
        }
      }

      @media only screen and (max-width: $mobileView) {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }

  .shipping-province {
    display: flex;
    margin: 32px 0rem;

    @media only screen and (max-width: $mobileView) {
      width: initial;
      display: block;
    }

    .city {
      width: 280px;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }
      .city-label {
        font-size: 14px;
        line-height: 21px;
      }
      .city-field {
        margin-top: -25px;

        @media only screen and (max-width: $mobileView) {
          margin-top: -18px;
        }
      }
    }

    .province {
      margin-left: 1rem;
      width: 224px;

      .province-label {
        font-size: 14px;
        line-height: 21px;
      }
      .province-field {
        margin-top: 7px;

        @media only screen and (max-width: $mobileView) {
          margin-top: 6px;
          width: initial;

          .selectbox {
            .mobileSelect {
              color: inherit;
            }
          }
        }
      }

      @media only screen and (max-width: $mobileView) {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }

  .shipping-country-code {
    display: flex;

    @media only screen and (max-width: $mobileView) {
      width: initial;
      display: block;
    }

    .country {
      width: 200px;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }
      .country-label {
        font-size: 14px;
        line-height: 21px;
      }
      .country-field {
        margin-top: -25px;

        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          input:disabled {
            opacity: 1;
          }
          .text-field-input {
            color: text;
          }
        }

        @media only screen and (max-width: $mobileView) {
          margin-top: -18px;
        }
      }
    }

    .postal-code {
      margin-left: 1rem;
      width: 200px;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }
      .postal-code-label {
        font-size: 14px;
        line-height: 21px;
      }
      .postal-code-field {
        margin-top: -25px;

        @media only screen and (max-width: $mobileView) {
          margin-top: -18px;
        }
      }

      @media only screen and (max-width: $mobileView) {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }

  .PostalCodeNote {
    margin: 6px 0px 0px 10px;
    font-family: 'AvenirNextLTPro-Regular';
    font-size: 14px;
  }
}

.checkout {
  .Deactivated-Button {
    justify-content: end;

    @media only screen and (max-width: $mobileView) {
      width: auto;
      padding-right: 0;
    }
  }
  .checkout-note {
    // margin-bottom: 32px;
    // margin-top: 32px;
  }
}

.note {
  margin-top: 16px;
}
