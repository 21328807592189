@import './vars.scss';

.wrapper {
  flex: 1 1 auto;
  background-color: gray;
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 20px;
  padding-right: 20px;
}

.sidebar {
  width: 240px;
  flex: 0 0 240px;
  min-height: 718px;
  background-color: $secondary5;
  position: relative;
}

.content {
  padding: 24px 24px 150px 24px;
  position: relative;
}

.content-subtext {
  margin: 16px 0 24px 0;
  line-height: 24px;
  font-family: $regularFont;
}

.content-title {
  font-size: 22px;
}

.error {
  .error-message {
    display: block;
  }
}

.error-message {
  font-size: 14px;
  color: $feedbackError;
  padding-left: 35px;
  background: url('../images/error.svg') no-repeat 13px center;
  background-size: 16px;
  margin-top: 11px;
  display: none;
  background-position-y: top;
  font-family: $boldFont;
}

.mobiles-sidebar-title {
  display: none;
}

@media screen and (max-width: $mobileView) {
  .content-error-block-title {
    font-size: 16px;
  }

  .content-title {
    font-size: 20px;
  }

  .wrapper {
    background: none;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    min-height: 0;
    width: 100%;
    flex-grow: 1;
  }

  .mobiles-sidebar-title {
    display: block;
    padding: 16px 16px 10px 16px;
    font-size: 22px;
    color: $black;
    line-height: 32px;
  }

  .footer-copyright {
    display: none;
  }

  .footer-logo {
    margin: 0 auto;
  }

  .main-header-logo {
    width: 113px;
    height: 30px;
  }

  .contentState {
    .content {
      display: block;
    }

    .sidebar {
      display: none;
    }

    .main-header-logo {
      display: none;
    }

    .main-header {
      justify-content: left;
    }
  }
}
