@import '../../assets/scss/vars';
.contactless-trans-faq-nav {
  .side-menu {
    font-family: 'AvenirNextLTPro-Regular';
    .side-menu-items {
      ul {
        margin: 0px 0px 0px 15px;
        padding: 0px;
        li {
          margin: 12px 0px;
          a {
            font-family: 'AvenirNextLTPro-Regular';
            font-size: 14px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $mobileView) {
    display: none;
  }
}
