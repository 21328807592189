@import '../../assets/scss/vars.scss';

.transitusagereport-wrapper {
  margin-top: 20px;
  //width: 90%;
  // @media only screen and (max-width: $mobileView) {
  //   width: 91%;
  //   margin-left: 20px;
  // }
  .transitusage-note {
    margin-bottom: 20px;
  }
  // .after-note{
  //     // padding-top: 20px;
  // }
  .getreports-section {
    padding: 10px 5px;
    .transitusage-subnote {
      font-family: $regularFont;
      margin-bottom: 20px;
    }
    .dropdown-labeltexts {
      #selectyearlabel {
        margin-right: 100px;
      }
    }
    .reports-selection {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #e8e8e8;
      padding-bottom: 30px;
      @media only screen and (max-width: $mobileView) {
        display: block;
      }
    }
    .selectdropdown1,
    .selectdropdown2 {
      width: 34%;
      height: 48px;
      // text-align: center;
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        margin-top: 6px;
        width: initial;
      }
      .mobileSelect {
        /* CSS specific to iOS devices */
        color: #181818;
      }
    }
    .selectdropdown2 {
      .selectbox {
        @media only screen and (max-width: $mobileView) {
          margin-top: 25px;
        }
      }
    }
    button {
      display: inline-block;
    }
  }
  #reportavailable-label {
    margin-top: 20px;
    .button-label {
      padding-top: 20px;
      display: flex;
      @media only screen and (max-width: $mobileView) {
        display: block;
      }
      .export-csv {
        .export-csv-label {
          @media only screen and (max-width: $mobileView) {
            @supports (-webkit-touch-callout: none) {
              /* CSS specific to iOS devices */
              color: #191919;
            }
          }
        }
      }
      .download-pdf {
        @media only screen and (max-width: $mobileView) {
          @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */
            color: #191919;
          }
          margin-bottom: 10px;
        }
        .download-text {
          text-decoration: none;
        }
      }
      .csv-label {
        text-decoration: none;
        padding-left: 20px;
      }
    }
  }
  .availablereports-section {
    margin-top: 20px;
    padding: 10px 5px;

    .transitusage-endnote {
      font-family: $regularFont;
    }
  }
  .tur-note {
    .note {
      margin-bottom: 16px;
    }
    .withIcon {
      background-position: 17px 17px;
    }
  }
}
.show-results {
  margin-top: 30px;
}
.get-report-button {
  margin-top: 31px;
  @media only screen and (max-width: $mobileView) {
    margin-top: 10px;
  }
}
#reportavailable-label {
  font-family: $regularFont;
  margin-bottom: 5px;
  .button-label {
    display: flex;
    @media only screen and (max-width: $mobileView) {
      display: block;
    }
    .export-csv {
      margin-left: 20px;
      @media only screen and (max-width: $mobileView) {
        margin-left: 0;
      }
      .export-csv-label {
        @media only screen and (max-width: $mobileView) {
          @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */
            color: #191919;
          }
        }
      }
    }
  }
}

.transitusagewrapper-fr {
  margin-top: 20px;
  width: 109%;
  @media only screen and (max-width: $mobileView) {
    width: 91%;
    margin-left: 20px;
  }

  .tur-note {
    .note {
      margin-bottom: 16px;
    }
    .withIcon {
      background-position: 17px 17px;
    }
  }
  .transitusage-subnote {
    margin-bottom: 20px;
  }
  .reports-selection {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 30px;
    @media only screen and (max-width: $mobileView) {
      display: block;
    }
    .selectdropdown1 {
      width: 31%;
      margin-right: 21px;
      font-family: $boldFont;
      .selectbox {
        .label {
          margin-bottom: 32px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 8px;
          }
        }
        .mobileSelect {
          /* CSS specific to iOS devices */
          color: #181818;
        }
      }
    }
    .selectdropdown2 {
      width: 30%;
      margin-right: 21px;
      font-family: $boldFont;
      .mobileSelect {
        /* CSS specific to iOS devices */
        color: #181818;
      }
    }
    .get-report-button {
      width: 33%;
      margin-top: 55px;
      @media only screen and (max-width: $mobileView) {
        display: contents;
      }
      .buttonComponent {
        margin-top: 0;
      }
    }
  }
  #reportavailable-label {
    margin-bottom: 5px;
    .button-label {
      margin-top: 10px;
      a {
        text-decoration: none;
      }
    }
  }
  .transitusage-endnote {
    font-family: $regularFont;
  }
}
.downloadpdf-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 2px solid #e8e8e8;
  @media only screen and (max-width: $mobileView) {
    display: block;
  }
}
.disable-border {
  border-bottom: none;
}
