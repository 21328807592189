@import '../../assets/scss/vars';

.icon-button {
  display: flex;
  align-items: center;
  color: #000;
  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
}
