@import '../../assets/scss/vars.scss';

.block-title,
.content-text {
  margin: 0 $spacing-base;

  h2 {
    margin: 0;
  }
}

.saved-payment {
  border-top: 1px solid $secondary20;

  &-success {
    margin-bottom: $spacing-1;
    .success-block {
      padding-left: 16px;
      @media only screen and (max-width: $mobileView) {
        padding: 24px 16px 24px 8px;
      }
    }
  }

  &-error {
    .content-error-block-title {
      margin-bottom: 0;
    }
  }
}

.content-error-block.show-failed-icon {
  padding: 24px 0 24px 16px;
  @media only screen and (max-width: $mobileView) {
    padding: 28px 16px 28px 8px;
  }

  .content-error-block-title {
    line-height: 2rem;
    background: url('../../assets/images/Failed.svg') no-repeat left top;
    padding-left: 48px;
    padding-top: 0px;
    font-size: 20px;
    @media only screen and (max-width: $mobileView) {
      padding-left: 40px;
      font-size: 22px;
    }
  }
}

.remove-saved-payment-success {
  .success-block .success-context-text {
    font-size: $spacing-1;

    @media only screen and (min-width: $mobileView) {
      font-size: 14px;
    }
  }
}

.saved-payment-method-title {
  margin-bottom: $spacing-1;
}

.saved-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 11px;
}

.saved-card-title {
  .titleWithIcon-title {
    color: #191919;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0 8px;
  }
}

.remove-card {
  font-family: $boldFont;
  color: #3b8120;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
  height: 24px;
}

hr {
  border-bottom: 1px #bababa;
}

.max-cards-reached-btn {
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  color: $white;
  border: 1px solid #d1d1d1;
  background-color: #d1d1d1;
  font-family: $boldFont;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 16px;
  line-height: 24px;
  border-radius: 0;

  &:hover {
    cursor: pointer;
    background-color: $secondary80;
  }

  @media only screen and (min-width: $mobileView) {
    width: auto;
    min-width: 106px;
    margin-top: 0;
  }
}
