@import '../../assets/scss/vars.scss';

.orderacard-wrapper {
  width: auto;

  .h2-title {
    font-size: 48px;
    line-height: 72px;
    margin-bottom: 12px;
  }

  .orderacard-purchasenote {
    border: 1px solid #e8e8e8;
    margin-bottom: 15px;
    background-color: $white;
    padding-bottom: 15px;

    .orderacard-warning-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 15px;

      @media only screen and (max-width: $mobileView) {
        flex-direction: column-reverse;
      }

      .orderacard-return {
        margin-left: 10px;
      }

      .orderacard-checkout {
        margin-left: 10px;
      }
    }

    .orderacard-purchase-title {
      padding: 16px 0px 16px 24px;
      margin: 0px;
      font-size: 18px;
      line-height: 28px;
    }

    .orderacard-purchaseinfo {
      font-family: $regularFont;
      border-top: 1px solid #e8e8e8;
      padding: 16px 24px 32px 24px;
      margin: 0px;
      font-size: 16px;
      line-height: 24px;
    }

    .orderacard-learnmore {
      text-decoration: underline;
      font-family: $boldFont;
    }
  }

  .orderacard-note {
    border: 1px solid #e8e8e8;
    margin-bottom: 25px;
    font-family: $regularFont;
    border-left: 4px solid #8bb8e8;
    padding: 16px 32px 24px 24px;
    background-color: $white;
    font-size: 16px;
    line-height: 24px;

    p:first-child {
      margin-top: 0;
    }

    .orderacard-text {
      font-family: $boldFont;
    }
  }

  .orderacard-newprestoinfo {
    .orderacard-description {
      border: 1px solid #e8e8e8;
      margin-bottom: 15px;
      margin-top: 10px;
      font-family: $regularFont;
      background-color: $white;

      .orderacard-newcardinfo {
        border-bottom: 1px solid #e8e8e8;
        padding: 24px 24px 12px 24px;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
          flex-direction: column;
        }

        .orderacard-leftinfo {
          display: inline-flex;
          align-items: center;

          .prestoimage {
            width: 42px;
            height: 26.25px;

            @media only screen and (max-width: 768px) {
              display: none;
            }
          }

          .newpresto-subtitle {
            font-family: $boldFont;
            margin-left: 32px;
            font-size: 18px;
            line-height: 28px;

            @media only screen and (max-width: 768px) {
              margin-left: 0px;
              margin-bottom: 8px;
            }
          }
        }

        .orderacard-rightinfo {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          @media only screen and (max-width: 768px) {
            align-items: flex-start;
          }

          .orderacard-priceValue {
            font-family: $boldFont;
          }
        }
      }

      .orderacard-newcard-desc {
        padding: 12px 24px 32px 24px;
        font-size: 16px;
        line-height: 24px;

        p {
          margin: 0px;
        }
      }
    }
  }

  .orderacard-links {
    font-family: 'AvenirNextLTPro-Regular', sans-serif;
  }
}

@media only screen and (min-width: 920px) {
  .orderacard-wrapper {
    width: 624px;
  }
}

.orderacard-warning-note {
  border: 1px solid #e8e8e8;
  margin-bottom: 25px;
  border-left: 4px solid #8bb8e8;
  padding: 15px;
  background-color: #fff;

  .orderacard-warning-title {
    font-size: 18px;
  }

  .orderacard-purchase-warning {
    font-family: 'AvenirNextLTPro-Regular', sans-serif;
  }

  .orderacard-warning-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media only screen and (max-width: $mobileView) {
      flex-direction: column-reverse;
    }

    .orderacard-return {
      margin-left: 10px;
    }

    .orderacard-checkout {
      margin-left: 10px;
    }
  }
}