@import '../../assets/scss/vars.scss';

.instruction__container {
  text-align: left;
  margin: 0 1.5rem;

  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 10%;
    margin-left: 0;
  }

  @media only screen and (min-width: 1024px) {
    margin-right: 13%;
  }

  .instructionimage {
    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
    }

    img {
      position: relative;
      width: auto;

      @media only screen and (max-width: 768px) {
        width: 66%;
        height: auto;
      }
    }
  }

  .text-container {
    @media only screen and (min-width: 1024px) {
      padding-right: 22%;
    }

    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      padding-right: 12%;
    }

    .titleinstruction {
      @media only screen and (max-width: 768px) {
        margin-top: 2rem;
        margin-bottom: 1rem;
        line-height: 2.5rem;
        justify-content: center;
        text-align: center;
      }
    }

    .display-4 {
      @media only screen and (min-width: $mobileView) {
        position: relative;
        text-align: left;
        font-size: 2rem;
        line-height: 3rem;
        margin-top: 0;
        margin-bottom: 1rem;
      }

      @media only screen and (max-width: $mobileView) {
        margin: 0;
      }
    }

    .instructionContainer {
      margin-bottom: 6rem;
      font-size: 1.375rem;
      line-height: 2rem;
      text-align: left;

      @media only screen and (max-width: 768px) {
        margin-top: 1em;
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: center;
      }
    }
  }
}

.instruction_Container_Vertical {
  margin: 0 1.5rem -0.3;

  .instructionimage {
    padding-top: 2.125rem;
    height: 6.875rem;
    width: auto;
    text-align: left;

    @media (max-width: 768px) {
      padding-top: 2.125rem;
      height: 6.875rem;
      width: auto;
      text-align: left;
    }
  }

  .display-4 {
    margin: 1.5rem 0 1rem 0;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    font-weight: 700;
    font-family: $boldFont;

    @media (max-width: 768px) {
      margin: 1.5rem 0 1rem 0;
      font-size: 1.125rem;
      line-height: 1.6875rem;
      font-weight: 700;
    }
  }

  .instructionContainer {
    font-size: 1.125rem;
    line-height: 1.75rem;

    @media (max-width: 768px) {
      .instructionContainer {
        font-size: 1.125rem;
        line-height: 1.75rem;
      }
    }
  }
}

.presto-img {
  background-color: #f3f3f3;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: $mobileView) {
    padding-top: 0px;
  }

  .instructionimage {
    display: block;
    max-width: 1440px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
      display: flex;
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    img {
      width: 100%;
      height: 100%;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .text-container {
    margin-top: 48px;
    margin-bottom: 48px;

    @media only screen and (max-width: $mobileView) {
      margin: 32px 16px;
    }

    .display-4 {
      font-size: 26px;
      color: #191919;
      display: block;
      font-weight: 500;
      line-height: 40px;
      font-family: $boldFont;
      margin: 0 auto 24px;
      width: 944px;

      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 16px;
        width: 100%;
      }
    }

    .instructionContainer {
      margin: 0 auto;
      width: 944px;

      p {
        width: 70%;
        font-size: 16px;
        color: #191919;
        line-height: 24px;
        display: block;
        margin-top: 18px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: $regularFont;

        @media only screen and (max-width: $mobileView) {
          width: 100%;
          font-size: 16px;
          line-height: 24px;
        }

        a {
          color: #191919;
          cursor: pointer;
          font-family: $boldFont;
          text-decoration: underline;
        }
      }

      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }
}

.main-content {
  .presto-ttc-image {
    background-color: #fafafa;
    padding-bottom: 60px;
    max-width: 1178px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;

    @media only screen and (max-width: $mobileView) {
      display: flex;
      padding-bottom: 20px;
      max-width: 100%;
    }

    .instructionimage {
      float: left;
      width: 33.33%;
      position: relative;
      padding-left: 15px;
      padding-right: 15px;

      @media only screen and (max-width: $mobileView) {
        display: none !important;
      }

      img {
        max-width: 100%;
        display: block;
        max-height: fit-content;
        object-fit: contain;
      }
    }

    .text-container {
      left: 80px;
      font-family: $regularFont;

      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        width: 100%;
        padding-right: 15px;
        float: left;
        position: unset;
      }

      .display-4 {
        font-size: 2.2rem !important;
        color: #181818;
        display: block;
        font-weight: 600;

        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 1.1;
      }

      .instructionContainer {
        width: 58%;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }

        p {
          font-size: 1.6rem;
          margin-bottom: 0.7rem;
          color: #666;
          width: auto;
          line-height: 2em;
          display: block;
          margin-top: 0 !important;
          font-family: $regularFont;

          @media only screen and (max-width: $mobileView) {
            text-align: left;
          }

          a {
            color: #3a811e;
            cursor: pointer;
            font-family: $regularFont;
          }
        }

        ul {
          margin-top: 0;
          display: block;
          list-style-type: disc;

          @media only screen and (max-width: $mobileView) {
            text-align: left;
          }

          li {
            color: #666;
            font-family: $regularFont;
            font-size: 1.6rem;
            margin-bottom: 0.7rem;
            display: list-item;
            text-align: -webkit-match-parent;

            a {
              color: #337ab7;
              text-decoration: none;
              font-family: $regularFont;
            }

            a:focus,
            :hover {
              color: #23527c;
              text-decoration: underline;
            }

            a:active,
            :hover {
              outline: 0;
            }
          }
        }
      }
    }
  }
}

.imageparagraph1-etickets {
  background-color: #f3f3f3;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: $mobileView) {
    padding-top: 0px;
  }

  .arrow-icon {
    display: flex;

    .instructionimage {
      padding-top: 2px;
      margin: 0;
    }

    img {
      width: 100%;
    }

    .link {
      margin-top: 0px !important;
      padding-left: 10px;
    }

    a {
      font-size: 17px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .instructionimage {
    display: block;
    max-width: 1440px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
      display: flex;
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    img {
      width: 100%;
      height: 100%;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .text-container {
    width: 944px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 34px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 16px;
      padding-top: 20px;
      width: unset;
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 16px;
    }

    .display-4 {
      font-size: 26px;
      color: #191919;
      display: block;
      font-weight: 500;
      line-height: 1.1;
      font-family: $boldFont;
      margin-top: 20px;
      margin-bottom: 10px;

      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
        line-height: 32px;
      }
    }

    .instructionContainer {
      p {
        width: 65%;
        font-size: 16px;
        color: #191919;
        line-height: 1.5em;
        display: block;
        margin-top: 18px;
        font-family: $regularFont;

        @media only screen and (max-width: $mobileView) {
          width: unset;
          font-size: 16px;
          line-height: 24px;
        }

        a {
          color: #191919;
          cursor: pointer;
          font-family: $boldFont;
          text-decoration: underline;
        }
      }
    }
  }
}

.row {
  @media only screen and (max-width: $mobileView) {
    display: inline-block !important;
  }
}

.column {
  max-width: 25%;

  @media only screen and (max-width: $mobileView) {
    max-width: 100%;
  }
}

.imageparagraph2-etickets {
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #dadada;
  height: 360px;
  margin-bottom: 25px;

  @media only screen and (max-width: $mobileView) {
    height: auto;
  }

  .instructionimage {
    display: block;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (max-width: $mobileView) {
      margin-bottom: 20px;
      display: flex;
      max-width: 100%;
      padding-left: 60px;
      padding-right: 0 !important;

      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        padding-left: 0;
      }
    }

    img {
      display: block;
      max-width: 100%;
      object-fit: contain;
      height: 168px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 200px;
      }
    }
  }

  .text-container {
    padding-right: 15px;
    padding-left: 15px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 22px;
      padding-right: 22px;
    }

    .display-4 {
      display: block;
      font-family: $regularFont;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #191919;
      margin-top: 0;
      line-height: 1.6em;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .instructionContainer {
      p {
        font-size: 16px;
        color: #191919;
        line-height: 22px;
        display: block;
        margin: 0 0 10px;
        font-family: $regularFont;

        @media only screen and (max-width: $mobileView) {
          text-align: left;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.new-mainContent {
  .findoutlet-image {
    @media only screen and (max-width: $mobileView) {
      display: flex;
      flex-direction: column-reverse;
    }

    .text-container {
      .instructionContainer {
        width: 55%;
        font-size: 16px;
        display: block;
        color: #191919;
        font-family: $regularFont;

        @media only screen and (max-width: $mobileView) {
          width: 100%;
        }

        p {
          line-height: 24px;
          margin-top: 0;
        }

        ul {
          margin-top: 0;
          list-style-type: disc;
          line-height: 32px;

          @media only screen and (max-width: $mobileView) {
            line-height: 21px;
          }
        }
      }
    }

    .instructionimage {
      float: right;
      margin-bottom: 64px;

      @media only screen and (max-width: $mobileView) {
        float: none;
        display: contents;
        margin-bottom: 0px;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;

        @media only screen and (max-width: $mobileView) {
          margin-left: 48px;
          margin-top: 16px;
          width: 68%;
        }
      }
    }

  }

  .findoutlet-iconimage1 {
    padding-top: 5px;
    padding-left: 18px;
    display: flex;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    width: 98%;
    margin-bottom: 30px;

    @media only screen and (max-width: $mobileView) {
      width: 95%;
      padding-top: 11px;
      margin-bottom: 0;
    }

    .instructionContainer {
      padding-top: 7px;
      padding-bottom: 32px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-family: $regularFont;
        color: #191919;
        margin-top: 8px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .anchor-links {
        color: #191919;
        text-decoration: underline;
        font-family: $boldFont;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: -3px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 13px;
        }
      }
    }

    ul {
      margin-top: 0;
      list-style-type: disc;
      line-height: 2.6rem;
    }

    .instructionimage {
      display: flex;
      padding-top: 15px;

      img {
        height: 39px;
        width: 38px;
        margin-right: 20px;

        @media only screen and (max-width: $mobileView) {
          height: 23px;
          width: 32px;
          margin-right: 13px;
        }
      }
    }
  }

  .findoutlet-iconimage2 {
    padding-top: 5px;
    padding-left: 18px;
    display: flex;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    width: 98%;
    margin-bottom: 17px;

    @media only screen and (max-width: $mobileView) {
      width: 95%;
      padding-top: 11px;
    }

    .instructionContainer {
      padding-top: 8px;
      padding-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 2px;
        padding-bottom: 0px;
        padding-right: 16px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-family: $regularFont;
        color: #191919;
        line-height: 1.5rem;
        padding-right: 5px;
        margin-top: 8px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .anchor-links {
        color: #191919;
        text-decoration: underline;
        font-family: $boldFont;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: -3px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 13px;
        }
      }
    }

    ul {
      margin-top: 0;
      list-style-type: disc;
      line-height: 2.6rem;
    }

    .instructionimage {
      display: flex;
      padding-top: 20px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 14px;
      }

      img {
        height: 27px;
        width: 38px;
        margin-right: 20px;

        @media only screen and (max-width: $mobileView) {
          height: 23px;
          width: 32px;
          margin-right: 13px;
        }
      }
    }
  }

  .findoutlet-iconimage3 {
    padding-top: 5px;
    padding-left: 18px;
    display: flex;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    width: 98%;
    margin-bottom: 17px;

    @media only screen and (max-width: $mobileView) {
      width: 95%;
      padding-top: 11px;
      padding-left: 16px;
    }

    .instructionContainer {
      padding-bottom: 34px;
      padding-top: 5px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 16px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-family: $regularFont;
        color: #191919;
        margin-top: 8px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .anchor-links {
        color: #191919;
        text-decoration: underline;
        font-family: $boldFont;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: -3px;

        @media only screen and (max-width: $mobileView) {
          margin-top: 13px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    ul {
      margin-top: 0;
      list-style-type: disc;
      line-height: 2.6rem;
    }

    .instructionimage {
      display: flex;
      padding-top: 20px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 14px;
      }

      img {
        height: 27px;
        width: 38px;
        margin-right: 20px;

        @media only screen and (max-width: $mobileView) {
          height: 23px;
          width: 32px;
          margin-right: 13px;
        }
      }
    }
  }

  .findoutlet-iconimage4 {
    padding-top: 5px;
    padding-left: 18px;
    display: flex;
    border: 1px solid #dadada;
    background-color: #ffffff;
    width: 98%;
    margin-bottom: 17px;

    @media only screen and (max-width: $mobileView) {
      width: 95%;
      padding-top: 11px;
    }

    .instructionContainer {
      padding-top: 6px;
      padding-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-family: $regularFont;
        color: #191919;
        width: 90%;
        margin-top: 8px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
          width: 93%;
        }
      }

      .anchor-links {
        color: #191919;
        text-decoration: underline;
        font-family: $boldFont;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: -3px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 13px;
        }
      }
    }

    ul {
      margin-top: 0;
      list-style-type: disc;
      line-height: 2.6rem;
    }

    .instructionimage {
      display: flex;
      padding-top: 20px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 14px;
      }

      img {
        height: 27px;
        width: 38px;
        margin-right: 20px;

        @media only screen and (max-width: $mobileView) {
          height: 23px;
          width: 32px;
          margin-right: 13px;
        }
      }
    }
  }

  .findoutlet-iconimage5 {
    padding-top: 5px;
    padding-left: 18px;
    display: flex;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    width: 98%;
    margin-bottom: 17px;

    @media only screen and (max-width: $mobileView) {
      width: 95%;
      padding-top: 11px;
    }

    .instructionContainer {
      padding-top: 6px;
      padding-bottom: 33px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 16px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-family: $regularFont;
        color: #191919;
        margin-top: 8px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
          padding-left: 1px;
        }
      }

      .anchor-links {
        color: #191919;
        text-decoration: underline;
        font-family: $boldFont;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: -3px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 13px;
        }
      }
    }

    ul {
      margin-top: 0;
      list-style-type: disc;
      line-height: 2.6rem;
    }

    .instructionimage {
      display: flex;
      padding-top: 20px;

      @media only screen and (max-width: $mobileView) {
        padding-top: 14px;
      }

      img {
        height: 27px;
        width: 38px;
        margin-right: 20px;

        @media only screen and (max-width: $mobileView) {
          height: 23px;
          width: 32px;
          margin-right: 13px;
        }
      }
    }
  }
}

.main-content {
  .icon {
    display: flex;
    width: 50%;

    @media only screen and (max-width: $mobileView) {
      padding-left: 75px;
    }

    .instructionimage {
      position: absolute;
      right: 160px;
      padding-right: 200px;

      @media only screen and (max-width: $mobileView) {
        padding-right: 0px;
        right: 0px;
        position: static;
      }
    }

    .text-container {
      .display-4 {
        position: absolute;
        right: 165px;
        padding-right: 150px;
        padding-top: 15px;
        font-family: $boldFont;
        margin: 0;

        @media only screen and (max-width: $mobileView) {
          position: static;
          padding-left: 3px;
          font-size: 1.5rem;
          padding-top: 10px;
        }
      }
    }
  }

  .icons {
    display: flex;

    width: 50%;

    @media only screen and (max-width: $mobileView) {
      position: relative;
      bottom: 54px;
      padding-left: 200px;
    }

    .instructionimage {
      position: absolute;
      right: 100px;
      padding-right: 140px;

      @media only screen and (max-width: $mobileView) {
        padding-right: 0px;
        right: 0px;
        position: static;
      }
    }

    .text-container {
      .display-4 {
        position: absolute;
        right: 100px;
        padding-right: 100px;
        padding-top: 15px;
        margin: 0;

        @media only screen and (max-width: $mobileView) {
          position: static;
          padding-left: 5px;
          font-size: 1.5rem;
          padding-top: 10px;
        }
      }
    }
  }
}

.take-our-survey-image {
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  width: 624px;
  position: relative;
  margin-bottom: 80px;

  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-right: 16px;
    width: unset;
    position: static;
    margin-bottom: 64px;
  }

  .instructionimage {
    display: block !important;

    img {
      width: 100%;

      @media only screen and (max-width: $mobileView) {
        height: 176px;
      }
    }
  }

  .text-container {
    padding: 16px 60px 60px 60px;

    @media only screen and (max-width: $mobileView) {
      padding: 24px 30px 15px 30px;
    }

    p {
      font-family: $regularFont;
      font-size: 16px;
      color: #191919;
      line-height: 24px;
      padding-bottom: 10px;
      width: 98%;

      @media only screen and (max-width: $mobileView) {
        margin-top: 0px;
        width: 100%;
      }
    }

    h2 {
      display: none;
    }

    .Button {
      text-align: center;
      padding-top: 30px;

      @media only screen and (max-width: $mobileView) {
        padding-bottom: 32px;
        padding-top: 12px;
      }

      a {
        background: black;
        color: white;
        text-decoration: none;
        padding: 12px 16px;

        @media only screen and (max-width: $mobileView) {
          padding-left: 75px;
          padding-right: 75px;
        }
      }
    }

    .Button-french {
      text-align: center;
      padding-top: 30px;

      @media only screen and (max-width: $mobileView) {
        padding-bottom: 60px;
      }

      a {
        background: black;
        color: white;
        text-decoration: none;
        padding: 15px;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}

.customer-charter-image {
  margin: 80px auto;
  width: 944px;

  img {
    width: 944px;
  }

  @media only screen and (max-width: $mobileView) {
    display: none;
  }
}

.self-serve-reload-content {
  font-family: $regularFont;
  position: relative;
  line-height: 1.5rem;
  margin-bottom: 16px;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 32px;
  }

  .instructionimage {
    position: absolute;
    right: 0px;
    margin-top: 15px;
    width: 40%;

    @media only screen and (max-width: $mobileView) {
      position: static;
      margin-left: auto;
      margin-right: auto;
      width: 192px;
    }

    img {
      width: 248px;

      @media only screen and (max-width: $mobileView) {
        width: 192px;
        height: 368px;
      }
    }
  }

  .instructionContainer {
    width: 54%;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }

    .selfserve-audio-support-para {
      margin-right: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-right: 0px;
      }
    }
  }

  ul {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 26px;
    line-height: 2rem;

    @media only screen and (max-width: $mobileView) {
      line-height: 21px;
    }

    li {
      margin-bottom: 5px;
      padding-left: 10px;

      @media only screen and (max-width: $mobileView) {
        line-height: 1.3rem;
        margin-bottom: 8px;
      }
    }
  }
}

.imagewithpara-get-presto {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: 944px;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    width: unset;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      background-color: #ffffff;
    }

    img {
      width: 464px;
      height: 168px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .text-container {
    border: 1px solid #dadada;
    width: 480px;
    background-color: #fff;

    @media only screen and (max-width: $mobileView) {
      width: unset;
      border-left: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      border-right: 1px solid #dadada;
      border-top: 0px solid #dadada;
      height: auto;
    }

    .display-4 {
      margin-top: 24px;
      margin-bottom: 8px;
      padding-left: 30px;
      font-family: $boldFont;
      font-size: 16px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .instructionContainer {
      padding-left: 30px;
      padding-right: 10px;
      font-size: 16px;
      color: #191919;

      @media only screen and (max-width: $mobileView) {
        padding-left: 16px;
        padding-right: 16px;
        width: -webkit-fill-available;
      }

      .para-with-link {
        a {
          @media only screen and (max-width: $mobileView) {
            float: none;
          }
        }
      }

      p {
        font-family: $regularFont;
        font-size: 16px;
        line-height: 24px;
        color: #191919;
        margin-top: 0px;

        @media only screen and (max-width: $mobileView) {
          margin-bottom: 16px;
        }

        a {
          font-family: $boldFont;
          font-size: 16px;
          line-height: 24px;
          color: #191919;

          @media only screen and (max-width: $mobileView) {
            float: right;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}

.imagewithpara-get-presto-black {
  display: flex;
  flex-direction: row;
  margin-left: 170px;
  margin-right: 170px;
  padding-bottom: 56px;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 32px;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      width: 86%;
      background-color: #ffffff;
      margin-left: 25px;
    }

    img {
      width: 412px;
      height: 165px;

      @media only screen and (max-width: $mobileView) {
        height: 200px;
        width: 100%;
      }
    }
  }

  .text-container {
    border: 1px solid #dadada;
    width: 465px;
    background-color: #ffffff;

    @media only screen and (max-width: $mobileView) {
      width: 86%;
      border-left: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      border-right: 1px solid #dadada;
      border-top: 0px solid #dadada;
      height: auto;
      margin-left: 25px;
    }

    .display-4 {
      margin-top: 24px;
      margin-bottom: 8px;
      padding-left: 30px;
      font-family: $boldFont;
      font-size: 16px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .instructionContainer {
      padding-left: 30px;
      padding-right: 10px;
      font-size: 16px;
      color: #191919;

      @media only screen and (max-width: $mobileView) {
        padding-left: 16px;
        padding-right: 16px;
      }

      p {
        font-family: $regularFont;
        font-size: 16px;
        line-height: 24px;
        color: #191919;
        margin-top: 0px;

        @media only screen and (max-width: $mobileView) {
          margin-bottom: 24px;
        }

        a {
          font-family: $boldFont;
          font-size: 16px;
          line-height: 24px;
          color: #191919;

          @media only screen and (max-width: $mobileView) {
            float: right;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}

.imagewithpara-get-presto-white {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  padding-left: 170px;
  padding-right: 170px;
  padding-bottom: 15px;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      width: 86%;
      background-color: #ffffff;
      margin-left: 25px;
    }

    img {
      width: 412px;
      height: 165px;

      @media only screen and (max-width: $mobileView) {
        height: 200px;
        width: 100%;
      }
    }
  }

  .text-container {
    border: 1px solid #dadada;
    width: 465px;
    background-color: #ffffff;

    @media only screen and (max-width: $mobileView) {
      width: 86%;
      border-left: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      border-right: 1px solid #dadada;
      border-top: 0px solid #dadada;
      height: auto;
      margin-left: 25px;
    }

    .display-4 {
      margin-top: 24px;
      margin-bottom: 8px;
      padding-left: 30px;
      font-family: $boldFont;
      font-size: 16px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .instructionContainer {
      padding-left: 30px;
      padding-right: 10px;
      font-size: 16px;
      color: #191919;

      @media only screen and (max-width: $mobileView) {
        padding-left: 16px;
        padding-right: 16px;
      }

      p {
        font-family: $regularFont;
        font-size: 16px;
        line-height: 24px;
        color: #191919;
        margin-top: 0px;

        @media only screen and (max-width: $mobileView) {
          margin-bottom: 24px;
        }

        a {
          font-family: $boldFont;
          font-size: 16px;
          line-height: 24px;
          color: #191919;

          @media only screen and (max-width: $mobileView) {
            float: right;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}

.imagewithpara-get-presto-whitebackground {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  padding-left: 170px;
  padding-right: 170px;
  padding-bottom: 56px;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 32px;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      width: 86%;
      background-color: #ffffff;
      margin-left: 25px;
    }

    img {
      width: 412px;
      height: 165px;

      @media only screen and (max-width: $mobileView) {
        height: 200px;
        width: 100%;
      }
    }
  }

  .text-container {
    border: 1px solid #dadada;
    width: 465px;
    background-color: #ffffff;

    @media only screen and (max-width: $mobileView) {
      width: 86%;
      border-left: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      border-right: 1px solid #dadada;
      border-top: 0px solid #dadada;
      height: auto;
      margin-left: 25px;
    }

    .display-4 {
      margin-top: 24px;
      margin-bottom: 8px;
      padding-left: 30px;
      font-family: $boldFont;
      font-size: 16px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .instructionContainer {
      padding-left: 30px;
      padding-right: 10px;
      font-size: 16px;
      color: #191919;

      @media only screen and (max-width: $mobileView) {
        padding-left: 16px;
        padding-right: 16px;
      }

      p {
        font-family: $regularFont;
        font-size: 16px;
        line-height: 24px;
        color: #191919;
        margin-top: 0px;

        @media only screen and (max-width: $mobileView) {
          margin-bottom: 24px;
        }

        a {
          font-family: $boldFont;
          font-size: 16px;
          line-height: 24px;
          color: #191919;

          @media only screen and (max-width: $mobileView) {
            float: right;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}

.imagewithpara-get-presto-whitebackground1 {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  padding-left: 170px;
  padding-right: 170px;
  padding-bottom: 56px;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 32px;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      width: 86%;
      background-color: #ffffff;
      margin-left: 25px;
    }

    img {
      width: 412px;
      height: 165px;

      @media only screen and (max-width: $mobileView) {
        height: 200px;
        width: 100%;
      }
    }
  }

  .text-container {
    border: 1px solid #dadada;
    width: 465px;
    background-color: #ffffff;

    @media only screen and (max-width: $mobileView) {
      width: 86%;
      border-left: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      border-right: 1px solid #dadada;
      border-top: 0px solid #dadada;
      height: auto;
      margin-left: 25px;
    }

    .display-4 {
      margin-top: 24px;
      margin-bottom: 8px;
      padding-left: 30px;
      font-family: $boldFont;
      font-size: 16px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .instructionContainer {
      padding-left: 30px;
      padding-right: 10px;
      font-size: 16px;
      color: #191919;

      @media only screen and (max-width: $mobileView) {
        padding-left: 16px;
        padding-right: 16px;
      }

      p {
        font-family: $regularFont;
        font-size: 16px;
        color: #191919;
        font-size: 16px;
        line-height: 24px;
        margin-top: 0px;

        @media only screen and (max-width: $mobileView) {
          margin-bottom: 24px;
        }
      }
    }
  }
}

.imagewithpara-get-prestolink {
  display: flex;
  flex-direction: row;
  margin-left: 170px;
  margin-right: 170px;
  padding-bottom: 15px;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      width: 86%;
      background-color: #ffffff;
      margin-left: 25px;
    }

    img {
      width: 412px;
      height: 165px;

      @media only screen and (max-width: $mobileView) {
        height: 200px;
        width: 100%;
      }
    }
  }

  .text-container {
    border: 1px solid #dadada;
    width: 465px;
    background-color: #ffffff;

    @media only screen and (max-width: $mobileView) {
      width: 86%;
      margin-left: 25px;
      border-left: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      border-right: 1px solid #dadada;
      border-top: 0px solid #dadada;
      height: auto;
    }

    .display-4 {
      margin-top: 24px;
      margin-bottom: 8px;
      padding-left: 30px;
      font-family: $boldFont;
      font-size: 16px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .instructionContainer {
      padding-left: 30px;
      padding-right: 10px;
      font-size: 16px;
      color: #191919;

      @media only screen and (max-width: $mobileView) {
        padding-left: 16px;
        padding-right: 16px;
        float: right;
        margin-top: 24px;
      }

      p {
        font-family: $regularFont;
        font-size: 16px;
        line-height: 24px;
        color: #191919;
        margin-top: 0px;

        a {
          font-family: $boldFont;
          font-size: 16px;
          line-height: 24px;
          color: #191919;

          @media only screen and (max-width: $mobileView) {
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}

.imagewithpara-get-prestolink1 {
  display: flex;
  flex-direction: row;
  margin-left: 170px;
  margin-right: 170px;
  padding-bottom: 15px;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      width: 86%;
      background-color: #ffffff;
      margin-left: 25px;
    }

    img {
      width: 412px;
      height: 165px;

      @media only screen and (max-width: $mobileView) {
        height: 200px;
        width: 100%;
      }
    }
  }

  .text-container {
    border: 1px solid #dadada;
    width: 465px;
    background-color: #ffffff;

    @media only screen and (max-width: $mobileView) {
      width: 86%;
      margin-left: 25px;
      border-left: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      border-right: 1px solid #dadada;
      border-top: 0px solid #dadada;
      height: auto;
    }

    .display-4 {
      margin-top: 24px;
      margin-bottom: 8px;
      padding-left: 30px;
      font-family: $boldFont;
      font-size: 16px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .instructionContainer {
      padding-left: 30px;
      padding-right: 10px;
      font-size: 16px;
      color: #191919;

      @media only screen and (max-width: $mobileView) {
        padding-left: 16px;
        padding-right: 16px;
        float: right;
        margin-top: 24px;
      }

      p {
        font-family: $regularFont;
        font-size: 16px;
        line-height: 24px;
        color: #191919;
        margin-top: 0px;

        a {
          font-family: $boldFont;
          color: #191919;
          font-size: 16px;
          line-height: 24px;

          @media only screen and (max-width: $mobileView) {
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}

.prestocard_homepage_threecolumn_layout {
  justify-content: space-between;
  column-gap: 80px;
  background-color: #ffffff;
  padding-top: 79px;
  padding-bottom: 79px;
  width: 944px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    padding-top: 24px;
    padding-bottom: 48px;
    width: unset;
    margin: 0 16px;
  }

  .column {
    max-width: unset;
  }

  .row.instructionimage {
    justify-content: center;
  }

  .instantimage_column_one {
    text-align: center;

    .instructionimage {
      margin-bottom: 20px;

      @media only screen and (max-width: $mobileView) {
        margin-bottom: 8px;
        text-align: center;
      }

      img {
        height: 200px;
        width: 260px;

        @media only screen and (max-width: $mobileView) {
          height: 162px;
          width: 216px;
        }
      }
    }

    .text-container {
      .titleinstruction {
        justify-content: center;
      }

      .display-4 {
        justify-content: center;
        font-size: 26px;
        margin-bottom: 20px;
        line-height: 39px;
        margin-top: 0;

        @media only screen and (max-width: $mobileView) {
          text-align: center;
          margin-right: 0;
          width: 100%;
        }
      }

      .instructionContainer {
        text-align: center;
        font-family: 'AvenirNextLTPro-Regular';
        line-height: 1.5em;

        @media only screen and (max-width: $mobileView) {
          width: unset;
        }
      }
    }
  }

  .touchlessimg_column_two {
    text-align: center;

    .instructionimage {
      margin-top: 10px;
      margin-bottom: 42px;

      @media only screen and (max-width: $mobileView) {
        margin-bottom: 8px;
        text-align: center;
      }

      img {
        height: 168px;
        width: 220px;

        @media only screen and (max-width: $mobileView) {
          height: 162px;
          width: 216px;
        }
      }
    }

    .text-container {
      .titleinstruction {
        justify-content: center;
      }

      .display-4 {
        justify-content: center;
        font-size: 26px;
        line-height: 39px;
        margin-bottom: 20px;
        margin-top: 0;

        @media only screen and (max-width: $mobileView) {
          text-align: center;
          margin-right: 0;
          width: 100%;
        }
      }

      .instructionContainer {
        text-align: center;
        font-family: 'AvenirNextLTPro-Regular';
        line-height: 1.5em;

        @media only screen and (max-width: $mobileView) {
          width: unset;
        }
      }
    }
  }

  .discountsimg_column_three {
    text-align: center;

    .instructionimage {
      margin-bottom: 20px;

      @media only screen and (max-width: $mobileView) {
        margin-bottom: 8px;
        text-align: center;
      }

      img {
        height: 200px;
        width: 250px;

        @media only screen and (max-width: $mobileView) {
          height: 162px;
          width: 216px;
        }
      }
    }

    .text-container {
      .titleinstruction {
        justify-content: center;
      }

      .display-4 {
        justify-content: center;
        font-size: 26px;
        line-height: 39px;
        margin-bottom: 20px;
        margin-top: 0;

        @media only screen and (max-width: $mobileView) {
          text-align: center;
          margin-right: 0;
          width: 100%;
        }
      }

      .instructionContainer {
        text-align: center;
        font-family: 'AvenirNextLTPro-Regular';
        line-height: 1.5em;

        @media only screen and (max-width: $mobileView) {
          width: unset;
        }
      }
    }
  }
}

.presto-ttc-image-with-paragraph {
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid #bababa;
  padding-bottom: 48px;
  width: 944px;
  margin: 48px auto 0px;

  @media only screen and (max-width: $mobileView) {
    display: block;
    margin-top: 0;
    width: unset;
    margin: 32px 16px 0px;
    padding-bottom: 32px;
  }

  .instructionimage {
    margin-top: 58px;

    @media only screen and (max-width: $mobileView) {
      margin-top: 0px;
      width: 100%;
    }

    img {
      width: 330px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .text-container {
    font-family: 'AvenirNextLTPro-Regular';

    .display-4 {
      font-family: 'AvenirNextLTPro-Demi';
      font-size: 26px;
      line-height: 40px;
      margin: 0;

      @media only screen and (max-width: $mobileView) {
        margin-top: 32px;
        font-size: 22px;
        line-height: 32px;
      }
    }

    .instructionContainer {
      margin-right: 48px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        margin-right: 0;
      }

      p {
        line-height: 24px;
      }

      .arrow-icon1 {
        display: flex;
        line-height: 24px;

        @media only screen and (max-width: $mobileView) {
          margin-bottom: 32px;
        }

        .to-display-flex {
          display: flex;
          margin-bottom: 16px;

          div {
            margin-top: -4px;
          }
        }

        @media only screen and (max-width: $mobileView) {
          line-height: 24px;
        }

        .instructionimage {
          margin-top: 0px;
        }
      }

      .reduce-margin {
        @media only screen and (max-width: $mobileView) {
          margin-bottom: 16px;
        }
      }

      ul {
        line-height: 32px;

        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
          padding-left: 25px;
          margin-bottom: 0;
        }

        li {
          padding-left: 5px;

          @media only screen and (max-width: $mobileView) {
            line-height: 21px;
            padding-bottom: 10px;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.presto-ttc-image-with-paragraph-morelength {
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid #bababa;
  padding-bottom: 48px;
  width: 944px;
  margin: 48px auto 0px;

  @media only screen and (max-width: $mobileView) {
    display: block;
    margin-top: 0;
    width: unset;
    margin: 32px 16px 0px;
    padding-bottom: 32px;
  }

  .instructionimage {
    margin-top: 95px;

    @media only screen and (max-width: $mobileView) {
      margin-top: 0px;
      width: 100%;
    }

    img {
      width: 330px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .text-container {
    font-family: 'AvenirNextLTPro-Regular';

    .display-4 {
      font-family: 'AvenirNextLTPro-Demi';
      font-size: 26px;
      line-height: 40px;
      width: 110%;
      margin: 0;

      @media only screen and (max-width: $mobileView) {
        margin-top: 32px;
        font-size: 22px;
        line-height: 32px;
        width: 100%;
      }
    }

    .instructionContainer {
      margin-right: 48px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        width: 100%;
        margin-right: 0;
      }

      p {
        line-height: 24px;
      }

      .arrow-icon1 {
        display: flex;
        line-height: 24px;

        .to-display-flex {
          display: flex;

          div {
            margin-top: -4px;
          }
        }

        @media only screen and (max-width: $mobileView) {
          line-height: 24px;
        }

        .instructionimage {
          margin-top: 0px;
        }
      }

      .reduce-margin {
        @media only screen and (max-width: $mobileView) {
          margin-bottom: 16px;
        }
      }

      ul {
        line-height: 32px;

        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
          padding-left: 25px;
        }

        li {
          padding-left: 5px;

          @media only screen and (max-width: $mobileView) {
            line-height: 21px;
            padding-bottom: 10px;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.disable-border-bottom {
  border-bottom: none;
  margin-bottom: 32px;

  @media only screen and (max-width: $mobileView) {
    margin-bottom: 16px;
  }
}

.new_presto_bus_devices {
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid #bababa;
  padding-bottom: 48px;
  width: 944px;
  margin: 0 auto 48px;

  @media only screen and (max-width: $mobileView) {
    display: block;
    width: unset;
    margin: 32px 16px 0px;
    padding-bottom: 32px;
  }

  .instructionimage {
    margin-right: 8px;
    margin-top: 60px;

    @media only screen and (max-width: $mobileView) {
      margin-top: 0px;
      margin-bottom: 30px;
      margin-right: 0px;
    }

    img {
      height: 200px;
      width: 325px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }

  .text-container {
    .display-4 {
      font-size: 24px;
      margin-bottom: 10px;
      margin-top: 0;
    }

    .instructionContainer {
      font-family: 'AvenirNextLTPro-Regular';
      width: 92%;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: 96%;
      }
    }
  }
}

.new_presto_station_devices {
  display: flex;
  flex-direction: row-reverse;
  width: 944px;
  margin: 0 auto 80px;

  @media only screen and (max-width: $mobileView) {
    display: block;
    width: unset;
    margin: 32px 16px 0px;
    padding-bottom: 64px;
  }

  .instructionimage {
    margin-right: 8px;
    margin-top: 60px;

    @media only screen and (max-width: $mobileView) {
      margin-top: 0px;
      margin-bottom: 30px;
      margin-right: 0px;
    }

    img {
      height: 200px;
      width: 325px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }

  .text-container {
    .display-4 {
      font-size: 24px;
      margin-bottom: 10px;
      margin-top: 0;
    }

    .instructionContainer {
      font-family: 'AvenirNextLTPro-Regular';
      width: 92%;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: 96%;
      }

      ul {
        line-height: 32px;

        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
        }

        li {
          padding-left: 9px;

          @media only screen and (max-width: $mobileView) {
            padding-bottom: 9px;
          }
        }
      }
    }
  }
}

.HowToUse-img-para {
  @media only screen and (max-width: $mobileView) {
    margin-bottom: 16px;
  }

  img {
    width: 100%;
    height: 170px;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }

  .text-container {
    height: 40px;
    background: #fff;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;

    @media only screen and (max-width: $mobileView) {
      margin: -6px 0px 0px 0px;
      height: 55px;
    }

    .arrow-icon1 {
      .Learn-Presto-App {
        margin: 0;
        font-size: 16px;
      }
    }

    a {
      text-decoration: none;
      padding-left: 8px;
    }
  }
}

.Hello-presto-img {
  background-color: #f3f3f3;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: $mobileView) {
    padding-top: 0px;
  }

  .instructionimage {
    display: block;
    max-width: 1440px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
      display: flex;
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    img {
      width: 100%;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .text-container {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      padding-left: 22px;
      padding-top: 20px;
      width: unset;
    }

    .display-4 {
      font-size: 26px;
      color: #181818;
      display: block;
      font-weight: 500;
      line-height: 40px;
      font-family: $boldFont;
      margin-top: 32px;
      margin-bottom: 0;

      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
        line-height: 32px;
      }
    }

    .instructionContainer {
      p {
        width: 90%;
        font-size: 16px;
        color: #181818;
        line-height: 24px;
        display: block;
        margin-bottom: 0;
        font-family: $regularFont;

        @media only screen and (max-width: $mobileView) {
          width: 98%;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.Image-with-para-hello {
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #dadada;
  height: 370px;

  @media only screen and (max-width: $mobileView) {
    height: auto;
    margin-bottom: 25px;
  }

  .instructionimage {
    display: block;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (max-width: $mobileView) {
      margin-bottom: 20px;
      display: flex;
      max-width: 100%;
    }

    img {
      display: block;
      max-width: 100%;
      object-fit: contain;
      height: 168px;

      @media only screen and (max-width: $mobileView) {
        max-width: 100%;
        height: 200px;
        display: block;
      }
    }
  }

  .text-container {
    padding-right: 15px;
    padding-left: 15px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 22px;
      padding-right: 22px;
    }

    .display-4 {
      display: block;
      font-family: $boldFont;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #181818;
      margin-top: 0;
      line-height: 24px;
      margin-top: 16px;
      padding-right: 5px;

      @media only screen and (max-width: $mobileView) {
        font-size: 19px;
      }
    }

    .instructionContainer {
      p {
        font-size: 16px;
        color: #181818;
        line-height: 24px;
        display: block;
        margin: 0 0 10px;
        font-family: $regularFont;

        @media only screen and (max-width: $mobileView) {
          text-align: left;
          font-size: 19px;
          line-height: 28px;
        }
      }
    }
  }
}

.Image-with-para-hello1 {
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #dadada;
  height: 370px;

  @media only screen and (max-width: $mobileView) {
    height: auto;
    margin-bottom: 25px;
  }

  .instructionimage {
    display: block;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (max-width: $mobileView) {
      margin-bottom: 20px;
      display: flex;
      max-width: 100%;
    }

    img {
      display: block;
      max-width: 100%;
      object-fit: contain;
      height: 168px;

      @media only screen and (max-width: $mobileView) {
        max-width: 100%;
        height: 200px;
        display: block;
        margin-left: 60px;
      }
    }
  }

  .text-container {
    padding-right: 15px;
    padding-left: 15px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 22px;
      padding-right: 22px;
    }

    .display-4 {
      display: block;
      font-family: $boldFont;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #181818;
      margin-top: 0;
      line-height: 24px;
      margin-top: 16px;
      padding-right: 5px;

      @media only screen and (max-width: $mobileView) {
        font-size: 19px;
      }
    }

    .instructionContainer {
      p {
        font-size: 16px;
        color: #181818;
        line-height: 24px;
        display: block;
        margin: 0 0 10px;
        font-family: $regularFont;

        @media only screen and (max-width: $mobileView) {
          text-align: left;
          font-size: 19px;
          line-height: 28px;
        }
      }
    }
  }
}

.Image-with-para-hello2 {
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #dadada;
  height: 370px;

  @media only screen and (max-width: $mobileView) {
    height: auto;
    margin-bottom: 25px;
  }

  .instructionimage {
    display: block;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (max-width: $mobileView) {
      margin-bottom: 20px;
      display: flex;
      max-width: 100%;
    }

    img {
      display: block;
      max-width: 100%;
      object-fit: contain;
      height: 168px;

      @media only screen and (max-width: $mobileView) {
        max-width: 100%;
        height: 200px;
        display: block;
        margin-left: 30px;
      }
    }
  }

  .text-container {
    padding-right: 15px;
    padding-left: 15px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 22px;
      padding-right: 22px;
    }

    .display-4 {
      display: block;
      font-family: $boldFont;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #181818;
      margin-top: 0;
      line-height: 24px;
      margin-top: 16px;
      padding-right: 5px;

      @media only screen and (max-width: $mobileView) {
        font-size: 19px;
      }
    }

    .instructionContainer {
      p {
        font-size: 16px;
        color: #181818;
        line-height: 24px;
        display: block;
        margin: 0 0 10px;
        font-family: $regularFont;

        @media only screen and (max-width: $mobileView) {
          text-align: left;
          font-size: 19px;
          line-height: 28px;
        }
      }
    }
  }
}

.Usingpresto-Img {
  display: flex;
  width: 944px;
  margin: 0 auto 16px;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    margin: 32px 16px 16px;
    width: unset;
  }

  img {
    height: auto;

    width: 385px;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }

  .text-container {
    border: 1px solid #dadada;
    width: 76%;
    background-color: #ffffff;
    padding: 24px 24px 24px 32px;

    @media only screen and (max-width: $mobileView) {
      width: unset;
      margin-top: -5px;
      padding: 16px 16px 24px 16px;
    }

    .display-4 {
      font-size: 20px;
      color: black;
      margin-bottom: 8px;
      margin-top: 0;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    p {
      font-family: $regularFont;
      line-height: 24px;
      color: black;
      margin-top: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px;
      }

      a {
        font-family: $boldFont;
        color: black;
      }
    }

    .presto-cards-options-para1 {
      font-family: $regularFont;
      line-height: 24px;
      color: black;
      margin-top: 0px;
      margin-bottom: 24px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }

      a {
        font-family: $boldFont;
        color: black;
      }
    }

    .presto-cards-options-para2 {
      font-family: $regularFont;
      line-height: 24px;
      color: black;
      margin-top: 0px;
      margin-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }

      a {
        font-family: $boldFont;
        color: black;
      }
    }
  }
}

.new-to-presto-prestocard {
  display: inline-flex;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  margin: 0 auto 16px;

  @media only screen and (max-width: $mobileView) {
    display: block;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      width: 100%;
      text-align: center;
    }

    img {
      height: 250px;
      width: 250px;
      margin-top: 25px;
      margin-left: 45px;
      padding-left: 20px;

      @media only screen and (max-width: $mobileView) {
        margin-left: 0px;
        width: 200px;
        height: 200px;
        margin-top: 0px;
        padding-left: 0px;
      }
    }

    img[alt~='contactless'] {
      @media only screen and (min-width: 920px) {
        width: 383px;
        margin-left: -20px;
        margin-right: -68px;
        margin-top: 0px;
      }

      @media only screen and (max-width: $mobileView) {
        width: 280px;
        max-width: 100%;
        height: auto;
      }
    }

    img[alt~='card'] {
      @media only screen and (min-width: 920px) {
        margin-top: -25px;
        height: 280px;
        width: 280px;
        margin-right: -15px;
        margin-left: 30px;
      }

      @media only screen and (max-width: $mobileView) {
        margin-top: -10px;
      }
    }

    img[alt~='etickets'] {
      @media only screen and (min-width: 920px) {
        margin-top: 14px;
      }

      @media only screen and (max-width: $mobileView) {
        margin-top: 14px;
      }
    }

    img[alt~='ticket'] {
      @media only screen and (min-width: 920px) {
        margin-top: 0px;
      }
    }
  }

  .text-container {
    margin-top: 37px;
    margin-left: 85px;
    margin-right: 24px;

    @media only screen and (max-width: $mobileView) {
      margin-left: 16px;
      margin-top: 5px;
      margin-right: 16px;
    }

    .display-4 {
      font-size: 20px;
      margin: 0;

      @media only screen and (max-width: $mobileView) {
        font-size: 18px;
      }
    }

    .instructionContainer {
      font-family: $regularFont;
      width: 95%;
      line-height: 1.5em;

      .learnmore-link {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
        }
      }
    }
  }
}

.new-to-presto-prestocontactless {
  display: inline-flex;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  width: 944px;
  margin: 0 auto 20px auto;

  @media only screen and (max-width: $mobileView) {
    display: block;
    width: 100%;
  }

  .instructionimage {
    img {
      height: 250px;
      width: 270px;
      margin-top: 0px;
      margin-left: 45px;
      padding-left: 20px;

      @media only screen and (max-width: $mobileView) {
        margin-left: 50px;
        width: 200px;
        height: 200px;
        margin-top: -20px;
        padding-left: 0px;
      }
    }
  }

  .text-container {
    margin-top: 37px;
    margin-left: 72px;

    @media only screen and (max-width: $mobileView) {
      margin-left: 15px;
      margin-top: 5px;
    }

    .display-4 {
      font-size: 20px;
      margin: 0;

      @media only screen and (max-width: $mobileView) {
        font-size: 18px;
      }
    }

    .instructionContainer {
      font-family: $regularFont;
      width: 95%;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: 90%;
      }

      .learnmore-link {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
        }
      }
    }
  }
}

.new-to-presto-prestoetickets {
  display: inline-flex;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  width: 944px;
  margin: 0 auto 20px auto;

  @media only screen and (max-width: $mobileView) {
    display: block;
    width: 100%;
  }

  .instructionimage {
    img {
      height: 250px;
      width: 265px;
      margin-top: 25px;
      margin-left: 45px;
      padding-left: 36px;

      @media only screen and (max-width: $mobileView) {
        margin-left: 50px;
        width: 200px;
        height: 200px;
        margin-top: 20px;
        padding-left: 0px;
      }
    }
  }

  .text-container {
    margin-top: 37px;
    margin-left: 65px;

    @media only screen and (max-width: $mobileView) {
      margin-left: 15px;
      margin-top: 5px;
    }

    .display-4 {
      font-size: 20px;
      margin: 0;

      @media only screen and (max-width: $mobileView) {
        font-size: 18px;
      }
    }

    .instructionContainer {
      font-family: $regularFont;
      width: 95%;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: 90%;
      }

      .learnmore-link {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
        }
      }
    }
  }
}

.new-to-presto-prestoticket {
  display: inline-flex;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  width: 944px;
  margin: 0 auto 20px auto;

  @media only screen and (max-width: $mobileView) {
    display: block;
    width: 100%;
  }

  .instructionimage {
    img {
      height: 235px;
      width: 235px;
      margin-top: 8px;
      margin-left: 45px;
      padding-left: 20px;

      @media only screen and (max-width: $mobileView) {
        margin-left: 50px;
        width: 200px;
        height: 200px;
        margin-top: -10px;
        padding-left: 0px;
      }
    }
  }

  .text-container {
    margin-top: 37px;
    margin-left: 116px;

    @media only screen and (max-width: $mobileView) {
      margin-left: 15px;
      margin-top: 5px;
    }

    .display-4 {
      font-size: 20px;
      margin: 0;

      @media only screen and (max-width: $mobileView) {
        font-size: 18px;
      }
    }

    .instructionContainer {
      font-family: $regularFont;
      width: 95%;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: 90%;
      }

      .learnmore-link {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
        }
      }
    }
  }
}

.official-app-imagewithpara {
  background-color: #fff;
  display: flex;
  padding-bottom: 16px;

  @media only screen and (max-width: $mobileView) {
    display: block;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      display: grid !important;
    }

    img {
      object-fit: cover;
      width: 382px;
      height: 285px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 200px;
      }
    }
  }

  .text-container {
    border: 1px solid #efeded;
    padding-left: 30px;
    padding-top: 30px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .display-4 {
      font-size: 18px;
      margin: 0;
    }

    .instructionContainer {
      font-family: $regularFont;
      width: 95%;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: unset;
      }

      .learnmore-link {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
        }
      }
    }
  }
}

.presto-eticketapp-imagewithpara {
  background-color: #fff;
  display: flex;
  padding-bottom: 56px;

  @media only screen and (max-width: $mobileView) {
    padding-bottom: 32px;
    display: block;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      display: grid !important;
    }

    img {
      object-fit: cover;
      width: 382px;
      height: 285px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 200px;
      }
    }
  }

  .text-container {
    border: 1px solid #efeded;
    padding-left: 30px;
    padding-top: 30px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .display-4 {
      font-size: 18px;
      margin: 0;
    }

    .instructionContainer {
      font-family: $regularFont;
      width: 95%;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: unset;
      }

      .learnmore-link {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
        }
      }
    }
  }
}

.enjoy-discounts-new-to-presto-imagewithpara {
  background-color: #fff;
  display: flex;
  padding-bottom: 50px;

  @media only screen and (max-width: $mobileView) {
    padding-left: 30px;
    display: block;
  }

  .instructionimage {
    @media only screen and (max-width: $mobileView) {
      display: grid !important;
    }

    img {
      object-fit: cover;
      width: 355px;

      @media only screen and (max-width: $mobileView) {
        width: 294px;
        height: 200px;
      }
    }
  }

  .text-container {
    border: 1px solid #efeded;
    padding-left: 30px;
    padding-top: 30px;

    @media only screen and (max-width: $mobileView) {
      width: 144%;
      padding-left: 10px;
    }

    .display-4 {
      font-size: 18px;
      margin: 0;
    }

    .instructionContainer {
      font-family: $regularFont;
      width: 92%;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: 91%;
      }

      .learnmore-link {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
        }
      }
    }
  }
}

.Hello-free-Imagepara {
  margin: 32px auto 0px auto;
  padding-bottom: 48px;
  border-bottom: 1px solid #bababa;
  display: flex;
  flex-direction: row-reverse;
  width: 944px;

  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-right: 16px;
    flex-direction: column;
    padding-bottom: 32px;
    width: 91%;
  }

  .instructionimage {
    margin-top: 64px;

    @media only screen and (max-width: $mobileView) {
      margin-top: 0px;
    }

    img {
      width: 320px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }

  .display-4 {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 24px;
    color: #191919;
    text-align: left;
    font-family: $boldFont;
    margin-top: 0;

    @media only screen and (max-width: $mobileView) {
      margin-top: 32px;
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 32px;
    }
  }

  .instructionContainer {
    margin-right: 48px;

    @media only screen and (max-width: $mobileView) {
      margin-right: 0px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #191919;
      text-align: left;
      font-family: $regularFont;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

.Hello-free-Image {
  margin: 48px auto 0px auto;
  padding-bottom: 48px;
  border-bottom: 1px solid #bababa;
  display: flex;
  flex-direction: row-reverse;
  width: 944px;
  justify-content: space-between;

  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-right: 16px;
    flex-direction: column;
    padding-bottom: 32px;
    margin-top: 32px;
    width: 91%;
  }

  .instructionimage {
    margin-top: 64px;

    @media only screen and (max-width: $mobileView) {
      margin-top: 0px;
    }

    img {
      width: 320px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }

  .display-4 {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 24px;
    color: #191919;
    text-align: left;
    font-family: $boldFont;
    margin-top: 0;

    @media only screen and (max-width: $mobileView) {
      margin-top: 32px;
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 32px;
    }
  }

  .instructionContainer {
    margin-right: 48px;

    @media only screen and (max-width: $mobileView) {
      margin-right: 0px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #191919;
      text-align: left;
      font-family: $regularFont;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .bold-heading {
      margin-top: 8px;
      margin-bottom: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 16px;
      }
    }

    .bold-heading1 {
      margin-top: 8px;
      margin-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 16px;
      }
    }

    p+ul {
      margin-top: 16px;
    }

    strong {
      font-size: 18px;
      line-height: 28px;
    }

    ul {
      font-size: 16px;
      line-height: 32px;
      color: #191919;
      font-family: $regularFont;
      padding-left: 27px;

      @media only screen and (max-width: $mobileView) {
        font-size: 14px;
        line-height: 21px;
        margin-top: 24px;
        margin-bottom: 0px;
      }

      li {
        padding-left: 8px;

        @media only screen and (max-width: $mobileView) {
          padding-bottom: 8px;
        }
      }
    }
  }
}

.instructionimage-sm {
  display: none;
}

.Tax-credit-image-para {
  margin: 0 auto;
  width: 944px;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 64px;

  @media only screen and (max-width: $mobileView) {
    flex-direction: column;
    width: 90%;
  }

  .display-4 {
    font-size: 26px;
    line-height: 40px;
    font-family: $boldFont;
    color: #191919;
  }

  .instructionContainer {
    p {
      font-size: 16px;
      line-height: 24px;
      font-family: $regularFont;
      color: #191919;
      margin-top: 0px;
      width: 85%;

      @media only screen and (max-width: $mobileView) {
        margin-top: 32px;
        width: 100%;
        margin-bottom: 0px;
      }
    }

    .sub-para {
      margin-bottom: 80px;

      @media only screen and (max-width: $mobileView) {
        margin-bottom: 64px;
      }
    }
  }

  .instructionimage {
    margin-right: 53px;

    @media only screen and (max-width: $mobileView) {
      margin-right: 0px;
    }

    img {
      width: 400px;
      height: 224px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 200px;
      }
    }
  }
}

.Tax-credit-image {
  margin: 0 auto;
  width: 944px;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0px;

  @media only screen and (max-width: $mobileView) {
    flex-direction: column;
    width: 90%;
  }

  .display-4 {
    font-size: 26px;
    line-height: 40px;
    font-family: $boldFont;
    color: #191919;
  }

  .instructionContainer {
    p {
      font-size: 16px;
      line-height: 24px;
      font-family: $regularFont;
      color: #191919;
      margin-top: 0px;
      width: 85%;

      @media only screen and (max-width: $mobileView) {
        margin-top: 32px;
        width: 100%;
        margin-bottom: 0px;
      }
    }

    .sub-para {
      margin-bottom: 80px;
    }
  }

  .instructionimage {
    margin-right: 53px;

    @media only screen and (max-width: $mobileView) {
      margin-right: 0px;
    }

    img {
      width: 400px;
      height: 224px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: 200px;
      }
    }
  }
}

.order_new_card_image_para {
  @media only screen and (max-width: $mobileView) {
    display: none;
  }

  margin-top: 40px;

  .instructionimage {
    margin-bottom: 24px;

    img {
      margin-left: 80px;
    }
  }

  .instructionContainer {
    font-family: $regularFont;
    font-size: 16px;
    line-height: 24px;
    text-align: end;
  }
}

.new-to-presto-image-wrapper {
  width: 944px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    width: unset;
    margin: 0 16px;
  }
}

.image-with-paragraph-white-background {
  background-color: #ffffff;
}

.image-with-para-whitebg-wrapper {
  padding-bottom: 16px;
}

.image-with-para-whitebg-last-wrapper {
  padding-bottom: 56px;

  @media only screen and (max-width: $mobileView) {
    padding-bottom: 32px;
  }
}

.image-with-para-last-wrapper {
  padding-bottom: 32px;
}

.co-fares-ride-more-container {
  .display-4 {
    font-size: 48px;
  }
}

.participating-transit-agencies-map-img {
  //background-color: #f3f3f3;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: $mobileView) {
    padding-top: 0px;
  }

  .instructionimage {
    display: block;
    max-width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
      display: flex;
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    img {
      width: 100%;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: auto;
        //height: 100%;
      }
    }
  }

  .text-container {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      padding-left: 22px;
      padding-top: 20px;
      width: unset;
    }

    .display-4 {
      font-size: 26px;
      color: #181818;
      display: block;
      font-weight: 500;
      line-height: 40px;
      font-family: $boldFont;
      margin-top: 32px;
      margin-bottom: 0;

      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
        line-height: 32px;
      }
    }

    .instructionContainer {
      p {
        width: 90%;
        font-size: 16px;
        color: #181818;
        line-height: 24px;
        display: block;
        margin-bottom: 0;
        font-family: $regularFont;

        @media only screen and (max-width: $mobileView) {
          width: 98%;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.ridemore-left-content {
  float: left;
  max-width: 44%;
  margin-bottom: 5px;
  margin-right: 25px;
  line-height: 24px;

  p {
    font-family: $regularFont
  }

  @media only screen and (min-width: 768px) and (max-width: 920px) {
    max-width: 100%;
    width: unset;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
}

.dividerlarge {
  max-width: 960px;
  margin: auto !important;

  .ridemore-left-content {
    float: left;
    max-width: 45%;
    margin-bottom: 5px;
    font-family: $regularFont;

    @media only screen and (min-width: 768px) and (max-width: 920px) {
      max-width: 100%;
      width: unset;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }
  }
}

.co-fares-ride-more-container {
  max-width: 944px;
  margin: 0 auto;
  padding: 0 16px 0 16px;
}

.embed-video-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  border-radius: 20px;

  @media only screen and (max-width: 920px) {
    width: 100%;
  }
}

.embed-video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.embed-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}