@import '../../assets/scss/vars.scss';

.delete-account-success {

    @media only screen and (min-width: $mobileView) {
        width: 100%;
        font-size: 18px;
    }

    .cardBlockComponent p span{
        font-size: 16px;
    }

    @media only screen and (max-width: $mobileView) {
        margin-left: 10px;
        margin-right: 10px;
    }

    .cardBlockComponent-content {
        @media only screen and (min-width: $mobileView) {
            padding-top: 28px;
        }
    }

    .step1-content-heading1 {
        font-family: $boldFont;
        margin-top: 0;
    }

    .step1-content-heading2 {
        font-family: $boldFont;
    }

    .unordered-second-para {
        margin-bottom: 4px
    }

    .link {
        margin: 0;
    }

    #externalLinkText {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 21px;
    }

    .redBackground {
        background-color: #d0011b;
        border: none;

        @media only screen and (max-width: $mobileView) {
            margin-bottom: 16px;
        }
    }

    .step2-content-description1 {
        padding-top: 12px;

        @media only screen and (max-width: $mobileView) {
            padding-top: 0;
        }
    }

    .step2-content-description2 {
        padding-top: 16px;
    }

    .step2-btn-wrapper {
        margin-top: 40px;

        @media only screen and (max-width: $mobileView) {
            margin-top: 32px;
        }
    }

    .step3-para1 {
        text-align: center;
        padding-top: 0px;
        margin-bottom: 0px;
    }

    .step3-para2 {
        text-align: center;
        padding-top: 0px;
        margin-top: 0px;

        @media only screen and (max-width: $mobileView) {
            margin-bottom: 32px;
        }
    }

    .step3-para3 {
        text-align: center;
        padding-top: 0px;
        margin: 0px;
    }

    .step3-para4 {
        text-align: center;
        padding-top: 8px;
        margin: 0px;
    }
    
    .step3-img1 {
        text-align: center;
        margin-top: 32px;

        @media only screen and (max-width: $mobileView) {
            padding-left: 18px;
            padding-right: 16px;
            margin-bottom: 8px;
        }
        .step3-image{
            height: 87px;
        }

    }

    .button-wrapper-right {
        @media only screen and (max-width: $mobileView) {
            padding-left: 18px;
            padding-right: 16px;
        }
    }
}