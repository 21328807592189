@import '../../assets/scss/vars';

.success-block {
  padding: 24px 16px;
  border: 1px solid $secondary10;
  border-left: 4px solid $feedbackSuccess;
  background-color: $white;

  @media only screen and (min-width: $mobileView) {
    padding: 24px;
  }
}

.success-block-title {
  color: $feedbackSuccess;
  font-size: 18px;
  font-family: $boldFont;
  padding-left: 48px;
  line-height: 32px;
  margin-bottom: 15px;
  background: url('../../assets/images/Success.svg') no-repeat left top;
  -webkit-print-color-adjust: exact;

  > p {
    margin: 0;
  }

  @media only screen and (min-width: $mobileView) {
    font-size: 22px;
  }

  &.without-margin-bottom {
    margin-bottom: 0;
  }
}

.success-block-text {
  line-height: 24px;
}

.success-context-text {
  font-family: $regularFont;
  font-size: 14px;
  color: $black;
  margin-top: 10px;
  margin-bottom: 8px;
  line-height: 21px;

  @media only screen and (min-width: $mobileView) {
    margin-top: 16px;
  }
}
