@import '../../assets/scss/vars.scss';

.contactless-unpaid-fare-header {

  #unpaid-fare-title,
  #unpaid-fare-success-title,
  #unpaid-fare-failure-title {
    .headline {
      margin: $spacing-1 0px;
    }
  }
}

.contactless-unpaid-fare--title {
  display: flex;
  color: #d5011d;
  font-size: 22px;
  font-family: 'AvenirNextLTPro-Demi', sans-serif;
  padding-left: 48px;
  line-height: 32px;
  margin-bottom: 16px;
  background: url('../../assets/images/error.svg') no-repeat left top;

  .content-error-block-title--right {
    margin-left: auto;
  }
}

.unpaid-fare-count {
  font-family: 'AvenirNextLTPro-Regular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.contactless-unpaid-fare--desc {
  margin: 24px 0px;
  font-family: 'AvenirNextLTPro-Regular';
}

.unpaid-fare-total-link {
  margin: 10px 0px;
}

.contactless-unpaid-transaction {}

.unpaid-fare-success-container {
  .unpaid-fare-success-content {
    padding: 20px;
    border: 1px solid #d1d1d1;
    background-color: #ffffff;
    margin-bottom: 0 !important;
    border-left: 4px solid $feedbackSuccess;

    .unpaid-fare-success--title {
      background: url('../../assets/images/Success.svg') no-repeat left top;
      -webkit-print-color-adjust: exact;
      display: inline-block;
      padding-bottom: 10px;
      line-height: 32px;

      #message {
        color: $feedbackSuccess;
        font-size: 22px;
        font-family: $boldFont;
        padding-left: 50px;
      }
    }

    .unpaid-fare-success--subtext {
      font-family: 'AvenirNextLTPro-Regular';
      padding: 10px 0px;
      font-size: 14px;
    }

    .unpaid-fare-success--info {
      list-style-type: none;
      margin: 0px;
      padding: 0px;

      li {
        border-bottom: 2px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        padding: 23px 0px;

        .key {}

        .value {}
      }
    }
  }

  .unpaid-fare-success--footer {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;

    .unpaid-fare--float-right {
      display: flex;

      button {
        margin-left: 10px;
      }
    }
  }
}

.unpaid-fare-error-container {
  .unpaid-fare-error-content {
    padding: $spacing-1 $spacing-3 $spacing-1 $spacing-1 ;
    border: 1px solid #d1d1d1;
    background-color: #ffffff;
    line-height: $spacing-2;
    margin-bottom: 0 !important;
    border-left: 4px solid $feedbackError;

    .unpaid-fare-error--title {
      background: url('../../assets/images/Failed.svg') no-repeat left top;
      -webkit-print-color-adjust: exact;
      display: inline-block;
      padding-bottom: 10px;
      line-height: 32px;

      #message {
        color: $feedbackError;
        font-size: 22px;
        font-family: $boldFont;
        padding-left: 50px;
      }
    }

    .unpaid-fare-error--subtext {
      font-family: 'AvenirNextLTPro-Regular';
      padding: 10px 0px;
      font-size: 14px;
    }
    .unpaid-fare-error--sublink {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .unpaid-fare-error--footer {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;

    .unpaid-fare--float-right {
      display: flex;

      button {
        margin-left: 10px;
      }
    }
  }
}

.contactless-unpaid-fare--back {
  cursor: pointer;
  display: flex;
  margin: $spacing-1 0px;
  align-items: center;
}

.pay-unpaid-fare-container {
  margin-top: 40px;

  .pay-unpaid-fare {
    padding: 24px;
    background: $white;

    &--title {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 48px;
    }

    &--desc {
      font-family: 'AvenirNextLTPro-Regular';
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin: 8px 0px;
    }

    &--info {
      display: flex;

      .masked-card {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-transform: capitalize;
        margin-left: 9px;
      }
    }
  }

  .pay-unpaid-fare-footer {
    margin-top: 16px;
    float: right;
  }
}