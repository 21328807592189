@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.mfa-container {
    padding: 0 0 20px;
    background-color: #fff;
    max-width: 40rem;
    margin-bottom: 16px;
}


.verification-status-container {
    display: flex;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    font-family: 'AvenirNextLTPro-Demi';

    @media only screen and (max-width: $mobileView) {
        line-height: 17px;
    }

    .status-message {
        margin: 0px;
        padding-left: 4px;
        font-family: 'AvenirNextLTPro-Regular' !important;
    }
}

.two-step-verify-container {
    .cardBlockComponent-title {
        @media only screen and (min-width: $mobileView) {
            margin-top: 9px;
            padding-bottom: 17px;
 
            &:focus-visible {
                outline: 0.25rem solid #8bb8e8;
            }
        }
        @media only screen and (max-width: $mobileView) {
            font-size: 16px;
            font-weight: 500;
            line-height: 17px;
            background-size: 24px 24px;
        }
    }

    .masked-phone-container {
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
        border-bottom: 1px solid $secondary10;
        font-size: 22px;
        line-height: 32px;

        @media only screen and (max-width: $mobileView) {
            font-size: 16px;
            font-weight: 500;
            padding: 16px;
            line-height: 17px;
        }
    }

    .cardBlockComponent-content {
    @media only screen and (max-width: $mobileView) {
            padding-top:32px;
        }
        .verification-status-message {
            @media only screen and (max-width: $mobileView) {
                font-size: 16px;
                letter-spacing: 0;
                line-height: 17px;
            }
        }
    }

    .buttonComponent {
        @media only screen and (max-width: $mobileView) {
            line-height: 17px;
            margin-top:32px;
        }
    }
}