@import '../../assets/scss/vars';

.checkboxComponent {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;

  input {
    height: 20px;
    min-width: 20px;
    width: 20px;
    margin-left: 4px;
    margin-right: 12px;
    appearance: none;
    border: 1px solid $secondary60;
    border-radius: 2px;
    padding: 0;
    margin-top: 3px;

    &:checked {
      border-color: $black;
      background: url('../../assets/images/Selected@3x.png') -11px center no-repeat;
      background-size: 38px;
    }
    &:checked::-ms-check {
      display: none;
    }
  }

  &-span {
    font-family: $regularFont;
  }

  &-label {
    position: relative;
    top: 5px;
    font-family: $boldFont;
  } 
}
.centeredCheckbox-label {
  .checkboxComponent-label{
    top: 2px;
  }
}
.caslCheckbox-label {
  .checkboxComponent-label{
    top: 0;
  font-family: $regularFont;
  line-height: 24px;
  }
}

