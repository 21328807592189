@import '../../assets/scss/vars';

.buttonComponent {
  @media print {
    -webkit-print-color-adjust: exact;
  }
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  color: $white;
  border: 1px solid $black;
  background-color: $black;
  font-family: $boldFont;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 16px;
  line-height: 24px;
  border-radius: 0;

  &.firstOrder {
    @media only screen and (min-width: $mobileView) {
      order: -1;
      margin-left: 0;
    }
    @media print {
      order: -1;
      margin-left: 0;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $secondary80;
  }

  &.white {
    background-color: $white;
    color: $black;
  }

  &.disabled {
    color: $secondary60;
    background-color: $secondary20;
    border: 0;
    pointer-events: none;
  }

  &.textTransform {
    text-transform: none;
  }

  &.right {
    margin-left: auto;
    display: flex;
  }

  .button-iconAfter {
    margin: 0 0 -3px 5px;
  }

  @media only screen and (min-width: $mobileView) {
    width: auto;
    min-width: 106px;
    margin-top: 0;
  }
}
