@import '../../assets/scss/vars.scss';
.change-card-pin {
  margin: 0;
  padding-top: 16px;
  ::-ms-reveal {
    display: none;
  }
  @media only screen and (max-width: $mobileView) {
    width: 100%;
  }
}

.change-pin-title {
  font-size: 30px;
  display: block;
  font-weight: bold;
  color: #191919;
  font-family: $boldFont;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 30px;

  @media only screen and (max-width: $mobileView) {
    margin-top: 16px;
  }
}

.changepin-wrapper {
  padding-top: 30px;
  // margin: 30px 30px 10px;
  padding: 20px;
  background-color: #ffffff;
}

.pincontent-divider {
  margin-top: 20px;
  border-bottom: 3px solid #f3f3f3;
}

.createpin-title {
  display: flex;
  justify-content: space-between;
  font-family: $regularFont;
}

.changepin-buttons {
  display: flex;
  // justify-content: flex-end;
  flex-direction: row-reverse;
  margin-top: 15px;
  //padding-right: 30px;
  //padding-left: 30px;
  //   width: 100%;
  //   background: #f3f3f3;
  //   margin-left: -30px;
  //   padding-top: 10px;
  .confirm-btn {
    padding-left: 15px;
  }

  @media only screen and (max-width: $mobileView) {
    // flex-direction: column-reverse;
    display: block;
    .confirm-btn {
      padding-left: 0px;
    }
  }
}

.change-card-pin-message {
  border: 1px solid #d1d1d1;
  // margin: 30px;
  padding: 18px;
  background-color: #ffffff;
  margin-bottom: 10px !important;
  border-left: 4px solid $feedbackSuccess;
  .success-message {
    background: url('../../assets/images/Success.svg') no-repeat left top;
    -webkit-print-color-adjust: exact;
    padding-bottom: 10px;
    line-height: 32px;
    border-bottom: 3px solid #f3f3f3;
    @media only screen and (max-width: $mobileView) {
      border-bottom: none;
    }
    #message {
      color: $feedbackSuccess;
      font-size: 18px;
      font-family: $boldFont;
      padding-left: 50px;
    }
  }
}

.message-pin-description {
  padding-top: 10px;
  color: #000;
  @media only screen and (max-width: $mobileView) {
    padding-left: 48px;
  }
  p {
    font-family: 'AvenirNextLTPro-Regular';
    font-size: 14px;
    margin: 0px;
  }
}

.change-card-pin-message-error {
  border: 1px solid #d1d1d1;
  //  margin: 30px;
  padding: 18px;
  background-color: #ffffff;
  margin-bottom: 10px !important;
  border-left: 4px solid $feedbackError;
  .error-pin-message {
    background: url('../../assets/images/Failed.svg') no-repeat left top;
    -webkit-print-color-adjust: exact;
    padding-bottom: 10px;
    line-height: 32px;
    border-bottom: 3px solid #f3f3f3;
    @media only screen and (max-width: $mobileView) {
      border-bottom: none;
    }
    #messageerror {
      color: $errorTitle;
      font-size: 18px;
      font-family: $boldFont;
      padding-left: 50px;
    }
  }
}

.changepin-footer {
  display: flex;
  justify-content: space-between;
  // margin: 0px 30px;
  flex-direction: row-reverse;

  @media only screen and (max-width: $mobileView) {
    display: block;
    margin: 0;
    #printiconchangepin {
      display: none;
    }
    // .mangage-btn {
    //   padding: 0 30px;
    // }
  }
}

.pin-note-pad {
  padding-top: 15px;
}

.pin-min-length {
  font-family: $regularFont;
  margin-top: 10px;
  margin-left: 20px;
}
