@import '../../assets/scss/vars.scss';

.main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 624px;
  border: 1px solid #e8e8e8;
  background-color: #b7dd79;
  box-sizing: border-box;
  height: 256px;
  @media only screen and (max-width: $mobileView) {
    flex-direction: column;
    position: relative;
    align-items: unset;
    width: 100%;
    height: auto;
  }

  .left-block {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 32px;
    box-sizing: border-box;

    .create-my-account {
      @media only screen and (max-width: $mobileView) {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 24px;
      }
    }
  }

  .right-block {
    background-image: linear-gradient(to bottom right, #b7dd79 50%, #fafafa 50%);
    height: 256px;
    display: flex;
    align-items: center;
    padding-right: 24px;
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 32px;
    box-sizing: border-box;
    @media only screen and (max-width: $mobileView) {
      justify-content: center;
      padding-bottom: 160px;
    }
  }

  .title {
    h1 {
      color: #191919;
      font-family: $boldFont;
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
      width: 327px;
      @media only screen and (max-width: $mobileView) {
        width: 315px;
      }
    }
  }

  .description {
    p {
      color: #191919;
      font-family: $regularFont;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      width: 327px;
      @media only screen and (max-width: $mobileView) {
        width: 315px;
      }
    }
  }
}
