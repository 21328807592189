@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.advancedModal-header {
  background-color: $black;
  padding: $spacing-2;
  color: $white;

  &-text {
    font-weight: $boldFont;
    font-size: $fontSize-6;
    line-height: 40px;
  }

  &-subText {
    font-weight: $boldFont;
    font-size: $fontSize-3;
    color: $mediumGreen;
  }
}

.MuiDialog-paperWidthSm {
  max-width: 624px !important;
  .MuiDialogContent-root {
    padding: 0;
    .content {
      padding: $spacing-2 $spacing-1;

      .card-item .card-item-content .description {
        @include fontSize-14;
      }

      @media only screen and (min-width: $mobileView) {
        padding: $spacing-2 $spacing-2 $spacing-3 $spacing-2;
      }
    }
    &:first-child {
      padding-top: $spacing-1;
      @media only screen and (min-width: $mobileView) {
        padding-top: 0 !important;
      }
    }
  }
}

.popup-footer-section {
  padding: 0 $spacing-2 $spacing-2 $spacing-2;
  align-items: center;
}
