@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.autorenew {
  .autorenew-agreement-note {
    padding-top: 16px;
  }
}

.autorenew-agreement-success {
  padding-bottom: 24px;
}

.manage-autoRenew1--title {
  .header-title {
    margin-bottom: 32px;

    @media only screen and (min-width: $mobileView) {
      margin-bottom: 40px;
    }
  }
}

.manage-autoRenew-container {
  width: auto;
  // margin-left: 25px;
  margin-bottom: 20px;

  @media only screen and (min-width: $mobileView) {
    width: 624px;
    // margin-left: 25px;
  }

  .manage-autoRenew1 {
    &--title {
      text-align: center;
    }

    &--summary {
      padding: 16px;
      font-family: 'AvenirNextLTPro-Regular';
      background-color: $white;
      margin-bottom: 12px;

      .billing-date {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $secondary10;

        p:nth-child(2) {
          font-family: 'AvenirNextLTPro-Demi', sans-serif;
        }

        @media only screen and (max-width: $mobileView) {
          display: block;

          p:nth-child(1) {
            margin-bottom: 0;
          }

          p:nth-child(2) {
            margin-top: 0;
          }
        }
      }

      .end-date-field {
        form {
          .selectbox {
            // margin-top: 16px;
            @media only screen and (min-width: $mobileView) {
              width: 144px;
              display: inline-block;
              margin: 0 16px 0px 0px;
            }
          }
        }

        .end-date-field--label {
          font-family: 'AvenirNextLTPro-Demi', sans-serif;
          font-size: 14px;
          padding: 7px 0px;
        }
      }
    }

    .checkout-payment-total {
      align-items: baseline;
      display: flex;
      border-top: 1px solid $secondary20;
      padding-top: $spacing-1;
      padding-bottom: $spacing-base;
      font-family: $boldFont;
      font-size: 16px;

      @media only screen and (min-width: $mobileView) {
        display: block;
        font-size: 16px;
        padding-top: 20px;
        text-align: right;
      }

      span {
        font-size: 20px;
        margin-left: auto;
        margin-right: 0;
        position: relative;
        top: 2px;
        padding-left: $spacing-base;

        @media only screen and (min-width: $mobileView) {
          font-size: 22px;
          padding-left: $spacing-4;
        }
      }
    }

    .checkout-subtext {
      font-size: 14px;
      font-family: $regularFont;
      text-align: center;
      padding: 0 0 $spacing-1;

      @media only screen and (min-width: $mobileView) {
        padding: 0 50px $spacing-1;
      }
    }

    .content-error-block {
      padding: 12px;
      margin-bottom: 0px;
    }

    .cardBlockComponent-title {
      @media only screen and (min-width: $mobileView) {
        padding: 16px;
      }
    }

    .cardBlockComponent-content {
      @media only screen and (min-width: $mobileView) {
        padding: 24px 16px;
      }
    }
  }
}

.checkout-terms-block {
  border-top: 1px solid $secondary20;
  padding-top: $spacing-1;
  padding-bottom: 24px;
}

.manageautorenew2-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  flex-direction: row-reverse;

  @media only screen and (max-width: $mobileView) {
    display: block;
    margin: 0;
    margin-top: 24px;

    #manageautorenew2-printicon {
      display: none;
    }
  }
}

.manage-autoRenew1-selectbox1-pad {
  @media only screen and (max-width: $mobileView) {
    margin-top: 0px;
  }
}

.manage-autoRenew1-selectbox2-pad {
  @media only screen and (max-width: $mobileView) {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}

.autonrenew1-dropdowns {
  @media only screen and (min-width: $mobileView) {
    display: flex;
  }
}

.autorenew-steps-pad {
  padding-top: 20px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 14px;
  }
}

.manage-autoRenew-container {
  .checkoutGrid-wrapper {
    margin-top: 0;

    .checkoutGrid {
      padding-top: 0;

      .checkoutGridItem {
        padding: $spacing-1 0 0;
        border: none;
      }
    }
  }

  .successModifier {
    .checkoutGrid {
      padding-bottom: 16px !important;
    }
  }

  @media only screen and (min-width: $mobileView) {
    .success-block-title {
      margin-left: 14px;
    }
    .without-margin-bottom {
      margin-left: 0px;
    }
  }
}

.manage-autorenew {
  .autorenew-agreement-success {
    padding-left: 65px;
  }
  .success-block {
    padding: 24px 16px;
    .checkoutGrid-wrapper {
      border-top: none !important;
      .checkoutGrid-info-wrapper {
        padding: 0;
        .checkoutGridItem-logo-block {
          padding-left: 0;
        }
      }
    }
  }
}
