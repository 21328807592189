.ttc-checkout {
  .header-title {
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 5px;
  }

  .confirmation-block {
    text-align: center;
    // font-family: 'AvenirNextLTPro-Regular';
  }

  .description {
    text-align: center;
    font-family: 'AvenirNextLTPro-Regular';
  }
}
