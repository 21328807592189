@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.go-default-trip-title {
    font-size: 48px;
    font-family: $boldFont;
    margin: 40px 0px 0px 0px;
    line-height: 72px;

    @media only screen and (max-width: $mobileView) {
        margin: 5px 0px 0px 0px;
        font-size: 30px;
    }
}

.less-transaction {
    font-family: $regularFont;
    line-height: 24.5px;
}

.trip-message {
    // font-size: 48px;
    font-family: $regularFont;
    line-height: 24.5px;

    @media only screen and (max-width: $mobileView) {
        margin: 0px 0px 0px 0px;
    }
}

.set-block-container {
    margin-top: 40px;

    @media only screen and (max-width: $mobileView) {
        margin: 30px 0px 0px 0px;
    }

    .station-title {
        font-family: $boldFont;
        font-size: 22px;
        padding: 8px 40px 24px 40px;
        margin: -16px 0 -15px 0px;
        background-color: #ffffff;
        border: 1px solid #e8e8e8;
        border-bottom: none;

        @media only screen and (max-width: $mobileView) {
            // margin: -15px 0px;
            margin-left: -6px;
        }

        .icon {
            margin: 0px;

            img {
                min-height: 48px;
                width: 48px;
            }
        }

        .station-sub-title {
            margin: -40px 0px 0px 60px;
            font-size: 22px;

            @media only screen and (max-width: $mobileView) {
                font-size: 16px;
                margin-top: -39px;
            }
        }
    }

    .station {
        margin: 50px 100px 50px 20px;

        @media only screen and (max-width: $mobileView) {
            margin: 15px 30px 20px 15px;
        }
    }

    .select-station2 {
        margin-top: 50px;

        @media only screen and (max-width: $mobileView) {
            margin-top: 20px;
        }
    }

    .form {
        padding: 10px 20px;
        margin: 10px 0px;
        background-color: #ffffff;
        border: 1px solid #e8e8e8;
        border-top: 1.5px solid #d1d1d1;
    }

    .btn {
        margin-top: 25px;
    }

    .retrieve-station {
        margin: 30px 100px 30px 20px;

        @media only screen and (max-width: $mobileView) {
            margin: 20px 30px 20px 15px;
        }
    }

    .station1 {
        display: flex;
        margin-bottom: 16px;

        .get-station1 {
            font-family: $boldFont;
            width: 50%;

            @media only screen and (max-width: $mobileView) {
                flex: 1;
            }

            div {
                @media only screen and (max-width: $mobileView) {
                    width: 110px;
                }
            }
        }

        .station-name1 {
            font-family: $regularFont;

            @media only screen and (max-width: $mobileView) {
                flex: 2;
                margin-left: 5px;
            }
        }
    }

    .station2 {
        display: flex;

        .get-station2 {
            font-family: $boldFont;
            width: 50%;

            @media only screen and (max-width: $mobileView) {
                flex: 1;
            }

            div {
                @media only screen and (max-width: $mobileView) {
                    width: 110px;
                }
            }
        }

        .station-name2 {
            font-family: $regularFont;

            @media only screen and (max-width: $mobileView) {
                flex: 2;
                margin-left: 5px;
            }
        }
    }
}

.set-trip-step2 {
    border: 1px solid #d1d1d1;
    margin: 20px 0px;
    padding: 18px;

    @media only screen and (max-width: $mobileView) {
        margin: 20px 0px 0px 0px;
    }

    background-color: #ffffff;
    margin-bottom: 10px !important;
    border-left: 4px solid $feedbackSuccess;

    .success-message {
        background: url('../../assets/images/Success.svg') no-repeat left top;
        // -webkit-print-color-adjust: exact;
        padding-bottom: 10px;
        line-height: 32px;
        // border-bottom: 1px solid #191919;

        @media only screen and (max-width: $mobileView) {
            border: none;
        }

        #message {
            color: $feedbackSuccess;
            font-size: 30px;
            font-family: $boldFont;
            padding-left: 50px;
        }
    }

    .success-description {
        font-family: $regularFont;
        margin: -10px 0px 25px -40px;
        line-height: 24px;

        @media only screen and (max-width: $mobileView) {
            margin: 10px 0px 20px 10px;
        }
    }

    hr {
        border: 0.9px solid #d1d1d1;
        margin-right: 25px;
        margin-left: 8px;

        @media only screen and (max-width: $mobileView) {
            margin: 0px;
            border: 0.8px solid #d1d1d1;
        }
    }

    .success-sub-description {
        font-family: $regularFont;
        margin: 0px 0px 10px 9px;
        line-height: 24px;
        border-top: 1.5px solid #d1d1d1;
        padding-top: 10px;
    }
}

.go-default-error-block {
    padding: 18px;
    border: 1px solid #e8e8e8;
    border-left: 4px solid #d63d3d;
    background-color: #ffffff;
    margin: 20px 0px 10px 0px;

    .error-block-title {
        color: $feedbackError;
        font-size: 22px;
        font-family: $boldFont;
        padding-left: $spacing-5;
        line-height: 32px;
        margin-bottom: $spacing-1;
        background: url('../../assets/images/error.svg') no-repeat left top;
    }

    @media only screen and (max-width: $mobileView) {
        margin-top: 20px;
        padding: 10px;
    }
}

.error-blocked {
    font-family: $regularFont;
    line-height: 24px;
    margin-top: 30px;

    @media only screen and (max-width: $mobileView) {
        margin-top: 25px;
    }
}