@import '../../assets/scss/vars.scss';

.B2CCreateAccount {
  width: 624px;
  margin: 0 auto;
  @media only screen and (max-width: $mobileView) {
    width: unset;
    margin: unset;
  }
  .three-steps {
    @media only screen and (max-width: $mobileView) {
      width: 320px;
    }
  }
  .account-info-description {
    font-family: $regularFont;
    text-align: center;
    margin-bottom: 32px;
    line-height: 24px;
  }

  .account-info-block {
    // text-align: center;
    // align-items: center;
    // width: 624px;
    // margin: 0 auto;
    margin-bottom: 50px;
    .customer-type-error {
      .content-error-block {
        padding: 12px;
        border: none;
        border-left: none;
        margin-bottom: 0;
        padding-bottom: 0;
        .content-error-block-title {
          font-size: 14px;
          background-size: 16px;
          line-height: 24px;
          padding-left: 28px;
          margin-bottom: 0;
        }
      }
    }
    .account-info-textinputs {
      padding: 0px 24px 36px 24px;
      .inlineError {
        margin-top: -3px;
        margin-bottom: 10px;
      }
      .text-field:first-child {
        margin-top: 12px;
      }
    }
    .account-info-terms-and-conditions {
      padding-top: 20px;
      padding-bottom: 24px;
    }
    .next-button {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 18px;
      padding-right: 24px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  .account-info-captcha {
    font-family: $regularFont;
    width: 624px;
    margin: 48px auto;
    // text-align: center;
  }
  @media only screen and (max-width: $mobileView) {
    width: unset;
    margin: unset;
  }
}
