@import '../../../assets/scss/vars';

.transitPassItem {
  background-color: $white;
  border: 1px solid $secondary10;
  position: relative;
  margin-top: $spacing-base;

  &.success {
    border: none;
    border-top: 2px solid $secondary20;

    &-wrapper {
      padding: $spacing-1 0;
    }
  }

  &-wrapper {
    padding: 24px;
    position: relative;
    display: flex;

    & + .transitPassItem-wrapper {
      padding-top: 0;
      padding-left: $spacing-5;

      @media only screen and (min-width: $mobileView) {
        padding-left: $spacing-12;
      }
    }
  }

  &-logo-block {
    display: flex;
    align-items: start;
    padding-right: $spacing-base;
    width: $spacing-2;

    @media only screen and (min-width: $mobileView) {
      padding: 0 $spacing-1 0 $spacing-base;
      width: $spacing-7;
    }

    img {
      width: $spacing-2;
      display: flex;

      @media only screen and (min-width: $mobileView) {
        width: $spacing-7;
      }
    }
  }

  &-texts-block {
    flex: 1;

    &-wrapper {
      @media only screen and (min-width: $mobileView) {
        display: flex;
        margin-top: $spacing-base;
      }

      & + .transitPassItem-texts-block-wrapper {
        margin-top: $spacing-2;
        border-top: 1px solid $secondary20;
        padding-top: $spacing-1;
      }
    }
  }

  &-middle {
    @media only screen and (min-width: $mobileView) {
      flex: 1;
    }
  }

  &-right {
    margin-top: $spacing-base;

    @media only screen and (min-width: $mobileView) {
      flex: 1;
      text-align: right;
      margin-top: 0;
    }
  }

  &-bottom {
    flex: 1;
    border-top: 1px solid $secondary20;
    padding-top: $spacing-1;

    @media only screen and (min-width: $mobileView) {
      display: flex;
    }
  }

  &-action {
    margin-top: $spacing-base;

    // @media only screen and (min-width: $mobileView) {
    //   margin-top: 0;
    // }

    button {
      text-decoration: underline;
      padding: 0;
    }
  }

  &-date {
    font-family: $regularFont;
    font-size: 14px;
    margin-top: $spacing-base;
  }

  &-label {
    font-family: $regularFont;
  }
}
.success {
  .transitPassItem-wrapper {
    @media only screen and (max-width: $mobileView) {
      padding-left: 0;
      padding-bottom: 16px;
      padding-top: 8px;
    }
  }
  .TTCCancelPassItem-wrapper {
    @media only screen and (max-width: $mobileView) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.pass-managenew-btn {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}

.TTCCancelPassItem-wrapper {
  padding: 0px 24px;
  position: relative;
  display: flex;

  .transitPassItem-bottom {
    padding-bottom: 16px;
  }
}

.hide-ttc-logo {
  @media only screen and (max-width: $mobileView) {
    display: none;
  }
}
