@import '../../assets/scss/vars.scss';
.GoogleMap{
    margin-bottom: 16px;
}
#google
{
    @media only screen and (max-width: $mobileView)
    {
        width: 365px;
    }
    
}