@import '../../assets/scss/vars.scss';

.redeemvoucher-header {
  font-size: 32px;
  text-align: center;
  margin-bottom: 8px;
  line-height: 48px;
  margin-top: 38px;
  @media only screen and (min-width: $mobileView) {
    font-size: 48px;
    line-height: 72px;
  }
  @media only screen and (max-width: $mobileView) {
    margin-bottom: 8px;
    margin-top: 24px;
  }
}
.redeemvoucher-whole-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $mobileView) {
    align-items: unset;
    .redeemvoucher-wrapper {
      margin-left: 0;
      padding: 0;
    }
  }

  .redeemvoucher-success-content {
    overflow-wrap: break-word;
    font-family: 'AvenirNextLTPro-Regular';
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
  }

  .card-block {
    .redeemvoucher-tab {
      .cardBlockComponent {
        .cardBlockComponent-title {
          border-bottom: 0;
          overflow-wrap: break-word;
        }
      }
    }
  }
  .card-block-image {
    margin-bottom: 0;
    .cardBlockComponent-content {
      padding-bottom: 0;
    }
  }
}
.redeemvoucher-wrapper {
  width: auto;
  margin-left: 25px;

  @media only screen and (min-width: $mobileView) {
    width: 624px;
  }
  .redeemvoucher-tab {
    .cardBlockComponent {
      .cardBlockComponent-content {
        .birthdate-redeem {
          font-family: $boldFont;
          display: block;
          margin-top: 16px;
        }
      }
    }
  }

  .block3 {
    margin-top: 16px;
    .redeem-success {
      .without-margin-bottom {
        @media only screen and (max-width: $mobileView) {
          padding-top: 4px;
        }
      }
    }
  }

  .please-note {
    font-family: $boldFont;
  }

  .item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #d1d1d1;
    padding-bottom: 16px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .item {
      .agencyOption-date {
        font-family: 'AvenirNextLTPro-Regular';
        @media only screen and (max-width: $mobileView) {
          margin-top: 8px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .item-image-wrapper {
    display: flex;
    align-items: center;
    @media only screen and (max-width: $mobileView) {
      display: flex;
      align-items: flex-start;
    }

    .item-image {
      margin-right: 10px;
      img {
        margin-right: 10px;
      }
    }
    .item-wrapper {
      flex: 2 1;
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .item-note {
    // line-height: $lineHeight-24;
    @media only screen and (max-width: $mobileView) {
      font-size: 21px;
    }
  }
  .price-note {
    @media only screen and (max-width: $mobileView) {
      font-size: 21px;
    }
  }
  .redeemvoucher-tab {
    .setup-autoload {
      background-color: $white;
    }
    .redeem-success {
      margin-bottom: 15px;
    }
    .checkout-terms-block {
      border-top: 2px solid $secondary20;
      padding-top: 16px;
      margin-top: 15px;
    }
    @media only screen and (max-width: $mobileView) {
      .h2-title {
        margin-top: 8px;
      }
    }
  }
  .note-step1 {
    .note {
      margin-top: 0;
      span {
        p {
          margin: 16px 0;
        }
      }
    }
  }
  .note-step3 {
    .cardBlockComponent {
      .cardBlockComponent-title {
        border-bottom: 2px solid #e8e8e8;
      }
      // .cardBlockComponent-content {
      //   padding-left: 20px;
      // }
    }
    .note {
      margin-top: 0;
      margin-bottom: 16px;
      border: 2px solid #e8e8e8;
      border-left: 4px solid #8bb8e8;

      .note-redeem {
        font-family: $boldFont;
      }
    }
    .withIcon {
      background-position: 17px 12px;
    }
  }
  .step3 {
    .withIcon {
      margin-top: 16px;
    }
  }
  .text-input-redeem {
    display: flex;
    margin-bottom: 10px;

    .text-field-wrapper {
      // .text-field-input {
      //   ::placeholder {
      //     /* Chrome, Firefox, Opera, Safari 10.1+ */
      //     color: red;
      //     opacity: 1; /* Firefox */
      //   }
      // }
      input::placeholder {
        color: #181818;
      }
    }

    .text-field {
      margin-top: 8px;
    }
    .text-field-input-error {
      border-bottom: 2px solid #d63d3d;
    }

    @media only screen and (max-width: $mobileView) {
      gap: 0.5rem;
      .text-field:nth-child(1) {
        flex: 1;
      }
      :nth-child(2) {
        flex: 1;
      }
      :nth-child(3) {
        flex: 1.1;
      }
    }
  }
  .date-picker {
    display: flex;
    align-items: flex-end;
    @media only screen and (max-width: $mobileView) {
      padding-right: 20px;
      .datePickerInput {
        margin-bottom: 0;
      }
      .react-datepicker__year-dropdown-container--select {
        flex: 0 0;
      }
      .react-datepicker__month-select {
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          color: #191919;
        }
      }
      .react-datepicker__year-select {
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          color: #191919;
        }
      }
    }
    .react-datepicker__navigation--next {
      width: 50px;
    }
    .react-datepicker__navigation--previous {
      width: 50px;
    }
    .react-datepicker__month-container {
      padding: 8px;
    }
    .react-datepicker__header {
      padding-top: 10px;
    }
  }
  .text-input-redeem + .inlineError {
    width: 60%;
    margin-left: 3px;
  }
  .redeem-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    .redeem-print {
      margin-bottom: 24px;
      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
    .redeem-confirm-button {
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }
}
.secondBlock {
  .redeemvoucher-tab {
    .cardBlockComponent {
      .cardBlockComponent-title {
        border-bottom: 2px solid #e8e8e8;
      }
      .cardBlockComponent-content {
        padding-left: 20px;
      }
    }
  }
}
.submit-button {
  .redeemvoucher-tab {
    .button-wrapper {
      margin-top: 0;
      .buttonComponent:first-child {
        @media only screen and (max-width: $mobileView) {
          margin-top: 4px;
        }
      }
    }
  }
}

.redeemvoucher-add {
  .redeemvoucher-header {
    margin-top: 24px;
  }
  .option-already-added {
    font-size: 16px;
    line-height: 24px;
    margin: $spacing-1 0 0;
  }
  .selectbox {
    vertical-align: top;
  }
}
.voucher-title {
  text-align: center;
  font-size: 18px;
  font-family: $regularFont;
  padding-bottom: 32px;
  line-height: 24px;
}
.react-datepicker__header__dropdown--select {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.react-datepicker__month-select {
  border: 0;
  border-bottom: 2px solid #000;
  padding: 10px;
  font-size: 16px;
  background-color: #e8e8e8;
  padding-left: 24px;
  padding-right: 24px;
}

.react-datepicker__year-select {
  border: 0;
  border-bottom: 2px solid #000;
  padding: 10px;
  font-size: 16px;
  background-color: #e8e8e8;
  padding-left: 24px;
  padding-right: 24px;
}

.react-datepicker__year-read-view--selected-year {
  margin-right: 10px;
}

.react-datepicker__month-read-view--selected-month {
  margin-right: 10px;
}

.react-datepicker__month-read-view--down-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.react-datepicker__year-read-view--down-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.error-card-block {
  border-left: 3px solid #de3d3d;
  width: 90%;
  margin-left: 30px;
  .redeem-error {
    display: flex;
  }
  .error-occured {
    display: flex;
    color: #d63d3d;
    font-family: 'AvenirNextLTPro-Demi';
    padding-bottom: 10px;
    font-size: 20px;
  }
}
.print-screen-error {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 30px;
}
.item-price {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 2px solid #d1d1d1;
  margin-top: 20px;
  font-family: 'AvenirNextLTPro-Demi';
  @media only screen and (max-width: $mobileView) {
    padding: 16px 0;
  }
}
.item-price-discounted {
  padding-top: 16px;
  .item-image-wrapper {
    .item-image {
      margin-left: 20px;
      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
    .item-wrapper {
      .item {
        margin-right: 16px;
        @media only screen and (max-width: $mobileView) {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: $mobileView) {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        :first-child {
          width: 200px;
        }
      }
    }
  }
}

.confirmation-buttons {
  display: flex;
  .checkout-btn {
    padding-left: 20px;
    .buttonComponent:first-child {
      @media only screen and (max-width: $mobileView) {
        margin-top: 4px;
      }
    }
  }

  @media only screen and (max-width: $mobileView) {
    flex-direction: column;

    .checkout-btn {
      padding-left: 0px;
    }
  }
}
.redeem-card-note {
  text-align: left;
  overflow-wrap: break-word;
  font-family: 'AvenirNextLTPro-Regular';
  font-size: 15px;
  line-height: 30px;
  padding: 18px 24px 0;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
  }
}
// .redeem-voucher--steps {
//   padding-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 14px;
//   }
// }
.redeem-terms-block {
  .checkout-terms-block {
    padding-bottom: 12px;

    .checkboxComponent-label {
      top: 3px;
    }
  }
}
.redeemvoucher-stepper-wrapper {
  padding-top: 16px;
}
