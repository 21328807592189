@import '../../assets/scss/vars.scss';

.password-success-block {
  width: 39rem;
  padding-bottom: 24px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
    margin-top: 22px;
  }
}

.password-error-block {
  width: 39rem;

  @media only screen and (max-width: $mobileView) {
    width: auto;
    margin-top: 22px;
  }
}

.password-wrapper {
  padding: 0px 0px 20px 0px;
  background-color: #fff;
  margin-bottom: 16px;
  max-width: 40rem;

  @media only screen and (max-width: $mobileView) {
    width: auto;
  }

  .password-flex {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;

    @media only screen and (max-width: $mobileView) {
      display: block;
    }

    .password-label {
      font-size: 18px;
      margin-top: 10px;
      display: flex;
      align-items: center;

      @media only screen and (max-width: $mobileView) {
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
      }

      span {
        padding-right: 12px;

        @media only screen and (max-width: $mobileView) {
          justify-content: flex-end;
          display: flex;
        }
      }

      .labelName {
        &:focus-visible {
          outline: 0.25rem solid #8bb8e8;
        }

        @media only screen and (max-width: $mobileView) {
          padding-top: 0;
        }
      }
    }

    .password-content {
      @media only screen and (max-width: $mobileView) {
        margin-top: -1.5rem;
      }

      input[type='password'][disabled] {
        //color: inherit;

        @media only screen and (max-width: $mobileView) {
          @supports (-webkit-touch-callout: none) {
            //color: #191919;
          }
        }
      }

      input {
        border: none;
        font-size: 40px;
        margin-top: 10px;
        background-color: transparent;
        color: #191919;
        -webkit-text-fill-color: #191919;
        opacity: 1;
        text-align: end;
        padding-right: 0px;

        @supports (-moz-appearance: none) {
          font-size: 24px;
          margin-top: 15px;
        }

        @media only screen and (max-width: $mobileView) {
          width: 8rem;
          padding-left: 0;
          font-size: 30px;
          text-align: start;
        }
      }
    }
  }

  .password-button {
    flex-direction: row-reverse;
    padding-right: 20px;
    padding-top: 10px;

    @media only screen and (max-width: $mobileView) {
      width: auto;
      padding-left: 20px;
    }
  }

  .password-header {
    font-size: 21px;
    padding-left: 24px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #d1d1d1;
  }

  .update-pass-descr {
    font-family: 'AvenirNextLTPro-Regular';
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 20px;
  }

  .update-pass-form {
    padding-left: 24px;
    padding-right: 24px;

    .update-input-block {
      ::-ms-reveal {
        display: none;
      }

      .current-password {
        display: flex;
        // width: 450px;
        margin: 1.5rem 0rem 1.5rem 0rem;

        @media only screen and (max-width: $mobileView) {
          width: initial;
          display: block;
        }

        .update-current {
          width: 17rem;

          @media only screen and (max-width: $mobileView) {
            width: auto;
          }

          .input-block-field {
            margin-top: -25px;
            display: flex;
            position: relative;

            @media only screen and (max-width: $mobileView) {
              margin-top: -18px;
            }

            .text-field .text-field-input {
              padding-right: 52px;
            }

            .eye-Icon {
              margin-top: 48px;
              position: absolute;
              left: 82%;
              color: grey;

              @media only screen and (max-width: $mobileView) {
                margin-top: 40px;
                left: 90%;
              }
            }
          }
        }
      }

      .new-pass-block {
        display: flex;
        // width: 450px;
        margin: 1.5rem 0rem 1.5rem 0rem;

        // align-items: flex-end;
        @media only screen and (max-width: $mobileView) {
          width: initial;
          display: block;
        }

        .update-new-pass {
          width: 17rem;

          @media only screen and (max-width: $mobileView) {
            width: auto;
          }

          .update-new-field {
            margin-top: -25px;
            display: flex;
            position: relative;

            @media only screen and (max-width: $mobileView) {
              margin-top: -18px;
            }

            .text-field .text-field-input {
              padding-right: 52px;
            }

            .eye-Icon {
              margin-top: 48px;
              position: absolute;
              left: 82%;
              color: grey;

              @media only screen and (max-width: $mobileView) {
                margin-top: 40px;
                left: 90%;
              }
            }
          }
        }

        .confirm-new-pass {
          width: 17rem;
          margin-left: 1rem;

          .input-block-label {
            width: 90%;
          }

          @media only screen and (max-width: $mobileView) {
            width: auto;
          }

          .confirm-new-field {
            margin-top: -25px;
            display: flex;
            position: relative;

            @media only screen and (max-width: $mobileView) {
              margin-top: -18px;
            }

            .text-field .text-field-input {
              padding-right: 52px;
            }

            .eye-Icon {
              margin-top: 48px;
              position: absolute;
              left: 82%;
              color: grey;

              @media only screen and (max-width: $mobileView) {
                margin-top: 40px;
                left: 90%;
              }
            }
          }

          @media only screen and (max-width: $mobileView) {
            margin-left: 0;
            margin-top: 25px;
          }
        }
      }
    }

    .update-Pass-Button {
      justify-content: flex-end;
      padding-top: 10px;
      margin-right: 32px;

      .buttonComponent:nth-child(1) {
        background: #fff;
        color: #000;
      }

      @media only screen and (max-width: $mobileView) {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}