@import '../../assets/scss/vars';

.pagination {
  display: flex;

  &-page {
    flex: 1 auto auto;
    align-items: center;
    justify-content: center;
    display: flex;
    @media only screen and (min-width: $mobileView) {
      width: 68px;
    }
  }

  &-button {
    border: 1px solid $black;
    width: 44px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.second {
      border-left: none;
    }

    &.disabled {
      background-color: $secondary20;
    }
  }
}
