@import '../../assets/scss/vars';

.system-message {
  padding: $spacing-2;
  border: 1px solid $secondary10;
  background-color: $white;
  margin-bottom: $spacing-1;

  &.red {
    border-left: 4px solid $feedbackError;

    .system-message-heading {
      display: flex;
      justify-content: space-between;

      .system-message-title {
        color: $feedbackError;
        background: url('../../assets/images/error.svg') no-repeat left top;
      }

      .system-message-balance {
        color: $feedbackError;
      }
    }
  }

  &.blue {
    border-left: 4px solid $mediumCyanBlue;

    .system-message-heading {
      display: flex;
      justify-content: space-between;


      .system-message-title {
        background: url('../../assets/images/Info.svg') no-repeat left top;
      }

      .system-message-balance {
        color: $feedbackError;
      }
    }
  }

  &-title {
    font-size: 22px;
    background-size: $spacing-2;
    background-position: 0 5px;
    margin-bottom: 0;
    line-height: $spacing-3;
    padding: 0 0 $spacing-1 $spacing-5;
  }

  &-balance {
    font-size: 22px;
    background-size: $spacing-2;
    background-position: 0 5px;
    margin-bottom: 0;
    line-height: $spacing-3;
    padding: 0 0 $spacing-1 $spacing-5;
  }

  &-text {
    font-family: $regularFont;
    font-size: $spacing-1;
    line-height: $spacing-2;
  }
}