@import '../../assets/scss/vars.scss';

.loyalty-summary-container {
  @media only screen and (max-width: $mobileView) {
    width: auto;
    margin-left: 0px;
    margin-top: 10px;
    padding: 0px;
  }
  &--description {
    font-family: 'AvenirNextLTPro-Regular';
    line-height: 24px;
  }
  &--form {
    margin-bottom: 20px;
    form {
      display: flex;
      flex-direction: column;
      align-items: end;
      @media only screen and (min-width: $mobileView) {
        flex-direction: row;
      }
      .selectbox {
        margin-top: 15px;
        flex-basis: 100%;
        min-width: 100%;

        @media only screen and (min-width: $mobileView) {
          flex-basis: 35%;
          min-width: 35%;
          padding-right: 16px;
        }
        &.selectbox-error {
          .inlineError {
            position: inherit;
            @media only screen and (min-width: $mobileView) {
              position: absolute;
            }
          }
        }
      }
      .buttonComponent {
        flex-basis: 20%;
        height: 49px;
        @media only screen and (min-width: $mobileView) {
          margin-top: 40px;
          margin-left: 40px;
        }
      }
      .mobileSelect {
        color: inherit;
      }
    }
  }
  &--output {
    border: 2px solid $secondary10;
    background-color: $white;
    &-noRecord {
      padding: 20px;
      font-family: 'AvenirNextLTPro-Regular';
      text-align: center;
    }
    &-data {
      .loyalty-summary--info {
        list-style-type: none;
        margin: 0px;
        padding: 20px;
        li {
          display: flex;
          justify-content: space-between;
          .key {
            font-family: 'AvenirNextLTPro-Regular';
            flex-basis: 50%;
          }
          .value {
            flex-basis: 50%;
            text-align: right;
          }
        }
        li:not(:last-child) {
          border-bottom: 2px solid #eeeeee;
          padding-bottom: 15px;
        }
        li:not(:first-child) {
          padding-top: 15px;
        }
      }
    }
    &-error {
      border: 2px solid $secondary10;
      text-align: center;
      font-family: 'AvenirNextLTPro-Regular';
      padding: 20px;
      h4 {
        padding: 0px 0px 15px 0px;
        margin: 0px;
      }
    }
  }
  &--footer {
    margin: 13px 0px;
    @media only screen and (max-width: $mobileView) {
      display: none;
    }
  }
}
