@import '../../assets/scss/vars.scss';

.email-verify-container {
  margin: auto;
  width: 620px;
  padding: 20px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
  }

  h2 {
    font-size: 48px;
    line-height: 72px;
    text-align: center;
  }

  .email-verify-content {
    padding: 30px 10px;
    background-color: #fff;

    &.success {
      border-left: 5px solid $feedbackSuccess;
    }

    &.error {
      border-left: 5px solid $feedbackError;
    }

    .email-verify-success-message {
      background: url('../../assets/images/Success.svg') no-repeat left top;
      .verify-email-info {
        font-family: 'AvenirNextLTPro-bold';
      }
      #message {
        color: $feedbackSuccess;
        @media only screen and (min-width: $mobileView) {
          padding-bottom: 16px;
          border-bottom: 2px solid #dedede;
        }
      }
    }

    .email-verify-error-message {
      background: url('../../assets/images/error.svg') no-repeat left top;

      #message {
        color: $feedbackError;
        @media only screen and (min-width: $mobileView) {
          padding-bottom: 16px;
          border-bottom: 2px solid #dedede;
        }
      }
    }

    .email-verify-success-message,
    .email-verify-error-message {
      -webkit-print-color-adjust: exact;
      padding-bottom: 10px;
      line-height: 32px;

      p {
        margin: 0px;
        font-family: 'AvenirNextLTPro-Regular';
        line-height: 24px;
        margin-top: 10px;
        padding-left: 50px;
      }

      #message {
        font-size: 18px;
        font-family: $boldFont;
        padding-left: 50px;
      }
    }
  }

  .email-verify-footer {
    margin: 10px 0px;
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: right;

    @media only screen and (max-width: $mobileView) {
      flex-direction: column-reverse;
    }

    .buttonComponent {
      margin-left: 10px;

      @media only screen and (max-width: $mobileView) {
        margin-bottom: 5px;
        margin-left: 0px;
      }
    }
  }
}
