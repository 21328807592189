@import '../../assets/scss/vars.scss';

.topNav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  font-family: AvenirNextLTPro-Regular;
}

.topNav-both {
  position: fixed;
  top: 118px;
  width: 100%;
  z-index: 200;
  font-family: AvenirNextLTPro-Regular;
}

.topNav-smartbanner {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 200;
  font-family: AvenirNextLTPro-Regular;
}

.topNav-translate {
  position: fixed;
  top: 40px;
  width: 100%;
  z-index: 200;
  font-family: AvenirNextLTPro-Regular;
}

.topNav__wrapper {
  // padding: 0 12%;
  margin: 0 auto;
  height: 3.5rem;
  //  background: #191919;
  width: 1024px;
  display: flex;
  align-items: center;
  font-weight: 700;

  @media only screen and (max-width: 920px) {
    padding: 0 5%;
    width: unset;
  }

  // @media only screen and (min-width: 1024px) and (max-width: 1260px) {
  //   padding: 0 12% 0 11%;
  // }
  .prestologolink {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    margin-top: 4px;
  }

  .topNav__left,
  .topNav__right {
    display: none;
    margin-left: 7%;

    .link {
      text-decoration: none;
      color: #fff;
      font-family: "AvenirNextLTPro-Demi", sans-serif;
      white-space: nowrap;
    }

    .topNav__link {
      text-decoration: none;
      color: #fff;
      font-family: "AvenirNextLTPro-Demi", sans-serif;
      white-space: nowrap;
      cursor: pointer;
    }

    .topNav__link {
      text-decoration: none;
      color: #fff;
      font-family: "AvenirNextLTPro-Demi", sans-serif;
      white-space: nowrap;
      cursor: pointer;
    }

    .topNav__link.active {
      color: #8bb8e8;
    }
  }

  .topNav__left {
    .topNav__link:first-child {
      margin-right: 2.9rem;
    }
  }

  .topNav__right {
    .topNav__link:first-child {
      margin-right: 2.5rem;
    }
  }

  .topNav__hamburger {
    display: none;
    margin-left: auto;

    .topNav__hamburger-container {
      color: #fff;
    }
  }
}

.topNav__container {
  background-color: #191919;

  @media (max-width: 768px) {
    .topNav__wrapper {
      .topNav__hamburger {
        display: block;
      }
    }
  }

  @media (min-width: 768px) {
    .topNav__wrapper {
      .topNav__left {
        display: block;
      }

      .topNav__right {
        display: flex;
        align-items: flex-end;
        margin-left: auto;
      }
    }
  }

  @media only screen and (max-device-width: 1365px) and (min-device-width: 1261px) {
    .topNav__wrapper {

      //  padding: 0 12% 0 16%;
      .topNav__left {
        display: block;
      }

      .topNav__right {
        display: flex;
        align-items: flex-end;
        margin-left: auto;
      }
    }
  }
}

.presto-nav-mobile {
  display: none;
}

.presto-nav-mobile.show-nav {
  display: block;
}

.nav__wrapper.presto-nav-mobile {
  position: fixed;
  top: 3.4375rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.84);

  .nav__container {
    max-width: 77vw;
    left: 23vw;
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    background: #fff;
    top: 3.5rem;
    max-height: 100vh;
    overflow-y: scroll;

    .nav__section {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 61px;
      overflow: hidden;
      transition: all 0.5s;

      .nav__section-title {
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: left;
        border-bottom: 1px solid #7d7d7d;
        padding: 1rem;
        // font-family: AvenirNextLTPro-Regular;
        margin-left: auto;
        position: relative;
        width: 100%;
        color: #191919;
        font-family: 'AvenirNextLTPro-Demi', sans-serif;

        .signin__title {
          color: #191919;
          text-decoration: none;
        }

        .nav__section-title__link {
          text-decoration: none;
          color: #1e1e1e;
        }
      }

      .nav__section-title--blue {
        background-color: #8bb8e8;
      }

      .nav__section-language {
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: left;
        padding: 1rem;
        font-family: AvenirNextLTPro-Regular;
        margin-left: auto;
        position: relative;
        width: 100%;
      }
    }

    .nav__section:last-child {
      margin-bottom: 4.4375rem;
    }

    .nav__sub-section {
      list-style: none;
      padding-left: 1.5rem;
      margin-top: 2rem;

      .nav__sub-section-title {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.75rem;
        margin-bottom: 0.5rem;
        font-family: AvenirNextLTPro-Regular;
        position: relative;

        .nav__sub-section-title__link {
          text-decoration: none;
          color: #191919;
        }
      }

      .nav__sub-section-title.nav__sub-section-title--active {
        position: relative;
      }

      .nav__sub-section-title.nav__sub-section-title--active:before {
        content: '';
        position: absolute;
        background-color: #8bb8e8;
        height: 100%;
        width: 0.25rem;
        margin-left: -0.5rem;
      }

      .nav__link-container {
        margin-bottom: 0.875rem;

        .nav__link {
          line-height: 1.3125rem;
          font-size: 0.875rem;
          text-decoration: none;
          color: #191919;
          font-family: AvenirNextLTPro-Regular;
        }
      }

      .nav__link-section-title--active {
        position: relative;
      }

      .nav__link-section-title--active:before {
        content: '';
        position: absolute;
        background-color: #8bb8e8;
        height: 100%;
        width: 0.25rem;
        margin-left: -0.5rem;
      }
    }
  }
}

.presto-header-logo {
  width: 98px;
  height: 20px;
}