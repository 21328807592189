@import '../../assets/scss/vars.scss';

.NotificationBanner-wrapper {
  margin-bottom: 1px;
  .NotificationBanner-container {
    padding: 10px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    @media only screen and (min-width: $mobileView) {
      width: 780px;
    }
    @media only screen and (min-width: 1120px) {
      width: 950px;
    }

    .NotificationBanner {
      display: flex;

      img {
        align-self: auto;
      }

      .NotificationBanner-content {
        p {
          margin: 0px 5px;
        }
      }
    }

    img {
      align-self: center;
      cursor: pointer;
    }
  }
}
