@import '../../assets/scss/vars';

.subHeader {
  color: $black;
  text-align: left;
  margin-bottom: $spacing-3;
  font-family: $regularFont;
  line-height: 24px;

  &.centered {
    text-align: center;
  }
}
