@import '../../assets/scss/vars.scss';

.contact-us-form-accordion {
  background-color: $white;
  font-size: $spacing-1;
  margin-bottom: 1rem;
  // margin-right: 4%;
  line-height: 2rem;
  border: 1px solid #e8e8e8;
  @media only screen and (max-width: $mobileView) {
    margin-right: auto;
    // margin-left: 20px;
  }
  p {
    color: $text-color-black;
  }
  button:focus:not(:focus-visible) {
    outline: none;
  }

  .accordion-heading {
    padding: 24px 16px 24px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    line-height: 2rem;
    // border: 1px solid #e8e8e8;
    box-shadow: none;
    // outline: none;
    @media only screen and (max-width: $mobileView) {
      padding-left: 16px;
    }
    img {
      float: right;
      margin-left: $spacing-1;
      @media only screen and (max-width: $mobileView) {
        margin-right: -8px;
      }
    }

    img.up {
      transform: rotate(180deg);
    }

    p {
      margin: 0;
      padding: 0;
      text-align: left;
    }
  }
  .accordion-content {
    border-top: 1px solid #e8e8e8;
    p {
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 20px;
      }
    }
    .cardBlockComponent {
      border: none;
      margin-bottom: 8px;
    }
    .contact-us-form {
      .parent-wrapper {
        display: flex;
        @media only screen and (max-width: $mobileView) {
          display: block;
        }
        .column-one {
          margin-right: 35px;
          width: 100%;
          .text-field {
            margin-top: 8px;
            margin-bottom: 21px;
            @media only screen and (max-width: $mobileView) {
              margin-bottom: 30px;
            }
          }
        }
        .column-two {
          width: 100%;
          .text-field {
            margin-top: 8px;
          }
          .selectbox {
            margin-top: 8px;
            font-family: $boldFont;
            .textField {
              width: 250px;
            }
          }
        }
      }
      .extra-margin {
        @media only screen and (max-width: $mobileView) {
          margin-top: 30px;
        }
      }
      .comments-questions {
        @media only screen and (max-width: $mobileView) {
          margin-top: 30px;
        }
        .comments-bold {
          font-size: 14px;
          font-family: $boldFont;
        }
        .comments-not-bold {
          font-size: 14px;
        }
        // height: 100px;
        .text-field {
          margin-top: 8px;
        }
      }
      .checkbox-contactus-form {
        margin-top: 24px;
        @media only screen and (max-width: $mobileView) {
          margin-top: 50px;
        }
        .checkboxComponent-label {
          font-family: $regularFont;
        }
        margin-bottom: 40px;
      }
    }
  }
}

.contact-us-form-policy {
  padding-top: 40px;
}
