@import '../../../assets/scss/vars';

.transactionFilters {
  background: $white;
  border: 1px solid $secondary10;
  padding: $spacing-1;
  position: relative;
  margin-top: $spacing-base;

  &-label {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: $spacing-base;
    text-transform: uppercase;
  }

  &-options {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-base;
    @media only screen and (min-width: $mobileView) {
      flex-direction: row;
      flex-wrap: wrap;

      .checkboxComponent,
      .radioInput {
        min-width: 50%;
      }
    }

    .checkboxComponent,
    .radioInput {
      flex: 1;
      width: 100%;
      margin-bottom: $spacing-base;
    }
  }

  &-block {
    margin-bottom: $spacing-3;
    .dateRange-label {
      display: block;
      margin-bottom: 8px;
    }
    .dateRange {
      .modal-wrapper {
        @media only screen and (min-width: $mobileView) {
          width: 438px;
        }
      }
      .daterange-modal {
        @media only screen and (min-width: $mobileView) {
          top: 10%;
        }
        @media only screen and (max-width: $mobileView) {
          overflow-y: auto;
        }
        .modal-text {
          @media only screen and (min-width: $mobileView) {
            padding: 0 14px;
          }
        }
        .modal-title {
          @media only screen and (min-width: $mobileView) {
            padding: 24px 12px 16px;
          }
        }
        .button-wrapper {
          @media only screen and (min-width: $mobileView) {
            margin-top: 19px;
          }
          // .button-wrapper-right {
          //   @media only screen and (min-width: $mobileView){
          //   margin-left: 180px;
          // }
          // }
        }
        .react-datepicker__input-container {
          padding: 6px 2px 9px 0px;
        }
        .react-datepicker {
          .react-datepicker__current-month {
            padding-bottom: 10px;
          }
          .react-datepicker__header__dropdown {
            margin-bottom: 10px;
            .react-datepicker__month-dropdown-container {
              font-family: $boldFont;
              border-bottom: 2px solid #191919;
              margin-right: 50px;
              font-size: 16px;
              @supports (-webkit-touch-callout: none) {
                /* CSS specific to iOS devices */
                color: #191919;
              }
            }
            .react-datepicker__year-dropdown-container {
              font-family: $boldFont;
              border-bottom: 2px solid #191919;
              font-size: 16px;
              @supports (-webkit-touch-callout: none) {
                /* CSS specific to iOS devices */
                color: #191919;
              }
            }
          }
        }
      }
    }
    @media only screen and (min-width: $mobileView) {
      margin-bottom: $spacing-2;
    }
  }

  @media only screen and (min-width: $mobileView) {
    padding: $spacing-2 $spacing-1 $spacing-2 $spacing-2;
  }
}
