@import '../../assets/scss/vars.scss';

.mfa-success-block,
.mfa-incomplete-block,
.mfa-error-block {
    padding-bottom: 24px;

    .content-error-block-title {
        margin-bottom: 0px;
    }
}

.mfa-success-block {
    @media only screen and (max-width: $mobileView) {
        .success-block {
            padding: 28px 15px 28px 16px !important;

            .success-block-title {
                line-height: 24px;
                padding-left: 31px;
                background-size: 24px 24px;
                background-position: 0% 16%;
            }
        }

    }
}

.mfa-incomplete-block {
    @media only screen and (max-width: $mobileView) {
        .incomplete-block {
            padding: 28px 15px 28px 16px !important;
        }

        .incomplete-block-title {
            line-height: 24px;
            padding-left: 31px;
            background-size: 24px 24px;
            background-position: 0% 16%;
        }
    }
}

.mfa-error-block {
    @media only screen and (max-width: $mobileView) {
        .content-error-block {
            padding: 28px 15px 28px 16px;

            .content-error-block-title {
                line-height: 24px;
                padding-left: 31px;
                background-size: 24px 24px;
                background-position: 0% 16%;
            }
        }

    }
}

.login-security-heading {
    .headline {
        font-size: 48px;
        margin: 32px 0 16px;
        line-height: 72px;

        @media only screen and (max-width: $mobileView) {
            font-size: 32px;
            line-height: 48px;
        }
    }
}

.mfa-error-block div:focus,
.mfa-incomplete-block div:focus,
.mfa-success-block div:focus,
.password-error-block div:focus,
.password-success-block div:focus {
    outline: none;
}

.successIcon:focus-visible {
    outline: 0.25rem solid #8bb8e8;
}