@import '../../assets/scss/vars.scss';

.home-banner {
  &.Travel_History {
    padding-bottom: 11.375rem;
    margin-bottom: 0;
    @media only screen and (max-width: 768px) {
      padding-bottom: 1.75rem;
    }
  }
  .banner-inner {
    .herobutton-container-fluid {
      .herobutton-slogan {
        margin-top: 6.875rem;
        @media only screen and (max-width: 768px) {
          padding-bottom: 24px;
          margin-top: 0;
        }
        h2 {
          position: relative;
          text-align: left;
          font-size: 3rem;
          padding-top: 80px;
          margin: 0rem 0 0.25rem 0;
          line-height: 4.5rem;
          color: #1c1c1c;
          @media only screen and (max-width: 768px) {
            font-size: 1.5em;
            line-height: 3rem;
            margin: 0 1.5rem;
            margin-bottom: 3.25rem;
            z-index: 1;
          }
        }
        .herobuttonimage03 {
          position: relative;
          img {
            position: absolute;
            right: 0;
            //  width: 50%;
            max-width: 50%;
            object-fit: cover;
            height: auto;
            // top: -4.2rem;
            @media only screen and (max-width: 768px) {
              position: relative;
              width: 100%;
              max-width: 100%;
              bottom: 0;
              margin: 0 0 2rem 2rem;
            }
          }
        }
        .herobutton-prestoLink {
          width: 50%;
          margin: 1em 0 4rem 0;
          text-align: left;
          font-size: 1.375rem;
          color: #191919;
          line-height: 2rem;
          font-family: $regularFont;
          @media only screen and (max-width: 768px) {
            margin: 0 1.5rem 2.5rem 1.5rem;
            width: auto;
          }
          ol {
            li {
              strong {
                font-family: $regularFont;
              }
            }
          }
          p:first-child {
            font-family: $boldFont;
            font-weight: bold;
            font-size: 1.5em;
            margin-top: 0;
          }
          p:nth-last-child(3) {
            font-family: $boldFont;
            font-weight: bold;
            font-size: 1.5em;
            margin-top: 0;
          }
        }
        .herobutton-btn {
          height: 55px;
          background-color: #191919;
          color: #fff;
          font-size: 1rem;
          font-weight: bold;
          padding: 1rem;
          border: none;
          text-transform: uppercase;
          cursor: pointer;
          font-family: $boldFont;
          text-decoration: none;
          text-align: center;
          width: auto;
          margin-bottom: 1.25rem;
          @media only screen and (max-width: 768px) {
            margin: 0 1.5rem;
            display: flex;
            flex-wrap: wrap;
            width: fit-content;
            height: auto;
          }
        }
        .herobutton-btn:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.home-banner-with-button {
  margin: 0 auto 0;
  z-index: 1;
  // max-width: 1680px;
  width: 944px;
  @media only screen and (max-width: $mobileView) {
    width: unset;
  }
  .banner-inner {
    .herobutton-container-fluid {
      display: flex;
      @media only screen and (max-width: $mobileView) {
        flex-direction: column-reverse;
      }
      .herobutton-slogan {
        margin-top: 124px;
        width: 50%;
        // padding-left: 8%;
        padding-right: 56px;
        @media only screen and (max-width: $mobileView) {
          padding-bottom: 56px;
          margin: 0 16px;
          width: unset;
          padding-left: 0;
          padding-right: 0;
        }
        h2 {
          text-align: left;
          font-size: 32px;
          margin: 0rem 0 0.25rem 0;
          line-height: 48px;
          color: #1c1c1c;
          @media only screen and (max-width: $mobileView) {
            font-size: 26px;
            line-height: 40px;
          }
        }

        .herobutton-prestoLink {
          margin: 24px 0 40px;
          text-align: left;
          font-size: 16px;
          color: #191919;
          line-height: 24px;
          font-family: $regularFont;
          @media only screen and (max-width: $mobileView) {
            margin: 16px 0 24px;
            width: auto;
          }
          .download_presto_para {
            @media only screen and (min-width: $mobileView) {
              font-size: 26px;
              line-height: 39px;
            }
          }
        }
        .herobutton-btn {
          height: 55px;
          background-color: #191919;
          color: #fff;
          font-size: 16px;
          line-height: 24px;
          padding: 1rem;
          border: none;
          text-transform: uppercase;
          cursor: pointer;
          font-family: $boldFont;
          text-decoration: none;
          text-align: center;
          width: auto;
          margin-bottom: 1.25rem;
          @media only screen and (max-width: $mobileView) {
            width: 100%;
            height: auto;
            margin-bottom: 0;
          }
        }
        .herobutton-btn:hover {
          opacity: 0.7;
        }
      }

      .herobuttonimage03 {
        width: 50%;
        margin-top: 80px;
        margin-bottom: 80px;

        @media only screen and (max-width: $mobileView) {
          width: unset;
          margin: 40px 16px 24px;
        }
        img {
          width: 100%;
          height: 464px;

          @media only screen and (max-width: $mobileView) {
            width: 100%;
            bottom: 0;
            height: auto;
          }
        }
      }
    }
  }
}
// .Discover_more_about_presto {
//   margin-bottom: 0px;
//   @media only screen and (max-width: $mobileView) {
//     position: relative;
//   }
//   #Discover_more_about_presto {
//     margin-left: 150px;
//     padding-bottom: 100px;
//     margin-bottom: 0px;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 16px;
//       margin-right: 16px;
//       padding-bottom: 32px;
//     }
//     .herobutton-slogan {
//       margin-top: 0px;
//       h2 {
//         width: 35%;
//         font-size: 32px;
//         line-height: 48px;
//         padding-top: 100px;
//         @media only screen and (max-width: $mobileView) {
//           width: 100%;
//           padding-top: 265px;
//           margin-left: 0px;
//           font-size: 26px;
//           margin-bottom: 16px;
//           line-height: 40px;
//         }
//       }
//     }
//     .herobuttonimage03 {
//       @media only screen and (max-width: $mobileView) {
//         position: absolute;
//         top: 225px;
//       }
//       img {
//         top: -175px;
//         width: 45%;
//         height: 475px;
//         @media only screen and (max-width: $mobileView) {
//           width: 96%;
//           height: 15%;
//           margin-left: 0px;
//           margin-bottom: 24px;
//         }
//       }
//     }
//     .herobutton-prestoLink {
//       margin-bottom: 40px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//         width: 100%;
//         margin-bottom: 24px;
//       }
//       .discover_more_para {
//         font-family: $regularFont;
//         font-size: 16px;
//         width: 84%;
//         line-height: 1.5em;
//         margin-bottom: 35px;
//         @media only screen and (max-width: $mobileView) {
//           width: 100%;
//         }
//       }
//       + button {
//         padding: 12px 16px;
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 0px;
//           width: 100%;
//         }
//       }
//     }
//   }
// }
// .learnmore_para {
//   @media only screen and (max-width: $mobileView) {
//     position: relative;
//   }
//   #learnmore_para {
//     margin-left: 150px;
//     padding-bottom: 35px;
//     margin-bottom: 0px;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 16px;
//       padding-bottom: 40px;
//       margin-top: 48px;
//     }
//     .herobutton-slogan {
//       margin-top: 0px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//       h2 {
//         @media only screen and (max-width: $mobileView) {
//           display: none;
//         }
//       }
//     }
//     .herobuttonimage03 {
//       margin-right: 100px;
//       @media only screen and (max-width: $mobileView) {
//         position: static;
//       }
//       img {
//         height: 424px;
//         position: absolute;
//         width: 424px;
//         margin-top: 80px;
//         @media only screen and (max-width: $mobileView) {
//           width: 288px;
//           height: 160px;
//           margin-left: 0px;
//           right: 16px;
//           margin-bottom: 0px;
//         }
//       }
//     }
//     .herobutton-prestoLink {
//       margin-bottom: 40px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//         width: 100%;
//         margin-bottom: 24px;
//       }
//       .download_presto_para {
//         font-family: $regularFont;
//         width: 82%;
//         line-height: 39px;
//         margin-bottom: 35px;
//         font-size: 26px;
//         padding-top: 49px;
//         @media only screen and (max-width: $mobileView) {
//           font-size: 16px;
//           margin-top: 200px;
//           line-height: 24px;
//           width: 100%;
//           padding-top: 4px;
//           margin-bottom: 24px;
//         }
//       }
//       + button {
//         padding: 12px 16px;
//         @media only screen and (max-width: $mobileView) {
//           width: 95%;
//           margin-left: 0px;
//         }
//       }
//     }
//   }
// }
