.contactless-dashboard-page {
  .contentPageLayout-sidebar {
    display: flex;
    flex-direction: column;
    width: 250px !important;
    left: 10% !important;
    margin: 14px 0px 0px 0px !important;
    .contentPageLayout-sidebar {
      flex-basis: 240px;
      display: block;
      position: static;
      margin: 0px;
    }
    .contentPageLayout-content {
      flex-basis: 600px;
    }

    .new-mainContent {
      flex-basis: 600px;
    }
  }
}
