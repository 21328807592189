@import '../../assets/scss/vars';

.text-field {
  position: relative;
  margin-top: $spacing-2;
  width: 100%;

  @media only screen and (min-width: $mobileView) {
    margin-top: $spacing-3;
    margin-right: $spacing-1;
  }

  .text-field-label,
  .text-field-input,
  .bellow-text {
    display: block;
  }
  .text-field-label {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: $spacing-base;
    font-family: $boldFont;
    &.longLabel {
      @media only screen and (min-width: $mobileView) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 48px;
      }
    }
  }
  .bellow-text {
    font-family: $regularFont;
    font-size: 14px;
    padding-left: $spacing-1;
    margin-top: $spacing-base;
    line-height: 21px;
  }

  .text-field-input {
    border: none;
    border-bottom: 1px solid $black;
    padding: 14px $spacing-2 10px $spacing-1;
    box-sizing: border-box;
    color: $secondary80;
    background-color: $secondary10;
    font-size: 16px;
    font-family: $regularFont;
    width: 100%;
    line-height: $spacing-2;
    border-radius: 0;
    &:focus {
      outline: none;
    }
    &:hover {
      // cursor: pointer;
      border-bottom: 1px solid $black;
      box-shadow: 0px 1px $black;
    }
  }
  .viewIcon,
  .hideIcon {
    float: right;
    position: relative;
    z-index: $zindex-textInputFloatingIcon;
  }
  .viewIcon {
    margin-top: -41px;
    img {
      width: $spacing-3;
      height: $spacing-3;
    }
  }
  .hideIcon {
    margin-top: -35px;
    img {
      width: $spacing-3;
      height: 18px;
    }
  }
  &.error {
    .text-field-input {
      border-color: $feedbackError;
      box-shadow: 0px 0.5px $feedbackError;
    }
  }
  &.white-input {
    input {
      background-color: $white;
      border: 1px solid $secondary30;
      width: 256px;
      @media only screen and (min-width: $mobileView) {
        width: 248px;
      }
      &:focus {
        border-bottom: 2px solid $black;
      }
    }
  }
  &.margin-top-spacing1 {
    margin-top: $spacing-1;
  }
  &.margin-top-spacing2 {
    margin-top: $spacing-2;
  }
  &.margin-top-0 {
    margin-top: 0;
  }
  &.margin-top-0-desktop-only {
    @media only screen and (min-width: $mobileView) {
      margin-top: 0;
    }
  }
  &.moneyIcon {
    .text-field-wrapper {
      position: relative;
      overflow: hidden;
      &:before {
        content: '$';
        position: absolute;
        font-family: $regularFont;
        left: $spacing-1;
        top: 0;
        bottom: 0;
        line-height: $spacing-2;
        padding: 14px 0 10px 0;
        display: block;
      }
    }
    input {
      display: block;
      padding-left: 34px;
      box-sizing: border-box;
    }
  }
  &.disabled {
    input {
      border-bottom: 0px;
      background-color: $secondary20;
      color: #000;
    }
  }
  .text-field-input {
    padding-right: 40px;
  }
}

.apt-label {
  .text-field {
    @media only screen and (min-width: $mobileView) {
      margin-top: 11px;
    }
  }
}

.phn-label {
  .text-field {
    @media only screen and (min-width: $mobileView) {
      margin-top: 11px;
    }
  }
}

.text-field-wrapper {
  input:active {
    border-bottom: 2px solid $black;
  }
}

::-ms-reveal {
  display: none;
}
