@import '../../assets/scss/vars';

.forgot {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 0 0 0;
  text-align: initial;
}

.forgot-title {
  font-size: 32px;
  margin: 64px 0 32px 0;
  text-align: center;
}

.forgot-content-wrapper {
  width: 464px;
  margin: 0 auto;
}

.forgot-content {
  background-color: $white;
  padding: 22px 24px;
}

.forgot-text {
  font-size: 18px;
  line-height: 28px;
  padding: 15px 0;
  color: $black;
}

.forgot-policy {
  font-family: $regularFont;
  line-height: 24px;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 50px;
  a {
    font-family: $regularFont;
  }
}

.forgot-question {
  margin-bottom: 40px;
  margin-top: 15px;
}

.forgot-question-title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.forgot-question-text {
  font-family: $regularFont;
}

.username-success {
  padding-left: 48px;
  font-family: $regularFont;
  span {
    font-family: $boldFont;
  }
}

@media screen and (max-width: 920px) {
  .forgot {
    margin-top: 35px;
    padding-top: 0;
  }
  .forgot-title {
    margin-top: 24px;
  }
  .forgot-content-wrapper {
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
  .forgot-text {
    padding-bottom: 0;
  }
}
