@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.tac-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: auto;
  z-index: $zindex-modalBackdrop;
}

.tac-modal-padright {
  @media only screen and (min-width: $mobileView) {
    padding-right: 24px;
  }
}

.tac-modal-wrapper {
  position: fixed;
  top: 15%;
  align-items: center;
  background-color: $white;
  margin: 0 auto;
  z-index: $zindex-modalContent;
  height: 472px;  
  overflow-y: auto;
  @supports not (-ms-high-contrast: none) {
    left: $spacing-2;
    right: $spacing-2;
  }

  @media only screen and (min-width: $mobileView) {
    max-width: 464px;
  }
}

.tac-modal {
  padding: $spacing-2 $spacing-1 $spacing-3;
  overflow: auto;
  @media only screen and (min-width: $mobileView) {
    padding: $spacing-2 0 $spacing-2 $spacing-2;
  }

  &-box {
    overflow: auto;
    height: 256px;
    margin-bottom: 17px;
    @media only screen and (min-width: $mobileView) {
      margin-bottom: 25px;
      height: 260px;
    }
    @media print {
      height: auto;
    }
  }

  &-content {
    @media only screen and (min-width: $mobileView) {
      margin-right: $spacing-2;
    }

    &-title {
      font-family: $boldFont;
      font-size: 26px;
      line-height: $spacing-5;
      @media only screen and (min-width: $mobileView) {
        font-size: $spacing-3;
        margin-bottom: $spacing-2;
      }
    }

    &-text {
      font-family: $regularFont;
      font-size: $spacing-1;
      line-height: $spacing-2;

      h2 {
        @include fontSize-16;
        text-align: left;
        font-family: $boldFont;
      }
    }

    &-iconButtons {
      margin-top: 20px;
      @media only screen and (min-width: $mobileView) {
        margin-top: 0;
        display: flex;
        align-items: center;
      }

      &-email {
        @media only screen and (min-width: $mobileView) {
          margin-left: -19px;
        }
      }
    }
    &-emailLine {
      margin-top: $spacing-1;
      margin-bottom: $spacing-1;
      @media only screen and (min-width: $mobileView) {
        margin-top: $spacing-2;
      }
    }
    &-success {
      margin-bottom: $spacing-base;
    }
    &-emailSend {
      @media only screen and (min-width: $mobileView) {
        display: flex;
        margin-top: -$spacing-1;
      }
      &-button {
        @media only screen and (min-width: $mobileView) {
          margin-top: $spacing-5;
        }
      }
    }
  }
}

.email-button {
  font-family: $boldFont;
  font-size: $spacing-1;
  cursor: pointer;
  display: block;
  order: -1;
  margin-right: 44px;
  height: $spacing-2;
  padding: 0 0 0 $spacing-4;
  border: none;
  background: transparent url('../../assets/images/emailIcon.svg') 0 -5px no-repeat;
  &:focus {
    outline: none;
  }
}

.checkboxComponent-span {
  // font-weight: 600;
}
