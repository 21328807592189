@import '../../assets/scss/vars';

.note {
  padding: 16px 24px;
  background-color: $white;
  outline: 1px solid $secondary10;
  border-left: 4px solid $mediumCyanBlue;
  line-height: 24px;

  &.withIcon {
    padding-left: 50px;
    background: $white url('../../assets/images/Info.svg') no-repeat 10px 13px;
    background-size: 32px;

    @media only screen and (min-width: $mobileView) {
      padding-left: 60px;
      background-position: 17px 19px;
    }
  }

  &.note-bottom-margin {
    margin-bottom: $spacing-1;
  }

  span {
    font-family: $regularFont;

    p {
      margin: 0;
    }
  }
}
