@import '../../assets/scss/vars.scss';

.dividershort {
    margin: 0;
}

.dividershort hr {
    width: 965px;
    border: 1px solid #d1d1d1;
    margin: 30px auto 0;
}

@media only screen and (max-width: 990px) {
    .dividershort hr {
        width: 97%;
    }
}