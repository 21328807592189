@import '../../assets/scss/vars.scss';

.google-wallet-page-main-content {
  .dividerlarge {
    margin: 0px;

    hr {
      border: 1px solid #d1d1d1;
      margin: 0px;
    }
  }

  .image-with-paragraph-white-background {
    color: #191919;
    margin: 0 auto;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      width: auto;
    }

    .Hello-presto-img {
      margin-top: 25px;
      margin-bottom: 50px;

      @media only screen and (max-width: $mobileView) {
        margin: 32px 0;
        display: block;
      }

      .instructionimage-md {
        display: flex;

        @media only screen and (max-width: $mobileView) {
          display: none !important;
        }
      }

      .instructionimage-sm {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .google-wallet-content-wrapper {
    padding-top: 45px;
    padding-bottom: 0;
    background-color: #fff;

    @media only screen and (max-width: $mobileView) {
      padding-left: 16px;
      margin-left: 0;
      margin-right: 0;
      padding-right: 16px;
      width: unset;
      padding-top: 16px;
    }

    .display-4 {
      font-family: $boldFont;
      font-size: 32px;
      line-height: 32px;
      color: #191919;
      margin: 0 auto;
      width: 944px;
      padding-bottom: 24px;

      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
        line-height: 32px;
        width: unset;
        color: #191919;
        margin: 0 auto;
        font-family: $boldFont;
      }
    }

    .contentDescription {
      margin: 0 auto;
      width: 944px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }

      .google-wallet-content-block-description {
        font-family: $regularFont;

        p:not(:last-child) {
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          font-family: $regularFont;
          line-height: 24px;
          display: block;
          text-align: left;
          color: #191919;
          margin-top: 8px;
          margin-bottom: 0;
          width: 100%;

          @media only screen and (max-width: $mobileView) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .thumbnail-container {
    width: 944px;
    margin: 50px auto 0px auto;

    @media only screen and (max-width: $mobileView) {
      margin: 24px 16px 0px 16px;
      width: auto;
    }

    .thumbnail-collection {
      background-color: #fff;
      padding: 0px;
      margin: 0px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      .thumbnail-collection-title-left-align {
        margin: auto auto 24px auto;

        @media only screen and (max-width: $mobileView) {
          font-size: 22px;
          line-height: 32px;
          margin: auto auto 16px auto;
          text-align: left;
          width: auto;
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-family: $regularFont;
        margin: auto auto 24px auto;

        @media only screen and (max-width: $mobileView) {
          margin: auto auto 16px auto;
        }
      }

      .transit-agencies-icons {
        border-bottom: none;
      }

      .thumbnail-collection-divider {
        border-bottom: 1px solid #d1d1d1;
        padding-bottom: 50px;

        @media only screen and (max-width: $mobileView) {
          padding-bottom: 24px;
        }

        li {
          padding: 0px 10px;
          margin: 0px;

          @media only screen and (max-width: $mobileView) {
            padding: 0px 10px;
            margin: 0px;
          }
        }
      }
    }
  }

  .Hello-free-Image {
    margin: 50px auto 0px auto;
    padding-bottom: 50px;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    width: 944px;
    justify-content: space-between;

    @media only screen and (max-width: $mobileView) {
      padding: 24px 0px;
      margin: 0px auto;
      gap: 10px;
      flex-direction: column;
      width: 91%;
    }

    .instructionimage {
      margin-top: 64px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 0px;
        text-align: center;
      }

      img {
        width: 320px;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }
      }
    }

    .display-4 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 24px;
      color: #191919;
      text-align: left;
      font-family: $boldFont;
      margin-top: 0;

      @media only screen and (max-width: $mobileView) {
        margin-top: 0px;
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 32px;
      }
    }

    .instructionContainer {
      margin-right: 48px;

      @media only screen and (max-width: $mobileView) {
        margin-right: 0px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: #191919;
        text-align: left;
        font-family: $regularFont;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .bold-heading {
        margin-top: 8px;
        margin-bottom: 24px;

        @media only screen and (max-width: $mobileView) {
          margin-top: 16px;
        }
      }

      .bold-heading1 {
        margin-top: 8px;
        margin-bottom: 0px;

        @media only screen and (max-width: $mobileView) {
          margin-top: 16px;
        }
      }

      p+ul {
        margin-top: 16px;
      }

      strong {
        font-size: 18px;
        line-height: 28px;
      }

      ul {
        font-size: 16px;
        line-height: 32px;
        color: #191919;
        font-family: $regularFont;
        padding-left: 27px;

        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
          margin-top: 24px;
          margin-bottom: 0px;
        }

        li {
          padding-left: 8px;

          @media only screen and (max-width: $mobileView) {
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  .Hello-presto-main-title {
    padding: 45px 0 30px 0;

    @media only screen and (max-width: $mobileView) {
      padding: 24px 16px;
    }

    .contentDescription {
      p {
        width: auto;
        color: #1a1a1a;

        @media only screen and (max-width: $mobileView) {
          margin-top: 16px;
          color: #191919;
        }
      }
    }
  }

  .Hello-free-Image-pay-presto {
    margin: 48px auto 0;
    padding-bottom: 50px;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    width: 944px;
    justify-content: space-between;

    @media only screen and (max-width: $mobileView) {
      margin-left: 16px;
      margin-right: 16px;
      flex-direction: column;
      padding-bottom: 24px;
      margin-top: 24px;
      width: 91%;
    }

    .text-container {
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
      }
    }

    .instructionimage {
      margin-top: 64px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 0px;
      }

      img {
        width: 320px;

        @media only screen and (max-width: $mobileView) {
          width: 100%;
        }
      }
    }

    .display-4 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 24px;
      color: #191919;
      text-align: left;
      font-family: $boldFont;
      margin-top: 0;

      @media only screen and (max-width: $mobileView) {
        margin-top: 0;
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 32px;
      }
    }

    .instructionContainer {
      margin-right: 48px;

      @media only screen and (max-width: $mobileView) {
        margin-right: 0px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: #191919;
        text-align: left;
        font-family: $regularFont;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .bold-heading {
        margin-top: 8px;
        margin-bottom: 24px;

        @media only screen and (max-width: $mobileView) {
          margin-top: 16px;
        }
      }

      .bold-heading1 {
        margin-top: 8px;
        margin-bottom: 0px;

        @media only screen and (max-width: $mobileView) {
          margin-top: 16px;
        }
      }

      p+ul {
        margin-top: 16px;
      }

      strong {
        font-size: 18px;
        line-height: 28px;
      }

      ul {
        font-size: 16px;
        line-height: 32px;
        color: #191919;
        font-family: $regularFont;
        padding-left: 27px;

        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
          margin-top: 24px;
          margin-bottom: 0px;
        }

        li {
          padding-left: 8px;

          @media only screen and (max-width: $mobileView) {
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  .Hello-presto {
    padding-top: 44.6px;

    @media only screen and (max-width: $mobileView) {
      padding-top: 24px;
      padding-bottom: 8px;
    }

    .contentDescription {
      p {
        width: 100%;
      }
    }
  }

  .Hello-presto-get-card {
    padding-top: 50px;

    @media only screen and (max-width: $mobileView) {
      padding-top: 14px;
      padding-bottom: 0px;
    }

    .contentDescription {
      p {
        width: 100%;
        margin-top: 0px;
      }
    }
  }

  .Hello-presto-convert-card {
    padding-top: 50px;

    @media only screen and (max-width: $mobileView) {
      padding-top: 14px;
      padding-bottom: 0px;
    }

    .contentDescription {
      p {
        width: 100%;
      }
    }
  }

  .accordion-staticpage-section-wrapper {
    .Accordion-HelloPresto {
      width: 100%;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }

      .accordion-content {
        background-color: $white;
      }
    }
  }

  .accordion-heading {
    line-height: 24px;
    color: #191919;
  }

  .accordion-content {
    font-size: 14px;
    line-height: 24px;
    color: #191919;
  }

  .Presto-FAQ {
    h2.display-4 {
      font-size: 48px;
      line-height: 66px;
      color: #191919;
    }

    @media only screen and (max-width: $mobileView) {
      font-size: 26px;
      line-height: 32px;
    }

    .google-wallet-content-block-description {
      p {
        margin-bottom: 24px;
      }
    }
  }
}