@import '../../assets/scss/vars.scss';

#sidebar {
  .contactless-user-card~.side-wrapper {
    .side-menu {
      margin-top: 16px;
    }
  }
}

.side-wrapper {
  display: none;

  @media only screen and (min-width: $mobileView) {
    display: inline-block;
    vertical-align: top;
    width: 100%;

    .side-menu {
      margin: 40px 0 0;
      color: $black;

      h2 {
        font-size: 22px;
        line-height: 32px;
        padding-right: 12px;
        text-align: right;
        margin: 20px 0 0;
      }

      ul {
        text-align: right;
        padding: 0;
        margin-top: 0;

        li {
          font-family: $regularFont;
          font-size: 16px;
          list-style: none;
          padding-right: 12px;
          line-height: 24px;
          margin-bottom: 16px;
          clear: both;

          &.active {
            padding-right: 8px;
            font-family: $boldFont;
            border-right: 4px solid $mediumGreen;
          }

          a {
            text-decoration: none;
            font-family: inherit;
          }

          &.divider {
            padding: 0;

            hr {
              height: 1px;
              border: 0;
              border-top: 1px solid $secondary20;
              width: 224px;
            }
          }
        }
      }

      .welcome-msg {
        margin-bottom: $spacing-base;

        h2 {
          font-size: 32px;
          font-family: $boldFont;
          line-height: 48px;
          margin-top: 0;
          max-width: 100%;
          overflow: hidden;
          padding-right: 0;

          div {
            word-break: break-all;
          }
        }

        .isSmall {
          font-size: 26px;
        }

        img {
          max-width: 144px;
        }
      }
    }
  }
}