@import '../../assets/scss/vars.scss';

.addCard {
  &-separator {
    border: 0;
    border-bottom: 1px solid $secondary20;
    margin: 16px 0;

    @media only screen and (min-width: $mobileView) {
      margin: 24px 0;
    }
  }

  &-formRow {
    margin-left: -16px;
    margin-right: -16px;
  }

  &-formControl {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 256px;
    margin-bottom: 29px;

    .text-field {
      margin-top: 16px;
    }
  }

  &-termsAndConditions {
    font-family: $boldFont;
    .inlineError {
      margin-left: 20px;
    }
  }

  &-cardSwitcher {
    //TODO: Create reausable atom and reuse in all places where Tab sitcher is used
    padding: 16px 0 32px 0;

    .desktop {
      display: none;
    }

    .mobile {
      display: inline-block;
    }

    @media only screen and (min-width: $mobileView) {
      .desktop {
        display: inline-block;
      }
      .mobile {
        display: none;
      }
    }

    a {
      text-decoration: none;

      &:focus {
        //TODO: Accesibility issues - disucss how to handle focus state in order to not break keyboard assesibility in all Tabs switchers
        outline: none;
      }

      &:first-child {
        margin-right: 5%;

        @media only screen and (min-width: $mobileView) {
          margin-right: 32px;
        }
      }

      &:last-child {
        margin-left: $spacing-2;

        @media only screen and (min-width: $mobileView) {
          margin-left: $spacing-3;
        }
      }

      div {
        display: inline;
        font-family: $boldFont;
        font-size: 19px;
        line-height: 32px;
        color: $secondary60;
      }
      &.inactive {
        cursor: pointer;
      }
      &.active {
        border-bottom: 5px solid $black;
        padding-bottom: 5px;
        cursor: default;

        div {
          color: $black;
        }
      }
      @media only screen and (min-width: $mobileView) {
        padding: 0 0 32px;

        &-desktop {
          display: block;
        }
        &-mobile {
          display: none;
        }
      }
    }
  }

  &-noMargin {
    margin-bottom: 0;
  }

  &-smallMargin {
    margin-bottom: 16px;
  }

  .mediaImageList {
    margin-top: 16px;
  }

  .success-context-text {
    padding-left: 48px;

    @media only screen and (min-width: $mobileView) {
      padding-left: 0;
    }
  }
}

.add-card-wrapper {
  text-align: left;

  @media only screen and (min-width: $mobileView) {
    display: block;
    color: $black;
  }

  .add-card-header {
    font-family: $boldFont;
    font-size: 32px;
    color: $black;
    line-height: 48px;
    padding: 16px 0 16px 24px;

    @media only screen and (min-width: $mobileView) {
      font-size: 48px;
      text-align: center;
      line-height: 72px;
      padding: 32px 0;
    }
  }

  .break {
    background-color: $secondary5;
    border: 1px solid $secondary5;
    border-top: 1px solid $secondary10;
    margin: -1px;
  }

  .add-card {
    background-color: $white;

    &.inside-buttons {
      margin: 0 0 40px 0;
    }

    border: 1px solid $secondary10;

    @media only screen and (min-width: $mobileView) {
      width: 624px;
    }

    &-title {
      font-family: $boldFont;
      font-size: 20px;
      line-height: 32px;
      border-bottom: 1px solid $secondary10;
      text-align: initial;
      padding: 16px;

      @media only screen and (min-width: $mobileView) {
        font-size: 22px;
        padding: 16px 0 16px 24px;
      }
    }

    &-note {
      margin: 16px;
      @media only screen and (min-width: $mobileView) {
        margin: 16px 20px 16px 28px;
      }
    }

    &-line {
      hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid $secondary20;
        padding: 0;
        margin: 0 16px;
      }

      @media only screen and (min-width: $mobileView) {
        hr {
          margin: 0 20px 16px 28px;
        }
      }
    }

    &-additional {
      font-family: $boldFont;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 16px;

      @media only screen and (min-width: $mobileView) {
        font-size: 22px;
        margin: 16px 20px 16px 0;
      }
    }

    &-bottom {
      &-text {
        font-family: $regularFont;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        padding: 16px 0 0 0;

        @media only screen and (min-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    &-content {
      padding: $spacing-2;

      &-text {
        display: flex;
        font-family: $regularFont;
        font-size: 16px;
        padding: 16px 0;
        padding-top: 0;
        line-height: 24px;
        @media only screen and (min-width: $mobileView) {
          padding: 0;
        }
      }
      &-image {
        padding-top: 16px;
      }

      &-form {
        .text-field {
          text-align: initial;
          display: inline-block;
          margin: 0;
          padding-top: 16px;
        }

        .associated-to-card {
          display: flex;
          flex-direction: column;
        }

        .add-card-input {
          .error-message {
            max-width: 216px;
          }

          min-height: 106px;

          @media only screen and (min-width: $mobileView) {
            display: inline-block;
            vertical-align: top;
            padding: 0 16px;
          }
        }

        input {
          @media only screen and (min-width: $mobileView) {
            width: 256px;
          }
        }
      }
    }

    &-success {
      margin-top: $spacing-base;
    }
  }
}

.mobile-button-line {
  background-color: $secondary20;
  height: 1px;
  width: 100%;
  margin-top: 16px;

  @media only screen and (min-width: $mobileView) {
    display: none;
  }
}

.importantNote {
  background-color: #fff;
  margin-bottom: 16px;
  padding: 5px 20px;
  border-left: solid 4px #8bb8e8;
  line-height: 24px;
  font-family: "AvenirNextLTPro-Regular",sans-serif;
}
