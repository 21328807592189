@import '../../assets/scss/vars.scss';

.payment-modal {
  .modal-text-only {
    padding: $spacing-4 0 !important;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: auto;
    z-index: $zindex-modalBackdrop;
  }

  .modal-wrapper {
    position: fixed;
    top: 160px;
    align-items: center;
    left: 0;
    right: 0;
    background-color: $white;
    margin: 12px;
    padding: 0;
    z-index: $zindex-modalContent;
    width: auto;
    top: 30px;
    height: 82%;

    @media only screen and (min-width: $mobileView) {
      width: 850px;
      margin: 0 auto;
      left: $spacing-2;
      right: $spacing-2;
    }
  }

  .modal-header {
    width: 100%;
    background-color: $black;
    display: flex;
    height: auto;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .modal-title {
    color: $white;
    font-family: 'AvenirLT-Roman', sans-serif !important;
    font-size: $fontSize-32;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 48px;
    padding: 0 30px;

    .header-close-icon {
      position: absolute;
      right: 10px;
      display: none;
      margin: 0px 20px;
    }

    @media only screen and (min-width: $mobileView) {
      display: block;
    }
  }

  .modal-outerDiv {
    width: 100%;
    height: 100%;
  }

  .modal-checkout {
    position: relative !important;
    height: 100%;
  }

  .icon-border:active {
    border: 1px solid $white;
    border-radius: 3px;
  }
}
