@import '../../assets/scss/vars';

.connectionErrorWrapper {
  background-color: $white;

  .connection-title {
    padding: $spacing-7 $spacing-1;

    .title {
      font-size: 22px;
      font-family: $boldFont;
      text-align: center;
      line-height: $spacing-3;
      margin-bottom: $spacing-base;
      margin-top: 0;
      color: $black;

      @media only screen and (min-width: $mobileView) {
        font-size: 26px;
      }
    }

    p {
      color: $black;
      padding: 0;
      margin-bottom: 0;
    }
  }
  p {
    padding: $spacing-4 $spacing-1;
    text-align: center;
    line-height: $spacing-2;
    color: $secondary30;
  }
}
