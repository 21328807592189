@import '../../../../assets/scss/vars.scss';

.ttc-checkout {
  .success-block {
    padding: 16px 16px 10px 16px;
    margin-top: 20px;
    @media only screen and (max-width: $mobileView) {
      padding: 24px 19px 16px 16px !important;
    }

    .success-block-title {
      margin-bottom: 20px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 32px;
        line-height: 28px;
      }
    }
    .success-context-text {
      font-size: 16px;
      line-height: 24px;
    }

    .success-block-text {
      .succeess-agencyOption-wrapper {
        border-top: 2px solid #e8e8e8;
        margin-bottom: 15px;
        @media only screen and (max-width: $mobileView) {
          margin-bottom: 0;
        }

        .success-agencyOption-header {
          display: flex;
          justify-content: space-between;
          padding: 10px 0px;
          border-bottom: 2px solid #e8e8e8;
        }

        .success-agencyOption-content {
          padding-top: 12px;
          padding-bottom: 12px;
          display: flex;

          .success-agencyOption-title-logo-block {
            flex: 0.17 1;
            padding-left: 6px;
            @media only screen and (max-width: $mobileView) {
              padding-left: 0;
              flex: 0;
              img {
                display: none;
              }
            }
          }

          .agencyOption-title-block {
            flex: 1.5 2;

            .agencyOption-date {
              font-family: 'AvenirNextLTPro-Regular';
            }
          }
        }

        .payment-amount {
          border-top: 1px solid #d1d1d1;
          text-align: right;
          font-family: 'AvenirNextLTPro-Demi', sans-serif;
          padding-top: 16px;
          display: flex;
          justify-content: end;

          .payment-label {
            padding-right: 20px;
            @media only screen and (max-width: $mobileView) {
              padding-right: 8px;
            }
          }
        }
      }
    }
  }

  .agencyOption-wrapper {
    .update-note {
      font-family: 'AvenirNextLTPro-Regular';
      padding-top: 24px;
      padding-left: 8px;
      padding-bottom: 8px;
      line-height: 21px;
    }
  }

  .bold-content {
    font-weight: bold;
  }

  .success-block-details {
    div:last-child {
      border-bottom: none;
    }
    margin-top: 16px;
    padding: 0px 24px;
    // padding-right: 20px;
    // padding-top: 20px;

    background-color: white;
    @media only screen and (max-width: $mobileView) {
      padding: 16px;
      // padding-right: 16px;
      // padding-top: 24px;
    }

    .transaction-type {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
      .bold-content {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
          width: 40%;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .response-code {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
      .bold-content {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
          width: 40%;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .iso-code {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
      .bold-content {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
          width: 40%;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .date-time {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
      .bold-content {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
          width: 40%;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .reference-number {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
      .bold-content {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
          width: 50%;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}

.update-note-ttc {
  font-family: 'AvenirNextLTPro-Regular';
  padding-top: 24px;
  padding-bottom: 8px;
  line-height: 21px;
}

.ttc-pass-visibleId {
  font-family: 'AvenirNextLTPro-Regular';
}

.ttc-pass-nickname {
  margin-bottom: 4px;
}
