.deactivate-parent {
  .success-block {
    padding: 23px;
    margin-bottom: 16px;

    .success-content-block {
      padding-top: 20px;
      display: none;

      .bold-content {
        font-weight: bold;
      }

      .content-header {
        padding: 0px 0px 5px 0px;
        border-bottom: 2px solid #d1d1d1;
      }

      .date-time {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }

      .reference-number {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
