@import '../../assets/scss/vars.scss';

.create-note .note span {
  display: flex;
  align-items: flex-start;
}

.crt-margin {
  // margin: 0 19%;
  background: #fff;
  width: 624px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    margin: unset;
    width: unset;

    &.create-note {
      margin: 0 16px;
    }

    &.error-note {
      margin: 0 16px;
    }
  }
}

.create-account-techincal-error {
  border: 1px solid #d1d1d1;
  padding: 8px 18px;
  background-color: #ffffff;
  margin-bottom: 16px !important;
  border-left: 4px solid $feedbackError;
  .error--message {
    background: url('../../assets/images/error.svg') no-repeat left top;
    -webkit-print-color-adjust: exact;
    line-height: 32px;
    #messageerror {
      color: $errorTitle;
      font-size: 16px;
      font-family: $boldFont;
      padding-left: 50px;
    }
  }
}

.sec-margin {
  margin-top: 16px;
  //padding: 30px 20px 0px 20px;
}

.sec-padding {
  padding: 30px 40px 0px 40px;

  @media only screen and (max-width: $mobileView) {
    padding: 30px 16px 0px 16px;
  }
}

.sec-form-padding {
  padding: 0px 40px 0px 40px;

  @media only screen and (max-width: $mobileView) {
    padding: 0px 16px 0px 16px;
  }
}

.create-account-header {
  font-size: 32px;
  line-height: 48px;
}

.security-header {
  font-size: 18px;
  line-height: 28px;
  margin: 16px 0;
}

.header-family {
  font-family: $regularFont;
  line-height: 24px;
}

.textinput-flex {
  display: flex;

  @media only screen and (max-width: $mobileView) {
    display: block;
  }
}

.firstinput-wrapper {
  width: 50%;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
  }
  .firstinput-custom {
    .text-field {
      @media only screen and (max-width: $mobileView) {
        padding-top: 24px;
        margin-top: 0;
      }
    }
  }
}

.secondinput-wrapper {
  width: 50%;
  margin-left: 1rem;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
    margin-left: unset;
  }
}

.firstinput2-wrapper {
  width: 344px;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
  }
}

.secondinput2-wrapper {
  width: 184px;
  margin-left: 1rem;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
    margin-left: unset;
  }
}

.firstinput3-wrapper {
  width: 400px;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
  }
}

.content-divider {
  margin-top: 40px;
  border-bottom: 1px solid #d1d1d1;
}

.content-divider2 {
  margin-top: 32px;
  border-bottom: 1px solid #d1d1d1;
}

.section-divider {
  margin-top: 40px;
  border-bottom: 16px solid #f3f3f3;
  width: 115%;
  margin-left: -40px;
  @media only screen and (max-width: $mobileView) {
    width: unset;
  }
}

.section-divider2 {
  margin-top: 32px;
  border-bottom: 1px solid #d1d1d1;
  width: 115%;
  margin-left: -40px;
  @media only screen and (max-width: $mobileView) {
    width: unset;
  }
}

.account-padding {
  padding-top: 32px;
}

.checkbox-padding {
  padding-top: 32px;
}

.content-padding {
  padding-top: 10px;
}

.province-margin {
  margin-top: 36px;
}

.content-topmargin {
  margin-top: 10px;
}

.content-leftmargin {
  margin-left: 30px;
}

.account-info {
  padding-top: 24px;
}

.captch-margin {
  margin-top: 40px;
  margin-bottom: 80px;

  background: #f3f3f3 !important;

  @media only screen and (max-width: $mobileView) {
    margin: 32px 20px 32px;
  }
}

.btn-flex {
  display: flex;
  flex-direction: row-reverse;
  width: 624px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    display: block;
    width: unset;
    margin-right: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.btn-created-flex {
  display: flex;
  flex-direction: row-reverse;

  @media only screen and (max-width: $mobileView) {
    display: block;
  }
}

.btn-padding {
  padding-left: 20px;
  //padding-right: 3px;
  //padding-bottom: 20px;
  padding-top: 16px;

  @media only screen and (max-width: $mobileView) {
    padding-left: 0px;
    padding-top: 24px;
    padding-bottom: 5px;
    padding-right: 0px;
  }
}

.cancel-btn {
  @media only screen and (min-width: $mobileView) {
    padding-top: 16px;
  }
}

.acc-create-success-header {
  padding: 20px;
}

.acc-create-success-header-text {
  font-size: 2rem;

  @media only screen and (max-width: $mobileView) {
    font-size: 1.5rem;
  }
}

.acc-create-success-header-subText {
  font-family: $regularFont;
}

.create-terms-block {
  padding-top: 20px;
  padding-bottom: 24px;

  .checkboxComponent {
    .checkboxComponent-label {
      color: inherit;

      button {
        color: inherit;
      }
    }
  }
}

.acc-creation-checkbox {
  .checkboxComponent-label {
    font-family: $regularFont;
  }
}

.p-margin {
  margin-bottom: 0;
}

.password-padding {
  padding-top: 16px;
}

.note-img-pad {
  padding-right: 8px;
}
