@import '../../assets/scss/vars.scss';

.account-deletion-container {
    .cardBlockComponent-title {
        @media only screen and (min-width: $mobileView) {
            margin-top: 9px;
            padding-bottom: 17px;

            &:focus-visible {
                outline: 0.25rem solid #8bb8e8;
            }
        }

        @media only screen and (max-width: $mobileView) {
            font-size: 16px;
            font-weight: 500;
            line-height: 17px;
            background-size: 24px 24px;
        }
    }

    .cardBlockComponent-content {
        @media only screen and (max-width: $mobileView) {
            padding-top: 32px;
        }

        .delete-account-content {
            @media only screen and (max-width: $mobileView) {
                font-size: 16px;
                letter-spacing: 0;
                line-height: 17px;
            }
        }
    }

    .buttonComponent {
        @media only screen and (max-width: $mobileView) {
            line-height: 17px;
            margin-top: 32px;
        }
    }
}