@import '../../assets/scss/vars';

.contentPageLayout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .contentPageLayout-header {
    display: block;
  }

  .contentPageLayout-main {
    display: flex;
    justify-content: center;
    flex: 1 auto auto;
    padding-top: 56px;
  }

  .contentPageLayout-content {
    margin: 0 16px;
    width: 100%;

    @media only screen and (min-width: $mobileView) {
      width: 624px;
    }
  }

  .contentPageLayout-sidebar {
    display: none;

    @media only screen and (min-width: $mobileView) {
      display: block;
      width: 224px;
      margin-right: $spacing-9;
    }
  }

  .contentPageLayout-footer {
    h1 {
      margin-bottom: 0;
    }
  }
}

// css as per new design for sticky Nav and static PX for presto pages
// please don't modify the below css

#contactless-page-layout {
  padding-top: 56px;

  .para {
    table {
      @media only screen and (max-width: $mobileView) {
        width: auto !important;
      }
    }
  }

  .new-pagesidenav {
    width: 100%;

    @media only screen and (min-width: $mobileView) {
      display: flex;
      width: 40%;
      top: 2.5rem;
      min-height: calc(93% - 8.5rem);
      z-index: 100;
      flex-direction: column;
      position: -webkit-sticky;
      position: sticky;
      float: left;
    }
  }

  .accordion-section {
    clear: both;
  }

  .nav__sub-section {
    margin-bottom: 1.875rem;
  }

  .home-banner {
    .herobuttonimage03 {
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }

  #How_Contactless_Works {
    @media only screen and (max-width: $mobileView) {
      padding-top: $spacing-4;
      padding-bottom: $spacing-8;
    }
  }

  .instruction__container {
    .row {
      @media only screen and (max-width: $mobileView) {
        display: flex !important;
      }
    }

    .titleinstruction {
      @media only screen and (max-width: $mobileView) {
        text-align: center;
      }
    }
  }

  .instruction_Container_Vertical {
    .row {
      img {
        width: 122px;
        height: 110px;
      }
    }

    .instructionimage {
      @media only screen and (max-width: $mobileView) {
        width: 100%;
        text-align: center;
        padding-top: 1.125rem;
      }
    }

    .text-container {
      margin-right: 24px;
    }
  }

  .accordionContainer__accordionButton {
    @media only screen and (max-width: $mobileView) {
      margin-bottom: 0;
    }
  }

  .accordionContent {
    padding: 0 0 24px 24px;

    @media only screen and (max-width: $mobileView) {
      padding: 0;
    }
  }

  .new-mainContent {
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    min-height: calc(100vh - 18.5rem);

    @media only screen and (min-width: $mobileView) {
      overflow: visible;
      position: relative;
      float: left;
      width: 60%;
    }
  }

  +.new-pageFooter {
    margin-top: 0;
  }
}

#presto-page-layout {
  margin-left: auto;
  margin-right: auto;
  padding-top: 56px;

  @media only screen and (max-width: $mobileView) {
    margin: unset;
    padding-left: 16px;
    padding-right: 16px;
  }

  .new-pagesidenav {
    width: 100%;

    @media only screen and (min-width: $mobileView) {
      //display: flex;
      width: 224px;
      top: 2.5rem;
      min-height: calc(93% - 8.5rem);
      z-index: 100;
      position: -webkit-sticky;
      position: sticky;
      float: left;
      margin-right: 96px;
    }
  }

  .new-mainContent {
    max-width: 100%;
    overflow: hidden;
    min-height: calc(100vh - 18.5rem);

    @media only screen and (min-width: $mobileView) {
      overflow: visible;
      position: relative;
      float: left;
      width: 624px;
    }

    &.delete-main-content {
      @media only screen and (min-width: $mobileView) {
        float: none;
        width: 662px;
      }
    }
  }
}

.new-pageFooter {
  margin-top: 64px;

  @media only screen and (min-width: $mobileView) {
    margin-top: 80px;
  }

  .footer {
    margin: 0;
  }
}

.map-content {
  margin-left: auto;
  margin-right: auto;
  width: 943px;
  height: 100%;

  @media only screen and (max-width: $mobileView) {
    margin: unset;
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.cso-height {
  max-width: 100%;
  overflow: hidden;

  @media only screen and (min-width: $mobileView) {
    overflow: visible;
    position: relative;
    float: left;
    width: 624px;
  }
}


.contentPageLayout-header-banner {
  position: relative;
  padding-top: 135px;
}

.contentPageLayout-header-contactless {
  position: relative;
  padding-top: 56px;
}

.contentPageLayout-header-padding {
  position: relative;
  padding-top: 80px;
}