@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.header-search {
  opacity: 0;
  visibility: hidden;
  position: absolute;

  &.active {
    border: 1px solid $secondary10;
    opacity: 1;
    visibility: visible;
    margin-top: -8px;
    background-color: $white;
    margin-left: -216px;

    @media only screen and (min-width: 921px) and (max-width: 1119px) {
      margin-left: -20px;
    }
  }

  .search-close-button {
    display: inline-block;
    line-height: 40px;
    cursor: pointer;
    border: none;
    background-color: $white;
    padding: 0 14px;
    margin: $spacing-base $spacing-base $spacing-base 0;

    img {
      margin: 0;
      width: auto;
      height: auto;
    }

    &:focus {
      outline: none;
    }
  }

  .search-input {
    @include fontSize-16;
    width: 105px;
    background-color: $white;
    padding: $spacing-1;
    border: none;
    font-family: $regularFont;
    color: $secondary80;

    @media only screen and (min-width: 1120px) {
      width: 180px;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $secondary60;
    }
  }
}