@import '../../assets/scss/vars.scss';

.balanceBlock-component {
  background-color: $white;

  @media only screen and (min-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding: $spacing-1;
  }

  &.hide {
    display: none;
  }

  .balanceBlock-link {
    font-family: $regularFont;
    display: block;
    font-size: 14px;
  }

  .balanceBlock-transit {
    display: flex;
    flex-direction: row;
    padding: $spacing-1;
    border-top: 1px solid $secondary20;


    .transit-info {
      flex: 3;
      align-self: center;

      .product-title {
        margin-bottom: $spacing-base;
      }

      .product-description {
        font-family: $regularFont;
        font-size: 14px;
      }
    }

    .transit-image {
      flex: 1;
    }
  }

  .balanceBlock {
    background-color: $white;

    @media only screen and (min-width: $mobileView) {
      display: flex;
      padding: 0 $spacing-1 $spacing-base $spacing-2;
      @media print {
        padding-left: 0px;
      }
    }

    .balanceBlock-left {
      padding: $spacing-1 $spacing-1 $spacing-2;
      border-bottom: 1px solid $secondary10;

      @media only screen and (min-width: $mobileView) {
        border-bottom: none;
        border-right: 2px solid #928b8b6b;
        padding: 0;
        flex: 1;
      }

      .balanceBlock-title {
        margin-bottom: 0;
      }
    }

    .balanceBlock-balance {
      font-size: $spacing-3;
      line-height: $spacing-5;
    }
    .balanceBlock-mw-autoload {
      margin-top: 16px;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 21px;
      font-family: $regularFont;
    }
    .balanceBlock-mw-fareType {
      font-size: 14px;
      line-height: 24px;
      font-family: $regularFont;
    }
    .balanceBlock-mw-expiry {
      font-size: 14px;
      line-height: 24px;
      font-family: $regularFont;
    }
    .balanceBlock-mw-note{
      font-size:12px;
      line-height: 16px;
      font-family: $regularFont;
      font-style: italic;
      padding-top:12px;
      padding-right:16px;
      @media screen and (max-width: $mobileView) {
        padding-right:24px;
      }
    }

    .balanceBlock-right {
      padding: $spacing-1;
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: $mobileView) {
        padding: 0 0 0 $spacing-4;
        flex: 2;
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .pending-progress {
        &:last-child {
          .pending-date.product-description {
            margin-bottom: 0;
          }
        }

        p {
          padding: 0;
          margin: 0;
        }

        .pending-description {
          display: flex;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .balanceBlock-wrapper {
      margin-left: $spacing-9;

      @media only screen and (min-width: $mobileView) {
        margin: 0 auto;
      }

      .pending-link {
        display: flex;
        cursor: pointer;
        text-decoration: underline;
      }

      .balanceBlock-link {
        margin-top: $spacing-1;
        width: 90%;
      }
    }

    .product-lists {
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-bottom: $spacing-base;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }

      .product,
      .products-empty,
      .products,
      .product-image {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
      }

      .products-empty {
        align-items: baseline;
        height: 100%;
        // justify-content: space-between;
      }

      .product-image img {
        width: 42px;

        @media screen and (min-width: $mobileView) {
          width: 56px;
        }
      }

      .product-image.product-image-count-2 {
        padding-top: 12px;
        img {
          width: 77px;

          @media screen and (min-width: $mobileView) {
            width: 80px;
            height: 40px;
          }
          @media screen and (max-width: $mobileView) {
            width: 60px;
            height: 45px;
          }
        }
      }

      .product-image.product-image-count-3 img {
        width: 56px;

        @media screen and (min-width: $mobileView) {
          width: 50px;
        }
      }

      .product-image.product-image-count-4 {
        padding-top: 24px;
        img {
          width: 42px;

          @media screen and (min-width: $mobileView) {
            width: 50px;
            height: 40px;
          }
          @media screen and (max-width: $mobileView) {
            width: 42px;
            height: 30px;
          }
        }
      }

      .product-image.product-image-count-5 {
        padding-top: 24px;
        @media screen and (max-width: $mobileView) {
          margin-top: 10px;
        }
        img {
          width: 36px;
          max-height: 33px;
          object-fit: contain;

          @media screen and (min-width: $mobileView) {
            width: 45px;
            max-height: 28px;
          }
        }
      }

      .product-image.product-image-count-6 img {
        width: 32px;

        @media screen and (min-width: $mobileView) {
          width: 42px;
        }
      }

      .product {
        flex-direction: row;
        display: flex;

        .product-image {
          align-self: flex-start;
          flex: 1;
          max-width: 56px;
          margin-right: $spacing-2;
        }

        .product-info {
          flex: 2;

          .product-title {
            margin: 0;
            padding: 0 0 $spacing-1;
            @media screen and (min-width: $mobileView) {
              padding-bottom: 8px;
            }
            @media screen and (max-width: $mobileView) {
              padding-bottom: 0;
            }
          }
          .product-description {
            margin: 0;
            padding: 0 0 $spacing-1;
          }

          .balanceBlock-link {
            padding-top: $spacing-2;
          }
        }
      }
    }

    .balanceBlock-title,
    .product-title {
      line-height: $spacing-2;
      display: flex;
      margin-bottom: $spacing-base;

      @media only screen and (min-width: $mobileView) {
        margin-bottom: $spacing-base;
      }

      .title {
        flex: 1;
      }
    }

    .balanceBlock-description,
    .product-description {
      font-size: 14px;
      font-family: $regularFont;

      @media only screen and (min-width: $mobileView) {
        max-width: 355px;
        margin-top: $spacing-base;
        margin-bottom: $spacing-base;
      }
    }

    .balanceBlock-description {
      margin-top: 0 !important;
      @media only screen and (min-width: $mobileView) {
        margin-bottom: 23px !important;
      }
    }
  }
}
@media only screen and (min-width: $mobileView) {
  html[lang='fr-CA'] .balanceBlock-component .balanceBlock .balanceBlock-description {
    margin-bottom: 39px !important;
  }
}

.balance-card--accordion-section {
  background-color: $white;
  font-size: $spacing-1;
  line-height: 2rem;
  margin-top: 10px;
  // padding: 2px;
  button.balance-card--accordion-heading {
    outline-width: initial;
  }

  .balance-card--accordion-heading {
    display: flex;
    justify-content: space-between;
    padding: $spacing-1;
    align-items: center;
    width: 100%;
    line-height: 2rem;
    border: 2px solid #e8e8e8;
    box-shadow: none;
    color: #000;

    .selected-card-text {
      text-align: left;
      .small-text-top {
        font-family: 'AvenirNextLTPro-Regular', sans-serif;
      }
    }

    img.up {
      transform: rotate(180deg);
    }
  }

  .balance-card--accordion-wrapper {
  }
}
