@import '../../../assets/scss/vars.scss';
@import '../../../assets/scss/mixins.scss';

.transitPass-wrapper {
  .payment-plan-modal {
    .modal-wrapper {
      position: absolute;
      left: 0;
      right: 8rem;
      padding-left: 24px;
      padding-right: 24px;
      @media only screen and (max-width: $mobileView) {
        left: 24px;
        right: 24px;
        padding-bottom: 24px;
      }

      .modal-title {
        padding: 24px 12px 16px 0px;
        @media only screen and (max-width: $mobileView) {
          padding: 24px 0 16px;
        }
      }

      .modal-text {
        padding: 0 6px;

        .payment-plan-wrapper {
          margin-top: 31px;
          border: 2px solid #e8e8e8;

          .payment-plan-header {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: center;
            border-bottom: 2px solid #e8e8e8;
            font-size: 24px;
            font-family: 'AvenirNextLTPro-Demi';
          }

          .payment-plan-content {
            margin-left: 16px;
            margin-right: 16px;

            .payment-plan-subtitle {
              margin-left: 6px;
              margin-right: 6px;
              padding-top: 12px;
              padding-bottom: 12px;
              display: flex;
              font-family: 'AvenirNextLTPro-Demi';
              @media only screen and (max-width: $mobileView) {
                align-items: flex-end;
                font-size: 14px;
                margin-right: 0;
              }

              .ttcTerm {
                flex: 0.1 1;
                padding-right: 56px;
              }

              .ttcDiscount {
                flex: 0.4 1;
                padding-right: 48px;
              }

              .ttcPrice {
                flex: 0.4 1;
                @media only screen and (max-width: $mobileView) {
                  padding-right: 8px;
                }
              }
            }

            .payment-plan-data {
              margin-left: 6px;
              margin-right: 6px;
              padding-top: 12px;
              padding-bottom: 12px;
              display: flex;
              border-top: 2px solid #e8e8e8;

              .ttcTerm {
                flex: 0.4 1;
              }

              .ttcDiscount {
                flex: 0.4 1;
              }

              .ttcPrice {
                flex: 0.4 1;
              }
            }
          }
        }

        .payment-plan-button {
          float: right;
          margin-top: 16px;
          @media only screen and (max-width: $mobileView) {
            width: 100%;
            margin-top: 0;
          }
        }
      }

      .button-wrapper {
        margin-right: 6px;
      }
    }
  }
  .transit-pass-paragraphs {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.transit-pass-renew-dropdowns {
  display: flex;

  @media only screen and (max-width: $mobileView) {
    display: block;
  }

  .trasnit-pass-year-select {
    margin-left: 20px;

    @media only screen and (max-width: $mobileView) {
      margin-left: 0px;
    }
  }

  .trasnit-pass-year-select {
    margin-top: 0px;

    @media only screen and (max-width: $mobileView) {
      margin-top: 24px;
    }
  }
}

.transitPass-wrapper {
  .buttonComponent {
    margin-left: 16px !important;
    @media only screen and (max-width: $mobileView) {
      margin-left: 0 !important;
    }
  }
  @media only screen and (max-width: $mobileView) {
    .button-wrapper-right {
      padding: 16px;
    }
  }
}
