@import '../../assets/scss/vars';

.content-error-block {
  padding: $spacing-2;
  border: 1px solid $secondary10;
  border-left: 4px solid $feedbackError;
  background-color: $white;
  margin-bottom: $spacing-1;

  &.small {
    padding: 12px 0 12px 4px;

    .content-error-block-title {
      font-size: 16px;
      background-size: 24px;
      background-position: 0 5px;
      padding-left: 29px;
      margin-bottom: 0;
      min-height: 26px;
      padding-top: 4px;
    }
  }

  &.no-bg{
    margin-bottom: $spacing-1;
    border: 0;
    background-color: #f3f3f3;
    padding-left: 0;

    .content-error-block-title {
      font-size: 14px;
      background-size: 18px 28px;
      padding-left: 24px;
    }
  }
}
.contactlessTransactionHistory-unpaidFare-error .content-error-block-title {
  color: $errorTitle;
}

.content-error-block-title {
  color: $feedbackError;
  font-size: 22px;
  font-family: $boldFont;
  padding-left: $spacing-5;
  line-height: 32px;
  margin-bottom: $spacing-1;
  background: url('../../assets/images/error.svg') no-repeat left top;
}

.content-error-block-text,
.content-error-block-text-margin {
  font-family: $regularFont;
  line-height: 24px;
}

.content-error-block-text {
  margin-left: 0;
}

.content-error-block-text-margin {
  margin-left: $spacing-5;
}
