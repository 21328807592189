@import '../../assets/scss/vars';
@import '../../assets/scss/mixins';

.checkoutGrid-wrapper {
  margin-top: $spacing-3;

  @media only screen and (min-width: $mobileView) {
    margin-top: $spacing-2;
  }

  .checkoutGrid-card {
    padding-bottom: $spacing-base;
    line-height: $lineHeight-24;

    span {
      font-family: $regularFont;
    }

    &.successNickname {
      border-bottom: 2px solid $secondary20;
    }
  }

  &.successModifier {
    .checkoutGrid-footer,
    .checkoutGridItem-remove {
      display: none;
    }

    .checkoutGrid {
      border: none;

      @media only screen and (min-width: $mobileView) {
        padding: $spacing-1 0;
      }
    }

    .checkoutGridItem {
      padding: $spacing-1 0 $spacing-1;
    }

    .checkoutGridItem-date {
      margin-bottom: 0;
    }

    @media only screen and (max-width: $mobileView) {
      .checkoutGrid-header-item {
        &:first-child {
          padding-left: 0;
          font-size: 14px;
          line-height: 21px;
          margin-right: 80px;
        }

        &:last-child {
          padding: 0 0 16px 0;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}

.checkoutGrid-wrapper.successModifier {
  .checkoutGrid {
    .checkoutGrid-header-item {
      &:nth-child(2) {
        @media only screen and (min-width: $mobileView) {
          padding-right: 80px;
        }
      }
    }
  }
}

.checkoutGrid {
  background-color: $white;
  border: 1px solid $secondary10;
  padding: $spacing-1 0;
  .checkoutGrid-newCard-header {
    border-bottom: 2px solid #d1d1d1;
    padding-bottom: 16px;
    padding-left: 16px;
    font-size: 20px;
  }
  .checkoutGrid-info-wrapper {
    padding: 16px 16px 0px 16px;
  }

  .checkoutGrid-header {
    border-bottom: 2px solid $secondary20;
    display: flex;
  }

  .checkoutGrid-header-item {
    padding: 0 0 $spacing-1 $spacing-1;

    &:first-child {
      flex: 1 1;
    }

    &:nth-child(2) {
      padding-right: $spacing-1;
      @media only screen and (min-width: $mobileView) {
        padding-right: 80px;
      }
      @media only screen and (max-width: $mobileView) {
        padding: 0px 0px 16px 0px;
        font-size: 16px;
        line-height: 21px;
        margin-right: 60px;
      }
    }

    &:last-child {
      padding-right: $spacing-1;
    }
  }

  .checkout-input-form {
    border-bottom: 2px solid #d1d1d1;

    .checkout-input-block {
      margin: 1.5rem 0rem;
      padding-left: 70px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .nickname-Note {
        width: 450px;
        font-family: 'AvenirNextLTPro-Regular';
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          width: fit-content;
          font-size: 16px;
          padding: 0 10px;
        }
      }

      .checkout-nickName {
        width: 300px;
        margin: 1.5rem 0rem 1.5rem 0rem;
        @media only screen and (max-width: $mobileView) {
          width: initial;
        }
        .nickname-field {
          margin-top: -25px;
          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }
      }

      .checkout-pin {
        display: flex;
        width: 450px;
        @media only screen and (max-width: $mobileView) {
          width: initial;
          display: block;
        }

        .pinNumber {
          width: 217px;
          @media only screen and (max-width: $mobileView) {
            width: initial;
          }
          .pin-field {
            margin-top: -25px;
            @media only screen and (max-width: $mobileView) {
              margin-top: -18px;
            }
          }
        }
        .confirmPinNumber {
          margin-left: 1rem;
          width: 217px;
          @media only screen and (max-width: $mobileView) {
            width: initial;
          }

          .confirmPin-field {
            margin-top: -25px;
            @media only screen and (max-width: $mobileView) {
              margin-top: -18px;
            }
          }
          @media only screen and (max-width: $mobileView) {
            margin-left: 0;
            margin-top: 25px;
          }
        }
      }
      .PinNumberNote {
        margin: 6px 0px 0px 17px;
        font-family: 'AvenirNextLTPro-Regular';
        font-size: 14px;
      }
    }

    .card-language {
      margin: 1.5rem 0;
      padding-left: 70px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 20px;
      }

      &.guestflow {
        margin-top: 0;
        @media only screen and (max-width: $mobileView) {
          margin: 1.5rem 0;
        }
      }

      .language-title {
        font-family: 'AvenirNextLTPro-Regular';
      }
      .language-content {
        display: flex;
        margin-top: 0.9rem;
        .card-language-english {
          padding-left: 0px;
          margin-left: 0.5rem;
        }
        .card-language-french {
          padding-left: 0;
          margin-left: 3rem;
        }
      }
    }
  }

  .checkoutGrid-footer {
    text-align: right;
    padding: 1 $spacing-1 $spacing-1 0;
    line-height: $lineHeight-24;
    border-top: 2px solid $secondary20;
  }
}

.checkout-total {
  @include fontSize-22;
  padding: 10px 24px 20px 0;
  text-align: right;

  @media only screen and (min-width: $mobileView) {
    @include fontSize-16;
    // padding-right: 0;
  }

  > span {
    @include fontSize-16;
    margin: 0 0;

    @media only screen and (min-width: $mobileView) {
      @include fontSize-18;
      margin: 0 0;
    }
  }

  &.successModifier {
    border: none;
    margin-top: 0;
    padding: 0 $spacing-1;
  }
}

.checkoutGrid-wrapper {
  .checkoutGrid {
    .note {
      padding: 16px 16px 25px 16px;
      margin-left: 24px;
      margin-right: 24px;
      margin-bottom: 24px;
      @media only screen and (max-width: $mobileView) {
        margin-right: 16px;
        margin-left: 16px;
      }
    }
  }
}

.checkout-note {
  //margin-top: $spacing-2;
  line-height: $lineHeight-24;
  @media only screen and (max-width: $mobileView) {
    font-size: 21px;
  }
}

.d-none {
  display: none;
}

.grid-additional-pad {
  @media only screen and (min-width: $mobileView) {
    padding: 0 24px;
  }
}
