@import '../../assets/scss/vars.scss';

.top-nav-authenticated {
  width: 100%;
  position: fixed;
  background-color: $black;
  z-index: $zindex-topNavigation;

  .modal-overlay,
  .modal-wrapper {
    z-index: 2022;
  }

  @media print {
    display: none;
  }
}

.disable-nav-link {
  pointer-events: none;
}

.header {
  @media print {
    display: none;
  }

  @media only screen and (min-width: 921px) {
    display: flex;
    justify-content: space-between;
    width: 1024px;
    height: 56px;
    // background-color: $black;
    //text-align: center;
    list-style: none;
    margin: 0 auto;
    // padding: 10px 0 0 0;
    align-items: center;
    // position: fixed;
    // z-index: $zindex-topNavigation;

    a.dropdown+.dropdown-menu {
      top: 56px;
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      z-index: $zindex-topNavigationDropdown;
      margin-top: 0;
    }

    .user-icon {
      position: absolute;
      top: 14px;

      .dropdown-menu {
        width: 191px;
        top: 42px !important;
        right: 12px;
      }

      a {
        margin-right: 0px;
      }
    }

    li.user-icon {
      ul.dropdown-menu {
        li {
          width: auto;
        }
      }
    }

    .logo-header {
      width: 98px;
      // height: 20px;
      vertical-align: top;
    }

    li {
      display: inline-block;
      vertical-align: middle;

      &:focus {
        outline: none;
      }

      .header-link:hover {
        border-bottom: 1px solid $white;
      }

      .external-image {
        display: none;
      }

      .cart {
        width: 26px;
        height: 26px;
        margin: 0 12px;
        display: block;
        cursor: pointer;
        outline: none;

        span.cart-quantity {
          position: relative;
          display: block;
          left: 20px;
          top: -35px;
          font-family: $boldFont;
          font-size: 12px;
          border-radius: 50%;
          color: $black;
          background-color: $mediumGreen;
          width: 20px;
          height: 20px;
          padding: 0;
          text-align: center;
          text-indent: 0;
          line-height: 20px;
        }
      }

      a {
        cursor: pointer;
        position: relative;
        color: $white;
        font-family: $boldFont;
        font-size: 14px;
        height: 33px;
        margin: 10px 16px;
        padding: 0px;
        text-decoration: none;

        &:focus {
          outline: #8bb8e8 solid 2px;
        }

        &.active {
          color: $mediumGreen;
        }

        &.user {
          display: block;
          width: 32px;
          margin-top: 0;

          &.dropdown,
          &:hover {
            position: relative;

            &:after {
              content: '';
              display: block;
              height: 2px;
              background-color: $white;
              position: absolute;
              width: 100%;
              bottom: 0;
            }
          }
        }

        &.search-icon {
          // display: block;
          width: 24px;
          background-size: 16px auto;
          margin-left: 125px;
          opacity: 1;
          visibility: visible;

          @media only screen and (min-width: 1120px) {
            margin-left: auto;
          }

          &.inactive {
            opacity: 0;
            visibility: hidden;
          }
        }

        &:hover {
          color: $white;
        }
      }

      .search-icon-desktop {
        //width: 16px;
        background-size: 16px auto;
      }

      .dropdown-menu {
        background-color: $white;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        list-style: none;
        padding: 0;


        li.menuitem--parent {
          .dropdown-menu-child {
            display: none;
          }
        }

        li.menuitem--parent:focus-within,
        li.menuitem--parent:hover {
          position: relative;

          .dropdown-menu-child {
            position: absolute;
            left: 100%;
            visibility: visible;
            display: block;
            opacity: initial;
            width: max-content;
            background: #fff;
            padding: 0px;
          }
        }

        li:not(:first-child) {
          border-top: none;
        }

        li {
          display: flex;
          border-left: 1px solid $secondary10;
          border-right: 1px solid $secondary10;
          border-bottom: 1px solid $secondary10;

          text-align: left;
          min-width: 10px;
          padding-right: 20px;
          width: 230px;

          &:hover {
            background-color: $secondary10;
          }

          a {
            width: 100%;
            height: 100%;
            line-height: 40px;
            padding-left: 20px;
            margin: 0px;
            color: $black;

            &:hover {
              border-bottom: none;
            }
          }
        }


      }
    }

    li:first-child {
      a {
        margin-left: 0;
      }
    }
  }
}

.header-mobile {
  @media only screen and (max-width: 920px) {
    display: block;
    z-index: $zindex-topNavigation;
    background-color: $black;
    margin: 0;
    padding: 0;
    position: fixed;
    height: 56px;
    width: 100%;

    .logo-header-mobile {
      height: 32px;
      margin-top: 12px;
      margin-left: $spacing-2;
    }

    .mobile-menu-wrapper {
      display: none;
      visibility: hidden;
      top: 56px;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
      overflow-y: auto;

      &.active {
        display: block;
        visibility: visible;
      }
    }

    div.mobile-menu-right {
      float: right;
      min-width: 99px;

      .cart {
        position: relative;
        top: 0;
        width: 26px;
        height: 26px;
        display: block;
        margin-right: $spacing-2;
        margin-top: 14px;
        float: left;

        span.cart-quantity {
          position: absolute;
          left: 14px;
          bottom: 12px;
          font-family: $boldFont;
          font-size: 12px;
          border-radius: 50%;
          color: $black;
          background-color: $mediumGreen;
          width: 20px;
          height: 20px;
          padding: 0;
          text-align: center;
          text-indent: 0;
          line-height: 20px;
        }
      }

      .hamburger {
        display: block;
        margin: $spacing-1 $spacing-1 0 0;
        width: 24px;
        height: 24px;
        text-align: center;
        float: right;

        button {
          color: $white;
          border: none;
          background-color: unset;
          padding: 0;
          width: 24px;
          height: 24px;

          img {
            width: 100%;
          }

          &.inactive {
            display: none;
          }

          &.close {
            font-size: 25px;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .dropdown-menu-mobile,
    .dropdown-secondlevelmenu-mobile {
      visibility: hidden;
      display: none;

      button.up img {
        transform: rotate(180deg);
      }

      &.active {
        font-size: 18px;
        position: absolute;
        background-color: $white;
        list-style: none;
        padding: 0;
        opacity: 1;
        visibility: visible;
        width: 100%;
        // z-index: $zindex-topNavigationDropdown;
        display: table;
        height: 100%;
      }

      .nav-border {
        width: 72px;
        background-color: $black;
        position: absolute;
        height: 100%;
      }

      li {
        display: block;
        border-bottom: 1px solid $secondary20;
        margin-left: 72px;

        .card-name {
          padding-top: $spacing-1;
          font-size: 16px;
          line-height: 24px;
        }

        .contactless-id-text {
          font-size: $fontSize-14;
          font-family: $regularFont;
          margin-right: $spacing-6;
        }

        .contactless-id-hidden {
          display: none;
        }

        .card-id {
          padding-bottom: $spacing-1;
          font-size: 14px;
          font-family: $regularFont;
          border: none;
          line-height: 21px;
        }

        .icon-button {
          outline: none;
        }

        .icon-button img {
          position: absolute;
          right: 0;
          margin-right: $spacing-1;
          width: 32px;
          height: 32px;
        }

        &>ul>li {
          margin-left: 23px;
          border-bottom: none;

          &>a {
            margin-left: -23px;
            font-family: $regularFont;
            font-size: 14px;
            color: $black;
          }
        }

        .dropdown-thirdlevelmenu {
          &-mobile {
            li {
              margin-left: -8px;
            }
          }
        }

        .dropdown-third-level {
          padding: 0;
          margin-left: $spacing-6;
          border-top: 1px solid $secondary20;

          li.third-level-link {
            margin-left: 0;
            border-bottom: none;
            margin-bottom: $spacing-1;

            &:first-child {
              margin-top: $spacing-1;
            }

            a {
              padding-left: 0;
              margin-left: 0;
              font-size: 14px;
              line-height: 21px;
              font-family: $regularFont;
            }
          }
        }

        a {
          padding-left: 24px;
          text-decoration: none;
          line-height: 56px;
          color: $black;

          &.dropdown+.dropdown-secondlevelmenu-mobile {
            background-color: $white;
            list-style: none;
            padding: 0;
            opacity: 1;
            visibility: visible;
            width: 100%;
            //  z-index: $zindex-topNavigationDropdown;
            position: relative;
            display: block;
          }

          &.dropdown {
            border-bottom: none;
            border-top: none;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}