@import '../../assets/scss/vars.scss';
.contentPageLayout {
  background-color: #fff;
  .contentPageLayout-main {
    justify-content: flex-end;
    padding-top: 0;
    .contentPageLayout-content {
      margin: 0;
      width: 60%;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
.home-banner {
  position: relative;
  margin: 0 0 6.25rem 0;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
    margin: 0;
  }
  .banner-inner {
    .container-fluid {
      .slogan {
        padding-top: 40px;
        @media only screen and (max-width: 768px) {
          padding: 0;
        }
        h1 {
          font-family: 'AvenirNextLTPro-Bold';
          position: relative;
          text-align: left;
          width: 72%;
          font-size: 3.75rem;
          margin: 0;
          padding-top: 5rem;
          line-height: 5.625rem;
          padding: 0;
          color: #191919;
          max-width: 500px;
          @media only screen and (max-width: 768px) {
            font-size: 2rem;
            line-height: 3rem;
            text-align: center;
            padding: 1.5rem 35px;
            // padding-top: 0.5rem;
            width: auto;
            z-index: 1;
          }
        }
        .prestoLink {
          width: 50%;
          color: #191919;
          text-align: left;
          margin-bottom: 4rem;
          max-width: 400px;
          div {
            line-height: 1.75;
            font-family: $boldFont;
            font-size: 1.375rem;
            p {
              margin: 2.5rem 0 0 0;
              font-size: 1.625rem;
              line-height: 2.5rem;
            }
          }
          @media only screen and (max-width: 768px) {
            width: auto;
            text-align: left;
            font-size: 1.375rem;
            line-height: 2rem;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            margin-bottom: 2.5rem;
          }
        }
        .heroimage03 {
          width: 100%;
          img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 59%;
            max-width: 59%;
            object-fit: cover;
            height: auto;
            @media only screen and (max-width: 768px) {
              position: relative;
              width: 100%;
              max-width: 100%;
              bottom: 0;
              top: 0;
            }
          }
          @media only screen and (max-width: 768px) {
            margin-top: -7rem;
          }
        }
      }
      .payment-banner {
        @media only screen and (max-width: 768px) {
          margin: 0 1.5rem;
        }
        p {
          margin-top: 6.5rem;
          margin-bottom: 1em;
          width: 44%;
          font-size: 1.625rem;
          line-height: 2.5rem;
          text-align: left;
          max-width: 320px;
          @media only screen and (max-width: 768px) {
            font-size: 1.375rem;
            margin-top: 2.5rem;
            line-height: 2rem;
            width: 70%;
            margin-bottom: 0;
          }
        }
        img {
          width: 40%;
          padding-bottom: 5.85rem;
          object-fit: contain;
          @media only screen and (max-width: 768px) {
            width: 100%;
            padding-bottom: 5.5rem;
          }
        }
      }
    }
  }
}
.home-banner::after {
  position: absolute;
  left: 0;
  right: 0;
  background-color: #f3f3f3;
  content: '';
  z-index: -1;
  margin-left: -100%;
  top: 0;
  bottom: 0;
}
