@import '../../assets/scss/vars';

.checkoutGridItem {
  padding: $spacing-1;
  position: relative;
  // display: flex;
  border-bottom: 2px solid $secondary20;

  @media only screen and (min-width: $mobileView) {
    padding: $spacing-1 0 $spacing-1;
  }

  .checkoutGridItem-title {
    line-height: 24px;
    margin-bottom: $spacing-base;
    margin-right: $spacing-1;

    @media only screen and (min-width: $mobileView) {
      margin-bottom: 0;
    }
    @media only screen and (max-width: $mobileView) {
      margin-right: 0px;
    }
    @media only print {
      margin-left: $spacing-1;
    }
  }

  .checkoutGridItem-date {
    font-family: $regularFont;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: $spacing-1;
  }

  .checkoutGridItem-remove {
    font-family: $regularFont;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
  }

  .checkoutGridItem-block {
    flex: 1;
    @media only screen and (min-width: $mobileView) {
      flex: 1;
      align-items: center;
    }
    @media only screen and (max-width: $mobileView) {
      flex: unset;
    }
    @media only screen and (max-width: $mobileView) {
      flex: unset;
    }
  }

  .checkoutGridItem-value-block {
    margin-top: $spacing-base;
    max-width: 160px;
    min-width: 50px;
    @media only screen and (min-width: $mobileView) {
      width: 250px;
      padding-right: $spacing-1;
      text-align: right;
    }

    @media print {
      padding-right: $spacing-1;
    }

    &.quantity {
      // padding-right: 29px;

      @media only screen and (min-width: $mobileView) {
        padding-right: 0;
      }
      // @media only screen and (max-width: $mobileView) {
      //   padding-right: 52px;
      // }
    }
    &.price {
      .checkoutGrid-value {
        @media only screen and (max-width: $mobileView) {
          text-align: right;
        }
      }
    }
  }
  .checkoutGridItem-content {
    display: flex;
    align-items: flex-start;
    .checkoutGridItem-logo-block {
      display: none;
      @media only screen and (min-width: $mobileView) {
        padding: 0 $spacing-1 0 $spacing-1;
        display: flex;
        align-self: center;
        width: 32px;
        height: 22px;

        img {
          align-self: center;
          position: relative;
          top: -5px;
        }
      }
    }
    .checkoutGridItem-data-block {
      //flex: 1 1;
      width: 100%;
      // border-bottom: 2px solid $secondary20;
      // padding-bottom: 16px;
      // @media only screen and (max-width: $mobileView) {
      //  flex: unset;
      //   margin-right: 38px;
      //   width: 130px;
      // }
      .show-by-col {
        flex-direction: column;
        align-items: baseline;
      }
      .checkoutGridItem-details {
        font-family: 'AvenirNextLTPro-Regular';
        font-size: 14px;
        line-height: 18px;
        padding-top: 12px;
        width: 14rem;
        p {
          margin: 0px;
        }
      }
    }
  }
  .checkoutGridItem-details-deactivate {
    font-family: 'AvenirNextLTPro-Regular';
    font-size: 14px;
    line-height: 18px;
    padding-top: 8px;
    margin-left: 72px;
    @media only screen and (max-width: $mobileView) {
      margin-left: 10px;
    }
    .checkoutgrid-flex {
      span {
        flex-basis: 77px;
      }

    }
    p {
      margin: 0px;
    }
  }
  .successModifier & {
    .checkoutGridItem-logo-block img {
      top: -2px;
    }
  }

  .checkoutGridItem-logo {
    align-self: stretch;
    width: 64px;
  }
  // &.checkoutGridItem-disable-border {
  //   border-bottom: none;
  // }
}

.checkoutgrid-flex {
  display: flex;
}

.checkout-grid-passes {
  padding-left: 8px;
}

.checkout-grid-passeswidth {
  width: 220px !important;

  @media only screen and (max-width: $mobileView) {
    width: 150px !important;
  }
}
