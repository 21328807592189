@import '../../assets/scss/vars';

.row {
  display: flex;
  border-bottom: 1px solid $secondary20;
  padding: $spacing-1 0 $spacing-1;
  font-size: 14px;

  @media only screen and (min-width: $mobileView) {
    border-width: 2px;
    font-size: 16px;
  }

  &.noBorder {
    border-bottom: 0;
  }

  &.noPaddingBottom {
    padding-bottom: 0;
  }

  div {
    margin: 0;
    flex: 1;

    &:last-child {
      text-align: right;
    }
  }

  .firstCell {
    padding-left: 8px;
  }
}
