@import '../../assets/scss/vars.scss';

.personalInformation {
  &-table {
    margin-bottom: $spacing-2;

    @media only screen and (min-width: $mobileView) {
      margin-bottom: $spacing-4;
    }
  }

  .transNoteEmpty-table {
    @media only screen and (min-width: $mobileView) {
      margin-bottom: $spacing-2;
    }
  }

  &-tableRow {
    border-top: 1px solid $secondary20;
    padding: $spacing-1 0;
  }

  .transNoteEmpty {
    @media only screen and (min-width: $mobileView) {
      padding-bottom: 0;
    }
  }

  &-emailNotificationNote {
    display: flex;

    img {
      margin-right: $spacing-1;
    }
  }
}

.update-personalinfo-message {
  margin-top: 30px;
  padding: 18px;
  background-color: #ffffff;
  margin-bottom: 10px !important;
  border-left: 4px solid $feedbackSuccess;

  .success-message {
    background: url('../../assets/images/Success.svg') no-repeat left top;
    -webkit-print-color-adjust: exact;
    line-height: 32px;

    #message {
      color: $feedbackSuccess;
      font-size: 18px;
      font-family: $boldFont;
      padding-left: 50px;
    }
  }

  @media only screen and (max-width: $mobileView) {
    padding: 32px 18px 32px 12px;
  }
}

.message-error-description {
  padding-top: 10px;
  color: #000;

  p {
    font-family: 'AvenirNextLTPro-Regular';
    font-size: 14px;
    margin: 0px;
  }

  @media only screen and (max-width: $mobileView) {
    padding-left: 50px;
  }
}

.update-personalinfo-message-error {
  border: 1px solid #d1d1d1;
  margin-top: 30px;
  padding: 18px;
  background-color: #ffffff;
  margin-bottom: 10px !important;
  border-left: 4px solid $feedbackError;

  .error-personalinfo-message {
    background: url('../../assets/images/Failed.svg') no-repeat left top;
    -webkit-print-color-adjust: exact;
    padding-bottom: 10px;
    line-height: 32px;

    //  border-bottom: 3px solid #f3f3f3;
    #messageerror {
      color: $errorTitle;
      font-size: 18px;
      font-family: $boldFont;
      padding-left: 50px;
    }
  }
}