@import '../../assets/scss/vars';

.radioInput {
  display: flex;
  position: relative;

  label {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid $secondary60;
    &.disabled {
      background: rgb(243, 243, 243);
    }
  }

  &-label {
    margin-left: 10px;
    margin-top: -2px;
    font-family: $boldFont;
  }

  input[type='radio'] {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: $zindex-radioInput;
    position: absolute;

    &:checked+label {
      width: 6px;
      height: 6px;
      border: 7px solid $black;
    }

    &:focus+label {
      outline-color: $black;
      outline-style: solid;
    }
  }
}