@import './vars';

// Typography

@mixin fontSize-12 {
  font-size: $fontSize-12;
  line-height: $lineHeight-18;
}

@mixin fontSize-14 {
  font-size: $fontSize-14;
  line-height: $lineHeight-24;
}

@mixin fontSize-16 {
  font-size: $fontSize-16;
  line-height: $lineHeight-24;
}

@mixin fontSize-18 {
  font-size: $fontSize-18;
  line-height: $lineHeight-27;
}

@mixin fontSize-20 {
  font-size: $fontSize-20;
  line-height: $lineHeight-30;
}

@mixin fontSize-22 {
  font-size: $fontSize-22;
  line-height: $lineHeight-33;
}

@mixin fontSize-26 {
  font-size: $fontSize-26;
  line-height: $lineHeight-39;
}

@mixin fontSize-32 {
  font-size: $fontSize-32;
  line-height: $lineHeight-48;
}

@mixin fontSize-48 {
  font-size: $fontSize-48;
  line-height: $lineHeight-72;
}

@mixin fontSize-60 {
  font-size: $fontSize-60;
  line-height: $lineHeight-90;
}
