@import '../../assets/scss/vars';
@import '../../assets/scss/mixins';

.deactivate-parent {
  width: 39rem;
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 100px;
  padding-top: 15px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
  }
  .transfer-card-header {
    margin-bottom: 40px;
    .header-title {
      width: 39rem;
      padding-bottom: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      @media only screen and (max-width: $mobileView) {
        width: auto;
      }
    }
    .header-text-note {
      text-align: center;
      font-family: $regularFont;
      margin-top: 0px;
    }
  }

  .deactivate-card-description {
    text-align: center;
    font-size: 18px;
    padding-bottom: 10px;
    width: 39rem;
    font-family: 'AvenirNextLTPro-Regular';

    @media only screen and (max-width: $mobileView) {
      width: auto;
    }
  }
}
