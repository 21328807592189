@import '../../assets/scss/vars.scss';

.autoload-header {
  font-size: 32px;
  text-align: center;
  margin-bottom: 8px;
  line-height: 48px;
  margin-top: 24px;
  @media only screen and (min-width: $mobileView) {
    font-size: 48px;
    line-height: 72px;
  }
}
.autoload-setup {
  width: auto;
  margin: 0 16px;
  @media only screen and (min-width: $mobileView) {
    width: 625px;
    margin: 0 auto;
  }

  .autoload-subheader {
    color: $black;
    text-align: center;
    margin-bottom: 32px;
    font-family: $regularFont;
    @media only screen and (min-width: $mobileView) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .setup-section {
    padding: 40px 16px 16px;
    background-color: $white;
    @media only screen and (min-width: $mobileView) {
      padding: 48px 32px 40px;
    }
    .section-left,
    .section-right {
      text-align: center;
      & > div {
        font-size: 18px;
        @media only screen and (min-width: $mobileView) {
          font-size: 16px;
        }
      }
      @media only screen and (min-width: $mobileView) {
        display: inline-block;
        width: 49%;
        margin-bottom: 72px;
      }
      .section-amount {
        margin-top: 8px;
        font-size: 26px;
        line-height: 48px;
        @media only screen and (min-width: $mobileView) {
          font-size: 32px;
        }
      }
    }
    .section-left {
      padding-bottom: 32px;
      border-bottom: 1px solid $secondary30;
      @media only screen and (min-width: $mobileView) {
        border-right: 1px solid $secondary30;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    .section-right {
      padding-top: 32px;
      padding-bottom: 48px;
      @media only screen and (min-width: $mobileView) {
        padding-top: 0;
        padding-bottom: 0px;
      }
    }
    .section-details-header {
      margin-bottom: 8px;
      @media only screen and (min-width: $mobileView) {
        margin-bottom: 16px;
        font-size: 22px;
      }
    }
    .section-details-amount {
      padding: 20px 0;
      border-top: 1px solid $secondary20;
      border-bottom: 1px solid $secondary20;
      @media only screen and (min-width: $mobileView) {
        padding: 16px 0;
      }
      & > div {
        font-size: 14px;
        display: inline-block;
        @media only screen and (min-width: $mobileView) {
          font-size: 16px;
        }
      }
      .amount {
        font-size: 18px;
        float: right;
        @media only screen and (min-width: $mobileView) {
          font-size: 22px;
        }
      }
    }
  }
  .autoload-form-wrapper {
    background-color: $white;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 26px;
    @media only screen and (min-width: $mobileView) {
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 32px;
    }
    .autoload-checkbox {
      display: flex;
      align-items: center;
      & > input {
        height: 25px;
        width: 25px;
        margin-right: 10px;
        appearance: none;
        border: 1px solid $secondary60;
        border-radius: 4px;
        padding: 0;

        &:checked {
          border-color: $black;
          background: url('../../assets/images/Selected@3x.png') -9px center no-repeat;
          background-size: 42px;
        }
        &:focus {
          outline: none;
        }
      }
      & > div {
        display: inline-block;
      }
    }

    .content-error-block {
      margin-top: -($spacing-2);
    }
  }

  .redirect-note {
    text-align: center;
    font-size: 14px;
    margin-top: 16px;
    font-family: $regularFont;
  }
}
