@import '../../assets/scss/vars';

.datePickerInput {
  width: 100%;
  display: block;
  overflow: hidden;
  margin-bottom: $spacing-3;

  @media only screen and (min-width: $mobileView) {
    // width: 146px;
    display: inline-block;
    margin-right: $spacing-1;
    margin-bottom: 0;
    vertical-align: top;
  }

  &-label {
    font-size: 14px;
    text-transform: uppercase;
    font-family: $boldFont;
    margin-bottom: $spacing-base;
  }

  &-icon {
    position: absolute;
    right: $spacing-base;
    top: 14px;
    width: 24px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0;
  }

  .react-datepicker {
    border-radius: 0;
    border: none;
    background-color: $secondary10;
    font-family: $regularFont;
  }

  .react-datepicker__header {
    background-color: $secondary10;
    border-bottom: 2px solid $secondary20;
    padding-top: 19px;
  }

  .react-datepicker__current-month {
    font-family: $boldFont;
  }

  .react-datepicker__navigation {
    border: none;
    top: 12px;
  }

  .react-datepicker__navigation--previous {
    width: 26px;
    height: 26px;
    background: url('../../assets/images/LeftArrow.svg') center no-repeat;
    background-size: 24px;
  }

  .react-datepicker__navigation--next {
    width: 26px;
    height: 26px;
    background: url('../../assets/images/Right.svg') center no-repeat;
    background-size: 24px;
  }

  .react-datepicker__day--selected {
    border-radius: 0;
    background-color: $black;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__input-container {
    padding: 6px 2px 14px 4px ;
    input {
      width: 100%;
      margin: 1px;
      background: $secondary10 url('../../assets/images/Calendar.svg') right $spacing-base center
        no-repeat;
      background-size: 24px;
      border: none;
      border-bottom: 1px solid $black;
      color: $black;
      font-family: $regularFont;
      font-size: 16px;
      padding: $spacing-1 $spacing-3 12px $spacing-1;
      box-sizing: border-box;

      @media only screen and (min-width: $mobileView) {
        width: 152px;
        box-sizing: inherit;
      }

      &::placeholder {
        color: $black;
      }

      &.calendar-is-open {
        // background-image: url('../../assets/images/up.svg');
      }
    }
  }

  .react-datepicker__day {
    font-size: 12px;
    border-radius: 0 !important;
    border: 1px solid transparent;
  }

  .react-datepicker__day--disabled {
    color: $secondary60;
  }

  .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--disabled) {
    background-color: $black;
    color: $white;
  }

  .react-datepicker__day:hover {
    background-color: $white !important;
    color: $black;
    border: 1px solid $black;
  }

  .react-datepicker__day--in-range {
    font-size: 12px;
    background-color: $black;
    color: $white;
    border-radius: 0;
    border: 1px solid $black;
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__year-text--in-selecting-range {
    background-color: $white;
    color: $black;
    border: 1px solid $black;
  }
  &-belowText{
    padding-left: $spacing-1;
  }
}
