@import '../../assets/scss/vars.scss';

.sub-title {
  font-size: 22px;
  line-height: 32px;
  padding: $spacing-1 0 $spacing-base 0;
  @media only screen and (min-width: $mobileView) {
    font-size: 22px;
  }
}

.getStarted {
  @media only screen and (min-width: $mobileView) {
    display: flex;
  }
}

.getStarted-block {
  display: flex;
  flex-direction: column;
  padding: $spacing-1 0;
  border-bottom: 2px solid $secondary20;

  .getStarted-text-wrapper {
    display: flex;
    flex-direction: row;
  }

  .getStarted-block-link {
    margin-left: $spacing-6;
  }

  &:last-child {
    border: none;
  }

  @media only screen and (min-width: $mobileView) {
    text-align: center;
    border-bottom: none;
    border-right: 2px solid $secondary20;
    padding: 0 $spacing-2;
    flex: 1;
    justify-content: space-between;

    .getStarted-text-wrapper {
      flex-direction: column;
    }
    .getStarted-block-link {
      margin-left: 0;
      text-align: center;
    }
  }
}

.getStarted-img-block {
  margin-right: $spacing-1;

  @media only screen and (min-width: $mobileView) {
    margin-right: 0;
  }

  img {
    margin-top: -4px;
    width: 40px;
  }
}

.getStarted-block-text {
  font-size: 14px;
  line-height: 21px;
  font-family: $regularFont;
  margin-bottom: $spacing-base;

  @media only screen and (min-width: $mobileView) {
    margin-bottom: $spacing-1;
  }
}

.addCard-success {
  .setup-section {
    padding: 0;
    margin-top: $spacing-base;
    @media only screen and (min-width: $mobileView) {
      margin-top: $spacing-1;
    }
  }
}
