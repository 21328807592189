@import '../../assets/scss/vars.scss';

.accordion-section {
  background-color: $white;
  font-size: $spacing-1;
  margin-bottom: 1rem;
  margin-right: 20%;
  line-height: 2rem;

  p {
    color: $text-color-black;
  }

  button.accordion-heading:focus {
    outline-width: initial;
  }

  .accordion-heading {
    display: flex;
    justify-content: space-between;
    padding: $spacing-1 $spacing-base $spacing-1 $spacing-1;
    align-items: center;
    width: 100%;
    line-height: 2rem;
    border: 1px solid #e8e8e8;
    box-shadow: none;
    // outline: none;

    img {
      float: right;
      margin-left: $spacing-1;
    }

    img.up {
      transform: rotate(180deg);
    }

    p {
      margin: 0;
      padding: 0;
      text-align: left;
    }

    @media only screen and (min-width: $mobileView) {
      padding: $spacing-2 $spacing-1 $spacing-2 $spacing-2;

      img {
        margin-left: $spacing-3;
      }
    }
  }

  button:focus:not(:focus-visible) {
    outline: none;
  }

  .accordion-content {
    font-family: $regularFont;
    color: $black;
    transition-delay: 1s;
    padding: $spacing-1 $spacing-1 $spacing-2;
    border-top: 1px solid $secondary10;
    border: 1px solid #e8e8e8;
    border-top: none;

    div p {
      margin: 0 0 $spacing-1 0;

      a {
        color: #191919;
        font-family: 'AvenirNextLTPro-Demi', sans-serif;
        border-bottom: 1px solid #191919;
        text-decoration: none;
      }
    }

    hr {
      margin: 0 0 $spacing-1;
    }

    div {

      p:last-of-type,
      li:last-of-type {
        margin: 0;
      }
    }

    @media only screen and (min-width: $mobileView) {
      padding: $spacing-2;
    }
  }

  @media screen and (max-width: $mobileView) {
    .support-faq-table tr {
      display: flex;
      flex-direction: column;
    }

    .support-faq-table tr:not(:first-child) {
      margin-top: $spacing-3;
    }

    .support-faq-table tr td:not(:first-child) {
      margin-top: $spacing-1;
    }

    .support-faq-td-45,
    .support-faq-td-10 {
      width: 100%;
    }
  }

  @media screen and (min-width: $mobileView) {
    .support-faq-table tr {
      display: table-row;
      flex-direction: row;
      margin-bottom: $spacing-3;
    }

    .support-faq-table tr td {
      padding-bottom: $spacing-3;
    }

    .support-faq-td-45 {
      width: 40%;
    }

    .support-faq-td-10 {
      width: 20%;
    }
  }

  .support-faq-table {
    width: 85%;
  }

  .support-faq-table tr td {
    text-align: center;
  }

  .support-faq-td-45,
  .support-faq-td-10 {
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    // padding: 0 1.5rem;
    margin-right: 0rem;
  }
}

#unpaid-accordian {
  margin-right: 0%;
}

.accordion-contents {
  background-color: #ffffff;
  font-size: 16px;
  margin-bottom: 1rem;
  margin-right: 20%;
  line-height: 2rem;
  width: 95%;
  border: 1px solid #e8e8e8;

  @media only screen and (max-width: $mobileView) {
    width: 98%;
  }

  .accordion-heading {
    padding: 24px 16px 24px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    line-height: 2rem;
    // border: 1px solid #e8e8e8;
    border: none !important;
    box-shadow: none;
    outline: none;

    p {
      margin: 0;
      padding: 0;
      text-align: left;
      color: #3a811e;
      display: block;

      font-size: 1.8rem;
      font-family: $boldFont;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.6rem;
        line-height: 2.3rem;
      }
    }

    img {
      margin-left: 32px;
      float: right;
      width: 56px;

      @media only screen and (max-width: $mobileView) {
        width: 30px;
      }
    }

    img.up {
      transform: rotate(180deg);
    }
  }

  button {
    background: transparent;

    cursor: pointer;
    font-size: 16px;
    font-family: $regularFont;

    border-radius: 0;
  }

  .accordion-content {
    padding: 24px;
    font-family: $regularFont;
    color: #666;
    // border: 1px solid #e8e8e8;

    p {
      font-size: 1.6rem !important;
      line-height: 2em;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.4rem !important;
      }
    }

    ul {
      li {
        font-size: 1.6rem;
        margin-bottom: 0.8rem;

        @media only screen and (max-width: $mobileView) {
          font-size: 1.4rem !important;
        }
      }
    }
  }
}

.accordion-renew-container {
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  button:focus:not(:focus-visible) {
    outline: none;
  }

  @media only screen and (max-width: 1124px) {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 99%;
  }

  .accordion-heading {
    font-family: $boldFont;
    line-height: 24px;
    text-align: left;
    // outline: none;
    width: 100%;

    @media only screen and (max-width: 1124px) {
      width: 75%;

      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        width: 72%;
      }
    }

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      color: #191919;
    }

    p {
      padding: 15px 4px 15px 18px;

      @media only screen and (max-width: $mobileView) {
        line-height: 24px;
        font-size: 16px;
        width: unset;
        padding-left: 16px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  img {
    position: absolute;
    right: 15px;
    top: 24px;

    @media only screen and (max-width: 1124px) {
      top: 24px;
    }
  }

  img.up {
    transform: rotate(180deg);
  }

  .accordion-content {
    font-family: $regularFont;
    border-top: 1px solid #dadada;
    padding-right: 4px;
    padding-left: 23px;
    font-size: 16px;
    line-height: 24px;

    @media only screen and (max-width: 1124px) {
      padding-left: 24px;
    }

    ul {
      padding-left: 27px;
      width: 88%;

      @media only screen and (max-width: 1124px) {
        padding-left: 15px;
      }

      li {
        margin-bottom: 15px;
        line-height: 1.5rem;
        color: #181818;
        padding-left: 10px;

        @media only screen and (max-width: 1124px) {
          width: 97%;
          line-height: 24px;
          font-size: 16px;
        }
      }
    }

    p {
      margin-top: 30px;
      width: 94%;
      line-height: 1.5rem;

      @media only screen and (max-width: 1124px) {
        width: 99%;
        line-height: 24px;
        font-size: 16px;
      }
    }
  }
}

#disable-margin-bottom {
  margin-bottom: 0;
}

.accordion-set-contents {
  background-color: #ffffff;
  font-size: 16px;
  margin-bottom: 1rem;
  line-height: 2rem;
  width: 75%;

  button:focus:not(:focus-visible) {
    outline: none;
  }

  @media only screen and (max-width: $mobileView) {
    margin-left: 21px;
    width: 88%;
  }

  .accordion-heading {
    padding: 25px 16px 25px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    line-height: 2rem;
    border: 1px solid #e8e8e8;
    box-shadow: none;

    // outline: none;
    @media only screen and (max-width: $mobileView) {
      padding: 10px 16px 10px 24px;
    }

    p {
      margin: 0;
      padding: 0;
      text-align: left;
      color: #181818;
      display: block;

      font-size: 1.2rem;
      font-family: $boldFont;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.3rem;
        line-height: 2.1rem;
      }
    }

    img {
      margin-left: 32px;
      float: right;
      width: 35px;

      @media only screen and (max-width: $mobileView) {
        width: 30px;
      }
    }

    img.up {
      transform: rotate(180deg);
    }
  }

  button {
    background: transparent;

    cursor: pointer;
    font-size: 16px;
    font-family: $regularFont;

    border-radius: 0;
  }

  .accordion-content {
    padding: 24px;
    font-family: $regularFont;

    border: 1px solid #e8e8e8;
    border-top: none;

    p {
      font-size: 1.1rem !important;
      line-height: 1.5rem;
      color: #181818;
      margin-top: 3px !important;
      margin-bottom: 3px !important;
    }

    ul {
      li {
        font-size: 1.1rem;
        line-height: 1.5rem;
        margin-bottom: 0.8rem;
        color: #181818;

        @media only screen and (max-width: $mobileView) {
          font-size: 1.1rem !important;
        }
      }
    }
  }
}

.ticket-vending-machines-accordion {
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  width: 78%;
  margin-bottom: 20px;
  position: relative;
  padding: 15px 0px 15px 20px;

  @media only screen and (max-width: 1124px) {
    margin-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .accordion-heading {
    font-family: $boldFont;
    line-height: 1.5rem;
    text-align: left;
    outline: none;
    width: 90%;

    @media only screen and (max-width: 1124px) {
      width: 75%;
    }

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      color: #181818;
    }
  }

  img {
    position: absolute;
    right: 15px;
    top: 24px;

    @media only screen and (max-width: 1124px) {
      top: 10px;
    }
  }

  .accordion-content {
    font-family: $regularFont;
    border-top: 1px solid #e8e8e8;
    margin-top: 12px;

    ul {
      margin-top: 28px;

      li {
        margin-bottom: 15px;
      }
    }

    p {
      margin-top: 30px;
      width: 93%;

      @media only screen and (max-width: 1124px) {
        width: 83%;
      }
    }
  }
}

.contact-us-accordion {
  background-color: $white;
  font-size: $spacing-1;
  // margin-right: 4%;
  line-height: 24px;
  border: 1px solid #e8e8e8;

  @media only screen and (max-width: $mobileView) {
    margin-right: auto;
    // margin-left: 20px;
  }

  p {
    color: $text-color-black;
  }

  button.accordion-heading:focus {
    outline-width: initial;
  }

  button:focus:not(:focus-visible) {
    outline: none;
  }

  .accordion-heading {
    display: flex;
    justify-content: space-between;
    padding: $spacing-1 $spacing-base $spacing-1 $spacing-1;
    align-items: center;
    width: 100%;
    line-height: 2rem;
    // border-bottom: 2px solid #e8e8e8;
    box-shadow: none;
    // outline: none;

    img {
      float: right;
      margin-left: $spacing-1;
    }

    img.up {
      transform: rotate(180deg);
    }

    p {
      margin: 0;
      padding: 0;
      text-align: left;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    @media only screen and (min-width: $mobileView) {
      padding: $spacing-2 $spacing-1 $spacing-2 $spacing-2;

      img {
        margin-left: $spacing-3;
      }
    }
  }

  .accordion-content {
    font-family: $regularFont;
    color: $black;
    transition-delay: 1s;
    padding: $spacing-2;
    border-top: 1px solid #e8e8e8;

    // border: 1px solid #e8e8e8;
    // border-top: none;
    @media only screen and (max-width: $mobileView) {
      padding-left: 16px;
      padding-bottom: 8px;
      padding: $spacing-1 $spacing-1 $spacing-2;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .text-center {
      text-align: inherit;
    }

    ul {
      padding-left: 30px;
      margin-bottom: 35px;

      li {
        line-height: 24px;
        padding-left: 10px;
        padding-bottom: 10px;

        &:last-child {
          padding-bottom: 16px;
        }
      }
    }

    button {
      display: inline-block;
      min-width: 120px;
      padding: 15px 16px;
      //padding-bottom: 15px;
      text-decoration: none;
      border-color: #000;
      background-color: #000;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
      margin-bottom: 16px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 1.4rem;
        font-weight: 200;
        line-height: 1;
        font-family: $regularFont;
      }
    }
  }
}

.Accordion-customer {
  background-color: #ffffff;
  border: 1px solid #dadada;
  margin-bottom: 16px;
  position: relative;

  @media only screen and (max-width: 1124px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .accordion-heading {
    font-family: $boldFont;
    line-height: 1.5rem;
    text-align: left;
    // outline: none;
    width: 100%;
    padding-left: 24px;

    @media only screen and (max-width: 1124px) {
      width: 75%;
      padding-left: 16px;
    }

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      color: #181818;
    }

    p {
      line-height: 24px;
      font-size: 16px;
      margin-top: 32px;
      margin-bottom: 32px;

      @media only screen and (max-width: $mobileView) {
        width: 101%;
        margin-top: 24px;
        margin-bottom: 24px;
      }
    }
  }

  img {
    position: absolute;
    right: 15px;
    top: 24px;

    @media only screen and (max-width: 1124px) {
      top: 20px;
    }
  }

  img.up {
    transform: rotate(180deg);
  }

  button:focus:not(:focus-visible) {
    outline: none;
  }

  .accordion-content {
    font-family: $regularFont;
    border-top: 1px solid #dadada;
    padding: 24px 25px 24px 25px;

    ul {
      padding-left: 24px;
      width: 88%;
      margin-bottom: 32px;
      margin-top: 0px;

      li {
        line-height: 32px;
        font-size: 16px;
        color: #191919;

        @media only screen and (max-width: 1124px) {
          width: 97%;
        }
      }
    }

    .disable-bottom-margin {
      margin-bottom: 0;
    }

    p {
      margin-top: 0px;
      width: 100%;
      line-height: 24px;
      font-size: 16px;
      margin-bottom: 24px;

      @media only screen and (max-width: 1124px) {
        width: 99%;
        line-height: 32px;
        font-size: 16px;
      }
    }

    p:last-child {
      margin-bottom: 0px;
    }

    .accordion-para {
      line-height: 32px !important;
    }

    h4 {
      font-size: 22px;
      line-height: 32px;
      font-family: $boldFont;
      color: #181818;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .grocery-heading {
      margin-bottom: 16px;
    }

    .grocery-headings {
      margin-top: 0px !important;
      margin-bottom: 16px !important;
    }

    h5 {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 8px;
      margin-top: 16px;
    }

    h6 {
      font-size: 16px;
      line-height: 24px;
      padding-left: 15px;
      margin-top: 0px;
      margin-bottom: 8px;
    }

    .ulclass {
      margin-bottom: 32px !important;
      padding-left: 41px;
    }

    .ul-grocery {
      margin-top: 8px !important;
    }

    .ul-grocerys {
      margin-bottom: 0px !important;
      margin-top: 8px !important;
    }

    .ul-class {
      margin-bottom: 16px;
      padding-left: 41px;
      margin-top: 0;
    }

    .ul-classes {
      margin-bottom: 0px !important;
    }

    .sub-para {
      line-height: 1.9rem;
    }
  }
}

.accordion-staticpage-section-wrapper {
  width: 944px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    width: unset;
  }

  .accordion-set-contents {
    @media only screen and (max-width: $mobileView) {
      margin-left: 16px;
      margin-right: 16px;
      width: unset;
    }
  }
}

.accordion-staticpage-section-wrapper:not(:last-child) {
  .Accordion-HelloPresto {
    margin-bottom: 16px;
  }
}

.Accordion-HelloPresto {
  background-color: #ffffff;
  border: 1px solid #dadada;
  width: 75%;
  position: relative;

  @media only screen and (max-width: $mobileView) {
    padding-top: 0px;
    padding-bottom: 0px;
    width: unset;
    margin-left: 16px;
    margin-right: 16px;
  }

  button:focus:not(:focus-visible) {
    outline: none;
  }

  .accordion-heading {
    font-family: $boldFont;
    line-height: 1.5rem;
    text-align: left;
    //outline: none;
    width: 100%;

    @media only screen and (max-width: 1124px) {
      width: 75%;
    }

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      color: #181818;
    }

    p {
      padding: 15px 4px 15px 23px;

      @media only screen and (max-width: $mobileView) {
        line-height: 1.7rem;
        font-size: 18px;
        width: 105%;
      }
    }
  }

  img {
    position: absolute;
    right: 15px;
    top: 24px;

    @media only screen and (max-width: 1124px) {
      top: 34px;
    }
  }

  img.up {
    transform: rotate(180deg);
  }

  .accordion-content {
    font-family: $regularFont;
    border-top: 1px solid #dadada;
    padding-right: 4px;
    padding-left: 23px;

    ul {
      padding-left: 27px;
      width: 88%;

      li {
        margin-bottom: 15px;
        line-height: 1.5rem;
        color: #181818;

        @media only screen and (max-width: 1124px) {
          width: 97%;
          line-height: 1.9rem;
          font-size: 20px;
        }
      }
    }

    p {
      margin-top: 30px;
      width: 94%;
      line-height: 1.5rem;

      @media only screen and (max-width: 1124px) {
        width: 99%;
        line-height: 1.8rem;
        font-size: 20px;
      }
    }

    h4 {
      font-size: 22px;
      font-family: $boldFont;
      color: #181818;
    }

    h5 {
      font-size: 17px;
    }

    h6 {
      font-size: 17px;
      padding-left: 15px;
      margin-top: -6px;
      margin-bottom: 0px;
    }
  }
}

.accordion-set-contents-add {
  background-color: #ffffff;
  font-size: 16px;
  line-height: 2rem;
  width: 75%;
  margin-bottom: 80px;

  button:focus:not(:focus-visible) {
    outline: none;
  }

  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-right: 16px;
    width: unset;
    margin-bottom: 64px;
  }

  .accordion-heading {
    padding: 25px 16px 25px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    line-height: 2rem;
    border: 1px solid #e8e8e8;
    box-shadow: none;

    // outline: none;
    @media only screen and (max-width: $mobileView) {
      padding: 10px 16px 10px 24px;
    }

    p {
      margin: 0;
      padding: 0;
      text-align: left;
      color: #181818;
      display: block;

      font-size: 1.2rem;
      font-family: $boldFont;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.3rem;
        line-height: 2.1rem;
      }
    }

    img {
      margin-left: 32px;
      float: right;
      width: 35px;

      @media only screen and (max-width: $mobileView) {
        width: 30px;
      }
    }

    img.up {
      transform: rotate(180deg);
    }
  }

  button {
    background: transparent;

    cursor: pointer;
    font-size: 16px;
    font-family: $regularFont;

    border-radius: 0;
  }

  .accordion-content {
    padding: 24px;
    font-family: $regularFont;

    p {
      font-size: 1.1rem !important;
      line-height: 1.5rem;
      color: #181818;
      margin-top: 3px !important;
      margin-bottom: 3px !important;
    }

    ul {
      li {
        font-size: 1.1rem;
        line-height: 1.5rem;
        margin-bottom: 0.8rem;
        color: #181818;

        @media only screen and (max-width: $mobileView) {
          font-size: 1.1rem !important;
        }
      }
    }
  }
}