@import '../../assets/scss/vars.scss';

.loadMyCard-wrapper {
  width: auto;

  @media only screen and (min-width: $mobileView) {
    width: 624px;
  }

  .autoload-tab {
    .setup-autoload {
      background-color: $white;
    }

    .autoload-rows {
      padding: $spacing-base $spacing-1 $spacing-1;
      background-color: $white;

      @media screen and (min-width: $mobileView) {
        padding: $spacing-1 $spacing-2 $spacing-2;
      }
    }

    .autoload-form-wrapper {
      padding: 1px 16px 40px 16px;
      display: block;
      text-align: left;
      background-color: $white;

      @media only screen and (min-width: $mobileView) {
        padding: 0 8px 40px 24px;
        display: flex;
      }
    }

    .autoload-title {
      padding-bottom: 16px;
      padding-top: 16px;
      border-bottom: 1px solid $secondary10;
      font-size: 22px;
      padding-left: 24px;
      line-height: 32px;
      background-color: $white;
    }

    .autoload-subtext {
      padding-top: 24px;
      padding-bottom: 8px;
      font-family: $regularFont;
      padding-right: 24px;
      padding-left: 16px;
      line-height: 24px;
      text-align: left;
      background-color: $white;

      @media only screen and (min-width: $mobileView) {
        padding-left: 24px;
      }
    }
  }

  .learn-note {
    margin-top: 32px;
  }
}