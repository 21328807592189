@import '../../assets/scss/vars';

.social-link {
    display: flex;
    text-decoration: none;
    font-family: $regularFont;
    font-size: 14px;

    img.social-icon {
      width: $spacing-3;
      height: $spacing-3;
      padding: 0 $spacing-1;

      @media only screen and (min-width: $mobileView) {
        padding: 0 $spacing-base 0 0;
        width: $spacing-1;
        height: $spacing-1;
      }
    }
  }