@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.deactivate-parent {
  .steps {
    margin-top: $spacing-4;
  }

  h2 {
    @include fontSize-18;
    font-family: $regularFont;
    text-align: center;
  }

  .checkout-text {
    display: none;
    margin-top: 29px;
  }

  .checkout-terms-block {
    border-top: 1px solid $secondary20;
    padding-top: 16px;
    padding-bottom: 24px;

    .checkboxComponent {
      .checkboxComponent-label {
        color: inherit;

        button {
          color: inherit;
        }
      }
    }
  }

  .checkout-payment-total {
    border-top: 1px solid $secondary20;
    padding-top: 16px;
    padding-bottom: 8px;
    text-align: right;
    font-family: $boldFont;

    @media only screen and (min-width: $mobileView) {
      padding-top: 20px;
    }

    span {
      font-size: 22px;
      position: relative;
      top: 2px;

      @media only screen and (min-width: $mobileView) {
        padding-left: 24px;
      }
    }
  }

  .checkout-textInput {
    max-width: 360px;

    > div {
      margin-top: 16px;
    }
  }

  .checkout-subtext {
    font-size: 14px;
    font-family: $regularFont;
    text-align: center;
    padding: 0 0 16px;

    @media only screen and (min-width: $mobileView) {
      padding: 0 50px 16px;
    }
  }

  .cardBlockComponent {
    .cardBlockComponent-content {
      .radio-card-Deactivate-payment {
        display: flex;
        margin-bottom: 16px;
        margin-left: 4px;

        @media only screen and (max-width: $mobileView) {
          margin-bottom: 16px;
          margin-left: 4px;
        }

        .new-card-content {
          padding-left: 10px;
          font-family: 'AvenirNextLTPro-Demi';

          .content-title {
            font-size: 16px;

            @media only screen and (max-width: $mobileView) {
              line-height: 1.6;
            }
          }

          .content-subtitle {
            margin-top: 4px;
            font-family: 'AvenirNextLTPro-Regular';

            @media only screen and (max-width: $mobileView) {
              line-height: 1.4;
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

.checkout-transactionInfoGrid {
  background-color: $white;
  padding: 0 16px;
  margin-top: 16px;
  font-size: 14px;

  @media only screen and (min-width: $mobileView) {
    font-size: 16px;
    padding: 0 24px;
  }

  .checkout-transactionInfoGrid-row {
    padding: 16px 0;
    border-bottom: 1px solid $secondary20;
    display: flex;

    &:last-child {
      border: none;
    }

    & > div {
      line-height: 24px;

      &:first-child {
        width: 40%;
        font-family: $regularFont;
      }

      &:last-child {
        width: 60%;
        text-align: right;
      }
    }
  }
}

.shipping-add-details {
  padding: 10px 0px 25px 0px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  margin-bottom: 18px;

  .shipping-header {
    font-size: 23px;
    padding: 0px 21px 12px 21px;
    border-bottom: 1px solid #d1d1d1;
  }

  .shipping-block {
    font-family: 'AvenirNextLTPro-Regular';
    padding: 12px 21px 0px 21px;
  }

  .shipping-street {
    font-family: 'AvenirNextLTPro-Regular';
    padding: 18px 21px 0px 21px;

    p {
      margin: 0;
    }
  }
}
