@import '../../assets/scss/vars.scss';

.unpaidTransaction {
  background: $white;
  border: 1px solid $secondary10;
  margin-bottom: $spacing-base;
  margin-top: $spacing-1;

  &:first-child {
    margin-top: 0;
  }

  .unpaidTransaction-head {
    padding: $spacing-1;
    position: relative;
    display: flex;
    span {
      position: absolute;
      right: $spacing-2;
      color: $feedbackError;
    }

    @media only screen and (min-width: $mobileView) {
      padding: $spacing-2 $spacing-1 $spacing-2 $spacing-2;
    }
  }

  .unpaidTransaction-right {
    display: block;
    width: 100%;
    @media only screen and (min-width: $mobileView) {
      display: flex;
    }
  }

  .unpaidTransaction-control {
    display: flex;
    align-items: flex-start;
    margin-left: $spacing-1;
    @media only screen and (min-width: $mobileView) {
      align-items: center;
    }
  }

  .unpaidTransaction-title {
    line-height: 24px;
  }

  .unpaidTransaction-subTitle {
    font-family: $regularFont;
    font-size: 12px;
    line-height: 21px;
    font-style: italic;
  }

  .unpaidTransaction-remove {
    font-family: $regularFont;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
  }

  .unpaidTransaction-block {
    margin-bottom: $spacing-base;
    @media only screen and (min-width: $mobileView) {
      flex: 1;
      align-items: center;
      display: flex;
      margin-bottom: 0;
    }
  }

  .unpaidTransaction-value-block {
    @media only screen and (min-width: $mobileView) {
      text-align: right;
      display: flex;
      align-items: center;
      align-self: flex-start;
    }
  }

  .unpaidTransaction-more {
    border-top: 2px solid $secondary10;
  }

  &-fare {
    text-align: right;
    font-family: $regularFont;
    font-size: 12px;
    font-style: italic;
    border-top: 1px solid $secondary10;
    padding: $spacing-base $spacing-2;
  }

  &.state-unpaidFare {
    border-left: 4px solid $feedbackError;

    .unpaidTransaction-total {
      color: $feedbackError;
    }
  }

  &-more-message {
    line-height: 21px;
    font-size: 14px;
    font-family: $regularFont;
    padding: $spacing-1 $spacing-2;
    border-bottom: 1px solid $secondary10;
  }

  &-unpaidFare-header {
    display: flex;
    height: 48px;
    align-items: center;
    padding: 0 $spacing-2;
    border-bottom: 1px solid $secondary10;
    color: $feedbackError;
    line-height: 24px;
  }

  &-unpaidFare-header-link {
    margin-left: auto;
    color: $black;
  }

  &-unpaidFare-header-icon {
    width: 24px;
    height: 24px;
    margin-right: $spacing-base;
  }
}
