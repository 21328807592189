@import '../../assets/scss/vars.scss';

.notification {
    background-color: $white;
    text-align: center;

    .notification-block{
      padding: $spacing-4 0;
    
    .notification-title {    
      font-size: $spacing-2;
      line-height: $spacing-4;
      margin-bottom: $spacing-base;
      margin-top: 0;
      color: $black;
    }

    .notification-text{
      font-size: $spacing-1;
    }
  }
}