@import '../../assets/scss/vars.scss';

.redeem-Voucher-wrapper {
  display: flex;
  flex-direction: column;
  .redeem-voucher {
    margin-top: 16px;
    .cardBlockComponent-title {
      border-bottom: 2px solid #928b8b42;
    }
    .cardBlockComponent-content {
      @media only screen and (max-width: $mobileView) {
        padding-bottom: 32px;
      }
    }
    .voucher-text {
      width: 45%;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
      .text-field {
        margin-top: 12px;
      }
      .error-message-redeem {
        .content-error-block {
          border: 0;
          border-top: 2px solid #d63d3d;
          height: 20px;
        }
      }
    }
    .disable-border {
      .text-field-wrapper {
        .text-field-input {
          border-bottom: 0;
        }
      }
    }
    .redeem-button {
      margin-top: 37px;
      display: flex;
      justify-content: flex-end;

      @media only screen and (max-width: $mobileView) {
        margin-top: 24px;
      }
    }
  }
  .note {
    margin-top: 0;
  }
}
