@import '../../assets/scss/vars.scss';
#App-store
{
    margin-top: 21px;
    margin-left: 30px;
    @media only screen and (max-width: $mobileView)
    {
        margin-left: 0px;
        margin-top: -10px;
    }
}