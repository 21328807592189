@import '../../assets/scss/vars';

.errorPage {
  width: auto;
  margin: 0 24px;

  @media only screen and (min-width: $mobileView) {
    width: 464px;
    margin: 0 auto;
  }

  h1.header-title {
    margin-top: $spacing-7;

    @media only screen and (min-width: $mobileView) {
      margin-top: $spacing-11;
    }
  }

  .subHeader {
    @media only screen and (min-width: $mobileView) {
      font-size: 16px;
    }
  }
}
