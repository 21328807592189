@import '../../assets/scss/vars';
.perks-column-layout {
  //margin-left: 175px;
  display: grid !important;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  width: 944px;
  margin: 0 auto;
  @media only screen and (max-width: $mobileView) {
    display: block !important;
    margin-left: 16px;
    margin-right: 16px;
    width: unset;
  }
  .presto_perks_row {
    background-color: #ffffff;
    padding-top: 24px;
    width: 464px;
    padding-bottom: 12px;
    margin-bottom: 24px;
    @media only screen and (max-width: $mobileView) {
      padding-top: 16px;
      margin-bottom: 16px;
      width: 100%;
    }
    .attractions_title {
      display: flex;
      margin-bottom: 16px;
      margin-left: 24px;
      margin-right: 24px;
      justify-content: space-between;
      height: 80px;
      @media only screen and (max-width: $mobileView) {
        display: block;
        //  margin-bottom: 48px;
        height: unset;
      }
      h3 {
        margin-top: 0px;
        margin-bottom: 0;
        // padding-left: 30px;
        font-size: 22px;
        line-height: 32px;
        width: 288px;
        height: 64px;
        @media only screen and (max-width: $mobileView) {
          font-size: 20px;
          width: unset;
          height: unset;
          margin-bottom: 16px;
        }
        // + div {
        // //  margin-top: 10px;
        //   // @media only screen and (max-width: $mobileView) {
        //   //   margin-left: 5px;
        //   // }
        // }
      }
      .save-label {
        background-color: yellowgreen;
        padding: 8px;
        font-size: 14px;
        margin-left: 3px;
        text-align: center;
        @media only screen and (max-width: $mobileView) {
          margin-left: 0px;
          width: fit-content;
        }
      }
      .status-label {
        display: block;
        white-space: nowrap;
        margin-bottom: 0px;
      }
    }
    .description-toggle {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      margin-bottom: 10px;
      @media only screen and (max-width: $mobileView) {
        -webkit-line-clamp: 3;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    .description-notoggle {
      @media only screen and (max-width: $mobileView) {
        border-bottom: 1px solid #dadada;
        padding-bottom: 24px;
        padding-left: 16px;
        padding-right: 16px;
      }
      .attractions-link {
        margin-left: 24px;
        margin-bottom: 30px;
        @media only screen and (max-width: $mobileView) {
          margin-left: 0px;
        }
      }
    }
    img {
      width: 464px;
      height: 256px;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
        height: auto;
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          width: 100%;
        }
      }
    }
    .attractions-description {
      margin-left: 24px;
      margin-right: 24px;
      font-family: $regularFont;
      line-height: 1.5em;
      // width: 90%;
      @media only screen and (max-width: $mobileView) {
        margin-left: 0px;
        margin-right: 0px;
        // width: 82%;
      }
      .attractions-link {
        margin-left: 0px;
      }
    }
    // .perks-promo {
    //   font-family: $regularFont;
    //   margin-left: 30px;
    //   line-height: 1.5em;
    //   @media only screen and (max-width: $mobileView) {
    //     margin-left: 15px;
    //     width: 85%;
    //   }
    //   + a {
    //     margin-left: 30px;
    //     margin-bottom: 30px;
    //     @media only screen and (max-width: $mobileView) {
    //       margin-left: 15px;
    //     }
    //   }
    // }
    .view_buttons {
      text-align: right;
      padding-right: 20px;
      .viewmore_button {
        font-family: $regularFont;
        text-decoration: underline;
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          color: #191919;
        }
      }
      .viewless_button {
        font-family: $regularFont;
        text-decoration: underline;
        margin-top: 43px;
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          color: #191919;
        }
        @media only screen and (max-width: $mobileView) {
          margin-top: 8px;
          line-height: 21px;
        }
      }
    }
  }
}

#presto-perks-first-item {
  margin-top: 48px;
  @media only screen and (max-width: $mobileView) {
    margin-top: 32px;
  }
}

// {
// .spring_cottage_row_1 {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 38px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 100%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 5px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 85%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 43px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .ottawa_cottage_row_1 {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 100%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 5px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 10px;
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .ottawa-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 85%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 28px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
.great_wolf_lodge_row_2 {
  background-color: #ffffff;
  padding-top: 30px;
  width: fit-content;
  padding-bottom: 12px;
  margin-top: 20px;
  @media only screen and (max-width: $mobileView) {
    padding-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
  .attractions_title {
    display: flex;
    margin-bottom: 6px;
    @media only screen and (max-width: $mobileView) {
      display: block;
      margin-bottom: 32px;
    }
    h3 {
      margin-top: 0px;
      margin-bottom: 0;
      padding-left: 30px;
      font-size: 22px;
      width: 70%;
      @media only screen and (max-width: $mobileView) {
        font-size: 20px;
        width: 90%;
        margin-bottom: 20px;
        padding-left: 15px;
      }
      + div {
        @media only screen and (max-width: $mobileView) {
          margin-left: 15px;
        }
      }
    }
    .save-label {
      background-color: yellowgreen;
      margin-left: 32px;
      padding: 8px;
      font-size: 14px;
      @media only screen and (max-width: $mobileView) {
        margin-left: 0px;
      }
    }
    .status-label {
      display: block;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
  .description-toggle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    margin-bottom: 10px;
    @media only screen and (max-width: $mobileView) {
      -webkit-line-clamp: 3;
    }
  }
  .description-notoggle {
    @media only screen and (max-width: $mobileView) {
      border-bottom: 1px solid #dadada;
      padding-bottom: 30px;
    }
  }
  img {
    width: 460px;
    @media only screen and (max-width: $mobileView) {
      width: 315px;
      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        width: 330px;
      }
    }
  }
  .attractions-description {
    margin-left: 30px;
    font-family: $regularFont;
    line-height: 1.5em;
    width: 90%;
    @media only screen and (max-width: $mobileView) {
      margin-left: 15px;
      width: 81%;
      margin-bottom: 0px;
    }
  }
  .greatwolf-description {
    margin-left: 30px;
    font-family: $regularFont;
    line-height: 1.5em;
    width: 90%;
    @media only screen and (max-width: $mobileView) {
      margin-left: 15px;
      width: 82%;
      margin-top: 0px;
    }
  }
  .perks-promo {
    font-family: $regularFont;
    margin-left: 30px;
    line-height: 1.5em;
    @media only screen and (max-width: $mobileView) {
      margin-left: 15px;
      width: 85%;
    }
  }
  .blackout-date {
    font-family: $regularFont;
    margin-left: 30px;
    @media only screen and (max-width: $mobileView) {
      margin-left: 15px;
    }
    + a {
      margin-left: 30px;
      margin-bottom: 30px;
      @media only screen and (max-width: $mobileView) {
        margin-left: 15px;
      }
    }
  }
  .view_buttons {
    text-align: right;
    padding-right: 20px;
    .viewmore_button {
      font-family: $regularFont;
      text-decoration: underline;
      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        color: #191919;
      }
    }
    .viewless_button {
      font-family: $regularFont;
      text-decoration: underline;
      margin-top: 45px;
      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        color: #191919;
      }
      @media only screen and (max-width: $mobileView) {
        margin-top: 20px;
      }
    }
  }
}
// .art_gallery_row_2 {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 62%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 46px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-left: 46px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 184px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .museum_row_3 {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 26px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 116px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .royal_botanical_gardens_row_3 {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .botanical-gardens-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 28px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .toronto_zoo_row_4 {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 91%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 164px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .ontario_science_row_4 {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 28px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .royal_ontario_row_5 {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     height: 201px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .royal-ontario-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 81%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 28px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .hockey_hall_row_5 {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 81%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 68px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .aga_khan_row_6 {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   margin-bottom: 80px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 60px;
//     margin-left: 0px;
//     margin-top: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 26px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 28px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }

// .spring_cottage_row_1_french {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 60%;
//       line-height: 1.5em;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 5px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 85%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 69px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .ottawa_cottage_row_1_french {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 60%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 100%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 5px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 10px;
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .ottawa-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 85%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 28px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .great_wolf_lodge_row_2_french {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 45%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 32px;
//       padding: 8px;
//       font-size: 14px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-right: 10px;
//       margin-left: 36px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .greatwolf-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 85%;
//     }
//   }
//   .blackout-date {
//     font-family: $regularFont;
//     margin-left: 30px;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 45px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .art_gallery_row_2_french {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 62%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 45px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-left: 38px;
//       margin-right: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 208px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .museum_row_3_french {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-right: 12px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 132px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .royal_botanical_gardens_row_3_french {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-right: 12px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .botanical-gardens-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 44px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .toronto_zoo_row_4_french {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-right: 12px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 91%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 204px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .ontario_science_row_4_french {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-right: 12px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 44px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .royal_ontario_row_5_french {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-right: 12px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     height: 201px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .royal-ontario-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 28px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .hockey_hall_row_5_french {
//   background-color: #ffffff;
//   margin-left: 48px;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 20px;
//     margin-left: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       line-height: 1.5em;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       padding: 8px;
//       font-size: 14px;
//       height: 18px;
//       margin-left: 10px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-right: 12px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 10px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//       padding-left: 15px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 0px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 0px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 116px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// .aga_khan_row_6_french {
//   background-color: #ffffff;
//   padding-top: 30px;
//   width: fit-content;
//   padding-bottom: 12px;
//   margin-top: 20px;
//   margin-bottom: 80px;
//   @media only screen and (max-width: $mobileView) {
//     padding-top: 20px;
//     margin-bottom: 60px;
//     margin-left: 0px;
//     margin-top: 0px;
//   }
//   .attractions_title {
//     display: flex;
//     margin-bottom: 6px;
//     @media only screen and (max-width: $mobileView) {
//       display: block;
//       margin-bottom: 32px;
//     }
//     h3 {
//       margin-top: 0px;
//       margin-bottom: 0;
//       padding-left: 30px;
//       font-size: 22px;
//       width: 70%;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 20px;
//         width: 90%;
//         margin-bottom: 20px;
//         padding-left: 15px;
//       }
//       + div {
//         @media only screen and (max-width: $mobileView) {
//           margin-left: 15px;
//         }
//       }
//     }
//     .save-label {
//       background-color: yellowgreen;
//       margin-left: 10px;
//       padding: 8px;
//       font-size: 14px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//     .status-label {
//       display: block;
//       white-space: nowrap;
//       margin-right: 12px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 0px;
//       }
//     }
//   }
//   .description-toggle {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     margin-bottom: 26px;
//     @media only screen and (max-width: $mobileView) {
//       -webkit-line-clamp: 3;
//     }
//   }
//   .description-notoggle {
//     @media only screen and (max-width: $mobileView) {
//       border-bottom: 1px solid #dadada;
//       padding-bottom: 30px;
//     }
//   }
//   img {
//     width: 460px;
//     @media only screen and (max-width: $mobileView) {
//       width: 315px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         width: 330px;
//       }
//     }
//   }
//   .attractions-description {
//     margin-left: 30px;
//     font-family: $regularFont;
//     line-height: 1.5em;
//     width: 90%;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//   }
//   .perks-promo {
//     font-family: $regularFont;
//     margin-left: 30px;
//     line-height: 1.5em;
//     @media only screen and (max-width: $mobileView) {
//       margin-left: 15px;
//       width: 82%;
//     }
//     + a {
//       margin-left: 30px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         margin-left: 15px;
//       }
//     }
//   }
//   .view_buttons {
//     text-align: right;
//     padding-right: 20px;
//     .viewmore_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//     }
//     .viewless_button {
//       font-family: $regularFont;
//       text-decoration: underline;
//       margin-top: 28px;
//       @supports (-webkit-touch-callout: none) {
//         /* CSS specific to iOS devices */
//         color: #191919;
//       }
//       @media only screen and (max-width: $mobileView) {
//         margin-top: 20px;
//       }
//     }
//   }
// }
// }
