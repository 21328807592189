@import '../../assets/scss/vars.scss';

.main-content {
  background-color: #fafafa;

  .Reportlostcard {
    background-color: #fafafa;

    .display-4 {
      color: #181818;
      font-weight: lighter;
      font-family: $regularFont;
      min-height: 1px;
      line-height: 1.1;
      display: block;
      text-align: center;
      padding: 40px 15px;
      font-size: 4.8rem;
      margin-bottom: 0 !important;
      position: relative;

      @media only screen and (max-width: $mobileView) {
        font-size: 2rem;
        line-height: 1.2em;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 65%;
        margin-top: 60px !important;
      }
    }

    .contentDescription {
      background-color: #fafafa;
      max-width: 1178px;
      margin-left: auto;
      margin-right: auto;
      font-family: $regularFont;

      .container {
        background: #fafafa;
      }

      .heading-paragraph {
        padding-left: 15px;
        padding-right: 15px;

        h3 {
          font-size: 22px;
          color: #181818;
          font-weight: 600;
          font-family: $regularFont;
          margin-bottom: 10px;

          @media only screen and (max-width: $mobileView) {
            font-size: 16px;
            font-weight: bold;
          }
        }

        p {
          color: #666;
          line-height: 2em;
          margin-top: 0px;
          font-size: 1.6rem;

          a {
            color: #3a811e;
            font-family: $regularFont;
          }
        }

        ol {
          display: block;
          list-style-type: decimal;
          line-height: 1.6rem;
          font-size: 1.6rem;
          line-height: 2em;

          li {
            color: #666;
            text-align: -webkit-match-parent;
            line-height: 2em;

            a {
              color: #3a811e;
            }
          }
        }
      }
    }
  }

  .Vouchers {
    padding-bottom: 80px;
    background-color: #ffffff;

    .display-4 {
      color: #181818;
      font-family: $boldFont;
      display: block;
      text-align: left;
      font-size: 3rem;
      position: relative;
      padding-top: 56px;
      line-height: 72px;
      padding-bottom: 96px;
      border-bottom: 1px solid #d1d1d1;
      margin: 0 auto;
      width: 944px;

      // padding-left: 178px;
      @media only screen and (max-width: $mobileView) {
        // padding-left: 16px;
        line-height: 48px;
        padding-top: 32px;
        font-size: 32px;
        padding-bottom: 32px;
        margin: 0px 16px;
        width: unset;
      }
    }

    .contentDescription {
      background-color: #f3f3f3;

      .heading-paragraph {
        // padding-top: 1px;
        // padding-left: 178px;
        background-color: #f3f3f3;
        width: 944px;

        @media only screen and (max-width: $mobileView) {
          padding-left: 16px;
          padding-right: 16px;
          width: unset;
        }

        h3 {
          // padding-top: 6px;
          font-size: 22px;
          line-height: 32px;
          color: #191919;
          font-weight: 500;
          font-family: $boldFont;
          margin-bottom: 10px;

          @media only screen and (max-width: $mobileView) {
            font-size: 22px;
            line-height: 32px;
            margin-top: 32px;
          }
        }

        p {
          color: #191919;
          line-height: 24px;
          margin-top: 20px;
          font-size: 16px;
          width: 64%;
          font-family: $regularFont;

          @media only screen and (max-width: $mobileView) {
            font-size: 16px;
            width: 99%;
            line-height: 24px;
            margin-top: 16px;
          }

          a {
            color: #191919;
            font-family: $boldFont;
            font-size: 16px;
          }
        }

        .bold-heading {
          font-size: 19px;
          width: 66%;
          margin-top: 32px;
          line-height: 1.7rem;

          @media only screen and (max-width: $mobileView) {
            font-size: 18px;
            width: 100%;
            margin-top: 32px;
            line-height: 28px;
          }
        }

        .bold-heading-para {
          margin-top: -9px;
        }

        .bold-heading-para1 {
          margin-top: -9px;
          width: 65%;

          @media only screen and (max-width: $mobileView) {
            width: 100%;
          }
        }

        strong {
          font-family: $boldFont;
          color: #191919;
        }

        ol {
          display: block;
          list-style-type: decimal;
          width: 59%;
          font-family: $regularFont;

          @media only screen and (max-width: $mobileView) {
            width: 94%;
            padding-left: 20px;
            margin-top: 24px;
            margin-bottom: 24px;
          }

          li {
            color: #191919;
            text-align: -webkit-match-parent;
            line-height: 24px;
            font-size: 16px;
            padding-bottom: 16px;
            padding-left: 5px;

            @media only screen and (max-width: $mobileView) {
              font-size: 14px;
              line-height: 21px;
              padding-left: 10px;
              padding-bottom: 8px;
            }

            a {
              color: #191919;
              font-family: $boldFont;
              font-size: 16px;

              @media only screen and (max-width: $mobileView) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .now-available {
    width: 944px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: $mobileView) {
      width: unset;
      padding-left: 16px;
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 16px;
    }

    .display-4 {
      font-size: 22px;
      color: #191919;
      display: block;
      font-weight: 500;
      line-height: 1.1;
      font-family: $boldFont;
      margin-top: 20px;
      margin-bottom: 10px;

      @media only screen and (max-width: $mobileView) {
        line-height: 28px;
        font-size: 18px;
      }
    }

    p {
      width: 65%;
      font-size: 16px;
      color: #191919;
      line-height: 1.5em;
      display: block;
      margin-top: 18px;
      font-family: $regularFont;

      @media only screen and (max-width: $mobileView) {
        width: unset;
        font-size: 16px;
        line-height: 24px;
      }

      a {
        color: #191919;
        cursor: pointer;
        font-family: $boldFont;
        text-decoration: underline;
      }
    }
  }

  .discover-more {
    background-color: #ffffff;
    padding-top: 35px;

    .display-4 {
      width: 944px;
      margin-left: auto;
      margin-right: auto;
      font-size: 26px;
      color: #191919;
      display: block;
      font-weight: 500;
      line-height: 1.1;
      font-family: $boldFont;
      margin-top: 20px;
      margin-bottom: 10px;

      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
        line-height: 32px;
        width: unset;
        padding-left: 16px;
        margin-left: 0px;
        margin-right: 0px;
        padding-right: 16px;
      }
    }

    p {
      width: 944px;
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      color: #191919;
      line-height: 1.5em;
      display: block;
      margin-top: 18px;
      font-family: $regularFont;

      @media only screen and (max-width: $mobileView) {
        width: unset;
        padding-left: 22px;
        margin-left: 0px;
        margin-right: 0px;
        padding-right: 22px;
        font-size: 16px;
        line-height: 24px;
      }

      a {
        color: #191919;
        cursor: pointer;
        font-family: $boldFont;
        text-decoration: underline;
      }
    }
  }

  .how-to-use-presto-etickets {
    width: 944px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 35px;
    padding-bottom: 35px;

    @media only screen and (max-width: $mobileView) {
      width: unset;
      padding-left: 16px;
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 16px;
    }

    h1 {
      font-size: 26px;
      color: #191919;
      display: block;
      font-weight: 500;
      line-height: 1.1;
      font-family: $boldFont;
      margin-top: 20px;
      margin-bottom: 10px;

      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
        line-height: 32px;
      }
    }

    h3 {
      font-size: 22px;
      color: #191919;

      @media only screen and (max-width: $mobileView) {
        line-height: 28px;
        font-size: 18px;
      }
    }

    ul {
      width: 59%;

      @media only screen and (max-width: $mobileView) {
        width: 91%;
        padding-left: 25px;
      }

      li {
        font-size: 16px;
        color: #191919;
        line-height: 1.5em;
        font-family: $regularFont;
        padding-bottom: 10px;

        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    p {
      width: 63%;
      font-size: 16px;
      color: #191919;
      line-height: 1.5em;
      display: block;
      margin-top: 18px;
      font-family: $regularFont;

      @media only screen and (max-width: $mobileView) {
        width: unset;
        font-size: 16px;
        line-height: 24px;
      }

      a {
        color: #191919;
        cursor: pointer;
        font-family: $boldFont;
        text-decoration: underline;
      }
    }
  }

  .transit-agency {
    background-color: #ffffff;
    padding-top: 35px;
    padding-bottom: 24px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .display-4 {
      width: 944px;
      margin-left: auto;
      margin-right: auto;
      line-height: 38px;
      font-size: 26px;
      color: #191919;
      display: block;
      font-weight: 500;
      font-family: $boldFont;
      margin-top: 20px;
      margin-bottom: 10px;

      @media only screen and (max-width: $mobileView) {
        width: unset;
        font-size: 22px;
        line-height: 32px;
      }
    }

    p {
      width: 944px;
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      color: #191919;
      line-height: 1.5em;
      display: block;
      margin-top: 18px;
      font-family: $regularFont;

      @media only screen and (max-width: $mobileView) {
        width: unset;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .terms-content {
    margin-bottom: 48px;
    margin-top: 48px;
    // margin-left: 175px;
    width: 944px;
    margin: 48px auto;

    @media only screen and (max-width: $mobileView) {
      margin: 32px 16px 32px 16px;
      width: 90%;
    }

    .display-4 {
      font-size: 32px;
      color: #191919;
      line-height: 48px;
      display: block;
      font-family: $boldFont;
      margin-bottom: 40px;

      @media only screen and (max-width: $mobileView) {
        line-height: 40px;
        font-size: 26px;
      }
    }

    .contentDescription {
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }

      p {
        font-size: 16px;
        color: #191919;
        line-height: 24px;
        font-family: $regularFont;
        width: 74%;
        border-bottom: 1px solid #d1d1d1;
        padding-bottom: 48px;

        @media only screen and (max-width: $mobileView) {
          width: 100%;
          font-size: 14px;
          line-height: 21px;
          padding-bottom: 32px;
        }
      }
    }

    strong {
      font-size: 22px;
      color: #191919;
      line-height: 32px;
      font-family: $boldFont;

      @media only screen and (max-width: $mobileView) {
        font-size: 20px;
      }
    }
  }

  .terms-content-1 {
    width: 944px;
    margin: 0 auto 30px;

    @media only screen and (max-width: $mobileView) {
      margin: 32px 16px 32px 16px;
      width: 90%;
    }

    span {
      text-decoration: none !important;
    }

    .display-4 {
      font-size: 32px;
      color: #191919;
      line-height: 48px;
      display: block;
      font-family: $boldFont;
      margin-bottom: 40px;

      @media only screen and (max-width: $mobileView) {
        line-height: 40px;
        font-size: 26px;
        margin-bottom: 32px;
      }
    }

    .contentDescription {
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }

      p {
        font-size: 16px;
        color: #191919;
        line-height: 24px;
        font-family: $regularFont;
        width: 75%;

        @media only screen and (max-width: $mobileView) {
          width: 100%;
          font-size: 14px;
          line-height: 21px;
        }

        a {
          color: #191919;
          font-family: $boldFont;
        }
      }

      .terms-social-last-para {
        margin-bottom: 64px;

        @media only screen and (max-width: $mobileView) {
          margin-bottom: 48px;
        }
      }
    }

    ul {
      padding-left: 25px;
      width: 71%;

      @media only screen and (max-width: $mobileView) {
        width: 88%;
      }

      li {
        font-size: 16px;
        color: #191919;
        line-height: 24px;
        font-family: $regularFont;
        padding-top: 10px;

        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    strong {
      color: #191919;
      font-family: $boldFont;
      font-size: 22px;
      line-height: 32px;

      @media only screen and (max-width: $mobileView) {
        font-size: 20px;
      }
    }
  }

  .terms-content-2 {
    width: 944px;
    margin: 0 auto 48px;

    @media only screen and (max-width: $mobileView) {
      margin: 32px 16px 32px 16px;
      width: 90%;
    }

    table {
      border: none !important;
      border-collapse: collapse;
      width: 74%;

      @media only screen and (max-width: $mobileView) {
        margin-top: 32px;
        width: 100%;
      }

      tr {
        vertical-align: top;

        :last-child {
          border: none;
        }
      }

      th {
        background-color: #dedede;
        font-family: $boldFont;
        font-size: 14px;
        line-height: 21px;
        color: #191919;
        text-align: left;
        padding: 21px 27px 22px 16px;
        border-right: 1px solid #d1d1d1;

        @media only screen and (max-width: $mobileView) {
          font-size: 12px;
          padding: 8px;
          line-height: 20px;
        }
      }

      td {
        color: #191919;
        font-family: $regularFont;
        font-size: 14px;
        line-height: 21px;
        padding: 10px 16px 17px 16px;
        border-right: 1px solid #d1d1d1;

        @media only screen and (max-width: $mobileView) {
          font-size: 12px;
          padding: 8px;
          line-height: 20px;
        }
      }

      tr:nth-child(even) {
        background-color: #ffffff;
      }
    }

    span {
      text-decoration: none !important;
    }

    .display-4 {
      font-size: 32px;
      color: #191919;
      line-height: 48px;
      font-family: $boldFont;
      margin-bottom: 40px;

      @media only screen and (max-width: $mobileView) {
        line-height: 40px;
        font-size: 26px;
      }
    }

    h2 {
      font-size: 22px;
      color: #191919;
      font-family: $boldFont;
      line-height: 32px;
      margin-top: 32px;

      @media only screen and (max-width: $mobileView) {
        font-size: 20px;
        line-height: 32px;
      }
    }

    h3 {
      @media only screen and (max-width: $mobileView) {
        font-size: 17px;
      }
    }

    .contentDescription {
      font-size: 16px;
      color: #191919;
      line-height: 24px;
      font-family: $regularFont;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }

      h3 {
        font-size: 22px;
        font-family: $boldFont;
        color: #191919;
      }
    }

    p {
      font-size: 16px;
      color: #191919;
      line-height: 24px;
      font-family: $regularFont;
      width: 74%;
      margin-top: 24px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        font-size: 14px;
        line-height: 21px;
      }

      a {
        color: #181818;
        font-family: $boldFont;
      }
    }

    .terms-last-para {
      border-bottom: 1px solid #d1d1d1;
      padding-bottom: 48px;

      @media only screen and (max-width: $mobileView) {
        padding-bottom: 32px;
      }
    }

    ul {
      padding-left: 65px;
      padding-right: 65px;

      @media only screen and (max-width: $mobileView) {
        padding-left: 50px;
        padding-right: 34px;
      }

      li {
        font-size: 16px;
        color: #181818;
        line-height: 24px;
        font-family: $regularFont;
        padding-top: 10px;
      }
    }

    strong {
      color: #181818;
      font-family: $boldFont;
    }
  }
}

.prestoticket {
  background-color: #ffffff;
  padding-top: 56px;
  padding-left: 170px;
  line-height: 1.3rem;
  padding-bottom: 32px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-left: 22px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 22px;
  }

  .display-4 {
    margin-top: 0px;
    font-family: $boldFont;
    font-size: 48px;
    line-height: 72px;
    color: #191919;
    margin-bottom: 8px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 8px;
    }
  }

  .contentDescription {
    p {
      width: 64%;
      font-size: 18px;
      font-family: $regularFont;
      line-height: 28px;
      display: block;
      text-align: left;
      color: #191919;
      margin-bottom: 0px;
      margin-top: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
    }
  }
}

.prestoticket-prices {
  background-color: #ffffff;
  padding-top: 48px;
  line-height: 1.3rem;
  padding-bottom: 56px;

  @media only screen and (max-width: $mobileView) {
    line-height: 4rem;
    padding-top: 24px;
    padding-bottom: 32px;
    margin-bottom: 0px;
    width: 90%;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 26px;
    line-height: 40px;
    color: #191919;
    margin: 0 auto 24px;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 32px;
      width: 100%;
    }
  }

  .contentDescription {
    margin: 0 auto;
    width: 944px;

    p {
      width: 64%;
      font-size: 16px;
      color: #191919;
      line-height: 24px;
      font-family: $regularFont;
      display: block;
      text-align: left;
      margin-top: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        margin-top: 0;
      }
    }

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
}

.paper-tickets-expire {
  background-color: #ffffff;
  padding-top: 48px;
  padding-bottom: 56px;

  @media only screen and (max-width: $mobileView) {
    line-height: 4rem;
    padding-top: 24px;
    margin-bottom: 0px;
    padding-bottom: 32px;
    width: 90%;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 26px;
    color: #191919;
    line-height: 40px;
    margin: 0 auto 24px;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 32px;
      width: 100%;
    }
  }

  .contentDescription {
    margin: 0 auto;
    width: 944px;

    p {
      width: 64%;
      font-size: 16px;
      color: #191919;
      line-height: 24px;
      font-family: $regularFont;
      display: block;
      text-align: left;
      margin-top: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        padding-bottom: 0;
      }
    }

    a {
      color: #191919;
    }

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
}

.prestoticket-use {
  padding-top: 48px;
  padding-bottom: 48px;
  line-height: 1.3rem;

  @media only screen and (max-width: $mobileView) {
    line-height: 4rem;
    padding-top: 32px;
    padding-bottom: 32px;
    width: 90%;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 26px;
    color: #191919;
    line-height: 40px;
    margin: 0 auto 24px;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 32px;
      width: 100%;
    }
  }

  .contentDescription {
    margin: 0 auto;
    width: 944px;

    p {
      width: 64%;
      font-size: 16px;
      color: #191919;
      line-height: 24px;
      font-family: $regularFont;
      display: block;
      text-align: left;
      margin-top: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        margin-top: 0;
      }
    }

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
}

.prestoticket-check {
  padding-top: 48px;
  padding-bottom: 80px;

  @media only screen and (max-width: $mobileView) {
    line-height: 4rem;
    padding-top: 32px;
    padding-bottom: 64px;
    width: 90%;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 26px;
    color: #191919;
    line-height: 40px;
    margin: 0 auto 24px;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 5px;
      font-size: 22px;
      line-height: 32px;
      width: 100%;
    }
  }

  .contentDescription {
    margin: 0 auto;
    width: 944px;

    p {
      width: 64%;
      font-size: 16px;
      color: #191919;
      line-height: 24px;
      font-family: $regularFont;
      display: block;
      text-align: left;
      margin-bottom: 0px;
      margin-top: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        margin-top: 0px;
      }
    }

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
}

.new-mainContent {
  .autoNewContents {
    padding-top: 32px;
    margin-right: auto;
    margin-left: auto;

    .display-4 {
      font-size: 3rem;
      font-family: $boldFont;
      color: #191919;
      font-weight: lighter;
      line-height: 72px;
      display: block;
      text-align: left;
      margin-bottom: 24px;
      margin-top: 0;

      @media only screen and (max-width: $mobileView) {
        // font-size: 4.8rem;
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 0;
      }
    }

    .contentDescription {
      .text-center {
        font-family: $regularFont;
        color: #191919;
        font-size: 1rem;
        line-height: 24px;
        padding-bottom: 47px;
        text-align: left;
        width: 98%;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
          width: 100%;
          margin-top: 8px;
          padding-bottom: 40px;
        }
      }
    }
  }

  .loading-funds-content {
    padding-top: 32px;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: $mobileView) {
      // padding-left: 13px;
      // padding-right: 13px;
      padding-top: 32px;
    }

    .display-4 {
      font-size: 48px;
      font-family: $boldFont;
      color: #191919;
      font-weight: lighter;
      line-height: 72px;
      display: block;
      text-align: left;
      margin-bottom: 16px;
      margin-top: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 10px;
      }
    }

    .contentDescription {
      font-family: $regularFont;
      color: #191919;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 50px;
      text-align: left;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        // width: 85%;
        padding-bottom: 40px;
      }
    }
  }

  .setting-up-load {
    padding-top: 32px;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: $mobileView) {
      padding-top: 32px;
    }

    .display-4 {
      font-size: 3rem;
      font-family: $boldFont;
      color: #191919;
      font-weight: lighter;
      line-height: 72px;
      display: block;
      text-align: left;
      margin-bottom: 25px;
      margin-top: 0;

      @media only screen and (max-width: $mobileView) {
        // font-size: 4.8rem;
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 10px;
      }
    }

    .text-center {
      font-family: $regularFont;
      color: #191919;
      font-size: 1rem;
      padding-bottom: 50px;
      text-align: left;
      width: 90%;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        padding-bottom: 40px;
      }
    }
  }

  .checking-balance {
    padding-top: 32px;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: $mobileView) {
      padding-top: 32px;
    }

    .display-4 {
      font-size: 48px;
      font-family: $boldFont;
      color: #181818;
      font-weight: lighter;
      line-height: 72px;
      display: block;
      text-align: left;
      margin-top: 0px;
      margin-bottom: 24px;

      @media only screen and (max-width: $mobileView) {
        // font-size: 4.8rem;
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 10px;
      }
    }

    .contentDescription {
      .text-center {
        font-family: $regularFont;
        color: #181818;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 50px;
        text-align: left;

        @media only screen and (max-width: $mobileView) {
          padding-bottom: 40px;
        }
      }
    }
  }

  .find-outlets-heading {
    text-align: left;
    padding-bottom: 16px;

    .display-4 {
      font-size: 3rem;
      font-family: $boldFont;
      color: #191919;
      line-height: 72px;
      display: block;
      margin-bottom: 0px;
      margin-top: 32px;

      @media only screen and (max-width: $mobileView) {
        font-size: 32px;
        line-height: 48px;
      }
    }

    .contentDescription {
      p {
        font-family: $regularFont;
        color: #191919;
        font-size: 16px;
        line-height: 24px;
        display: block;
        padding-bottom: 16px;
        border-bottom: 1.5px solid #dbdbdb;

        @media only screen and (max-width: $mobileView) {
          margin-top: 8px;
        }
      }
    }
  }

  .find-outlet-heading {
    text-align: left;
    padding-bottom: 16px;

    .display-4 {
      font-size: 3rem;
      font-family: $boldFont;
      color: #191919;
      line-height: 72px;
      display: block;
      margin-bottom: 0px;
      margin-top: 32px;

      @media only screen and (max-width: $mobileView) {
        font-size: 32px;
        line-height: 48px;
      }
    }

    .contentDescription {
      p {
        font-family: $regularFont;
        color: #191919;
        font-size: 16px;
        line-height: 24px;
        display: block;
        padding-bottom: 16px;
        border-bottom: 1.5px solid #dbdbdb;

        @media only screen and (max-width: $mobileView) {
          margin-top: 8px;
        }
      }
    }
  }

  .prestocard {
    padding-top: 32px;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: $mobileView) {
      // padding-left: 13px;
      padding-right: 13px;
      // padding-top: 36px;
      // width: 78%;
      margin-left: 0;
    }

    .display-4 {
      font-size: 48px;
      font-family: $boldFont;
      color: #191919;
      font-weight: lighter;
      line-height: 72px;
      display: block;
      text-align: left;
      margin-bottom: 16px;
      margin-top: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 10px;
      }
    }

    .contentDescription {
      .text-center {
        font-family: $regularFont;
        color: #191919;
        font-size: 16px;
        padding-bottom: 46px;
        text-align: left;
        width: 100%;
        line-height: 24px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
          width: 100%;
          padding-bottom: 40px;
        }
      }
    }
  }
}

.additional-information {
  background-color: #ffffff;
  padding-top: 56px;
  // padding-left: 180px;
  line-height: 1.3rem;
  padding-bottom: 60px;
  border-bottom: 2px solid #dadada;

  @media only screen and (max-width: $mobileView) {
    line-height: 4rem;
    margin-bottom: 32px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 32px 16px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 3rem;
    line-height: 72px;
    color: #191919;
    padding-bottom: 8px;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      width: 90%;
      margin: 0px;
    }
  }

  .contentDescription {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      font-size: 16px;
      line-height: 24px;
      width: 85%;
      margin: 0px;
    }

    p {
      font-size: 18px;
      font-family: $regularFont;
      line-height: 28px;
      display: block;
      text-align: left;
      color: #191919;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

.take-our-survey {
  padding-top: 20px;
  padding-right: 200px;
  padding-left: 200px;
  line-height: 1.3rem;
  padding-bottom: 32px;

  @media only screen and (max-width: $mobileView) {
    line-height: 4rem;
    padding-top: 10px;
    margin-bottom: 8px;
    width: 90%;
    padding-left: 22px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 0;
  }

  .display-4 {
    padding-top: 18px;
    font-family: $boldFont;
    font-size: 3rem;
    color: #191919;
    text-align: center;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
      padding-top: 5px;
    }
  }

  .contentDescription {
    p {
      font-size: 18px;
      font-family: $regularFont;
      line-height: 28px;
      display: block;
      color: #1a1a1a;
      text-align: center;
      margin-top: 6px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 1.5em;
        margin-bottom: 0px !important;
        margin-top: 8px;
      }
    }
  }
}

.customer-charter-title-content {
  background-color: white;
  //margin-top: 0;
  padding: 56px 0px 32px;
  border-bottom: 2px solid #dfdede;
  margin-bottom: 48px;

  @media only screen and (max-width: $mobileView) {
    padding: 32px 16px 32px 16px;
    margin-bottom: 32px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 48px;
    // margin-bottom: 8px;
    line-height: 72px;
    // margin-top: 0px;
    width: 944px;
    margin: 0 auto 8px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      width: 100%;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    font-weight: lighter;
    font-size: 18px;
    //width: 65%;
    line-height: 28px;
    color: #1a1a1a;
    width: 944px;
    margin: 0 auto;
    display: flex;

    #contentText {
      max-width: 675px;
    }

    #contentSpace {
      max-width: 269px;

      @media only screen and (max-width: $mobileView) {
        max-width: 0px;
      }
    }

    @media only screen and (max-width: $mobileView) {
      width: 100%;
      line-height: 24px;
      font-size: 16px;
      color: #191919;
    }
  }
}

.customer-charter-three-colum {
  width: 944px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    width: auto;
  }

  .column {
    max-width: unset !important;
  }
}

.promise-one {
  background-color: #ffffff;
  border-top: 8px solid #b7dd79;
  padding: 16px 24px 0px 24px;
  // margin-left: 150px;
  // width: 80%;
  height: 344px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;
  }
}

.promise-two {
  background-color: #ffffff;
  border-top: 8px solid #05c3de;
  padding: 16px 24px 0px 24px;
  margin-left: 16px;
  // width: 81%;
  height: 344px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 6px;
    }
  }
}

.promise-three {
  background-color: #ffffff;
  border-top: 8px solid #ff8674;
  padding: 16px 24px 0px 24px;
  margin-left: 16px;
  //  width: 80%;
  height: 344px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 7px;
    }
  }
}

.promise-four {
  background-color: #ffffff;
  border-top: 8px solid #a396ff;
  padding: 16px 24px 0px 24px;
  margin-top: 16px;
  height: 344px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 7px;
    }
  }
}

.promise-five {
  background-color: #ffffff;
  border-top: 8px solid #c91d69;
  padding: 16px 24px 0px 24px;
  margin-left: 16px;
  margin-top: 16px;
  // width: 81%;
  height: 344px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 7px;
    }
  }
}

.promises-paragraph {
  background-color: #e7e6e6;
  font-family: $regularFont;
  padding: 8px 24px 0px 24px;
  margin-left: 16px;
  margin-top: 16px;
  height: 360px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 64px 16px;
  }

  .contentDescription {
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 7px;
    }
  }
}

.promise-one-french {
  background-color: #ffffff;
  border-top: 8px solid #b7dd79;
  padding: 16px 24px 0px 24px;
  // margin-left: 150px;
  // width: 80%;
  height: 420px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;
  }
}

.promise-two-french {
  background-color: #ffffff;
  border-top: 8px solid #05c3de;
  padding: 16px 24px 0px 24px;
  margin-left: 16px;
  // width: 81%;
  height: 420px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 6px;
    }
  }
}

.promise-three-french {
  background-color: #ffffff;
  border-top: 8px solid #ff8674;
  padding: 16px 24px 0px 24px;
  margin-left: 16px;
  //  width: 80%;
  height: 420px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 7px;
    }
  }
}

.promise-four-french {
  background-color: #ffffff;
  border-top: 8px solid #a396ff;
  padding: 16px 24px 0px 24px;
  margin-top: 16px;
  height: 420px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 7px;
    }
  }
}

.promise-five-french {
  background-color: #ffffff;
  border-top: 8px solid #c91d69;
  padding: 16px 24px 0px 24px;
  margin-left: 16px;
  margin-top: 16px;
  // width: 81%;
  height: 420px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 16px 16px;
  }

  .display-4 {
    font-size: 26px;
    font-family: $boldFont;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 7px;
    }
  }
}

.promises-paragraph-french {
  background-color: #e7e6e6;
  font-family: $regularFont;
  padding: 8px 24px 0px 24px;
  margin-left: 16px;
  margin-top: 16px;
  height: 435px;

  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px 16px;
    margin: 0px 16px 64px 16px;
  }

  .contentDescription {
    line-height: 1.5rem;

    @media only screen and (max-width: $mobileView) {
      padding-right: 7px;
    }
  }
}

.self-serve-reload-title {
  .display-4 {
    font-family: $boldFont;
    font-size: 48px;
    margin-top: 32px;
    line-height: 72px;
    margin-bottom: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 8px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    margin-bottom: 16px;
    border-bottom: 1px solid #dfdddd;

    p {
      @media only screen and (max-width: $mobileView) {
        margin-top: 0px;
      }
    }
  }
}

.ticket-vending-machines-heading {
  line-height: 1.5rem;
  width: fit-content;

  .display-4 {
    font-family: $boldFont;
    font-size: 48px;
    margin-top: 32px;
    margin-bottom: 0px;
    line-height: 72px;

    @media only screen and (max-width: 1124px) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    border-bottom: 1px solid #dfdddd;

    p {
      @media only screen and (max-width: 1124px) {
        margin-top: 8px;
      }
    }
  }
}

#ticket-vending-machines-heading-id {
  ~div {
    .accordion-section {
      margin-right: 0;
    }
  }
}

.get-presto-ready {
  background-color: #ffffff;
  padding-top: 56px;
  padding-bottom: 32px;
  border-bottom: 1px solid #d1d1d1;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .display-4 {
    padding-left: 170px;
    margin-top: 0px;
    margin-bottom: 8px;
    font-family: $boldFont;
    font-size: 48px;
    color: #191919;
    line-height: 72px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      padding-left: 22px;
      padding-right: 0px;
    }
  }

  .contentDescription {
    p {
      padding-left: 170px;
      font-size: 18px;
      font-family: $regularFont;
      line-height: 28px;
      display: block;
      text-align: left;
      color: #191919;
      padding-right: 69px;
      margin-top: 0px;
      margin-bottom: 0px;
      width: 59%;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        padding-right: 22px;
        padding-left: 22px;
        width: 90%;
      }
    }
  }
}

.contentblock-get-presto-ready {
  // margin-left: 170px;
  padding-top: 48px;
  padding-bottom: 24px;

  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 32px;
    padding-bottom: 24px;
  }

  .display-4 {
    font-size: 26px;
    line-height: 40px;
    color: #191919;
    margin: 0 auto 24px;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
      width: unset;
    }
  }

  p {
    font-family: $regularFont;
    color: #191919;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
}

.contentblock-get-presto-ready-white {
  background-color: #ffffff;
  padding-left: 170px;
  padding-top: 48px;
  margin-bottom: -17px;

  @media only screen and (max-width: $mobileView) {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: -22px;
    padding-top: 25px;
  }

  .display-4 {
    font-size: 26px;
    line-height: 40px;
    color: #191919;
    margin-top: 0px;
    margin-bottom: 24px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  p {
    font-family: $regularFont;
    color: #191919;
    font-size: 16px;
    line-height: 24px;

    @media only screen and (max-width: $mobileView) {
      margin-bottom: 20px;
    }
  }
}

.sitemap-heading-content {
  background-color: white;
  margin-top: 0;
  padding: 40px 45px 40px 178px;
  border-bottom: 1px solid #d1d1d1;

  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 0px;
    padding-top: 32px;
    padding-bottom: 31px;
  }

  h1 {
    font-family: $boldFont;
    font-size: 48px;
    margin-bottom: 8px;
    line-height: 72px;
    margin-top: 16px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      width: 95%;
      margin-top: 0;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    font-size: 18px;
    line-height: 28px;

    @media only screen and (max-width: $mobileView) {
      line-height: 24px;
      font-size: 16px;
      width: 95%;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.refunds-content {
  background-color: #ffffff;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: 150px;
  padding-top: 35px;
  padding-bottom: 50px;
  width: 88%;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
  }

  .display-4 {
    font-size: 27px;
    font-family: $boldFont;
    width: 99%;
    margin-right: 79px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 15px;
    }
  }

  .contentDescription {
    float: right;
    // padding-left: 22%;
    padding-top: 15px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 4%;
    }
  }

  .refunds-sub-content {
    border: 1px solid #dadada;
    width: 72%;
    padding-left: 30px;
    padding-right: 28px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media only screen and (max-width: $mobileView) {
      width: 84%;
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      font-family: $regularFont;
      line-height: 1.5rem;
      font-size: 1rem;
      color: #181818;
      width: 103%;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 1.8rem;
        width: 100%;
      }

      a {
        font-family: $boldFont;
        color: #181818;
      }
    }
  }
}

.refunds-content-french {
  background-color: #ffffff;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: 150px;
  padding-top: 35px;
  padding-bottom: 50px;
  width: 88%;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-top: 20px;
  }

  .display-4 {
    font-size: 27px;
    font-family: $boldFont;
    width: 122%;
    margin-right: 80px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 15px;
    }
  }

  .contentDescription {
    float: right;
    // padding-left: 9%;
    padding-top: 15px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 4%;
    }
  }

  .refunds-sub-content {
    border: 1px solid #dadada;
    width: 72%;
    padding-left: 30px;
    padding-right: 28px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media only screen and (max-width: $mobileView) {
      width: 84%;
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      font-family: $regularFont;
      line-height: 1.5rem;
      font-size: 1rem;
      color: #181818;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }

      a {
        font-family: $boldFont;
        color: #181818;
      }
    }
  }
}

.expiry-content {
  background-color: #ffffff;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: 150px;
  padding-top: 35px;
  padding-bottom: 50px;
  width: 88%;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
  }

  .display-4 {
    font-size: 27px;
    font-family: $boldFont;
    width: 53%;
    margin-right: 80px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 15px;
    }
  }

  .contentDescription {
    float: right;
    // padding-left: 24%;
    padding-top: 15px;
    margin-left: -3px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 4%;
    }
  }

  .expiry-sub-content {
    border: 1px solid #dadada;
    width: 72%;
    padding-left: 30px;
    padding-right: 28px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media only screen and (max-width: $mobileView) {
      width: 84%;
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      font-family: $regularFont;
      line-height: 1.5rem;
      font-size: 1rem;
      color: #181818;
      width: 104%;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 1.8rem;
        width: 100%;
      }

      a {
        font-family: $boldFont;
        color: #181818;
      }
    }
  }
}

.expiry-content-french {
  background-color: #ffffff;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: 150px;
  padding-top: 35px;
  padding-bottom: 50px;
  width: 88%;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-top: 20px;
  }

  .display-4 {
    font-size: 27px;
    font-family: $boldFont;
    margin-right: 80px;
    width: 61%;

    @media only screen and (max-width: $mobileView) {
      padding-left: 15px;
    }
  }

  .contentDescription {
    float: right;
    // padding-left: 17%;
    padding-top: 15px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 4%;
    }
  }

  .expiry-sub-content {
    border: 1px solid #dadada;
    width: 72%;
    padding-left: 30px;
    padding-right: 28px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media only screen and (max-width: $mobileView) {
      width: 84%;
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      font-family: $regularFont;
      line-height: 1.5rem;
      font-size: 1rem;
      color: #181818;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }

      a {
        font-family: $boldFont;
        color: #181818;
      }
    }
  }
}

.TTC-content {
  background-color: #ffffff;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: 150px;
  padding-top: 35px;
  padding-bottom: 50px;
  width: 88%;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
  }

  .display-4 {
    font-size: 27px;
    font-family: $boldFont;
    width: 128%;
    margin-right: 81px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 20px;
    }
  }

  .contentDescription {
    float: right;
    // padding-left: 5%;
    padding-top: 15px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 4%;
    }
  }

  .TTC-sub-content {
    border: 1px solid #dadada;
    width: 72%;
    padding-left: 30px;
    padding-right: 28px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media only screen and (max-width: $mobileView) {
      width: 84%;
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      font-family: $regularFont;
      line-height: 1.5rem;
      font-size: 1rem;
      color: #181818;
      width: 103%;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 1.8rem;
        width: 101%;
      }

      a {
        font-family: $boldFont;
        color: #181818;

        @media only screen and (max-width: $mobileView) {
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }
    }

    .arrow-icon1 {
      display: flex;
      padding-bottom: 10px;
      padding-left: 10px;
      width: 101%;

      @media only screen and (max-width: $mobileView) {
        padding-left: 10px;
      }

      .instructionimage {
        padding-right: 10px;

        @media only screen and (max-width: $mobileView) {
          padding-right: 20px;
        }
      }

      img {
        width: 100%;

        @media only screen and (max-width: $mobileView) {
          width: 280%;
        }
      }

      a {
        @media only screen and (max-width: $mobileView) {
          font-size: 1.2rem;
          line-height: 28px;
        }
      }
    }

    .arrow-icon2 {
      display: flex;
      padding-left: 10px;

      @media only screen and (max-width: $mobileView) {
        padding-left: 10px;
      }

      .instructionimage {
        padding-right: 10px;

        @media only screen and (max-width: $mobileView) {
          padding-right: 20px;
        }
      }

      img {
        width: 100%;

        @media only screen and (max-width: $mobileView) {
          width: 200%;
        }
      }

      a {
        @media only screen and (max-width: $mobileView) {
          font-size: 1.2rem;
          line-height: 28px;
        }
      }
    }
  }
}

.TTC-content-french {
  background-color: #ffffff;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: 150px;
  padding-top: 35px;
  padding-bottom: 50px;
  width: 88%;

  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-top: 20px;
  }

  .display-4 {
    font-size: 27px;
    font-family: $boldFont;
    margin-right: 80px;
    width: 180%;

    @media only screen and (max-width: $mobileView) {
      padding-left: 20px;
      width: 97%;
    }
  }

  .contentDescription {
    float: right;
    // padding-left: 10%;
    padding-top: 15px;

    @media only screen and (max-width: $mobileView) {
      padding-left: 4%;
    }
  }

  .TTC-sub-content {
    border: 1px solid #dadada;
    width: 72%;
    padding-left: 30px;
    padding-right: 28px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media only screen and (max-width: $mobileView) {
      width: 84%;
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      font-family: $regularFont;
      line-height: 1.5rem;
      font-size: 1rem;
      color: #181818;

      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }

      a {
        font-family: $boldFont;
        color: #181818;

        @media only screen and (max-width: $mobileView) {
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }
    }

    .arrow-icon1 {
      display: flex;
      padding-bottom: 10px;
      padding-left: 10px;
      width: 101%;

      @media only screen and (max-width: $mobileView) {
        padding-left: 10px;
      }

      .instructionimage {
        padding-right: 15px;

        @media only screen and (max-width: $mobileView) {
          padding-right: 25px;
        }
      }

      img {
        width: 171%;
        padding-bottom: 30px;

        @media only screen and (max-width: $mobileView) {
          width: 597%;
        }
      }

      a {
        line-height: 28px;

        @media only screen and (max-width: $mobileView) {
          font-size: 1.2rem;
          line-height: 28px;
        }
      }
    }

    .arrow-icon2 {
      display: flex;
      padding-left: 10px;

      @media only screen and (max-width: $mobileView) {
        padding-left: 10px;
      }

      .instructionimage {
        padding-right: 12px;

        @media only screen and (max-width: $mobileView) {
          padding-right: 25px;
        }
      }

      img {
        width: 130%;
        padding-bottom: 30px;

        @media only screen and (max-width: $mobileView) {
          width: 450%;
        }
      }

      a {
        line-height: 28px;

        @media only screen and (max-width: $mobileView) {
          font-size: 1.2rem;
          line-height: 28px;
        }
      }
    }
  }
}

.presto-refunds {
  background-color: #ffffff;
  padding-top: 56px;
  padding-bottom: 32px;
  padding-left: 170px;
  border-bottom: 2px solid #dadada;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 48px;
    color: #181818;
    padding-bottom: 0 !important;
    line-height: 72px;
    margin-bottom: 0px;
    margin-top: 0px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  .contentDescription {
    width: 73%;

    @media only screen and (max-width: $mobileView) {
      width: 102%;
    }

    p {
      font-size: 18px;
      font-family: $regularFont;
      line-height: 28px;
      display: block;
      text-align: left;
      color: #181818;
      padding-right: 69px;
      margin-bottom: 0px;
      margin-top: 8px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        padding-right: 0;
      }
    }
  }
}

.contact-us-contact-transit-agency {
  width: 66%;

  // margin-left: 35%;
  @media only screen and (max-width: $mobileView) {
    width: 100%;
    margin-left: 0%;
  }

  .display-4 {
    font-size: 32px;
    line-height: 48px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 24px;
      margin-top: 0px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 24px;

    @media only screen and (max-width: $mobileView) {
      font-size: 16px;
      line-height: 24px;
      // margin-left: 20px;
    }
  }
}

.get-presto-card {
  background-color: #ffffff;
  padding-top: 56px;
  // padding-left: 170px;
  line-height: 1.3rem;
  padding-bottom: 60px;
  border-bottom: 2px solid #dadada;

  @media only screen and (max-width: $mobileView) {
    line-height: 4rem;
    padding-top: 32px;
    margin-bottom: 0px;
    width: 90%;
    padding-left: 22px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 22px;
    padding-bottom: 32px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 48px;
    line-height: 72px;
    // margin-top: 0;
    // margin-bottom: 0;
    color: #181818;
    padding-bottom: 0 !important;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
      margin-bottom: 0px;
      font-size: 32px;
      line-height: 48px;
      width: 90%;
    }
  }

  .contentDescription {
    p {
      font-family: $regularFont;
      line-height: 28px;
      display: block;
      text-align: left;
      color: #181818;
      font-size: 18px;
      // margin-top: 8px;
      // margin-bottom: 0px;
      width: 944px;
      margin: 8px auto 0;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.Cant-Buy {
  padding-top: 48px;
  padding-bottom: 48px;
  // padding-left: 170px;
  width: 944px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-top: 32px;
    padding-bottom: 0px;
    padding-right: 16px;
    width: unset;
  }

  .display-4,
  .contentDescription {
    width: 704px;

    @media only screen and (max-width: $mobileView) {
      width: inherit;
    }
  }

  h1 {
    font-size: 26px;
    margin-bottom: 0px;
    line-height: 40px;
    margin-top: 0px;

    @media only screen and (max-width: $mobileView) {
      width: 87%;
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }

  ul {
    font-family: $regularFont;
    padding-top: 24px;
    padding-left: 24px;
    margin-top: 0px;
    margin-bottom: 0px;

    @media only screen and (max-width: $mobileView) {
      width: 87%;
      padding-left: 29px;
      padding-top: 0px;
      padding-bottom: 32px;
    }

    li {
      padding-left: 5px;
      line-height: 24px;
      font-size: 16px;
    }
  }
}

.activate-presto {
  background-color: $white;
  padding-top: 48px;
  padding-bottom: 56px;

  // padding-left: 170px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-top: 24px;
    padding-bottom: 32px;
    padding-right: 16px;
  }

  .display-4 {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 24px;
    margin-top: 0px;
    width: 944px;
    margin: 0 auto 24px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 16px;
      width: unset;
    }
  }

  .contentDescription {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }

  p {
    font-family: $regularFont;
    width: 75%;
    line-height: 24px;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    @media only screen and (max-width: $mobileView) {
      width: 95%;
    }
  }

  .sub-para {

    // width: 62%;
    @media only screen and (max-width: $mobileView) {
      width: 89%;
    }
  }
}

.report-lost {
  padding-top: 48px;
  padding-bottom: 80px;
  width: 944px;
  margin: 0 auto;

  // padding-left: 170px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-top: 32px;
    padding-bottom: 64px;
    padding-right: 16px;
    width: unset;
  }

  .display-4 {
    font-size: 26px;
    margin-bottom: 24px;
    margin-top: 0px;
    width: 65%;
    line-height: 40px;

    @media only screen and (max-width: $mobileView) {
      width: 98%;
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }

  p {
    font-family: $regularFont;
    width: 64%;
    line-height: 24px;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    @media only screen and (max-width: $mobileView) {
      width: 95%;
    }
  }

  .arrow-icon1 {
    margin-top: 24px;
    margin-bottom: 24px;

    p {
      margin-bottom: 0px;
      padding-top: 0px;
    }

    img {
      margin-top: -6px;

      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
  }

  .link {
    width: 63%;
    padding-top: 9px;
    margin-bottom: 10px;

    @media only screen and (max-width: $mobileView) {
      width: 93%;
      margin-left: 0px !important;
    }

    #linkText {
      @media only screen and (max-width: $mobileView) {
        margin-left: 0px;
        padding-left: 0px !important;
      }
    }
  }

  ul {
    font-family: $regularFont;
    width: 70%;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 26px;

    @media only screen and (max-width: $mobileView) {
      width: 80%;
      padding-left: 29px;
    }

    li {
      padding-left: 5px;
      line-height: 24px;
      font-size: 16px;
      padding-bottom: 10px;
    }
  }

  .last-para {
    padding-top: 0px;
    margin-top: 0px;
  }
}

.transit-pass-header {
  margin-top: 32px;
  margin-bottom: 48px;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
    //margin-left: 20px;
  }

  .display-4 {
    font-size: 48px;
    line-height: 72px;
    margin-top: 32px;
    margin-bottom: 24px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  .contentDescription {
    font-family: 'AvenirNextLTPro-Regular';
    width: 100%;
    line-height: 1.5em;
  }
}

.Customer-service {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  border-bottom: 1.5px solid #dbdbdb;
  padding-bottom: 16px;

  .display-4 {
    font-size: 48px;
    font-family: $boldFont;
    color: #191919;
    font-weight: lighter;
    line-height: 72px;
    display: block;
    margin-bottom: 16px;
    margin-top: 32px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      font-weight: 300;
      line-height: 48px;
      margin-bottom: 8px;
    }
  }

  .contentDescription {
    p {
      font-family: $regularFont;
      color: #181818;
      font-size: 16px;
      line-height: 24px;
      display: block;
      margin-bottom: 0px;
      margin-right: 45px;
      width: 100%;

      @media only screen and (max-width: $mobileView) {
        width: 104%;
      }
    }
  }
}

.new-presto-devices-header {
  margin-bottom: 40px;

  @media only screen and (max-width: $mobileView) {
    margin-bottom: 30px;
  }

  h1 {
    background-color: #ffffff;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 48px;
    line-height: 72px;
    border-bottom: 1px solid #d1d1d1;

    @media only screen and (max-width: $mobileView) {
      padding: 45px 25px 35px 30px;
      font-size: 32px;
    }
  }

  .contentDescription {
    border-bottom: 1px solid #bababa;
    // margin-left: 170px;
    // width: 76%;
    // padding-top: 5px;
    width: 944px;
    margin: 0 auto;
    padding-bottom: 48px;
    padding-top: 48px;

    @media only screen and (max-width: $mobileView) {
      width: 79%;
      margin-left: 30px;
    }

    .new-presto-para {
      font-family: $regularFont;
      width: 72%;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: 97%;
      }

      span {
        display: block;
        margin-top: 25px;
      }
    }
  }
}

.Hello-presto-main-title {
  background-color: #ffffff;
  padding-top: 56px;
  padding-bottom: 32px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
    width: unset;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 60px;
    line-height: 84px;
    color: #191919;
    margin: 0 auto;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      width: unset;
    }
  }

  .contentDescription {
    margin: 0 auto;
    width: 944px;

    p {
      // width: 64%;
      font-size: 18px;
      font-family: $regularFont;
      line-height: 28px;
      display: block;
      text-align: left;
      color: #191919;
      margin-top: 8px;
      margin-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: unset;
      }

      @media only screen and (min-width: $mobileView) {
        width: 75%;
      }
    }

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
}

.Hello-presto {
  background-color: #ffffff;
  padding-top: 56px;
  padding-bottom: 32px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
    width: unset;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 48px;
    line-height: 72px;
    color: #191919;
    margin: 0 auto;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      width: unset;
    }
  }

  .contentDescription {
    margin: 0 auto;
    width: 944px;

    p {
      // width: 64%;
      font-size: 18px;
      font-family: $regularFont;
      line-height: 28px;
      display: block;
      text-align: left;
      color: #191919;
      margin-top: 8px;
      margin-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: unset;
      }

      @media only screen and (min-width: $mobileView) {
        width: 75%;
      }
    }

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
}

.Hello-presto-get-card {
  background-color: #ffffff;
  padding-top: 56px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
    padding-bottom: 16px;
    width: unset;
  }

  .display-4 {
    padding-bottom: 24px;
    font-family: $boldFont;
    font-size: 32px;
    line-height: 32px;
    color: #191919;
    margin: 0 auto;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
      width: unset;
      padding-bottom: 16px;
    }
  }

  .contentDescription {
    margin: 0 auto;
    width: 944px;

    p {
      font-size: 16px;
      font-family: $regularFont;
      line-height: 24px;
      display: block;
      text-align: left;
      color: #191919;
      margin-top: 8px;
      margin-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: unset;
      }

      @media only screen and (min-width: $mobileView) {
        width: 75%;
      }
    }

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
}

.Hello-presto-convert-card {
  background-color: #ffffff;
  padding-top: 56px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
    padding-bottom: 16px;
    width: unset;
  }

  .display-4 {
    padding-bottom: 24px;
    font-family: $boldFont;
    font-size: 32px;
    line-height: 32px;
    color: #191919;
    margin: 0 auto;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
      width: unset;
      padding-bottom: 16px;
    }
  }

  .contentDescription {
    margin: 0 auto;
    width: 944px;

    p {
      font-size: 16px;
      font-family: $regularFont;
      line-height: 24px;
      display: block;
      text-align: left;
      color: #191919;
      margin-top: 8px;
      margin-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: unset;
      }

      @media only screen and (min-width: $mobileView) {
        width: 75%;
      }
    }

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
}

.Get-to-know {
  background-color: #ffffff;
  //padding-left: 155px;
  padding-top: 32px;

  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
  }

  .contentDescription {
    p {
      width: 700px;
      padding-right: 243px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        padding: 0px;
      }
    }
  }

  .display-4 {
    font-size: 26px;
    color: #181818;
    display: block;
    font-weight: 500;
    line-height: 40px;
    font-family: $boldFont;
    // margin-top: 10px;
    // margin-bottom: 10px;
    width: 944px;
    margin: 10px auto;

    @media only screen and (max-width: $mobileView) {
      line-height: 32px;
      font-size: 22px;
      width: 100%;
    }
  }

  p {
    width: 944px;
    font-size: 16px;
    color: #181818;
    line-height: 1.5em;
    display: block;
    // margin-top: 18px;
    margin: 16px auto 0;
    font-family: $regularFont;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
      font-size: 19px;
    }
  }
}

.Learn-How-to-use {

  // padding-left: 155px;
  // padding-top: 35px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 22px;
    padding-right: 22px;
  }

  .display-4 {
    font-size: 26px;
    color: #181818;
    display: block;
    font-weight: 500;
    line-height: 40px;
    font-family: $boldFont;
    // margin-top: 20px;
    // margin-bottom: 8px;
    width: 944px;
    margin: 48px auto 8px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
      width: 100%;
    }
  }

  p {
    width: 944px;
    font-size: 16px;
    color: #181818;
    line-height: 24px;
    display: block;
    // margin-top: 16px;
    margin: 16px auto 0;
    font-family: $regularFont;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
}

.Presto-transit-para {
  background-color: #ffffff;

  // padding-left: 155px;
  // padding-top: 35px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .contentDescription {
    p {
      width: 700px;
      padding-right: 243px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        padding: 0px;
      }
    }
  }

  .display-4 {
    font-size: 26px;
    color: #181818;
    display: block;
    font-weight: 500;
    line-height: 40px;
    font-family: $boldFont;
    // margin-top: 0;
    // margin-bottom: 8px;
    line-height: 32px;
    width: 944px;
    margin: 0 auto 8px;
    padding-top: 48px;

    @media only screen and (max-width: $mobileView) {
      font-size: 26px;
      line-height: 32px;
      width: 100%;
      padding-top: 24px;
    }
  }

  p {
    width: 944px;
    font-size: 16px;
    color: #181818;
    line-height: 24px;
    display: block;
    margin-top: 8px;
    font-family: $regularFont;
    padding-bottom: 24px;
    margin: 8px auto 0;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
}

.Presto-FAQ {
  //padding-left: 155px;
  padding-top: 48px;
  width: 944px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
    width: unset;
  }

  .display-4 {
    font-size: 26px;
    color: #181818;
    display: block;
    font-weight: 500;
    line-height: 40px;
    font-family: $boldFont;
    margin-top: 0px;
    margin-bottom: 8px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
      width: 100%;
    }
  }

  p {
    // width: 65%;
    font-size: 16px;
    color: #191919;
    line-height: 24px;
    display: block;
    margin-top: 16px;
    margin-bottom: 32px;
    font-family: $regularFont;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }

  a {
    font-family: $boldFont;
  }
}

.Use-Presto {
  background-color: #ffffff;
  padding-top: 40px;
  line-height: 1.3rem;
  padding-bottom: 32px;
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 48px;

  @media only screen and (max-width: $mobileView) {
    padding: 32px 18px 36px 16px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .display-4 {
    padding-top: 15px;
    font-family: $boldFont;
    font-size: 3rem;
    line-height: 72px;
    color: #181818;
    padding-bottom: 0 !important;
    width: 944px;
    margin: 0 auto 8px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      padding-top: 0px;
      width: 100%;
      margin: 0px 0px 8px;
    }
  }

  .contentDescription {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
      margin: 0;
    }

    p {
      width: 75%;
      font-size: 18px;
      font-family: $regularFont;
      line-height: 26px;
      display: block;
      text-align: left;
      color: #181818;
      margin-top: 0px;
      margin-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
        width: unset;
      }
    }
  }
}

.presto-perks-header {
  padding-top: 70px;
  background-color: #ffffff;
  padding-left: 170px;
  padding-bottom: 20px;
  border-bottom: 2px solid #dfdede;
  margin-bottom: 20px;

  @media only screen and (max-width: $mobileView) {
    padding-left: 30px;
    padding-top: 45px;
  }

  h1 {
    font-size: 48px;
    margin-top: 0px;

    @media only screen and (max-width: $mobileView) {
      font-size: 36px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    font-size: 18px;
    width: 70%;
    line-height: 1.5em;

    @media only screen and (max-width: $mobileView) {
      font-size: 16px;
      width: 90%;
    }
  }

  ~.row {
    margin-left: 170px;

    @media only screen and (max-width: $mobileView) {
      margin-left: 30px;
    }

    .column {
      max-width: 40%;
    }
  }
}

.new-to-presto-header {
  background-color: white;
  padding: 60px 0px 15px 0px;
  border-bottom: 2px solid #dfdede;

  @media only screen and (max-width: $mobileView) {
    padding-left: 30px;
    padding-top: 45px;
  }

  .display-4 {
    font-size: 3rem;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    font-size: 18px;
    line-height: 28px;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: 95%;
      font-size: 16px;
      margin-left: 0 !important;
    }

    p {
      width: 75%;

      @media only screen and (max-width: $mobileView) {
        width: 90%;
      }
    }
  }
}

.say-hello-to-all-contentblock {
  padding: 48px 0px 24px;

  @media only screen and (max-width: $mobileView) {
    padding: 32px 16px 24px;
  }

  .display-4 {
    font-size: 24px;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      width: unset;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    line-height: 1.5em;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }

    p {
      margin-left: 0px !important;
    }
  }
}

.find-presto-product-contentblock {
  padding: 30px 0px 15px 0px;

  @media only screen and (max-width: $mobileView) {
    padding-left: 30px;
  }

  h1 {
    font-size: 26px;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      width: 100%;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    // width: 68%;
    line-height: 1.5em;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: 99%;
      margin-left: 0;
    }

    p {
      width: 75%;
      margin-left: 0px !important;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }
}

#comparingapps-new-to-presto {
  margin-top: 32px;

  @media only screen and (max-width: $mobileView) {
    margin-top: 16px;
  }
}

.comparing-apps-to-download-contentblock {
  background-color: #fff;
  padding: 48px 0px 24px;

  @media only screen and (max-width: $mobileView) {
    padding: 24px 16px;
  }

  .display-4 {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      font-size: 24px;
      width: unset;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    // width: 65%;
    line-height: 1.5em;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }

    // p {
    //   width: 75%;
    // }
  }
}

.new-to-presto-help-contentblock {
  padding-top: 30px;
  padding-bottom: 70px;

  @media only screen and (max-width: $mobileView) {
    margin-left: 30px;
  }

  h1 {
    width: 944px;
    margin: 0 auto;
    font-size: 26px;
  }

  .contentDescription {
    font-family: $regularFont;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: 88%;
      line-height: 1.5em;
    }
  }
}

.Hello-free {
  background-color: #ffffff;
  padding-top: 56px;
  padding-bottom: 60px;
  border-bottom: 2px solid #dadada;

  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 48px;
    color: #191919;
    margin-bottom: 8px;
    margin-top: 0px;
    line-height: 72px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      width: 100%;
    }
  }

  .contentDescription {
    margin-left: auto;
    margin-right: auto;
    width: 944px;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }

    p {
      font-size: 18px;
      font-family: $regularFont;
      line-height: 28px;
      display: block;
      text-align: left;
      color: #1a1a1a;
      margin-top: 0px;
      margin-bottom: 0px;

      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.Tax-credit-content {
  margin: 0 auto;
  width: 944px;
  padding-top: 48px;
  padding-bottom: 48px;
  border-bottom: 1.5px solid #d1d1d1;
  margin-bottom: 48px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
    padding-bottom: 32px;
    width: 90%;
  }

  .display-4 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 40px;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  p {
    font-family: $regularFont;
    margin-top: 24px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 24px;
    width: 74%;

    @media only screen and (max-width: $mobileView) {
      margin-top: 20px;
      width: 100%;
    }
  }

  ol {
    font-size: 16px;
    line-height: 32px;
    font-family: $regularFont;
    padding-left: 27px;

    @media only screen and (max-width: $mobileView) {
      font-size: 14px;
      line-height: 21px;
    }

    li {
      padding-left: 8px;

      @media only screen and (max-width: $mobileView) {
        padding-bottom: 8px;
      }
    }
  }

  ul {
    font-size: 16px;
    line-height: 32px;
    font-family: $regularFont;
    padding-left: 27px;

    @media only screen and (max-width: $mobileView) {
      font-size: 14px;
      line-height: 21px;
    }

    li {
      padding-left: 8px;

      @media only screen and (max-width: $mobileView) {
        padding-bottom: 8px;
      }
    }
  }
}

.Tax-credit {
  margin: 0 auto;
  width: 944px;
  margin-top: 48px;
  margin-bottom: 16px;

  @media only screen and (max-width: $mobileView) {
    margin-top: 32px;
    margin-bottom: 32px;
    width: 90%;
  }

  .display-4 {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 24px;
    margin-top: 0px;
    color: #191919;
    font-family: $boldFont;

    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #191919;
    font-family: $regularFont;
  }
}

.contactus-contentblock {
  background-color: white;
  //margin-top: 0;
  padding: 56px 0px 32px;
  border-bottom: 2px solid #dfdede;

  @media only screen and (max-width: $mobileView) {
    padding: 32px 16px 32px 16px;
    margin-bottom: 32px;
  }

  .display-4 {
    font-family: $boldFont;
    font-size: 48px;
    // margin-bottom: 8px;
    line-height: 72px;
    // margin-top: 0px;
    width: 944px;
    margin: 0 auto 8px;

    @media only screen and (max-width: $mobileView) {
      font-size: 32px;
      line-height: 48px;
      width: 100%;
    }
  }

  .contentDescription {
    font-family: $regularFont;
    font-weight: lighter;
    font-size: 18px;
    //width: 65%;
    line-height: 28px;
    color: #1a1a1a;
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
      line-height: 24px;
      font-size: 16px;
      color: #191919;
    }
  }

  +.row {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: auto;
    }

    .column {
      max-width: unset !important;
    }

    +.row {
      width: 944px;
      margin: 0 auto;

      @media only screen and (max-width: $mobileView) {
        width: auto;
      }

      .column {
        max-width: unset !important;
      }
    }

    @media only screen and (max-width: $mobileView) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.Commercial-content {
  margin-top: 32px;
  margin-bottom: 48px;

  @media only screen and (max-width: $mobileView) {
    margin-bottom: 40px;
  }

  h1 {
    font-size: 48px;
    line-height: 72px;
    margin-top: 0px;
    margin-bottom: 24px;
    color: #191919;
    font-family: $boldFont;

    @media only screen and (max-width: $mobileView) {
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 48px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #191919;
    font-family: $regularFont;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.Bulk-presto {
  margin-top: 32px;
  margin-bottom: 48px;

  @media only screen and (max-width: $mobileView) {
    margin-bottom: 40px;
  }

  h1 {
    font-size: 48px;
    line-height: 72px;
    margin-top: 0px;
    margin-bottom: 24px;
    color: #191919;
    font-family: $boldFont;

    @media only screen and (max-width: $mobileView) {
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 48px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #191919;
    font-family: $regularFont;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

#get-presto-ready-content {
  background-color: #fff;
}

.new-mainContent, .cso-height {
  @media only screen and (min-width: 920px) and (max-width: 970px){
    width: 540px !important;
  }
}

.additional-information-img-container-desktop{
  display: block;
  @media only screen and (max-width: $mobileView) {
    display: none;
  }
}

.additional-information-img-container-mobile{
  display: none;
  @media only screen and (max-width: $mobileView) {
    display: block;
  }
}