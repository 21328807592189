@import '../../assets/scss/vars.scss';
.homepage-banner {
  position: relative;
  @media only screen and (max-width: $mobileView) {
    position: static;
  }
  .heroimage {
    img {
      width: 100%;
      height: 680px;
      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
  }

  .heroimage-banner-home {
    background-image: url('../../assets/images/homepage-hero-banner.jpg');
    height: 680px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @media only screen and (max-width: $mobileView) {
      display: none;
    }
  }

  .prsto-intro-wrapper {
    width: 944px;
    margin: 0 auto;
    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
  .presto-intro {
    position: absolute;
    top: 18%;
    //left: 8%;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    padding: 0px 64px 64px 64px;
    @media only screen and (max-width: $mobileView) {
      position: static;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 0px;
    }
    h2 {
      font-family: 'AvenirNextLTPro-Bold';
      font-size: 60px;
      line-height: 72px;
      margin-bottom: 40px;
      @media only screen and (max-width: $mobileView) {
        font-size: 48px;
        text-align: center;
        margin-bottom: 24px;
      }
    }
    .external-image {
      display: none;
    }
    .presto-intro-buttons {
      margin-bottom: 80px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 48px;
      }
    }
    button {
      display: inline-block;
      margin-right: 15px;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
    span {
      display: block;
      text-align: left;
      text-decoration: underline;
      .external-image {
        display: none;
      }
      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
    a {
      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
  }
}
.animation-screen1-screen2 {
  position: relative;
  animation: slidescreens 0.5s;
  animation-timing-function: ease;
}
@keyframes slidescreens {
  from {
    right: -1000px;
  }
  to {
    right: 0px;
  }
}
.animation-hero {
  animation: slidehero 0.5s;
  animation-timing-function: ease;
}
@keyframes slidehero {
  from {
    left: -1000px;
  }
  to {
    left: 0px;
  }
}
.presto-users-0 {
  padding-left: 38px;
  padding-top: 80px;
  padding-bottom: 114px;
  background-color: #ffffff;
  @media only screen and (max-width: $mobileView) {
    padding: 30px 16px 16px 16px;
  }
  .presto-options-heading-wrapper {
    width: 944px;
    margin: 0 auto;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
    .back-icon {
      display: block;
      @media only screen and (max-width: $mobileView) {
        margin-left: 0px;
      }
      button {
        display: flex;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        margin-top: 9px;
      }
      p {
        margin-top: 12px;
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          color: #191919;
        }
      }
    }
    .home-presto-title {
      text-align: center;
      h1 {
        font-size: 60px;
        font-family: 'AvenirNextLTPro-Bold';
        line-height: 90px;
        margin-top: -24px;
        margin-bottom: 16px;
        @media only screen and (max-width: $mobileView) {
          font-size: 32px;
          font-family: $boldFont;
          line-height: 48px;
          margin-top: 20px;
          margin-bottom: 8px;
        }
      }
      span {
        font-size: 32px;
        line-height: 48px;
        @media only screen and (max-width: $mobileView) {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
  .presto-available-options {
    width: 944px;
    margin: 64px auto 0px;
    @media only screen and (max-width: $mobileView) {
      margin-top: 24px;
      margin-left: 0px;
      width: 100%;
    }
    ul {
      display: flex;
      padding-left: 0px;
      @media only screen and (max-width: $mobileView) {
        display: block;
      }
      .presto-options-list-0 {
        list-style-type: none;
        text-align: center;
        outline: 2px solid #e8e8e8;
        border-top: 8px solid #b7dd79;
        padding: 25px 0px;
        width: 22%;
        margin-left: 16px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          height: 68px;
          padding: 0px;
          border-top: 4px solid #b7dd79;
          margin-bottom: 16px;
          margin-left: 0px;
        }
        a {
          text-decoration: none;
          .external-image {
            display: none;
          }
        }
        img {
          @media only screen and (max-width: $mobileView) {
            float: left;
            width: 40px;
            height: 40px;
            padding: 16px 20px 16px 12px;
          }
        }
        .presto-options-description {
          @media only screen and (max-width: $mobileView) {
            padding-left: 0px;
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            @media only screen and (max-width: $mobileView) {
              // margin-top: 5px;
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              margin: 24px 8px 24px 0px;
            }
          }
          p {
            font-family: $regularFont;
            line-height: 1.5em;
            padding-left: 16px;
            padding-right: 16px;
            @media only screen and (max-width: $mobileView) {
              display: none;
            }
          }
        }
      }
      .presto-options-list-1 {
        list-style-type: none;
        text-align: center;
        outline: 2px solid #e8e8e8;
        border-top: 8px solid #05c3de;
        padding: 25px 0px;
        width: 22%;
        margin-left: 16px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          height: 68px;
          padding: 0px;
          border-top: 4px solid #05c3de;
          margin-bottom: 16px;
          margin-left: 0px;
        }
        a {
          text-decoration: none;
          .external-image {
            display: none;
          }
        }
        img {
          @media only screen and (max-width: $mobileView) {
            float: left;
            width: 40px;
            height: 40px;
            padding: 16px 20px 16px 12px;
          }
        }
        .presto-options-description {
          padding-left: 16px;
          padding-right: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-left: 0px;
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            @media only screen and (max-width: $mobileView) {
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              margin: 24px 8px 24px 0px;
            }
          }
          p {
            font-family: $regularFont;
            line-height: 1.5em;
            @media only screen and (max-width: $mobileView) {
              display: none;
            }
          }
        }
      }
      .presto-options-list-2 {
        list-style-type: none;
        text-align: center;
        outline: 2px solid #e8e8e8;
        border-top: 8px solid #a396ff;
        padding: 25px 0px;
        width: 22%;
        margin-left: 16px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          height: 68px;
          padding: 0px;
          border-top: 4px solid #a396ff;
          margin-bottom: 16px;
          margin-left: 0px;
        }
        a {
          text-decoration: none;
          .external-image {
            display: none;
          }
        }
        img {
          @media only screen and (max-width: $mobileView) {
            float: left;
            width: 40px;
            height: 40px;
            padding: 16px 20px 16px 12px;
          }
        }
        .presto-options-description {
          padding-left: 16px;
          padding-right: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-left: 0px;
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            @media only screen and (max-width: $mobileView) {
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              margin: 24px 8px 24px 0px;
            }
          }
          p {
            font-family: $regularFont;
            line-height: 1.5em;
            @media only screen and (max-width: $mobileView) {
              display: none;
            }
          }
        }
      }
      .presto-options-list-3 {
        list-style-type: none;
        text-align: center;
        outline: 2px solid #e8e8e8;
        border-top: 8px solid #ff8674;
        padding: 25px 0px;
        width: 22%;
        margin-left: 16px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          height: 68px;
          padding: 0px;
          border-top: 4px solid #ff8674;
          margin-bottom: 20px;
          margin-left: 0px;
        }
        a {
          text-decoration: none;
          .external-image {
            display: none;
          }
        }
        img {
          @media only screen and (max-width: $mobileView) {
            float: left;
            width: 40px;
            height: 40px;
            padding: 16px 20px 16px 12px;
          }
        }
        .presto-options-description {
          padding-left: 16px;
          padding-right: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-left: 0px;
            width: 100%;
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            @media only screen and (max-width: $mobileView) {
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              margin: 24px 8px 24px 0px;
            }
            @media only screen and (max-width: 338px) {
              margin: 12px 8px 12px 0px;
            }
          }
          p {
            font-family: $regularFont;
            line-height: 1.5em;
            @media only screen and (max-width: $mobileView) {
              display: none;
            }
          }
        }
      }
    }
  }
}
.presto-users-1 {
  padding-top: 80px;
  padding-bottom: 114px;
  background-color: #ffffff;
  @media only screen and (max-width: $mobileView) {
    padding: 30px 16px 16px 16px;
  }
  .presto-options-heading-wrapper {
    width: 944px;
    margin: 0 auto;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
    .back-icon {
      display: block;
      @media only screen and (max-width: $mobileView) {
        margin-left: 0px;
      }
      button {
        display: flex;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        margin-top: 9px;
      }
      p {
        margin-top: 12px;
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          color: #191919;
        }
      }
    }
    .home-presto-title {
      text-align: center;
      h1 {
        font-size: 60px;
        font-family: 'AvenirNextLTPro-Bold';
        line-height: 90px;
        margin-top: -24px;
        margin-bottom: 16px;
        @media only screen and (max-width: $mobileView) {
          font-size: 32px;
          font-family: $boldFont;
          line-height: 48px;
          margin-top: 20px;
          margin-bottom: 8px;
        }
      }
      span {
        font-size: 32px;
        line-height: 48px;
        @media only screen and (max-width: $mobileView) {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
  .presto-available-options {
    width: 1104px;
    margin: 64px auto 0px;
    @media only screen and (max-width: $mobileView) {
      margin-top: 24px;
      margin-left: 0px;
      width: 100%;
    }
    ul {
      display: flex;
      padding-left: 0px;
      @media only screen and (max-width: $mobileView) {
        display: block;
      }
      .presto-options-list-0 {
        list-style-type: none;
        text-align: center;
        outline: 2px solid #e8e8e8;
        border-top: 8px solid #b7dd79;
        padding: 25px 0px;
        width: 19%;
        margin-left: 16px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          height: 68px;
          padding: 0px;
          border-top: 4px solid #b7dd79;
          margin-bottom: 16px;
          margin-left: 0px;
        }
        a {
          text-decoration: none;
          .external-image {
            display: none;
          }
        }
        img {
          @media only screen and (max-width: $mobileView) {
            float: left;
            width: 40px;
            height: 40px;
            padding: 16px 20px 16px 12px;
          }
        }
        .presto-options-description {
          padding-left: 16px;
          padding-right: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-left: 0px;
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            @media only screen and (max-width: $mobileView) {
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              margin: 24px 8px 24px 0px;
            }
          }
          p {
            font-family: $regularFont;
            line-height: 1.5em;
            @media only screen and (max-width: $mobileView) {
              display: none;
            }
          }
        }
      }
      .presto-options-list-1 {
        list-style-type: none;
        text-align: center;
        outline: 2px solid #e8e8e8;
        border-top: 8px solid #05c3de;
        padding: 25px 0px;
        width: 19%;
        margin-left: 16px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          height: 68px;
          padding: 0px;
          border-top: 4px solid #05c3de;
          margin-bottom: 16px;
          margin-left: 0px;
        }
        a {
          text-decoration: none;
          .external-image {
            display: none;
          }
        }
        img {
          @media only screen and (max-width: $mobileView) {
            float: left;
            width: 40px;
            height: 40px;
            padding: 16px 20px 16px 12px;
          }
        }
        .presto-options-description {
          @media only screen and (max-width: $mobileView) {
            padding-left: 0px;
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            @media only screen and (max-width: $mobileView) {
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              margin: 24px 8px 24px 0px;
            }
          }
          p {
            font-family: $regularFont;
            line-height: 1.5em;
            padding-left: 16px;
            padding-right: 16px;
            @media only screen and (max-width: $mobileView) {
              display: none;
            }
          }
        }
      }
      .presto-options-list-2 {
        list-style-type: none;
        text-align: center;
        outline: 2px solid #e8e8e8;
        border-top: 8px solid #a396ff;
        padding: 25px 0px;
        width: 19%;
        margin-left: 16px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          height: 68px;
          padding: 0px;
          border-top: 4px solid #a396ff;
          margin-bottom: 16px;
          margin-left: 0px;
        }
        a {
          text-decoration: none;
          .external-image {
            display: none;
          }
        }
        img {
          @media only screen and (max-width: $mobileView) {
            float: left;
            width: 40px;
            height: 40px;
            padding: 16px 20px 16px 12px;
          }
        }
        .presto-options-description {
          padding-left: 16px;
          padding-right: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-left: 0px;
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            @media only screen and (max-width: $mobileView) {
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              margin: 24px 8px 24px 0px;
            }
          }
          p {
            font-family: $regularFont;
            line-height: 1.5em;
            @media only screen and (max-width: $mobileView) {
              display: none;
            }
          }
        }
      }
      .presto-options-list-3 {
        list-style-type: none;
        text-align: center;
        outline: 2px solid #e8e8e8;
        border-top: 8px solid #ff8674;
        padding: 25px 0px;
        width: 19%;
        margin-left: 16px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          height: 68px;
          padding: 0px;
          border-top: 4px solid #ff8674;
          margin-bottom: 16px;
          margin-left: 0px;
        }
        a {
          text-decoration: none;
          .external-image {
            display: none;
          }
        }
        img {
          @media only screen and (max-width: $mobileView) {
            float: left;
            width: 40px;
            height: 40px;
            padding: 16px 20px 16px 12px;
          }
        }
        .presto-options-description {
          @media only screen and (max-width: $mobileView) {
            padding-left: 0px;
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            @media only screen and (max-width: $mobileView) {
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              margin: 24px 8px 24px 0px;
            }
          }
          p {
            font-family: $regularFont;
            line-height: 1.5em;
            padding-left: 16px;
            padding-right: 16px;
            @media only screen and (max-width: $mobileView) {
              display: none;
            }
          }
        }
      }
      .presto-options-list-4 {
        list-style-type: none;
        text-align: center;
        outline: 2px solid #e8e8e8;
        border-top: 8px solid #c91d69;
        padding: 25px 0px;
        width: 19%;
        margin-left: 16px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          height: 68px;
          padding: 0px;
          border-top: 4px solid #c91d69;
          margin-bottom: 16px;
          margin-left: 0px;
        }
        a {
          text-decoration: none;
          .external-image {
            display: none;
          }
        }
        img {
          @media only screen and (max-width: $mobileView) {
            float: left;
            width: 40px;
            height: 40px;
            padding: 16px 20px 16px 12px;
          }
        }
        .presto-options-description {
          padding-left: 16px;
          padding-right: 16px;
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
            @media only screen and (max-width: $mobileView) {
              font-size: 16px;
              text-align: left;
              line-height: 24px;
              margin: 24px 8px 24px 0px;
            }
            @media only screen and (max-width: 338px) {
              margin: 12px 8px 12px 0px;
            }
          }
          p {
            font-family: $regularFont;
            line-height: 1.5em;
            @media only screen and (max-width: $mobileView) {
              display: none;
            }
          }
        }
      }
    }
  }
}
.hide {
  display: none;
}
