@import '../../assets/scss/vars';

.transit-agency-list-wrapper {
  border: 1px solid #e8e8e8;
  width: 96%;
  margin-bottom: 16px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
    // margin-left: 20px;
    // margin-bottom: 40px;
  }

  .transit-list {
    display: flex;
    border-bottom: 1.5px solid #dedede;
    margin-left: 25px;
    margin-right: 24px;
    margin-bottom: -1px;

    @media only screen and (max-width: $mobileView) {
      // margin-top: 15px;
      margin-left: 16px;
      // margin-bottom: 15px;
    }
    @media only screen and (max-width: 413px) {
      &:nth-child(5) .description,
      &:nth-child(9) .description,
      &:nth-child(10) .description,
      &:nth-child(11) .description {
        padding-top: 0px;
      }
    }

    &:nth-child(8) img {
      width: 50px !important;
      height: 64px;
      margin-right: 40px !important;
    }
    &:nth-child(9) img {
      width: 64px !important;
      height: 64px;
    }
    &:nth-child(10) img {
      width: 58px !important;
      height: 64px;
      margin-right: 30px !important;
    }
    &:nth-child(11) img {
      width: 50px !important;
      height: 55px;
      margin-right: 37px !important;
    }

    img {
      // height: 75px;
      // margin-right: 24px;
      // margin-top: 16px;
      // margin-bottom: 16px;
      // width: 75px;
      height: 55px;
      margin-right: 24px;
      margin-top: 16px;
      margin-bottom: 16px;
      margin-left: 25px;
      width: 64px;

      @media only screen and (max-width: $mobileView) {
        margin-left: 0;
      }
    }

    .title-description {
      line-height: 24px;

      .title {
        font-size: 11px;

        @media only screen and (max-width: $mobileView) {
          font-size: 16px;
          line-height: 24px;
        }

        h3 {
          margin-bottom: 0;
          font-size: 16px;
          line-height: 24px;

          @media only screen and (max-width: $mobileView) {
            margin-top: 0;
          }
        }
      }

      .description {
        // width: 53%;
        display: block;
        font-family: $regularFont;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.transit-agency-list-main-wrapper {
  background-color: white;
  @media only screen and (max-width: $mobileView) {
    padding-top: 30px;
  }

  .transit-list {
    .title-description {
      padding-top: 24px;
    }
  }
}

.Transit-class {
  background-color: white;
  padding-bottom: 56px;
  width: 944px;
  margin: 0 auto;

  @media only screen and (max-width: $mobileView) {
    padding-bottom: 32px;
    width: unset;
  }

  .transit-agency-list-wrapper {
    width: 50%;
    border: 1px solid #dadada;

    @media only screen and (max-width: $mobileView) {
      width: unset;
      margin-left: 16px;
      margin-right: 16px;
    }

    .transit-list {
      @media only screen and (max-width: $mobileView) {
        margin-bottom: -1px;
      }
    }
  }

  .description {
    padding-top: 10px;
  }
}

.transit-agency-list-container {
  @media only screen and (max-width: $mobileView) {
    //margin-left: 20px;
    width: 100%;
  }

  .transit-agency-list-wrapper {
    background-color: #ffffff;
    padding-left: 20px;
    width: unset;
    border: 1px solid #dadada;
    padding-bottom: 25px;
    @media only screen and (max-width: $mobileView) {
      padding-right: 20px;
    }

    h2 {
      @media only screen and (max-width: $mobileView) {
        width: 95%;
      }
    }

    :last-child {
      border: none;
    }

    h2 {
      font-family: $boldFont;
      font-size: 22px;
      margin-top: 25px;
      padding-right: 24px;
    }

    .transit-list {
      font-family: $regularFont;
      margin-left: 0px;
      padding-left: 25px;

      @media only screen and (max-width: $mobileView) {
        padding-left: 0px;
        padding-bottom: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-right: 0;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      img {
        height: 60px;
        @media only screen and (max-width: $mobileView) {
          margin-bottom: 0px;
          flex-basis: 35%;
          margin-top: 0;
          margin-right: 0;
        }
      }

      .title-description {
        @media only screen and (max-width: $mobileView) {
          margin-bottom: 0px;
          flex-basis: 65%;
          margin-left: 8px;
        }

        .title {
          margin-top: 28px;

          @media only screen and (max-width: $mobileView) {
            margin-top: 12px;
            margin-bottom: 8px;
          }

          h3 {
            font-weight: normal;
          }
        }
      }
    }
  }
}
