@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.remove-card-container {
  margin: 0;
  padding-top: 16px;

  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
  }
}

.remove-card-step2 {
  border: 1px solid #d1d1d1;
  margin: -30px 0px;
  padding: 18px;
  background-color: #ffffff;
  margin-bottom: 10px !important;
  border-left: 4px solid $feedbackSuccess;

  @media only screen and (max-width: $mobileView) {
    margin: -30px -40px 0px 0px;
  }

  .success-cardId {
    margin-bottom: 20px;
  }

  .success-message {
    background: url('../../assets/images/Success.svg') no-repeat left top;
    // -webkit-print-color-adjust: exact;
    padding-bottom: 10px;
    line-height: 32px;

    @media only screen and (max-width: $mobileView) {
      border: none;
      padding-right: 10px;
    }

    #message {
      color: $feedbackSuccess;
      font-size: 18px;
      font-family: $boldFont;
      padding-left: 50px;

      @media only screen and (max-width: $mobileView) {
        margin-right: 12px;
      }
    }
  }
}

.manage-button {
  margin-bottom: 20px;
}

.error-block {
  padding: 18px;
  border: 1px solid #e8e8e8;
  border-left: 4px solid #d63d3d;
  background-color: #ffffff;
  margin: -30px 0px 10px 0px;

  .content-error-block-title {
    color: $feedbackError;
    font-size: 19px;
    font-family: $boldFont;
    padding-left: $spacing-5;
    line-height: 32px;
    margin-bottom: $spacing-1;
    background: url('../../assets/images/error.svg') no-repeat left top;
  }

  @media only screen and (max-width: $mobileView) {
    margin-top: -30px;
    padding: 10px;
  }
}

.error-text {
  font-family: $regularFont;
  margin-top: 30px;

  @media only screen and (max-width: $mobileView) {
    margin-top: 25px;
  }
}

.remove-card-steps {
  @media only screen and (max-width: $mobileView) {
    font-size: 0px;
    margin: 0px;
  }
}

.remove {
  &-contactless {
    margin-left: -80px;
    margin-top: 40px;
    margin-right: -1px;

    @media only screen and (max-width: $mobileView) {
      margin-right: -35px;
    }

    .remove-contactless-header {
      align-items: center;
    }

    &-image {
      display: flex;
      position: relative;
    }
  }
}

.remove-contactless-title {
  font-family: $boldFont;
  font-size: 40px;
  margin: 20px 0px 50px 80px;
  line-height: 72px;

  @media only screen and (max-width: $mobileView) {
    font-size: 22px;
    margin: 0px 0px 30px 0px;
    text-align: center;
  }

}

.remove-contactless-details {
  margin-left: 80px;
  margin-top: -32px;
  margin-bottom: 30px;

  @media only screen and (max-width: $mobileView) {
    margin-right: 5px;
  }

  .name {
    @media only screen and (max-width: $mobileView) {
      width: 92%;
    }
  }
}

.name {
  font-family: $boldFont;
  text-decoration: underline;
}

.card-id {
  border-top: 1px solid #d1d1d1;
  padding: 12px 0px 12px 0px;

  @media only screen and (max-width: $mobileView) {
    margin-right: 30px;
  }
}

.card-details-contactless {
  font-family: $boldFont;
  border-bottom: 1px solid #d1d1d1;
  padding-left: 370px;
  margin-top: -35px;

  @media only screen and (max-width: $mobileView) {
    margin-right: 30px;
    padding: 25px 0px 0px 0px;
  }
}

.button-block {
  @media only screen and (max-width: $mobileView) {
    margin: 0px -5px 0px -80px;
  }
}

.text-contactless {
  font-family: $regularFont;

  @media only screen and (max-width: $mobileView) {
    padding: 0px 20px 0px 0px;
  }
}