@import '../../assets/scss/vars';
@import '../../assets/scss/mixins.scss';

.cardBlockComponent {
  background-color: $white;
  border: 1px solid $secondary10;
  margin-bottom: $spacing-1;

  &-title {
    font-size: 20px;
    margin: 0;
    padding: 18px 16px 14px;
    border-bottom: 1px solid $secondary10;
    @media only screen and (min-width: $mobileView) {
      font-size: 22px;
      padding: $spacing-1 $spacing-2;
    }
    &.successIcon {
      background: url('../../assets/images/Success.svg') no-repeat 95% 14px;
    }
    &.errorIcon {
      background: url('../../assets/images/error.svg') no-repeat 95% 14px;
    }
    &.successIcon,
    &.errorIcon {
      padding-right: $spacing-7;
      @media only screen and (min-width: $mobileView) {
        padding-right: $spacing-8;
      }
    }
  }
  &-titleSection-right {
    position: relative;

    &-text {
      position: absolute;
      right: $spacing-1;
      top: $spacing-1;
      @include fontSize-18;
      font-family: $boldFont;
      color: $feedbackError;
      @media only screen and (min-width: $mobileView) {
        right: $spacing-2;
        @include fontSize-22;
      }
    }
  }

  &-content {
    padding: $spacing-1;
    line-height: 24px;
    font-family: $regularFont;

    & p:first-child {
      margin-top: 0;
    }

    @media only screen and (min-width: $mobileView) {
      padding: $spacing-2;
    }
  }

  .cardBlockComponent-title{
    text-align: left;
  }
}
