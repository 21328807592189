@import '../../assets/scss/vars';
.value-row {
  padding: $spacing-1 0;
  font-size: 16px;
  line-height: 24px;
  border-top: 1px solid $secondary20;

  &:first-of-type {
    border: none;
  }

  @media only screen and (min-width: $mobileView) {
    display: flex;
  }

  div:last-child {
    @media only screen and (min-width: $mobileView) {
      text-align: right;
      padding-left: $spacing-base;
    }
  }

  .right-cell {
    flex: 1 1;
  }
  .left-cell {
    font-family: $regularFont;
  }

  .isBold {
    font-family: $boldFont;
  }

  &.disableTopPadding {
    padding-top: 0;
  }

  &.addBottomBorder {
    border-bottom: 1px solid $secondary20;
  }
}
