@import '../../assets/scss/vars';
@import '../../assets/scss/mixins.scss';

.features-list-info {
  display: flex;
  font-weight: bold;
  flex-direction: column;
  padding: $spacing-2;
  margin-top: $spacing-2;
  border: 1px solid $black;

  .features-list {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    font-family: $regularFont;
    line-height: 21px;
    @media only screen and (min-width: $mobileView) {
      flex-direction: row;
    }

    .list {
      display: flex;
      flex-direction: column;
      flex: 1;

      li {
        @include fontSize-14;
        font-weight: normal;
      }
    }
  }
}
