@import '../../assets/scss/vars.scss';

.warning {
    background-color: $white;
    border: 1px solid $secondary10;
    border-left: 4px solid $feedbackWarning;
    font-family: $regularFont;
    padding: $spacing-2 $spacing-1;

    @media only screen and (min-width: $mobileView) {
        padding: $spacing-2;
    }

    &-title {
        align-items: flex-start;
        display: flex;
        font-family: $boldFont;
        font-size: 18px;
        line-height: 28px;     
        margin-bottom: $spacing-1;

        @media only screen and (min-width: $mobileView) {
            font-size: 22px;
            line-height: 32px;   
        }
    }

    &-titleText {
        margin-top: 2px;

        @media only screen and (min-width: $mobileView) {
            margin-top: 0;
        }
    }

    &-titleImg {
        margin-right: $spacing-base;

        @media only screen and (min-width: $mobileView) {
            margin-right: $spacing-1;
        }
    }
}