@import '../../assets/scss/vars.scss';

.accordion_demo_Section {
  background-color: $white;
  font-size: $spacing-1;
  margin-bottom: $spacing-1;
  border: 1px solid #e8e8e8;
  padding-bottom: 0;
  margin-right: 20%;
  @media only screen and (max-width: 768px) {
    margin: 0 1.5rem 1rem 1.5rem;
    padding-bottom: 0.5rem;
  }

  .accordionHeading {
    display: flex;
    justify-content: space-between;
    // padding: $spacing-1 $spacing-base $spacing-1 $spacing-1;
    align-items: center;
    width: 100%;
    box-shadow: none;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      padding: 0;
    }
    img {
      margin-left: 2rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      @media only screen and (min-width: 768px) {
        float: left;
      }
      @media only screen and (max-width: 768px) {
        padding: 0;
        margin-left: 0;
      }
    }

    img.up {
      transform: rotate(180deg);
    }
    .accordionContainer__imageHolder {
      width: 40%;
      margin: 0;
      @media only screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
      img {
        @media only screen and (max-width: 768px) {
          margin-top: 2rem;
          width: 10.3125rem;
        }
      }
    }

    .accordionContainer__paragraph {
      font-family: 'AvenirNextLTPro-Regular', sans-serif;
      font-size: 1.125rem;
      margin-top: auto;
      margin-bottom: auto;
      text-align: left;
      width: 35%;
      line-height: 2rem;
      @media only screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        margin: 1rem 0 1rem 0;
        font-size: 1rem;
        line-height: 1.75rem;
      }
    }

    .accordionContainer__accordionButton {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 1.5rem;
      width: 25%;
      text-align: right;
      text-decoration: underline;
      cursor: pointer;
      @media only screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        font-size: 1rem;
        color: #191919;
        margin-bottom: 1.5rem;
        margin-right: 0;
      }
    }
  }

  .accordionContent {
    font-family: $regularFont;
    color: $black;
    transition-delay: 1s;
    padding: $spacing-1 $spacing-1 $spacing-2;
    @media (max-width: 768px) {
      padding: 0;
      font-size: 0.875rem;
      text-align: left;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .row {
      @media (max-width: 768px) {
        flex-direction: column;
        .instruction_Container_Vertical {
          #instructionimage {
            padding-top: 0;
            height: auto;
            img {
              margin: 0 auto;
              width: 27%;
              height: auto;
            }
          }
          .text-container {
            #instructionTitle {
              font-size: 0.875rem;
              margin-top: 1rem;
              margin-bottom: 0;
              line-height: 1.5rem;
              font-weight: bold;
              font-family: $boldFont;
            }
            #instructionContainer {
              font-size: 0.875rem;
              line-height: 1.5rem;
              margin-top: 0;
              margin-bottom: 0.85rem;
              font-family: $regularFont;
            }
          }
        }
      }
    }
    .accordianLink {
      text-align: right;
      font-size: 1rem;
      @media (max-width: 768px) {
        text-align: center;
        margin-top: 1.5rem;
      }
    }

    div p {
      margin: 0 0 $spacing-1 0;
    }

    hr {
      margin: 0 0 $spacing-1;
    }

    div {
      p:last-of-type,
      li:last-of-type {
        margin: 0;
      }
    }
    .dividerlarge {
      hr {
        border: 1px solid #d1d1d1;
        border-bottom: 0px;
      }
    }
    @media only screen and (min-width: $mobileView) {
      padding: $spacing-2;
    }
  }

  @media screen and (max-width: $mobileView) {
    .support-faq-table tr {
      display: flex;
      flex-direction: column;
    }
    .support-faq-table tr:not(:first-child) {
      margin-top: $spacing-3;
    }
    .support-faq-table tr td:not(:first-child) {
      margin-top: $spacing-1;
    }
    .support-faq-td-45,
    .support-faq-td-10 {
      width: 100%;
    }
  }

  @media screen and (min-width: $mobileView) {
    .support-faq-table tr {
      display: table-row;
      flex-direction: row;
      margin-bottom: $spacing-3;
    }
    .support-faq-table tr td {
      padding-bottom: $spacing-3;
    }
    .support-faq-td-45 {
      width: 40%;
    }
    .support-faq-td-10 {
      width: 20%;
    }
  }

  .support-faq-table {
    width: 85%;
  }
  .support-faq-table tr td {
    text-align: center;
  }
  .support-faq-td-45,
  .support-faq-td-10 {
    text-align: center;
  }
}
