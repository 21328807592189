@import '../../assets/scss/vars.scss';

.cancelAutorenew {
  h2 {
    margin-bottom: $spacing-3;
    font-size: 18px;
    font-family: $regularFont;
  }

  .note-top-margin {
    margin-top: $spacing-1;
  }

  .success-block {
    border-bottom: none;
    .success-block-title {
      @media only screen and (max-width: $mobileView) {
        line-height: 28px;
      }
    }
    .success-context-text {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 24px;
      @media only screen and (max-width: $mobileView) {
        margin-top: 8px;
      }
    }
    .transitPassItem-wrapper {
      @media only screen and (min-width: $mobileView) {
        padding: 16px 0 24px 0px;
        .hide-ttc-logo {
          padding: 0 16px 0 0;
        }
      }
    }
    .transitPassItem-wrapper:nth-child(2) {
      padding-left: 90px;
    }
    .TTCCancelPassItem-wrapper {
      @media only screen and (min-width: $mobileView) {
        padding: 0px 0 0 96px;
      }
    }
  }
}

.cancelautorenew2-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  flex-direction: row-reverse;

  @media only screen and (max-width: $mobileView) {
    display: block;
    margin: 0;
    margin-top: 24px;
    #cancelautorenew2-printicon {
      display: none;
    }
  }
}
