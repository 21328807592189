@import '../../assets/scss/vars.scss';

.myProductsOverview {
  &-notificationText {
    font-size: 18px;
    font-family: $boldFont;
  }

  &-list {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  &-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .transit-passes-container {
      margin-top: 10px;

      .hr_line--1px {
        @media only screen and (max-width: $mobileView) {
          margin: 10px 0 16px 0;
        }
      }

      .myProductsOverview-transitPasses {
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        @media only screen and (min-width: $mobileView) {
          flex-direction: row;
          align-items: center;
        }
        p {
          margin: 0px;
          font-family: 'AvenirNextLTPro-Regular';
          font-size: 16px;
        }

        .myProductsOverview-transitPasses--icons {
          flex-grow: 1;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          width: 134px;

          @media only screen and (min-width: $mobileView) {
            display: flex;
            //flex-direction: row-reverse;
            justify-content: flex-end;
          }

          img {
            width: 32px;
            margin: 0 0 0 20px;
            font-size: 5px;
            line-height: 8px;
            height: 32px;
            @media only screen and (max-width: $mobileView) {
              margin: 0 20px 0 0;
            }
          }
        }
      }
    }

    .myProductsOverview-textWrapper {
      max-width: 200px;
      word-wrap: break-word;
      @media only screen and (min-width: $mobileView) {
        display: flex;
        flex-grow: 1;
        max-width: inherit;
        padding-bottom: 5px;
        padding-top: 22px;
      }
    }

    @media only screen and (min-width: $mobileView) {
      a {
        max-width: 350px;
        word-wrap: break-word;
        text-underline-position: from-font;
        @media only screen and (max-width: $mobileView) {
          text-underline-position: from-font;
        }
      }
    }
  }

  &-cardListItem {
    align-items: center;
    background-color: $white;
    display: flex;
    font-size: 20px;
    line-height: 32px;
    padding: $spacing-1;

    @media only screen and (min-width: $mobileView) {
      font-size: 22px;
      padding: $spacing-1 $spacing-2;
    }

    &:not(:first-child) {
      margin-top: $spacing-base;
    }

    > img {
      margin-right: $spacing-1;
      align-self: baseline;
      margin-top: 10px;
    }
  }

  &-cardBalance {
    font-family: $boldFont;
    margin-left: auto;
    margin-right: 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }

  &-headerIconLink {
    margin-left: $spacing-2;
    text-decoration: none;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      text-decoration: underline;
    }

    .mediaImage-small {
      margin-bottom: -$spacing-base;
      margin-right: $spacing-base;
    }
  }

  &-headerIconsWrapper {
    margin: 0 0 $spacing-base auto;
  }

  &-buttonsSeparator {
    margin: $spacing-2 0;
    border: 0;
    border-bottom: 1px solid $black;
  }
  
  .prestoIcon .mediaImage-small{
    margin-bottom: -4px;
  }

  .prestoIcon{
    display:inline-block;
  }
}
