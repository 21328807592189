@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.deactivate-new-shipping-addr-block {
  padding: 18px 0px 50px 0px;
  background-color: white;
  border: 2px solid #e8e8e8;
  width: 38rem;
  margin-left: 10px;
  margin-top: 20px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
    margin-left: auto;
  }

  .deactivate-new-shipping-header {
    font-size: 18px;
    padding: 0px 51px 21px 24px;
    border-bottom: 2px solid #d1d1d1;

    @media only screen and (max-width: $mobileView) {
      padding: 0px 21px 21px 15px;
      font-size: 23px;
    }
  }

  .formContent {
    display: flex;
    padding: 12px 30px 0px 30px;

    @media only screen and (max-width: $mobileView) {
      width: initial;
      display: block;
      padding: 0;
    }

    .shipping-curr-addr {
      width: 331px;
      padding-top: 15px;
      padding-left: 0;

      .radioInput label {
        display: block;
        width: 28px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #6e6e6e;
        margin-top: 4px;

        @media only screen and (max-width: $mobileView) {
          display: block;
          width: 24px;
          height: 18px;
          border-radius: 50%;
          border: 1px solid #6e6e6e;
          margin-top: 4px;
        }
      }

      .radioInput input[type='radio']:checked + label {
        width: 9px;
        height: 6px;
        border: 7px solid #191919;
        margin-top: 4px;

        @media only screen and (max-width: $mobileView) {
          width: 9px;
          height: 7px;
          border: 7px solid #191919;
          margin-top: 6px;
        }
      }

      .radioInput-label {
        padding-right: 40px;
        font-size: 14px;

        @media only screen and (max-width: $mobileView) {
          padding-right: 0;
          font-size: 21px;
          padding-left: 6px;
        }
      }

      .addressLine {
        width: 200px;
        margin-left: 30px;
        margin-top: 16px;

        @media only screen and (max-width: $mobileView) {
          width: auto;
          margin-left: 36px;
          margin-top: 28px;
        }
      }

      .shipping-curr-details {
        padding-left: 32px;
        padding-top: 10px;
        padding-right: 40px;
        font-family: 'AvenirNextLTPro-Regular';
        font-size: 14px;

        p {
          margin: 0;
        }

        @media only screen and (max-width: $mobileView) {
          padding-top: 14px;
          padding-left: 36px;
          font-size: 21px;
        }
      }

      @media only screen and (max-width: $mobileView) {
        width: auto;
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 21px;
      }
    }

    .shipping-diff-addr {
      width: 345px;
      padding-top: 15px;

      @media only screen and (max-width: $mobileView) {
        width: auto;
        display: block;
        padding-top: 2.5rem;
        padding-left: 15px;
        padding-right: 15px;
      }

      .radioInput label {
        display: block;
        width: 32px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #6e6e6e;
        margin-top: 4px;

        @media only screen and (max-width: $mobileView) {
          display: block;
          width: 32px;
          height: 18px;
          border-radius: 50%;
          border: 1px solid #6e6e6e;
          margin-top: 4px;
        }
      }

      .radioInput input[type='radio']:checked + label {
        width: 9px;
        height: 6px;
        border: 7px solid #191919;
        margin-top: 4px;

        @media only screen and (max-width: $mobileView) {
          width: 9px;
          height: 7px;
          border: 7px solid #191919;
          margin-top: 6px;
        }
      }

      .radioInput-label {
        padding-right: 40px;
        font-size: 14px;

        @media only screen and (max-width: $mobileView) {
          padding-right: 0;
          font-size: 21px;
          padding-left: 6px;
        }
      }

      .addressLine {
        width: 150px;
        padding-left: 52px;
        margin-top: 16px;
        margin-left: 30px;

        @media only screen and (max-width: $mobileView) {
          width: auto;
          margin-left: 32px;
          margin-top: 28px;
        }
      }

      .shipping-diff-details {
        padding-left: 32px;
        padding-top: 10px;
        padding-right: 40px;
        font-family: 'AvenirNextLTPro-Regular';
        font-size: 14px;

        @media only screen and (max-width: $mobileView) {
          width: auto;
          padding-top: 14px;
          padding-left: 36px;
          font-size: 21px;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .shipping-details-header {
    font-size: 21px;
    padding-left: 20px;
    padding-bottom: 14px;
    border-bottom: 2px solid #d1d1d1;
  }

  .shipping-title {
    font-family: 'AvenirNextLTPro-Regular';
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 24px;
  }

  .required {
    font-family: 'AvenirNextLTPro-Regular';
    padding-left: 20px;
  }

  .shipping-details-content {
    padding-left: 20px;
    padding-right: 20px;

    .shipping-username {
      display: flex;
      // width: 450px;
      margin: 1.5rem 0rem 1.5rem 0rem;

      @media only screen and (max-width: $mobileView) {
        width: initial;
        display: block;
      }

      .first-name {
        width: 17rem;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }

        .first-name-field {
          margin-top: -25px;

          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }
      }

      .last-name {
        width: 18rem;
        margin-left: 1rem;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }

        .last-name-field {
          margin-top: -25px;

          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }

        @media only screen and (max-width: $mobileView) {
          margin-left: 0;
          margin-top: 25px;
        }
      }
    }

    .shipping-address {
      display: flex;

      // width: 450px;
      @media only screen and (max-width: $mobileView) {
        width: initial;
        display: block;
      }

      .street-add {
        width: 24rem;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }

        .street-add-field {
          margin-top: -25px;

          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }
      }

      .apartment {
        margin-left: 1rem;
        width: 11rem;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }

        .apartment-field {
          margin-top: -25px;

          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }

        @media only screen and (max-width: $mobileView) {
          margin-left: 0;
          margin-top: 25px;
        }
      }
    }

    .shipping-province {
      display: flex;
      margin: 1.5rem 0rem 1.5rem 0rem;

      @media only screen and (max-width: $mobileView) {
        width: initial;
        display: block;
      }

      .city {
        width: 18rem;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }

        .city-field {
          margin-top: -25px;

          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }
      }

      .province {
        margin-left: 1rem;
        width: 13rem;

        .province-field {
          margin-top: 7px;

          @media only screen and (max-width: $mobileView) {
            margin-top: 6px;
            width: initial;
          }
        }

        @media only screen and (max-width: $mobileView) {
          margin-left: 0;
          margin-top: 25px;
        }
      }
    }

    .shipping-country-code {
      display: flex;

      @media only screen and (max-width: $mobileView) {
        width: initial;
        display: block;
      }

      .country {
        width: 14rem;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }

        .country-field {
          margin-top: -25px;

          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }
      }

      .postal-code {
        margin-left: 1rem;
        width: 14rem;

        @media only screen and (max-width: $mobileView) {
          width: auto;
        }

        .postal-code-field {
          margin-top: -25px;

          @media only screen and (max-width: $mobileView) {
            margin-top: -18px;
          }
        }

        @media only screen and (max-width: $mobileView) {
          margin-left: 0;
          margin-top: 25px;
        }
      }
    }

    .PostalCodeNote {
      margin: 6px 0px 0px 10px;
      font-family: 'AvenirNextLTPro-Regular';
      font-size: 14px;
    }
  }
}

.deactivate-new-note {
  margin-top: 16px;
  width: 38rem;
  padding-right: 0px;
  padding-left: 5px;
  margin-left: 26px;
}

.deactivate-parent {
  .Deactivated-Button {
    justify-content: end;
    flex-direction: row-reverse;
    margin-top: 16px;
    @media only screen and (max-width: $mobileView) {
      width: auto;
      padding-right: 0;
    }

    .buttonComponent:nth-child(2) {
      background: #fff;
      color: #000;
    }
  }

  .note {
    margin-left: 10px;
    padding: 16px 8px 16px 12px;
    width: 37rem;

    @media only screen and (max-width: $mobileView) {
      margin-left: 0px;
      margin-right: 0px;
      width: auto;
    }
  }
}
