@import '../../../assets/scss/vars';

.contactlessTransactionGridItem {
  background: $white;
  border: 1px solid $secondary10;
  margin-bottom: $spacing-base;

  &-head {
    padding: $spacing-1;
    position: relative;
    display: flex;
    @media only screen and (min-width: $mobileView) {
      padding: $spacing-2 $spacing-1 $spacing-2 $spacing-2;
    }
  }

  &-right {
    display: flex;
    width: 100%;
  }

  &-control {
    display: flex;
    align-items: center;
    margin-left: $spacing-1;
  }

  &-title {
    line-height: 24px;
  }

  &-subTitle {
    font-family: $regularFont;
    font-size: 12px;
    line-height: 21px;
    font-style: italic;
  }

  &-remove {
    font-family: $regularFont;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
  }

  &-block {
    margin-bottom: $spacing-base;
    flex: 1;
    align-items: center;
    display: flex;
  }

  &-value-block {
    text-align: right;
    @media only screen and (min-width: $mobileView) {
      display: flex;
      align-items: center;
      align-self: flex-start;
    }
  }

  &-more {
    border-top: 2px solid $secondary10;
  }

  &-pendingfare {
    text-align: right;
    font-family: $regularFont;
    font-size: $fontSize-12;
    font-style: italic;
    border-top: 1px solid $secondary10;
    padding: $spacing-base $spacing-2;
  }

  &-fare {
    text-align: right;
    font-family: $regularFont;
    font-size: 12px;
    text-transform: uppercase;
    font-style: italic;
    border-top: 1px solid $secondary10;
    padding: $spacing-base $spacing-2;
  }

  &.state-unpaidFare {
    border-left: 4px solid $feedbackError;

    .contactlessTransactionGridItem-total {
      color: $feedbackError;
    }

    .contactlessTransactionGridItem-unpaidFare-header {
      display: flex;
    }
  }

  &-more-message {
    line-height: 21px;
    font-size: 14px;
    font-family: $regularFont;
    padding: $spacing-1 $spacing-2;
    border-bottom: 1px solid $secondary10;
  }

  &-unpaidFare-header {
    display: none;
    height: 48px;
    align-items: center;
    padding: 0 $spacing-2;
    border-bottom: 1px solid $secondary10;
    color: $feedbackError;
    line-height: 24px;
  }

  &-unpaidFare-header-link {
    margin-left: auto;
    color: $black;
  }

  &-unpaidFare-header-icon {
    width: 24px;
    height: 24px;
    margin-right: $spacing-base;
  }
}
