@import '../../assets/scss/vars.scss';

.add-card-wrapper {
  text-align: left;

  @media only screen and (min-width: $mobileView) {
    color: $black;
  }

  .add-card-header {
    font-family: $boldFont;
    font-size: 32px;
    color: $black;
    line-height: 48px;
    padding: 16px 0 16px 24px;

    @media only screen and (min-width: $mobileView) {
      font-size: 48px;
      text-align: center;
      line-height: 72px;
      padding: 32px 0;
    }
  }

  // TODO refactor this
  .add-card-step2 .add-card-content {
    padding-bottom: 0;
  }

  // TODO refactor this
  .add-card-step2 .add-card-note {
    margin: 0;
  }

  // TODO refactor this
  .add-card-step2 .add-card-input {
    @media only screen and (min-width: $mobileView) {
      padding-left: 0 !important;
      padding-right: 32px !important;
    }
  }

  .add-card-step2 .add-card-form-wrapper {
    padding: 0 16px;
    padding-bottom: 16px;

    @media only screen and (min-width: $mobileView) {
      padding: 0 24px;
      padding-bottom: 24px;
    }
  }

  .break {
    background-color: $secondary5;
    border: 1px solid $secondary5;
    border-top: 1px solid $secondary10;
    margin: -1px;
  }

  .add-card {
    background-color: $white;

    &.inside-buttons {
      margin: 0 0 40px 0;
    }

    border: 1px solid $secondary10;

    @media only screen and (min-width: $mobileView) {
      width: 624px;
    }

    &-title {
      font-family: $boldFont;
      font-size: 20px;
      line-height: 32px;
      border-bottom: 1px solid $secondary10;
      text-align: initial;
      padding: 16px;

      @media only screen and (min-width: $mobileView) {
        font-size: 22px;
        padding: 16px 0 16px 24px;
      }
    }

    &-note {
      margin: 16px;
      @media only screen and (min-width: $mobileView) {
        margin: 16px 20px 16px 28px;
      }
    }

    &-line {
      hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid $secondary20;
        padding: 0;
        margin: 0px;
        margin-bottom: 16px;
      }

      @media only screen and (min-width: $mobileView) {
        hr {
          margin: 0;
          margin-top: 24px;
          margin-bottom: 16px;
        }
      }
    }

    &-additional {
      font-family: $boldFont;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 16px;

      @media only screen and (min-width: $mobileView) {
        font-size: 22px;
        margin: 16px 20px 16px 0;
      }
    }

    &-bottom {
      &-text {
        font-family: $regularFont;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        padding: 16px 0 0 0;

        @media only screen and (min-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    &-content {
      padding: $spacing-1;

      @media only screen and (min-width: $mobileView) {
        padding: $spacing-2;
      }

      &-text {
        display: flex;
        font-family: $regularFont;
        font-size: 16px;
        padding: 16px 0;
        padding-top: 0;
        line-height: 24px;
        @media only screen and (min-width: $mobileView) {
          padding: 0;

          div p:first-child {
            margin-top: 0;
          }

          div p:last-child {
            margin-bottom: 0;
          }
        }
      }

      &-image {
        padding-top: 16px;
      }

      &-logo {
        margin-top: 4px;
        margin-right: $spacing-2;
      }

      &-form {
        .text-field {
          text-align: initial;
          display: inline-block;
          margin: 0;
          padding-top: 16px;
        }

        .associated-to-card {
          display: flex;
          flex-direction: column;
        }

        .add-card-input {
          .error-message {
            max-width: 216px;
          }

          width: 100%;
          min-height: 106px;
          padding: 0;

          @media only screen and (min-width: $mobileView) {
            display: inline-block;
            max-width: 256px;
            vertical-align: top;
            padding: 0 16px;
          }

          &.add-card-spacing {
            @media only screen and (min-width: $mobileView) {
              padding: 0;
              &:nth-child(odd) {
                margin-right: $spacing-7;
              }
            }
          }
        }

        input {
          @media only screen and (min-width: $mobileView) {
            width: 256px;
          }
        }
      }
    }
  }
}

.mobile-button-line {
  background-color: $secondary20;
  height: 1px;
  width: 100%;
  margin-top: 16px;

  @media only screen and (min-width: $mobileView) {
    display: none;
  }
}
