@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.manageMyCards {

  // padding-top: 56px;
  // width: 91%;
  // @media only screen and (max-width: $mobileView) {
  //   margin-left: 20px;
  // }
  &-mediaItem {
    margin-bottom: 20px;

    &.isContactless {
      .manageMyCards-mediaItem-header {
        align-items: center;
      }
    }

    &-mediaWrapper {
      flex-shrink: 0;
      margin-right: $spacing-base;
      display: flex;
      position: relative;
      top: 2px;

      @media only screen and (min-width: $mobileView) {
        display: block;
        margin: (-$spacing-base) $spacing-2 (
          -$spacing-1) 0;
      }

      @media only screen and (max-width: $mobileView) {
        top: auto;
        // bottom: 18px;
      }

      .mediaImage-big {
        max-width: 24px;

        @media only screen and (min-width: $mobileView) {
          max-width: 64px;
        }
      }
    }

    &-header {
      align-items: start;
      display: flex;
      flex-grow: 1;
      cursor: pointer;

      @media only screen and (min-width: $mobileView) {
        align-items: center;
      }

      &-cardInfo {
        flex-grow: 1;

        @media only screen and (min-width: $mobileView) {
          display: flex;
        }

        >div {
          flex-grow: 1;
          align-self: center;
        }
      }

      &-cardInfoName {
        font-family: $boldFont;
        text-decoration: underline;
      }

      &-cardInfoNumber,
      &-cardInfoPrimaryFlag {
        @include fontSize-14;

        @media only screen and (min-width: $mobileView) {
          @include fontSize-16;
        }
      }

      &-cardBalance {
        margin-top: $spacing-base;

        @media only screen and (min-width: $mobileView) {
          margin-top: 0;
          text-align: right;
        }
      }

      &-cropAndScaleImg {
        position: relative;
        width: 16px;
        height: 16px;
        overflow: hidden;
        display: inline-block;
        margin-left: $spacing-base;

        img {
          height: 24px;
          width: 24px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%
          );
      }
    }

    &-toggleBtnWrapper {
      margin-left: $spacing-2;

      img {
        width: 24px;
        height: 24px;
        margin: 0;
      }

      @media only screen and (min-width: $mobileView) {
        margin-top: 5px;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  &-collapsibleContent {
    margin-left: $spacing-3;

    @media only screen and (min-width: $mobileView) {
      margin-left: $spacing-10;
    }
  }
}

&-mediaWithText {
  display: flex;
  align-items: top;

  @media only screen and (min-width: $mobileView) {
    align-items: center;
  }

  .mediaImage-big {
    width: 48px;

    @media only screen and (min-width: $mobileView) {
      width: 64px;
    }
  }
}

&-mediaWrapper {
  flex-shrink: 0;
  margin-right: $spacing-1;

  @media only screen and (min-width: $mobileView) {
    margin-right: $spacing-2;
  }
}

&-mediaHeader {
  flex-shrink: 0;
  display: flex;
}

.mediaImageList {
  margin: $spacing-1 0;

  img {
    padding-bottom: 0;
  }
}

.modal-wrapper {
  .modal-text {
    a {
      @media only screen and (max-width: $mobileView) {
        @supports (-webkit-touch-callout: none) {

          /* CSS specific to iOS devices */
          button:focus:not(:focus-visible) {
            outline: none;
          }
        }
      }

      img {
        margin-left: 5px;
      }
    }
  }
}
}

.fare-type-expiration {
  .value-row {
    border-top: 1px solid #d1d1d1;
    justify-content: space-between;
  }

  .expiration-button {
    text-decoration: underline;

    @media only screen and (max-width: $mobileView) {
      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        color: #191919;
      }
    }
  }

  button:focus:not(:focus-visible) {
    outline: none;
  }
}