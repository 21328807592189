@import '../../assets/scss/vars';

.button-wrapper {
  .print-button {
    display: none;
  }

  @media only screen and (min-width: $mobileView) {
    display: flex;
    margin-top: 16px;

    .print-button {
      font-family: $boldFont;
      font-size: 16px;
      cursor: pointer;
      display: block;
      order: -1;
      margin-right: 44px;
      height: 24px;
      padding: 0 0 0 32px;
      border: none;
      background: transparent url('../../assets/images/printer.svg') 0 center no-repeat;
      // &:focus {
      //   outline: none;
      // }
    }
  }
}
