@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.deactivated-card-withButton {
  width: 39rem;
  @media only screen and (max-width: $mobileView) {
    width: auto;
  }
  .deactivated-card-block {
    background-color: white;
    padding: 20px 0px 20px 0px;
    @media only screen and (max-width: $mobileView) {
      width: auto;
    }
    .card-content {
      .card-Number {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }
      .card-nick-name {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }
      .card-balance {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;

        b {
          flex-basis: 55%;
        }
      }
      .card-transitPass {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
        .transitPass {
          text-align: end;
        }
      }
    }
  }

  .deactivate-print {
    display: flex;
    justify-content: space-between;
    width: 39rem;
    flex-direction: row-reverse;
    margin-top: 16px;
    @media only screen and (max-width: $mobileView) {
      width: auto;
      display: block;
    }
    .icon-button {
      display: flex;
      padding-top: 8px;
      height: 24px;
      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
    .manage-button {
      // padding-top: 14px;
      // padding-bottom: 100px;
      @media only screen and (max-width: $mobileView) {
        padding-top: 0px;
      }
    }
  }

  .success-block {
    width: 36rem;
    @media only screen and (max-width: $mobileView) {
      width: auto;
    }
  }

  .success-context-text {
    @media only screen and (max-width: $mobileView) {
      padding-left: 50px;
    }
  }

  .content-error-block {
    .content-error-block-text-margin {
      margin-left: 0px;
      @media only screen and (max-width: $mobileView) {
        margin-left: 48px;
      }
    }
  }
}
