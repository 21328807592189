@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.transfer-card-withButton {
  width: 39rem;
  @media only screen and (max-width: $mobileView) {
    width: auto;
  }
  .deactivate-card-section {
    margin-top: 16px;
    background-color: white;
    padding: 20px 0px 20px 0px;
    margin-bottom: 16px;
    @media only screen and (max-width: $mobileView) {
      width: auto;
    }
    .card-header {
      font-size: 18px;
      padding: 0px 20px 21px 20px;
      border-bottom: 2px solid #d1d1d1;
    }
    .card-content {
      padding-left: 20px;
      padding-right: 20px;
      .card-Number {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }
      .card-nick-name {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }
      .card-balance {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 2px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }
      .card-transitPass {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;

        p:last-child {
          margin-bottom: 0;
        }

        b {
          flex-basis: 55%;
        }
      }
    }
  }
  .deactivate-card-disclaimer {
    font-size: 14px;
    font-family: 'AvenirNextLTPro-Regular';
    padding: 26px 0px 10px 12px;
  }
  .Deactivated-Button {
    justify-content: end;
    flex-direction: row-reverse;
    @media only screen and (max-width: $mobileView) {
      width: auto;
      padding-right: 0;
    }
    .buttonComponent:nth-child(2) {
      background: #fff;
      color: #000;
    }
  }
}

.transitpass-list-RLC {
  margin-top: 0;
  font-family: $boldFont;
  text-align: right;
}
