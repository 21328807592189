@import '../../assets/scss/vars';

.selectbox {
  position: relative;

  .label {
    font-size: 14px;
    margin-bottom: $spacing-base;
    font-family: $boldFont;
  }

  .textField {
    width: 304px;
    max-width: 100%;
    transition: none;
    background-color: $secondary10;

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      border-bottom: 1px solid $black;
      transition: none;
    }

    .MuiAutocomplete-input {
      padding: 15px 0 14px 14px !important;
      font-family: $regularFont;
      font-size: 16px;
      color: #191919;
    }
  }

  .MuiAutocomplete-popupIndicator {
    margin-right: 3px;

    span {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    color: #191919;
    opacity: 1;
  }
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-paper {
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    color: $black;
    font-size: 16px;
    background-color: $secondary10;

    .MuiAutocomplete-option {
      padding-top: 13px;
      padding-bottom: 13px;
      font-family: $regularFont;

      &[aria-selected='true'],
      &[data-focus='true'] {
        background-color: $secondary30;
      }
    }
  }
}

.mobileSelect {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../../assets/images/down.svg');
  background-size: 24px;
  background-position: right 6px center, 0 0;
  background-repeat: no-repeat;
  background-color: $secondary10;
  min-height: 48px;
  border: none;
  border-bottom: 1px solid $black;
  padding-left: $spacing-1;
  font-size: 16px;
  font-family: $regularFont;
  color: $black;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: $spacing-2;

  .select-placeholder {
    color: $secondary30;
  }

  &:hover {
    cursor: pointer;
  }

  /* Hide arrow icon in IE browsers */
  &::-ms-expand {
    display: none;
  }

  option {
    font-weight: normal;
  }
  .option-disabled {
    color: graytext;
  }

  &:disabled,
  &[aria-disabled='true'] {
    color: graytext;
  }

  &:disabled:hover,
  &[aria-disabled='true'] {
    border-color: #aaa;
  }
}

.Mui-focused .MuiFormControl-root {
  border: solid 0.5px;
}

.selectbox {
  &.selectbox-error {
    .textField {
      .MuiInput-underline:before {
        border-bottom: 1px solid $feedbackError;
      }
      .MuiInput-underline:hover:before {
        border-bottom: 2px solid $feedbackError;
      }
    }
  }
}

.disbaled-country {
  .MuiFormControl-root.MuiTextField-root {
    background-color: #d1d1d1;
  }

  .selectbox .textField .MuiInput-underline:before {
    border-bottom: unset;
  }

  .selectbox .textField .MuiAutocomplete-input {
    color: darkgrey;
  }
}
