.digital-distribution-app-icon {
  display: flex;
  width: 100%;
  margin: 20px 0;

  .icon {
    max-width: 150px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
