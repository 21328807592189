@import '../../assets/scss/vars.scss';

.b2cPersonalInformation {
  width: 624px;
  margin: 0 auto;
  @media only screen and (max-width: $mobileView) {
    width: unset;
    margin: unset;
  }
  .three-steps {
    @media only screen and (max-width: $mobileView) {
      width: 320px;
    }
  }
  .usersub-info {
    text-align: center;
    font-family: $regularFont;
    margin-bottom: 40px;
  }
  .cardBlockComponent-content {
    padding: 40px;
    @media only screen and (max-width: $mobileView) {
      padding: 16px;
    }
  }
  .textinput-flex {
    .cityinput-wrapper {
      width: 50%;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
    .provinceinput-wrapper {
      width: 50%;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
    .countryinput-wrapper {
      width: 50%;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
    .postalinput-wrapper {
      width: 50%;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }
  .info-btn-flex {
    padding-bottom: 40px;
    @media only screen and (max-width: $mobileView) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  .b2caccountcreation-success-content {
    margin-top: 20px;
    font-family: 'AvenirNextLTPro-Regular';
  }
  .b2c-gotodashboard-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 45px;
  }
}
