@import '../../assets/scss/vars';

@mixin product {
  min-width: 132px;
  width: 100%;

  @media only screen and (min-width: $mobileView) {
    text-align: left;
    width: 224px;
  }
}

@mixin price-quantity {
  min-width: 62px;
  font-family: $boldFont;
  text-align: right;
}

.shoppingcart-wrapper {
  display: flex;

  @media only screen and (min-width: $mobileView) {
    position: relative;
  }

  .shoppingcart {
    position: absolute;
    display: block;
    background-color: $secondary10;
    z-index: $zindex-shoppingCartContent;
    right: 0;
    left: 0;
    top: 54px;
    min-height: 225px;
    border: 2px solid $black;
    padding: 32px 16px;

    .success-block {
      padding: 16px;
      margin: -12px 0 22px;

      @media only screen and (min-width: $mobileView) {
        margin: (-$spacing-base) 0 20px;
      }

      .success-block-title {
        font-size: 14px;
        line-height: 21px;
        background-size: 20px;

        @media only screen and (min-width: $mobileView) {
          margin: 0;
          padding: 0 10px 0 32px;
          text-align: left;
        }
      }
    }
    .shopping-cart-content {
      max-height: 350px;
      overflow-y: auto;
      .shoppingcart-item--wrapper {
        margin: 0px 16px;
        padding-bottom: 24px;
      }
      .shoppingcart-items:not(:last-child) {
        .shoppingcart-item--wrapper {
          border-bottom: 2px solid $secondary20;
        }
      }
    }

    @media only screen and (min-width: $mobileView) {
      top: $spacing-base;
      left: auto;
      width: 416px;
      padding: 24px;
      min-height: 212px;
    }

    .shoppingcart-quantity {
      @include price-quantity;

      @media only screen and (min-width: $mobileView) {
        width: 65px;
      }
    }

    .shoppingcart-price {
      @include price-quantity;

      @media only screen and (min-width: $mobileView) {
        width: 95px;
      }
    }

    .shoppingcart-subtotal {
      font-family: $boldFont;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      padding: 8px 0 12px;

      @media only screen and (min-width: $mobileView) {
        padding: 8px 0 24px;
      }
    }

    .shoppingcart-checkout {
      @media only screen and (min-width: $mobileView) {
        display: flex;
        justify-content: flex-end;
      }
    }

    .shoppingcart-close {
      @media only screen and (min-width: $mobileView) {
        display: none;
      }
    }

    .shoppingcart-nickname {
      text-align: left;
      font-size: 14px;
      line-height: 21px;
      margin: 16px 0 0;
    }

    .shoppingcart-title {
      font-family: $boldFont;
      font-size: 14px;
      line-height: 21px;
      padding: 12px 16px 8px;
      background-color: $white;
      border-bottom: 2px solid $secondary20;
      display: flex;

      .shoppingcart-product {
        @include product;
      }
    }

    .shoppingcart-items {
      background-color: $white;
      // padding: 16px 16px 24px;
      font-size: 14px;

      .shoppingcart-item {
        display: flex;
        padding: 16px 0 8px;

        .shoppingcart-product {
          font-family: $boldFont;
          @include product;
          &.regular-font {
            font-family: $regularFont;
          }
        }
      }

      .shoppingcart-remove {
        display: flex;

        div {
          font-family: $regularFont;
          font-size: 14px;
          color: $black;
          line-height: 21px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.shoppingcart-background-layer {
  position: absolute;
  display: block;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-shoppingCardWrapper;
  background-color: $black;
  opacity: 0.6;

  @media only screen and (min-width: $mobileView) {
    background-color: transparent;
  }
}
