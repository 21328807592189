@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.transfer-card-with-Button {
  width: 39rem;
  @media only screen and (max-width: $mobileView) {
    width: auto;
  }
  .transfer-card-Form {
    width: 39rem;
    background-color: white;
    padding: 20px 0px 20px 0px;
    @media only screen and (max-width: $mobileView) {
      width: auto;
    }
    .transfer-card-header {
      font-size: 18px;
      padding: 0px 20px 21px 20px;
      border-bottom: 2px solid #d1d1d1;
    }
    .transfer-input-block {
      margin: 20px 20px 20px 20px;
      padding-bottom: 20px;
      border-bottom: 2px solid #d1d1d1;
      .presto-number {
        width: 28rem;
        @media only screen and (max-width: $mobileView) {
          width: auto;
        }
        // .input-block-label{

        // }
        .input-block-field {
          margin-top: -22px;
          // .text-field .text-field-input{
          //     padding-top: 20px;
          //     border-bottom: none;
          // }
        }
        .input-lavel {
          margin-top: 10px;
          font-family: 'AvenirNextLTPro-Regular';
          margin-left: 15px;
        }
      }
      .verification-number {
        width: 20rem;
        margin-top: 26px;
        @media only screen and (max-width: $mobileView) {
          width: auto;
        }
        // .input-block-label{

        // }
        .input-block-field {
          margin-top: -22px;
          // .text-field .text-field-input{
          //     padding-top: 20px;
          //     border-bottom: none;
          // }
        }
      }
    }
    .checkout-terms-block{
      margin-left: 16px;
      padding-top: 0px;
      border-top: none;
    }
  }
  .transfer-Button {
    justify-content: end;
    flex-direction: row-reverse;
    @media only screen and (max-width: $mobileView) {
      width: auto;
      padding-left: 16px;
      padding-right: 16px;
    }
    div:nth-child(2) {
      .buttonComponent {
        background: #fff;
        color: #000;
      }
    }
  }
}
