@import '../../assets/scss/vars';

.button-wrapper {
  @media only screen and (min-width: $mobileView) {
    display: flex;
    margin-top: 16px;

    .button,
    .buttonComponent {
      margin-left: 16px;
    }

    &-right {
      margin-left: auto;
      display: flex;
    }

    &-center {
      margin: 0 auto;
      display: flex;
    }
  }
}

.button-order-revere {
  @media only screen and (min-width: $mobileView) {
    flex-direction: row-reverse;
  }
}
