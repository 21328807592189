@import '../../assets/scss/vars';

.row {
  display: flex;
}
.whiterow {
  background-color: white;
}
.column {
  flex: 50%;
}
.CTA-Linkable-Image {
  padding-top: 32px;
  //margin-left: 155px;
  width: 944px;
  padding-bottom: 48px;
  margin: 0 auto;
  @media only screen and (max-width: $mobileView) {
    margin-left: 0px;
    width: 100%;
    text-align: center;
  }

  div.column:first-child {
    //  margin-right: 24px;
    @media only screen and (max-width: $mobileView) {
      margin-right: unset;
      margin-bottom: 8px;
    }
  }
}
.Two-column-Hello {
  background-color: white;
  // padding-left: 155px;
  // padding-right: 200px;
  // margin-top: -16px;
  grid-column-gap: 16px;
  padding-top: 24px;
  padding-bottom: 56px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
    // margin-top: -19px;
    padding-top: 20px;
    padding-bottom: 32px;
  }
  .Image-with-para-hello {
    height: 315px;
    @media only screen and (max-width: $mobileView) {
      height: auto;
    }
  }

  .column {
    flex: unset;
    width: 224px;

    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
}
.Two-column-Hello-french {
  background-color: white;
  // padding-left: 155px;
  // padding-right: 200px;
  // margin-top: -16px;
  grid-column-gap: 15px;
  padding-top: 24px;
  padding-bottom: 56px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
    //  margin-top: -19px;
    padding-top: 20px;
    padding-bottom: 32px;
  }
  .Image-with-para-hello {
    height: 370px;
    @media only screen and (max-width: $mobileView) {
      height: auto;
    }
  }
}
.Two-column-HelloApp {
  //padding-left: 155px;
  //margin-top: -16px;
  grid-column-gap: 16px;
  padding-top: 24px;
  width: 944px;
  margin: 0 auto;
  padding-bottom: 48px;

  .column {
    flex: unset;
    width: 304px;
    max-width: unset;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
    // margin-top: -19px;
    padding-top: 16px;
    padding-bottom: 32px;
    width: unset;
    &.row {
      display: block !important;
    }
    .row {
      display: block !important;
    }
  }
}
.get-presto-ready-white {
  background-color: #fff;
  @media only screen and (max-width: $mobileView) {
    padding: 24px 16px 32px;
  }
  .Twocolumn-UsingPresto {
    background-color: white;
    width: 944px;
    margin: 0 auto;
    padding-top: 48px;
    padding-bottom: 48px;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .column:first-child {
      max-width: 34%;
      @media only screen and (max-width: $mobileView) {
        max-width: none;
      }
    }
    .column {
      // margin-top: 30px;
      max-width: 66%;
      @media only screen and (max-width: $mobileView) {
        max-width: unset;
      }
    }
  }
}
.Twocolumn-UsingPresto1 {
  width: 944px;
  margin: 0 auto;
  padding-top: 48px;
  padding-bottom: 48px;
  @media only screen and (max-width: $mobileView) {
    // padding-left: 22px;
    padding: 32px 16px 16px;
    width: unset;
  }
  table {
    table-layout: fixed;
    tbody {
      tr {
        td:first-child {
          width: 184px !important;
          white-space: initial !important;
          p {
            padding: 10px 16px 16px 16px;
          }
        }
      }
    }
  }
  .column:first-child {
    max-width: 34%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
    }
  }
  .column {
    max-width: 66%;
    @media only screen and (max-width: $mobileView) {
      max-width: unset;
    }
  }
  .Accordianitem-image {
    @media only screen and (min-width: $mobileView) {
      &:nth-child(6) {
        .side-icons {
          margin-right: 32px;
        }
        button {
          padding: 24px 32px 24px;
        }
      }
      &:nth-child(8) {
        button {
          padding-top: 24px;
          padding-bottom: 23px;
        }
        .home-accordian-heading {
          top: 20px;
        }
      }
      &:nth-child(9) {
        .side-icons {
          margin-right: 30px;
        }
        button {
          padding: 26px 24px 26px 30px;
        }
        .home-accordian-heading {
          top: 20px;
        }
      }
      &:nth-child(11) {
        button {
          padding-left: 27px;
        }
        .side-icons {
          margin-right: 25px;
        }
      }
      &:nth-child(12) {
        .side-icons {
          margin-right: 33px;
        }
        button {
          padding: 24px 24px 24px 32px;
        }
      }
    }
    @media only screen and (max-width: $mobileView) {
      &:nth-child(6) {
        .side-icons {
          max-width: 32px;
          margin-right: 24px;
        }
        button {
          padding: 20px 16px;
        }
      }
      &:nth-child(8) {
        button {
          padding: 17px 16px 15px 8px;
        }
      }
      &:nth-child(9) {
        .side-icons {
          max-width: 36px;
          margin-right: 22px;
        }
        button {
          padding: 18px 16px;
        }
      }
      &:nth-child(11) {
        button {
          padding-left: 11px;
        }
        .side-icons {
          max-width: 44px;
        }
      }
      &:nth-child(12) {
        .side-icons {
          max-width: 29px;
          margin-right: 25px;
        }
        button {
          padding: 20px 16px 20px 18px;
        }
      }
    }
  }
}
.Twocolumn-UsingPresto2 {
  background-color: white;
  padding-top: 48px;
  // margin-top: 30px;
  width: 944px;
  margin: 0 auto;
  // padding-left: 155px;
  padding-bottom: 48px;
  @media only screen and (max-width: $mobileView) {
    // padding-left: 22px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
  }
  .column:first-child {
    max-width: 34%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
    }
  }
  .column {
    max-width: 66%;
    @media only screen and (max-width: $mobileView) {
      max-width: unset;
    }
  }
}
.Twocolumn-UsingPresto3 {
  width: 944px;
  margin: 0 auto;
  padding-bottom: 80px;
  padding-top: 48px;
  @media only screen and (max-width: $mobileView) {
    padding: 32px 16px 64px;
    width: 90%;
  }
  .column:first-child {
    max-width: 34%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
    }
  }
  .column {
    max-width: 66%;
    @media only screen and (max-width: $mobileView) {
      max-width: unset;
    }
  }
  .The-Presto-App1 {
    @media only screen and (max-width: $mobileView) {
      margin-bottom: 0px;
    }
  }
}
.Paper-forms-column1 {
  //padding-left: 178px;
  //padding-bottom: 20px;
  //padding-right: 155px;
  padding-top: 48px;
  width: 944px;
  margin: 0 auto;
  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 32px;
    margin-bottom: 32px;
    width: unset;
  }
  .column:first-child {
    max-width: 35%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
    }
  }
  .column {
    // margin-top: -11px;
    max-width: 65%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
    }
  }
}
.Paper-forms-column2 {
  background-color: white;
  padding-top: 48px;
  //padding-left: 178px;
  padding-bottom: 48px;
  // padding-right: 155px;
  width: 944px;
  margin: 48px auto 0;
  @media only screen and (max-width: $mobileView) {
    //margin-left: 16px;
    //margin-right: 16px;
    margin-top: 32px;
    padding-top: 0;
    padding-bottom: 32px;
    width: unset;
  }
  .column:first-child {
    max-width: 35%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
      margin-top: 0px;
    }
  }
  .column {
    // margin-top: 30px;
    max-width: 65%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
      margin-top: 24px;
    }
  }
}
.Paper-forms-column3 {
  padding-left: 178px;
  padding-bottom: 20px;
  padding-top: 30px;
  padding-right: 155px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-bottom: 8px;
  }
  .column:first-child {
    max-width: 35%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
    }
  }
  .column {
    max-width: 65%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
    }
  }
}
.Paper-forms-column4 {
  padding-top: 48px;
  width: 944px;
  margin: 0 auto;
  padding-bottom: 80px;
  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 32px;
    padding-bottom: 64px;
    width: unset;
  }
  .column:first-child {
    max-width: 35%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
    }
  }
  .column {
    max-width: 65%;
    @media only screen and (max-width: $mobileView) {
      max-width: none;
    }
  }
}

.twocolumn-wrapper {
  background: #fff;
}

.row {
  &.Two-column-Hello {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
  &.Two-column-Hello-french {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
}
#app-store-btn,
#google-play-btn {
  img {
    width: 216px;
    height: 64px;
  }
}
