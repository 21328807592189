@import '../../assets/scss/vars.scss';

.modal-text-only {
  padding: $spacing-4 0 !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: auto;
  z-index: 2000;
}

.modal-wrapper {
  position: fixed;
  top: 20%;
  align-items: center;
  left: $spacing-2;
  right: $spacing-2;
  background-color: $white;
  margin: 0 auto;
  padding: 0 $spacing-1 $spacing-1 $spacing-1;
  z-index: 2000;

  @media only screen and (min-width: $mobileView) {
    width: 464px;
  }
  @media only screen and (max-width: $mobileView) {
    top: 10%;
  }
}

.modal {
  &-title {
    font-family: $boldFont;
    font-size: 26px;
    line-height: $spacing-5;
    padding: $spacing-2 0 $spacing-1;

    @media only screen and (min-width: $mobileView) {
      font-size: $spacing-3;
      padding: $spacing-2 $spacing-2 $spacing-1;
    }
  }

  &-text {
    font-family: $regularFont;
    font-size: $spacing-1;
    line-height: $spacing-2;

    @media only screen and (min-width: $mobileView) {
      padding: 0 $spacing-2;
    }
  }
}

.sp-wrapper {
  max-width: 716px;
  top: 230px;

  .button-wrapper {
    padding: 0 37px 12px;
  }
}

.sp-text {
  padding: 0 38px 10px;
}
