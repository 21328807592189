@import '../../assets/scss/vars.scss';

.content-para-wrapper {
  background-color: #fff;
}
.para {
  font-size: 1.25rem;
  line-height: 2rem;
  color: #191919;
  font-family: 'AvenirNextLTPro-Regular';
  margin-right: 1.5rem;
  .para-desc {
    a {
      color: -webkit-link;
      font-family: inherit;
      color: -moz-hyperlinkText;
    }
    a:active {
      color: red;
    }
    a:visited {
      color: purple;
    }
  }
  .contactless-para-heading {
    strong {
      font-family: $regularFont;
    }
  }
}

.contactless-support-para {
  font-size: 1.25rem;
  line-height: 2rem;
  color: #191919;
  margin: 0;
  padding: 2rem 0 0;
  @media only screen and (max-width: 768px) {
    margin: 0 1.5rem;
  }
}
@media (max-width: 768px) {
  .para {
    margin: 0 1.5rem;
    margin-bottom: 3.5rem;
  }
}
@media (min-width: 768px) {
  .para {
    font-size: 1.375rem;
    line-height: 2rem;
    color: #191919;
    margin-right: 21%;
    margin-bottom: 4rem;
  }
  .contactless-support-para {
    font-size: 1.625rem;
    line-height: 2.5rem;
    margin-top: 0;
    margin-right: 20%;
    padding-bottom: 0;
  }
}
.main-content .Gray {
  padding-top: 0;
}

.main-content {
  .privacy-content {
    // margin-bottom: 30px;
    // display: inline-block;
    font-size: 16px;
    color: #191919;
    line-height: 1.5rem;
    font-family: $regularFont;
    // margin-left: 175px;
    // margin-top: 48px;
    width: 944px;
    margin: 48px auto 80px;
    @media only screen and (max-width: $mobileView) {
      font-size: 14px;
      line-height: 21px;
      width: unset;
      margin: 33px 16px 96px;
    }
    p {
      color: #191919;
      display: block;
      font-family: $regularFont;
    }
    strong {
      font-weight: 700;
      font-family: $boldFont;
      font-size: 18px;
      color: #181818;
    }
    h2 {
      font-size: 22px;
      color: #191919;
      margin-top: 32px;
      margin-bottom: 16px;
      font-family: $boldFont;
      line-height: 32px;
      display: block;
    }
    ol {
      margin-top: 0;
      margin-bottom: 8px;
      display: block;
      list-style-type: decimal;
      @media only screen and (max-width: $mobileView) {
        padding-left: 30px;
        margin-bottom: 25px;
      }
      li {
        line-height: 1.5rem;
        font-size: 1rem;
        color: #191919;
        display: list-item;
        margin-bottom: 8px;
        font-family: $regularFont;
        padding-left: 6px;
        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    ul {
      list-style-image: disc;
      padding: 0 0 30px 15px;
      margin-left: 20px;
      display: block;
      @media only screen and (max-width: $mobileView) {
        padding-left: 25px;
      }
      li {
        color: #191919;
        font-weight: normal;
        padding: 5px 0 5px 10px;
        font-size: 1rem;
        line-height: 1.5rem;
        display: list-item;
        font-family: $regularFont;
        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
        }
        a {
          color: #191919;
          text-decoration: underline;
          cursor: pointer;
          font-family: $boldFont;
        }
      }
    }
    .privacy-paragraphs {
      margin-top: 0px;
      margin-bottom: 50px;
    }
  }
  .additional-info-para {
    // padding-left: 180px;
    padding-top: 48px;
    padding-bottom: 48px;
    width: 944px;
    margin: 0 auto;
    // width: 56%;
    @media only screen and (max-width: $mobileView) {
      width: 90%;
      padding-left: 16px;
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 16px;
      padding-top: 0px !important;
    }
    p {
      font-family: $regularFont;
      font-size: 16px;
      line-height: 1.5rem;
      color: #191919;
      width: 75%;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
      a {
        font-family: $boldFont;
        color: #181818;
      }
    }
  }
}
.new-mainContent {
  .Loading-page {
    background: #fafafa;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    @media only screen and (max-width: $mobileView) {
      padding-left: 30px;
      padding-right: 30px;
    }
    .loading-funds1 {
      font-size: 16px;
      color: #181818;
      line-height: 26px;
      font-weight: bold;
      font-family: $regularFont;
    }
    .description-overview {
      margin-top: 16px;
    }
    p {
      font-size: 1.6rem;
      margin-bottom: 0.7rem;
      font-family: $regularFont;
      line-height: 2.6rem;
      color: #666;
      display: block;
      a {
        color: #3a811e;
        font-family: $regularFont;
        text-decoration: underline;
      }
    }
    ul {
      color: #3a811e;
      li {
        line-height: 1.67;
        font-size: 1.6rem;
        a {
          color: #3a811e;
          font-family: $regularFont;
          text-decoration: underline;
        }
      }
    }
  }
  .loading-funds-content-para {
    background: #ffffff;
    text-align: left;
    width: 100%;
    border: 1px solid #e8e8e8;
    padding-top: 28px;
    margin-bottom: 100px;
    @media only screen and (max-width: $mobileView) {
      // margin-left: 13px;
      // width: 92%;
      margin-bottom: 0;
    }
    .funds-sub-paras-1 {
      border-bottom: 1px solid #dadada;
      padding-bottom: 10px;
      padding-left: 24px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 4px;
      }
      .arrow-icon1 {
        font-size: 16px;
        line-height: 24px;
        .instructionimage {
          display: flex;
          margin-left: 6px;
          div {
            @media only screen and (max-width: $mobileView) {
              //padding-left: 8px;
              padding-right: 16px;
            }
          }
          a {
            padding-left: 8px;
            @media only screen and (max-width: $mobileView) {
              //padding-left: 0;
            }
          }
        }
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
          line-height: 32px;
        }
      }
      .description-overview {
        margin-top: 0;
        margin-bottom: 0;
        @media only screen and (max-width: $mobileView) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      p {
        padding-right: 20px;
        font-size: 16px;
        margin-bottom: 24px;
        font-family: $regularFont;
        color: #191919;
        display: block;
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          line-height: 24px;
          font-size: 16px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      ul {
        padding-top: 8px;
        padding-left: 27px;
        padding-right: 45px;
        margin-top: 0px;
        color: #191919;
        @media only screen and (max-width: $mobileView) {
          // padding-left: 20px;
          padding-right: 16px;
        }
        li {
          padding-left: 10px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 6px;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .funds-sub-paras-2 {
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;
      padding-top: 29px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 24px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-left: 24px;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
          line-height: 32px;
        }
      }
      .description-overview {
        margin-top: 0px;
        margin-bottom: 0;
      }
      p {
        padding-left: 24px;
        padding-right: 20px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #191919;
        display: block;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          line-height: 24px;
          font-size: 16px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      ul {
        padding-top: 8px;
        padding-left: 51px;
        padding-right: 45px;
        color: #181818;
        @media only screen and (max-width: $mobileView) {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 0;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            // padding-bottom: 15px;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 16px;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .funds-sub-paras-3 {
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;
      padding-top: 24px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 17px;
        padding-bottom: 6px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-left: 24px;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
        }
      }
      .description-overview {
        margin-top: 0px;
        margin-bottom: 0;
      }
      p {
        padding-left: 24px;
        padding-right: 20px;
        font-size: 16px;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #191919;
        display: block;
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          line-height: 24px;
          font-size: 16px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      ul {
        padding-top: 8px;
        padding-left: 51px;
        padding-right: 45px;
        color: #191919;
        font-size: 16px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 20px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 31px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 10px;
            line-height: 24px;
          }
          a {
            font-family: $boldFont;
            text-decoration: underline;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .funds-sub-paras-4 {
      padding-bottom: 16px;
      padding-top: 29px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 24px;
        padding-bottom: 6px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-left: 24px;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
        }
      }
      .description-overview {
        margin-top: 0;
        margin-bottom: 0;
      }
      p {
        padding-left: 24px;
        padding-right: 20px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #191919;
        display: block;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          line-height: 24px;
          font-size: 16px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 16px;
        }
      }
      ul {
        padding-top: 8px;
        padding-left: 51px;
        padding-right: 45px;
        color: #191919;
        font-size: 16px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 20px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 31px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 10px;
            line-height: 24px;
          }
          a {
            font-family: $boldFont;
            text-decoration: underline;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .autoload-content-para {
    background: #ffffff;
    text-align: left;
    width: 100%;
    border: 1px solid #e8e8e8;
    padding-top: 28px;
    margin-bottom: 100px;
    @media only screen and (max-width: $mobileView) {
      width: 99%;
      padding-top: 24px;
      margin-bottom: 0;
    }
    .autoload-subpara1 {
      border-bottom: 1px solid #d1d1d1;
      padding-bottom: 16px;
      padding-left: 24px;

      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 4px;
      }

      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
          line-height: 32px;
        }
      }
      .arrow-icon1 {
        line-height: 24px;
        .instructionimage {
          display: flex;
          margin-left: 6px;
          @media only screen and (max-width: $mobileView) {
            display: flex !important;
          }
          a {
            padding-left: 8px;
          }
          img {
            @media only screen and (max-width: $mobileView) {
              margin-top: 5px;
            }
          }
        }
      }
      .description-overview {
        margin-top: 0;
        margin-bottom: 0;
      }
      p {
        margin-top: 8px;
        padding-right: 20px;
        font-size: 1rem;
        margin-bottom: 24px;
        font-family: $regularFont;
        color: #191919;
        display: block;
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          line-height: 24px;
          font-size: 16px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      ul {
        margin-top: 0;
        padding-top: 8px;
        padding-left: 28px;
        padding-right: 45px;
        color: #191919;
        @media only screen and (max-width: $mobileView) {
          padding-left: 20px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            // padding-bottom: 15px;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 1rem;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .autoload-subpara2 {
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 16px;
      padding-top: 29px;
      padding-left: 24px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 24px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
          line-height: 32px;
        }
      }
      .description-overview {
        margin-top: 0;
        margin-bottom: 0;
      }
      .arrow-icon1 {
        line-height: 24px;
        .instructionimage {
          display: flex;
          margin-left: 6px;
          @media only screen and (max-width: $mobileView) {
            display: flex !important;
          }
          a {
            padding-left: 8px;
          }
          img {
            @media only screen and (max-width: $mobileView) {
              display: flex;
              margin-top: 5px;
            }
          }
        }
      }
      p {
        padding-right: 20px;
        font-size: 1rem;
        margin-bottom: 24px;
        font-family: $regularFont;
        color: #191919;
        display: block;
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          line-height: 24px;
          font-size: 16px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      ul {
        margin-top: 0;
        padding-top: 8px;
        padding-left: 28px;
        padding-right: 45px;
        color: #191919;
        @media only screen and (max-width: $mobileView) {
          padding-left: 20px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            // padding-bottom: 15px;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 1rem;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .autoload-subpara3 {
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 16px;
      padding-top: 29px;
      padding-left: 24px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 24px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
          line-height: 32px;
        }
      }
      .description-overview {
        margin-top: 0;
        margin-bottom: 0;
      }
      p {
        padding-right: 20px;
        font-size: 1rem;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #191919;
        display: block;
        line-height: 1.5rem;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          line-height: 24px;
          font-size: 16px;
        }
        a {
          color: #181818;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      ul {
        margin-top: 0;
        padding-top: 8px;
        padding-left: 18px;
        padding-right: 45px;
        color: #191919;
        @media only screen and (max-width: $mobileView) {
          padding-left: 20px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 0px;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 1rem;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .autorenew-content-para {
    background: #ffffff;
    text-align: left;
    width: 100%;
    border: 1px solid #dadada;
    padding-top: 28px;
    margin-bottom: 15px;
    @media only screen and (max-width: $mobileView) {
      width: 99%;
    }
    .autorenew-subpara1 {
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;

      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        // padding-top: 4px;
      }

      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-left: 24px;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
          line-height: 32px;
        }
      }
      .description-overview {
        margin-top: 16px;
      }
      p {
        padding-left: 24px;
        padding-right: 20px;
        font-size: 1rem;
        margin-bottom: -7px;
        font-family: $regularFont;
        color: #191919;
        display: block;
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          line-height: 24px;
          font-size: 16px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
          line-height: 24px;
        }
      }
      ul {
        padding-top: 13px;
        padding-left: 51px;
        padding-right: 45px;
        color: #181818;
        @media only screen and (max-width: $mobileView) {
          padding-left: 30px;
          padding-right: 0px;
        }
        li {
          padding-left: 10px;
          line-height: 1.9rem;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 0;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 1rem;
            line-height: 24px;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .autorenew-subpara2 {
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;
      padding-top: 29px;
      padding-left: 24px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 24px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
          line-height: 32px;
        }
      }
      .description-overview {
        margin-top: 16px;
      }
      p {
        padding-right: 20px;
        font-size: 1rem;
        line-height: 24px;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #191919;
        display: block;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          line-height: 24px;
          font-size: 16px;
          margin-bottom: 0;
        }
        a {
          color: #181818;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
          line-height: 24px;
        }
      }
      ul {
        padding-top: 8px;
        padding-left: 27px;
        padding-right: 45px;
        color: #191919;
        font-size: 16px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 30px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 0px;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 1rem;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .autorenew-subpara3 {
      padding-bottom: 16px;
      padding-top: 29px;
      padding-left: 24px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 24px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
          font-size: 22px;
          line-height: 32px;
        }
      }
      .description-overview {
        margin-top: 16px;
      }
      p {
        padding-right: 20px;
        font-size: 1rem;
        line-height: 24px;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #191919;
        display: block;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 16px;
          line-height: 24px;
          font-size: 16px;
        }
        a {
          color: #181818;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
          line-height: 24px;
        }
      }
      ul {
        padding-top: 8px;
        padding-left: 27px;
        padding-right: 45px;
        color: #181818;
        font-size: 16px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 30px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 0px;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 1rem;
            @media only screen and (max-width: $mobileView) {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .checking-balance-para {
    background: #ffffff;
    text-align: left;
    width: 100%;
    border: 1px solid #dadada;
    padding-top: 25px;
    margin-bottom: 100px;
    @media only screen and (max-width: $mobileView) {
      width: 99%;
    }
    .sub-paras-1 {
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;
      width: 93%;
      .arrow-icon1 {
        .instructionimage {
          margin-left: 32px;
          display: flex;
          @media only screen and (max-width: $mobileView) {
            margin-left: 5px;
          }
          // img {
          //   margin-right: 10px;
          // }
          a {
            font-size: 16px;
            line-height: 24px;
            padding-left: 8px;
          }
        }
      }
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
      }

      .loading-funds1 {
        font-size: 22px;
        color: #191919;
        font-family: $boldFont;
        padding-left: 24px;
        padding-right: 20px;
        line-height: 32px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      .description-overview {
        margin-top: 0;
        margin-bottom: 0;
      }
      p {
        padding-left: 24px;
        padding-right: 20px;
        font-size: 16px;
        margin-top: 8px;
        margin-bottom: 24px;
        font-family: $regularFont;
        color: #191919;
        display: block;
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 16px;
          line-height: 24px;
        }
      }
      ul {
        padding-left: 54px;
        padding-right: 45px;
        margin-top: 8px;
        color: #191919;
        @media only screen and (max-width: $mobileView) {
          padding-left: 25px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 15px;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .sub-paras-2 {
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;
      padding-top: 25px;
      width: 94%;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 35px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-left: 24px;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      .description-overview {
        margin-top: 0;
        margin-bottom: 0;
      }
      p {
        padding-left: 24px;
        padding-right: 20px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #191919;
        display: block;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
        a {
          color: #181818;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      ul {
        padding-left: 54px;
        padding-right: 45px;
        color: #181818;
        font-size: 16px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 25px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 15px;
          }
          a {
            font-family: $boldFont;
            text-decoration: underline;
            line-height: 24px;
            font-size: 16px;
          }
        }
      }
    }
    .sub-paras-3 {
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;
      padding-top: 25px;
      width: 94%;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 35px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-left: 24px;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      // .description-overview {
      //   margin-top: 16px;
      // }
      p {
        padding-left: 24px;
        padding-right: 20px;
        font-size: 16px;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #191919;
        display: block;
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 16px;
        }
      }
      ul {
        padding-left: 54px;
        padding-right: 45px;
        color: #191919;
        font-size: 16px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 25px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 15px;
          }
          a {
            color: #191919;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .sub-paras-4 {
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;
      padding-top: 25px;
      width: 94%;
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 35px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-left: 24px;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      // .description-overview {
      //   margin-top: 16px;
      // }
      p {
        padding-left: 24px;
        padding-right: 20px;
        font-size: 16px;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #181818;
        display: block;
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
        a {
          color: #191919;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      ul {
        padding-left: 54px;
        padding-right: 45px;
        color: #191919;
        font-size: 16px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 25px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 15px;
          }
          a {
            color: #181818;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .sub-paras-5 {
      padding-bottom: 32px;
      padding-top: 25px;
      .arrow-icon1 {
        .instructionimage {
          margin-left: 30px;
          @media only screen and (max-width: $mobileView) {
            margin-left: 10px;
          }
          a {
            padding-left: 8px;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      @media only screen and (max-width: $mobileView) {
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 35px;
      }
      .loading-funds1 {
        font-size: 22px;
        line-height: 32px;
        color: #191919;
        font-family: $boldFont;
        padding-left: 24px;
        padding-right: 20px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      .description-overview {
        margin-top: 16px;
      }
      p {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 1rem;
        margin-bottom: 0.7rem;
        font-family: $regularFont;
        color: #181818;
        display: block;
        line-height: 1.5rem;
        @media only screen and (max-width: $mobileView) {
          padding-left: 0px;
          padding-right: 0px;
        }
        a {
          color: #181818;
          font-family: $boldFont;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      ul {
        padding-left: 54px;
        padding-right: 45px;
        color: #181818;
        @media only screen and (max-width: $mobileView) {
          padding-left: 25px;
          padding-right: 20px;
        }
        li {
          padding-left: 10px;
          line-height: 1.8rem;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 15px;
          }
          a {
            color: #181818;
            font-family: $boldFont;
            text-decoration: underline;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .balancecheckers-para {
    display: block;
    @media only screen and (max-width: $mobileView) {
      margin-right: 0px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      color: #191919;
      margin: 0 0 10px;
      font-family: $regularFont;
      display: block;
      a {
        color: #191919;
        font-family: $boldFont;
        text-decoration: underline;
      }
    }
  }
  .selfserve-para {
    border-top: 1px solid #f0eded;
    margin: 50px auto 50px auto;
    padding-right: 15px;
    padding-left: 15px;

    p {
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: #666;
      font-family: $regularFont;
    }
  }
  .selfserve-tablecontent {
    padding-bottom: 40px;
    border: 1px solid #ccc;
    margin-left: 20px;
    margin-right: 20px;
    @media only screen and (max-width: $mobileView) {
      max-width: 100%;
    }

    #discountfaretable04 {
      border-bottom: 1px solid #dcddde;
      padding-right: 15px;
      padding-left: 15px;
      h4 {
        color: #181818;
        font-size: 2.2rem !important;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 1.1;
        display: block;
        padding: 10px 20px 10px 10px;
        font-family: $regularFont;
      }
    }
    #discountfaretable03 {
      .fareEligibilitySectionCol {
        // border-bottom: 1px solid #ccc;
        // padding: 20px 0;
        margin: 0;

        .fareEligibilitySectionColLeft {
          padding-left: 25px;
          font-size: 14px;
          font-weight: bold;
          color: #181818;
          width: 25%;
          padding-right: 25px;
          p {
            font-size: 1.2rem;
            margin-bottom: 0.7rem;
            line-height: 2.6rem;
            display: block;
            font-family: $regularFont;
          }
        }
        .fareEligibilitySectionColRight {
          font-size: 14px;
          width: 75%;
          p {
            font-size: 1.2rem;
            margin-bottom: 0.7rem;
            line-height: 2.6rem;
            display: block;
            font-family: $regularFont;
          }
          ul {
            li {
              list-style-type: none;
              font-size: 1.2rem;
              margin-bottom: 0.7rem;
              line-height: 2.6rem;
              display: block;
              font-family: $regularFont;
            }
          }
        }
      }
    }
  }
  .vendingmachine {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 50px;
    p {
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: #666;
      margin: 0 0 10px;
      font-family: $regularFont;
      display: block;
    }
    ul {
      font-size: 1.6rem;
      line-height: 2em;
      margin-top: 0;
      margin-bottom: 10px;
      font-family: $regularFont;
      li {
        display: list-item;
        text-align: -webkit-match-parent;
      }
    }
  }
}
.accessibility-paragraph {
  font-family: $regularFont;
  width: 57%;
  line-height: 1.5em;
  // padding-left: 175px;
  margin-bottom: 80px;
  width: 944px;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 64px;
  .accessible-features-list {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 75%;
    .accessible-features-listitem {
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    width: unset;
    margin: 32px 16px 64px;
    padding-top: 0;
    padding-bottom: 64px;
  }
  h2 {
    margin-top: 32px;
    margin-bottom: 16px;
    font-family: $boldFont;
    width: 75%;
    @media only screen and (max-width: 768px) {
      width: unset;
    }
  }
  ul {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 75%;
  }
}
.tvm-paragraph-content {
  font-family: $regularFont;
  margin-top: 32px;
  margin-bottom: 24px;
  ul {
    margin-top: 24px;
    margin-bottom: 32px;
    line-height: 2rem;
    @media only screen and (max-width: $mobileView) {
      line-height: 24px;
      padding-left: 31px;
      margin-bottom: 48px;
    }
    li {
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 8px;
      }
    }
  }
}
.selfserve-ttc-stations-list {
  @media only screen and (max-width: $mobileView) {
    margin-bottom: 70px;
  }
  .row {
    display: flex !important;
  }
  .fareEligibilityTable {
    color: #1a1a1a;
    font-family: $boldFont;
    font-size: 18px;
    line-height: 28px;
    padding: 16px;
    background-color: #eceaea;
    @media only screen and (max-width: $mobileView) {
      padding-left: 5px;
      font-size: 12px;
      line-height: 16px;
      padding: 8px;
    }
    h4 {
      margin: 0px;
    }
  }
  .fareEligibilitySectionCol {
    background-color: #fff;
    border-bottom: 1px solid #dfdddd;
    font-family: $regularFont;
    font-size: 14px;
    @media only screen and (max-width: $mobileView) {
      font-size: 12px;
    }
    .fareEligibilitySectionColLeft {
      border-right: 1px solid #dfdddd;
      padding-left: 16px;
      padding-right: 16px;
      width: 23%;
      line-height: 21px;
      @media only screen and (max-width: $mobileView) {
        line-height: 16px;
        padding-left: 8px;
        padding-right: 9px;
        padding-top: 6px;
        width: 30%;
      }
      p {
        font-family: $boldFont;
        @media only screen and (max-width: $mobileView) {
          margin: 0px;
        }
      }
    }
    .fareEligibilitySectionColRight {
      width: 79%;
      padding-left: 16px;
      padding-right: 16px;
      line-height: 21px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 8px;
        padding-top: 8px;
        padding-right: 8px;
      }
      p {
        line-height: 1.5rem;
        margin-bottom: 0px;
        @media only screen and (max-width: $mobileView) {
          margin-top: 0px;
          margin-bottom: 8px;
          line-height: 16px;
        }
      }
      .transit-location-lists {
        display: flex;
        @media only screen and (max-width: $mobileView) {
          display: block;
        }
        .transit-locations-firstlist {
          margin-right: 24px;
          ul {
            @media only screen and (max-width: $mobileView) {
              margin-bottom: 0px;
            }
          }
        }
        ul {
          padding-left: 26px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 8px;
            margin-top: 0px;
          }
          li {
            padding-left: 12px;
            @media only screen and (max-width: $mobileView) {
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
.self-serve-reload-para {
  font-family: $regularFont;
  margin-top: 32px;
  line-height: 1.5rem;
  @media only screen and (max-width: $mobileView) {
    display: block;
  }
}
.get-presto-ready-para {
  margin-left: auto;
  margin-right: auto;
  font-family: $regularFont;
  color: #191919;
  width: 944px;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 48px;
  p {
    width: 704px;
    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
  @media only screen and (max-width: $mobileView) {
    // padding-left: 25px;
    margin-left: 16px;
    margin-right: 16px;
    width: unset;
    margin-bottom: 32px;
    margin-top: 24px;
  }
  a {
    font-family: $boldFont;
  }
}
.contentpara-get-presto {
  background-color: #ffffff;
  // padding-left: 170px;
  //padding-top: 30px;
  padding-bottom: 56px;
  margin: 0 auto;
  width: 944px;
  @media only screen and (max-width: $mobileView) {
    margin: 0 16px;
    width: unset;
  }
  .route-schedule {
    width: 50%;
    border: 1px solid #dadada;
    background-color: #ffffff;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
    .Transit-images-get-presto {
      padding-top: 16px;
      border-bottom: 1px solid #dadada;
      padding-left: 24px;
      padding-bottom: 16px;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      @media only screen and (max-width: $mobileView) {
        padding-left: 1px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom-width: 1px;
      }
      // @supports (-webkit-touch-callout: none) {
      //   /* CSS specific to iOS devices */
      //   padding-bottom: 40px;
      //   padding-top: 30px;
      // }
      #instructionimage {
        float: left;
        height: 63px;
        flex-basis: 30%;
        @media only screen and (max-width: $mobileView) {
          width: 30%;
          flex-basis: 30%;
        }
        img {
          width: 75%;
          @media only screen and (max-width: $mobileView) {
            width: 90%;
            margin-top: 0px;
            height: 100%;
          }
          @supports (-moz-appearance: none) {
            width: 60px;
          }
        }
      }
      .text-container {
        padding-top: 0px;
        flex-basis: 70%;
        @media only screen and (max-width: $mobileView) {
          padding-top: 0;
          display: flex;
          // padding-left: 24px;
          flex-basis: 70%;
        }
        a {
          padding-left: 0px;
          font-size: 16px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-left: 8px;
          }
        }
      }
    }
    .Transit-images-get-presto1 {
      padding-top: 16px;
      padding-left: 24px;
      padding-bottom: 24px;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      @media only screen and (max-width: $mobileView) {
        // display: inline-flex;
        // padding-bottom: 25px;
        padding-left: 1px;
        border-bottom: 1px solid #dadada;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom-width: 1px;
      }
      #instructionimage {
        float: left;
        height: 63px;
        flex-basis: 30%;
        @media only screen and (max-width: $mobileView) {
          width: 30%;
          flex-basis: 30%;
        }
        img {
          width: 75%;
          @media only screen and (max-width: $mobileView) {
            width: 90%;
            height: 100%;
            margin-top: 0;
          }
          @supports (-moz-appearance: none) {
            width: 60px;
          }
        }
      }
      .text-container {
        padding-top: 0px;
        flex-basis: 70%;
        @media only screen and (max-width: $mobileView) {
          padding-top: 0px;
          display: flex;
          flex-basis: 70%;
        }
        // .linktag {
        //   @media only screen and (max-width: $mobileView) {
        //     padding-left: 8px;
        //   }
        // }
        // .linktag1 {
        //   @media only screen and (max-width: $mobileView) {
        //     padding-left: 8px;
        //   }
        // }
        a {
          padding-left: 0px;
          font-size: 16px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-left: 8px;
          }
        }
        #instructionTitle {
          @media only screen and (max-width: $mobileView) {
            display: none;
          }
        }
      }
    }
    .Transit-images-get-presto2 {
      padding-top: 16px;
      border-bottom: 1px solid #dadada;
      padding-left: 24px;
      padding-bottom: 16px;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      @media only screen and (max-width: $mobileView) {
        // padding-bottom: 25px;
        padding-left: 1px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom-width: 1px;
      }
      // @supports (-webkit-touch-callout: none) {
      //   /* CSS specific to iOS devices */
      //   padding-bottom: 45px;
      // }
      #instructionimage {
        float: left;
        height: 63px;
        flex-basis: 30%;
        @media only screen and (max-width: $mobileView) {
          width: 30%;
          flex-basis: 30%;
        }
        img {
          width: 75%;
          @media only screen and (max-width: $mobileView) {
            width: 90%;
            height: 100%;
            margin-top: 0;
          }
          @supports (-moz-appearance: none) {
            width: 60px;
          }
        }
      }
      .text-container {
        padding-top: 0px;
        flex-basis: 70%;
        @media only screen and (max-width: $mobileView) {
          padding-top: 0;
          display: flex;
          // padding-left: 24px;
          flex-basis: 70%;
        }
        a {
          padding-left: 0px;
          font-size: 16px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-left: 8px;
          }
        }
      }
    }
    .Transit-images-get-presto3 {
      padding-top: 16px;
      padding-left: 24px;
      padding-bottom: 16px;
      margin-left: 20px;
      margin-right: 20px;
      border-bottom: 1px solid #dadada;
      display: flex;
      @media only screen and (max-width: $mobileView) {
        // display: inline-flex;
        // padding-bottom: 25px;
        padding-left: 1px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom-width: 1px;
      }
      #instructionimage {
        float: left;
        height: 63px;
        flex-basis: 30%;
        @media only screen and (max-width: $mobileView) {
          width: 30%;
          flex-basis: 30%;
        }
        img {
          width: 68%;
          @media only screen and (max-width: $mobileView) {
            width: 90%;
            height: 100%;
            margin-top: 0;
          }
          @supports (-moz-appearance: none) {
            width: 60px;
          }
        }
      }
      .text-container {
        padding-top: 0px;
        flex-basis: 70%;
        @media only screen and (max-width: $mobileView) {
          padding-top: 0px;
          flex-basis: 70%;
          display: flex;
        }
        .linktag {
          @media only screen and (max-width: $mobileView) {
            padding-left: 8px;
          }
        }
        .linktag1 {
          @media only screen and (max-width: $mobileView) {
            padding-left: 8px;
          }
        }
        a {
          padding-left: 0px;
          font-size: 16px;
          line-height: 24px;
          @media only screen and (max-width: $mobileView) {
            padding-left: 1px;
          }
        }
        #instructionTitle {
          @media only screen and (max-width: $mobileView) {
            display: none;
          }
        }
      }
    }

    @media only screen and (min-width: $mobileView) {
      .prestoimg img {
        width: 64px !important;
        height: 64px;
        margin-left: 15px;
      }
      .prestoimg:nth-child(1) .text-container {
        padding-top: 16px;
      }
      .prestoimg:nth-child(8) img {
        width: 50px !important;
        height: 64px;
        margin-left: 20px;
      }
      .prestoimg:nth-child(9) img {
        width: 64px !important;
        height: 64px;
        margin-left: 15px;
      }
      .prestoimg:nth-child(10) img {
        width: 58px !important;
        height: 64px;
        margin-left: 15px;
      }
      .prestoimg:nth-child(11) img {
        width: 50px !important;
        height: 60px;
        margin-left: 15px;
      }
    }
  }
}
.report-lost-card-para {
  width: 944px;
  margin: 24px auto 80px;
  .report-lost-card-paragraph {
    font-family: $regularFont;
    //margin-left: 170px;
    // width: 944px;
    // margin: 24px auto 80px;
    // margin-bottom: 90px;
    width: 85%;
    @media only screen and (max-width: $mobileView) {
      margin: 24px 16px 64px;
      width: unset;
      // margin-bottom: 60px;
    }
    p {
      line-height: 24px;
      font-size: 16px;
    }
    .report-lost-card-query {
      font-family: $boldFont;
      font-size: 22px;
      line-height: 32px;
      margin-top: 32px;
      margin-bottom: 16px;
    }
    .arrow-icon1 {
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
      }
    }
    .report-lost-card-video {
      font-family: $boldFont;
      width: 95%;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 0px;
      }
    }
    ol {
      padding-left: 16px;
      li {
        padding-left: 6px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 8px;
          width: 95%;
        }
      }
    }
    ol.report-lost-card-bold {
      font-family: $boldFont;
    }
    .report-lost-card-subheadings {
      font-family: $boldFont;
      margin-top: 28px;
      font-size: 16px;
      line-height: 24px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 8px;
      }
    }
    .report-lost-card-lists {
      font-family: $regularFont;
      padding-left: 10px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 10px;
        width: 95%;
      }
      li {
        list-style-type: disc;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
        @media only screen and (max-width: $mobileView) {
          padding-left: 6px;
        }
      }
    }
    .report-lost-card-sublist {
      padding-left: 54px;
      line-height: 1.5em;
      @media only screen and (max-width: $mobileView) {
        padding-left: 20px;
      }
      li {
        margin-bottom: 12px;
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          a {
            text-decoration: none;
            font-family: $regularFont;
          }
        }
      }
    }
    .report-card-subpara {
      width: 102%;
      @media only screen and (max-width: $mobileView) {
        margin-left: -25px;
        width: 111%;
        margin-top: 24px;
        margin-bottom: 32px;
      }
    }
  }
  @media only screen and (max-width: $mobileView) {
    width: unset;
  }
}

.transit-usage-paragraph {
  font-family: 'AvenirNextLTPro-Regular';
  width: 90%;
  @media only screen and (max-width: $mobileView) {
    width: 91%;
    margin-left: 20px;
    margin-top: 20px;
  }
}
.transit-usage-paragraph-french {
  font-family: 'AvenirNextLTPro-Regular';
  width: 97%;
  @media only screen and (max-width: $mobileView) {
    width: 91%;
    margin-left: 20px;
    margin-top: 20px;
  }
}
.bottom-3links {
  width: 944px;
  margin: 0 auto 80px;
  font-family: $regularFont;
  color: #191919;
  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-bottom: 64px;
    width: 288px;
  }
  em {
    line-height: 24px;
    @media only screen and (max-width: $mobileView) {
      font-size: 16px;
    }
  }
  .arrow-icon1 {
    display: flex;
    line-height: 24px;
    margin-bottom: 16px;
    .instructionimage {
      @media only screen and (max-width: $mobileView) {
        padding-top: 20px;
      }
    }
    img {
      width: 100%;
      @media only screen and (max-width: $mobileView) {
        width: 150%;
      }
    }
    p {
      padding-left: 10px;
      margin-top: 0px;
      margin-bottom: 0px;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        width: 68%;
        line-height: 30px;
        padding-left: 20px;
      }
    }
  }
  .arrow-icon2 {
    display: flex;
    line-height: 24px;
    margin-bottom: 16px;
    .instructionimage {
      @media only screen and (max-width: $mobileView) {
        padding-top: 20px;
      }
    }
    img {
      width: 100%;
      @media only screen and (max-width: $mobileView) {
        width: 150%;
      }
    }
    p {
      padding-left: 10px;
      margin-top: 0px;
      margin-bottom: 0px;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        width: 90%;
        line-height: 30px;
        padding-left: 20px;
      }
    }
  }
  .arrow-icon3 {
    display: flex;
    line-height: 24px;
    margin-bottom: 16px;
    .instructionimage {
      @media only screen and (max-width: $mobileView) {
        padding-top: 20px;
      }
    }
    img {
      width: 100%;
      @media only screen and (max-width: $mobileView) {
        width: 150%;
      }
    }
    p {
      padding-left: 10px;
      margin-top: 0px;
      margin-bottom: 0px;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        width: 66%;
        line-height: 30px;
        padding-left: 20px;
      }
    }
  }
}
.content-property {
  //margin-left: 170px;
  //margin-top: 48px;
  margin: 48px auto 80px;
  width: 944px;
  @media only screen and (max-width: $mobileView) {
    padding-top: 0px;
    // margin-left: 16px;
    // margin-right: 16px;
    margin: 32px 16px 56px;
    width: unset;
  }
  p {
    font-family: $regularFont;
    color: #191919;
    font-size: 16px;
    width: 75%;
    line-height: 24px;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
    a {
      font-family: $boldFont;
      color: #191919;
    }
    strong {
      font-family: $boldFont;
      color: #191919;
    }
  }
  .bold-heading {
    line-height: 32px;
    font-size: 22px;
    margin-bottom: 16px;
    margin-top: 32px;
    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
    }
  }
  .bold-subheading {
    line-height: 28px;
    font-size: 18px;
    margin-bottom: 16px;
    margin-top: 32px;
    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
    }
  }
  .bold-heading1 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
    margin-top: 24px;
    @media only screen and (max-width: $mobileView) {
      padding-top: 0px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  ol {
    width: 70%;
    margin-top: 0;
    margin-bottom: 0px;
    @media only screen and (max-width: $mobileView) {
      width: 90%;
    }
    li {
      padding-left: 9px;
      font-family: $regularFont;
      color: #191919;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
ul {
  padding-left: 30px;
  .ul-content {
    font-family: 'AvenirNextLTPro-Regular';
    line-height: 24px;
    @media only screen and (min-width: $mobileView) {
      width: 75%;
    }
    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
}
.commercial-opportunities {
  padding: 10px;
  width: 93%;
  background-color: white;
  margin-bottom: 48px;
  @media only screen and (max-width: $mobileView) {
    padding: 16px;
    width: auto;
    line-height: 24px;
    // margin-left: 20px;
    margin-bottom: 32px;
  }
  p {
    font-family: $regularFont;
    margin-left: 22px;
    width: 93%;
    line-height: 24px;
    @media only screen and (max-width: $mobileView) {
      margin-left: 0;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
.whole-block {
  // padding-left: 170px;
  // padding-right: 152px;
  padding-top: 48px;
  width: 944px;
  margin: 0 auto;
  @media only screen and (max-width: $mobileView) {
    // padding-left: 22px;
    // padding-right: 22px;
    width: unset;
    margin: 0 16px;
    padding-top: 32px;
  }
  .display-4 {
    font-size: 26px;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 24px;
    @media only screen and (max-width: $mobileView) {
      line-height: 32px;
      font-size: 22px;
    }
  }
  .contentDescription {
    font-family: $regularFont;
    color: black;
    p {
      line-height: 24px;
      font-size: 16px;
      margin-top: 0px;
      margin-bottom: 24px;
    }
  }
  ul {
    font-family: $regularFont;
    width: 70.5%;
    padding-top: 40px;
    padding-bottom: 48px;
    margin-top: 0px;
    margin-bottom: 0px;
    @media only screen and (max-width: $mobileView) {
      margin-top: 24px;
      margin-bottom: 32px;
      padding-left: 29px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    li {
      padding-left: 8px;
      padding-bottom: 16px;
      line-height: 24px;
      font-size: 16px;
      @media only screen and (max-width: $mobileView) {
        width: 129%;
      }
    }
  }
  .full {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 16px;
    @media only screen and (max-width: $mobileView) {
      grid-template-columns: auto;
    }
    .Image1,
    .Image2,
    .Image3,
    .Image4 {
      position: relative;
    }
    .footer-link {
      // position: absolute;
      // bottom: 16px;
      // right: 16px;
      text-align: right;
      margin: 0px 16px 16px 0px;
      @media only screen and (max-width: $mobileView) {
        margin-top: 24px;
      }
    }
    .Image1 {
      border: 1px solid #dadada;
      background-color: $white;
      height: 360px;
      width: 224px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          width: 100% !important;
          height: auto !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding: 16px 16px 8px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding: 0px 16px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 32px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 16px;
          padding-bottom: 16px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image2 {
      border: 1px solid #dadada;
      background-color: $white;
      height: 360px;
      width: 224px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          width: 100% !important;
          height: auto !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding: 16px 16px 8px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding: 0px 16px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 32px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 16px;
          padding-bottom: 16px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image3 {
      border: 1px solid #dadada;
      background-color: $white;
      height: 360px;
      width: 224px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          width: 100% !important;
          height: auto !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding: 16px 16px 8px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding: 0px 16px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 8px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 16px;
          padding-bottom: 16px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image4 {
      border: 1px solid #dadada;
      background-color: $white;
      height: 360px;
      width: 224px;
      @media only screen and (max-width: $mobileView) {
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          width: 100% !important;
          height: auto !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding: 16px 16px 8px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding: 0px 16px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 32px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 16px;
          padding-bottom: 16px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
  }
}
.whole-block-presto {
  // padding-left: 170px;
  // padding-right: 154px;
  background-color: $white;
  padding-top: 48px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
  }
  .display-4 {
    font-size: 26px;
    // margin-top: 0px;
    // margin-bottom: 24px;
    line-height: 40px;
    width: 944px;
    margin: 0 auto 24px;
    @media only screen and (max-width: $mobileView) {
      width: unset;
      line-height: 32px;
      font-size: 22px;
    }
  }
  .contentDescription {
    font-family: $regularFont;
    color: black;
    width: 944px;
    margin: 0 auto;
    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
    p {
      line-height: 24px;
      font-size: 16px;
      margin-top: 0px;
      margin-bottom: 24px;
    }
  }
  .para-content {
    padding-top: 32px;
    padding-bottom: 56px;
    width: 944px;
    margin: 0 auto;
    @media only screen and (max-width: $mobileView) {
      padding-right: 22px;
      padding-top: 0px;
      padding-bottom: 32px;
      width: unset;
    }
    p {
      line-height: 24px;
      font-size: 16px;
      font-family: $regularFont;
      width: 75%;
      margin-top: 0px;
      margin-bottom: 0px;
      @media only screen and (max-width: $mobileView) {
        width: 110%;
      }
    }
  }

  .full-presto {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 16px;
    width: 944px;
    margin: 0 auto;
    @media only screen and (max-width: $mobileView) {
      grid-template-columns: auto;
      margin-bottom: 24px;
      width: unset;
    }
    .Image1,
    .Image2,
    .Image3,
    .Image4 {
      position: relative;
    }
    .footer-link {
      // position: absolute;
      // bottom: 16px;
      // right: 16px;
      text-align: right;
      margin: 0px 16px 16px 0px;
      @media only screen and (max-width: $mobileView) {
        margin-top: 24px;
      }
    }
    .Image1 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 222px;
      height: 392px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding: 16px 16px 8px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding: 0px 16px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 40px;
          // width: 85%;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 16px;
          padding-bottom: 16px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image2 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 222px;
      height: 392px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding: 16px 16px 8px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding: 0px 16px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 16px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 16px;
          padding-bottom: 16px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image3 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 222px;
      height: 392px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding: 16px 16px 8px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding: 0px 16px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 64px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 16px;
          padding-bottom: 16px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image4 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 222px;
      height: 392px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding: 16px 16px 8px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding: 0px 16px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 56px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
.whole-block-french {
  // padding-left: 170px;
  // padding-right: 153px;
  width: 944px;
  margin: 0 auto;
  padding-top: 48px;
  @media only screen and (max-width: $mobileView) {
    // padding-left: 22px;
    // padding-right: 22px;
    width: unset;
    margin: 0 16px;
    padding-top: 32px;
  }
  .display-4 {
    font-size: 26px;
    margin-top: 0px;
    margin-bottom: 24px;
    line-height: 40px;
    @media only screen and (max-width: $mobileView) {
      line-height: 32px;
      font-size: 22px;
    }
  }
  .contentDescription {
    font-family: $regularFont;
    color: black;
    p {
      line-height: 24px;
      font-size: 16px;
      margin-top: 0px;
      margin-bottom: 24px;
    }
  }
  ul {
    font-family: $regularFont;
    width: 70%;
    padding-top: 40px;
    padding-bottom: 48px;
    margin-top: 0px;
    margin-bottom: 0px;
    @media only screen and (max-width: $mobileView) {
      margin-top: 24px;
      margin-bottom: 32px;
      padding-left: 29px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    li {
      padding-left: 5px;
      line-height: 24px;
      font-size: 16px;
      @media only screen and (max-width: $mobileView) {
        width: 129%;
      }
    }
  }
  .full-french {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 15px;
    @media only screen and (max-width: $mobileView) {
      grid-template-columns: auto;
    }
    .Image1,
    .Image2,
    .Image3,
    .Image4 {
      position: relative;
    }
    .footer-link {
      // position: absolute;
      // bottom: 16px;
      // right: 16px;
      text-align: right;
      margin: 0px 16px 16px 0px;
      @media only screen and (max-width: $mobileView) {
        margin-top: 24px;
      }
    }
    .Image1 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 224px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          width: 100%;
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding-left: 15px;
          padding-top: 15px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding-left: 15px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-bottom: 40px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 15px;
          padding-top: 5px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image2 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 224px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          width: 100%;
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding-left: 15px;
          padding-top: 15px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding-left: 15px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          line-height: 24px;
          font-size: 16px;
          float: right;
          padding-right: 15px;
          padding-top: 5px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image3 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 224px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
        .instructionimage {
          @media only screen and (max-width: $mobileView) {
            width: 100%;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding-left: 15px;
          padding-top: 15px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding-left: 15px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 15px;
          padding-top: 5px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image4 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 224px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          width: 100%;
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding-left: 15px;
          padding-top: 15px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding-left: 15px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          margin-bottom: 40px;
          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0px;
          }
        }
        a {
          float: right;
          padding-right: 15px;
          padding-top: 46px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
  }
}
.whole-block-presto-french {
  // padding-left: 170px;
  // padding-right: 154px;
  background-color: $white;
  padding-top: 48px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
  }
  .display-4 {
    font-size: 26px;
    // margin-top: 0px;
    // margin-bottom: 24px;
    width: 944px;
    margin: 0 auto 24px;
    line-height: 40px;
    @media only screen and (max-width: $mobileView) {
      width: unset;
      line-height: 32px;
      font-size: 22px;
    }
  }
  .contentDescription {
    font-family: $regularFont;
    color: black;
    width: 944px;
    margin: 0 auto;
    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
    p {
      line-height: 24px;
      font-size: 16px;
      margin-top: 0px;
      margin-bottom: 24px;
    }
  }
  .para-content {
    padding-top: 32px;
    padding-bottom: 56px;
    width: 944px;
    margin: 0 auto;
    @media only screen and (max-width: $mobileView) {
      padding-right: 22px;
      padding-top: 0px;
      padding-bottom: 32px;
      width: unset;
    }
    p {
      line-height: 24px;
      font-size: 16px;
      font-family: $regularFont;
      width: 71%;
      margin-top: 0px;
      margin-bottom: 0px;
      @media only screen and (max-width: $mobileView) {
        width: 110%;
      }
    }
  }

  .full-presto-french {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 15px;
    width: 944px;
    margin: 0 auto;
    @media only screen and (max-width: $mobileView) {
      grid-template-columns: auto;
      margin-bottom: 24px;
      width: unset;
    }
    .Image1,
    .Image2,
    .Image3,
    .Image4 {
      position: relative;
      padding-bottom: 40px;
    }
    .footer-link {
      position: absolute;
      bottom: 16px;
      right: 16px;
      text-align: right;
    }
    .Image1 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 222px;
      margin-right: 31px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding-left: 15px;
          padding-top: 15px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding-left: 15px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          width: 85%;
          @media only screen and (max-width: $mobileView) {
            width: 89%;
            margin-top: 8px;
            margin-bottom: 24px;
          }
        }
        a {
          float: right;
          padding-right: 15px;
          padding-top: 40px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image2 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 222px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        padding-bottom: 15px;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding-left: 15px;
          padding-top: 15px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding-left: 15px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          width: 82%;
          @media only screen and (max-width: $mobileView) {
            width: 89%;
            margin-top: 8px;
            margin-bottom: 24px;
          }
        }
        a {
          float: right;
          padding-left: 18px;
          padding-top: 0;
          margin-top: -9px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image3 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 222px;
      @media only screen and (max-width: $mobileView) {
        margin-bottom: 16px;
        height: auto;
        padding-bottom: 15px;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding-left: 15px;
          padding-top: 15px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding-left: 15px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          width: 76%;
          @media only screen and (max-width: $mobileView) {
            width: 89%;
            margin-top: 8px;
            margin-bottom: 24px;
          }
        }
        a {
          float: right;
          padding-left: 17px;
          padding-top: 63px;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 0px;
            padding-bottom: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .Image4 {
      border: 1px solid #dadada;
      background-color: $white;
      width: 222px;
      @media only screen and (max-width: $mobileView) {
        height: auto;
        width: 100%;
      }
      .instructionimage {
        @media only screen and (max-width: $mobileView) {
          display: flex !important;
        }
        img {
          height: auto !important;
          width: 100% !important;
          @media only screen and (max-width: $mobileView) {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .text-container {
        width: 100%;
        .instructionTitle {
          padding-left: 15px;
          padding-top: 15px;
          font-family: $boldFont;
          line-height: 24px;
          font-size: 16px;
          @media only screen and (max-width: $mobileView) {
            padding-right: 15px;
          }
        }
        p {
          padding-left: 15px;
          font-family: $regularFont;
          line-height: 24px;
          font-size: 16px;
          width: 88%;
          @media only screen and (max-width: $mobileView) {
            width: 89%;
            margin-top: 8px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
.loading-transit-pass-container {
  padding-top: 15px;
  background-color: #ffffff;
  width: 100%;
  border: 1px solid #dadada;
  margin-bottom: 16px;
  :last-child {
    border-bottom: none !important;
  }
  @media only screen and (max-width: $mobileView) {
    // margin-left: 20px;
    // width: 89%;
  }
  .loading-transit-pass-subcontainer {
    border-bottom: 1px solid #dadada;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 32px;
    @media only screen and (max-width: $mobileView) {
      padding-left: 16px;
      padding-right: 16px;
      //  width: 90%;
    }
    .loading-transit-pass-process {
      display: block;
      .transit-pass-process-heading {
        font-size: 22px;
      }
      p {
        font-family: $regularFont;
        width: 95%;
        line-height: 1.5em;
        margin-bottom: 22px;
      }
      .arrow-icon1 {
        .instructionimage {
          margin-left: 6px;
          display: flex !important;
          a {
            padding-left: 4px;
          }
        }
      }
      ul {
        padding-left: 26px;
        @media only screen and (max-width: $mobileView) {
          width: 85%;
        }
        li {
          margin-bottom: 10px;
          padding-left: 10px;
        }
      }
    }
  }
}
.Customer-service-para {
  margin-top: 32px;
  margin-bottom: 32px;
  p {
    font-family: $regularFont;
    color: #191919;
    font-size: 16px;
    line-height: 24px;
    padding-right: 1px;
    @media only screen and (max-width: $mobileView) {
      margin-top: 5px;
      width: 100%;
    }
  }
}
.Customer-service-subpara {
  padding-bottom: 10px;
  p {
    margin-bottom: 32px;
    font-style: italic;
    font-family: 'AvenirNextLTPro-Regular';
  }
  h4 {
    font-family: $boldFont;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
    margin-top: 0px;
    color: #191919;
    @media only screen and (max-width: $mobileView) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0px;
    }
  }
  ul {
    font-family: $regularFont;
    margin-bottom: 24px;
    @media only screen and (max-width: $mobileView) {
      margin-top: 8px;
      margin-bottom: 16px;
    }
    li {
      font-size: 16px;
      line-height: 24px;
      color: #191919;
      padding-bottom: 8px;
    }
  }
  em {
    font-family: 'Avenir Next LT Pro Itlaic';
    line-height: 24px;
    font-size: 16px;
    color: #191919;
  }
}
.The-Presto-App {
  border: 1px solid #dadada;
  padding: 24px 32px 32px 32px;
  display: grid;
  grid-template-columns: auto auto;
  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 12px;
  }
  p {
    font-family: $regularFont;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 91%;
    margin-right: 40px;
    @media only screen and (max-width: $mobileView) {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .Button {
    text-align: center;
    padding-top: 24px;
    width: 136px;
    height: 48px;
    @media only screen and (max-width: $mobileView) {
      padding-bottom: 24px;
      width: unset;
      height: unset;
      padding-top: 0px;
    }
    a {
      background: black;
      color: white;
      text-decoration: none;
      padding: 12px 16px;
      // // padding: 15px;
      // // padding-left: 30px;
      // // padding-right: 30px;
      // @media only screen and (max-width: $mobileView) {
      //   padding: 12px 16px;
      // }
    }
  }
}
.The-Presto-App1 {
  border: 1px solid #dadada;
  padding: 24px 32px 32px 32px;
  display: flex;
  background-color: white;
  margin-bottom: 16px;
  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px;
  }
  p {
    font-family: $regularFont;
    width: 100.6%;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    // padding-left: 15px;
    @media only screen and (max-width: $mobileView) {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }
  }
  .Button {
    text-align: center;
    padding-top: 35px;
    width: 75%;
    @media only screen and (max-width: $mobileView) {
      padding-bottom: 60px;
    }
    a {
      background: black;
      color: white;
      text-decoration: none;
      padding: 15px;
      padding-left: 30px;
      padding-right: 30px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 75px;
        padding-right: 75px;
      }
    }
  }
}
.The-Presto-App2 {
  border: 1px solid #dadada;
  padding: 24px 32px 32px 32px;
  display: flex;
  background-color: white;
  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px;
  }
  .fare-Table {
    @media only screen and (max-width: $mobileView) {
      padding-right: 0px;
      padding-left: 0px;
    }
    h3 {
      background-color: #dedede;
      margin-top: 0;
      margin-bottom: 0;
      padding: 21px 0px 22px 16px;
      font-size: 14px;
      line-height: 21px;
      @media only screen and (max-width: $mobileView) {
        font-size: 12px;
        line-height: 16px;
        padding: 8px 8px 16px;
      }
    }
    table {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 2px solid #d1d1d1 !important;
      border-collapse: collapse;
    }
    tr:nth-child(even) {
      background-color: #f3f3f3;
    }
    tr :last-child {
      border-right: none !important;
    }
    td {
      border-top: none !important;
      border-left: none !important;
      border-bottom: none !important;
      border-right: 1px solid #d1d1d1 !important;
      vertical-align: top;
      p {
        font-family: $regularFont;
        font-size: 14px;
        line-height: 21px;
        padding: 10px 16px 16px 16px;
        margin-top: 0px;
        margin-bottom: 0px;
        @media only screen and (max-width: $mobileView) {
          font-size: 12px;
          line-height: 21px;
          padding: 8px;
        }
      }
      .bold-heading {
        font-family: $boldFont;
        padding: 10px 16px 0px;
        margin-bottom: 0px;
        margin-top: 0px;
        @media only screen and (max-width: $mobileView) {
          font-size: 12px;
          line-height: 16px;
          padding: 8px;
        }
      }
    }
  }
  .setchild-content-para {
    font-family: $regularFont;
    width: 95%;
    line-height: 24px;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 24px;
    @media only screen and (max-width: $mobileView) {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      margin-bottom: 32px;
    }
  }
  .Button {
    text-align: center;
    padding-top: 35px;
    width: 75%;
    @media only screen and (max-width: $mobileView) {
      padding-bottom: 60px;
    }
    a {
      background: black;
      color: white;
      text-decoration: none;
      padding: 15px;
      padding-left: 30px;
      padding-right: 30px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 75px;
        padding-right: 75px;
      }
    }
  }
}
.The-Presto-App-french {
  border: 1px solid #dadada;
  padding: 15px;
  display: flex;
  @media only screen and (max-width: $mobileView) {
    display: flex;
    flex-direction: column;
  }
  p {
    font-family: $regularFont;
    width: 95%;
    line-height: 22px;
    padding-left: 15px;
  }
  .Button {
    text-align: center;
    padding-top: 50px;
    width: 100%;
    @media only screen and (max-width: $mobileView) {
      padding-bottom: 60px;
      width: 100%;
    }
    a {
      background: black;
      color: white;
      text-decoration: none;
      padding: 15px;
      padding-left: 30px;
      padding-right: 30px;
      @media only screen and (max-width: $mobileView) {
        padding-left: 75px;
        padding-right: 75px;
      }
    }
  }
}
#new-to-presto-table-desktop {
  width: 944px;
  margin: 0 auto 50px;
  @media only screen and (max-width: $mobileView) {
    display: none;
  }
  table {
    border-collapse: collapse;
    border-bottom: 2px solid #dadada;
    @media only screen and (max-width: $mobileView) {
      margin-left: 30px;
    }
    tr:nth-child(even) {
      background-color: #fff;
    }
    th {
      background-color: #dfdede;
      padding-top: 10px;
      padding-bottom: 20px;
      text-align: left;
      border-right: 1px solid #c7c6c6;
      padding-left: 20px;
      width: 20%;
      strong {
        font-size: 1rem;
      }
    }
    tr {
      td {
        border-right: 1px solid #c7c6c6;
        width: 20%;
        padding-left: 16px;
        vertical-align: top;
        line-height: 21px;
        padding-right: 16px;
        font-size: 14px;
        position: relative;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 83%;
          height: 100%;
          position: absolute;
          @media only screen and (max-width: $mobileView) {
            width: 100%;
          }
        }
        p {
          font-size: 14px;
        }
        .inline-paragraphs {
          font-family: $regularFont;
          width: 96%;
        }
        .inline-links {
          font-family: $boldFont;
          .para-link {
            font-family: $regularFont;
            display: block;
          }
        }
        .first-row-ul {
          font-size: 14px;
          padding-left: 25px;
          font-family: $boldFont;
        }
        .other-option-lists {
          font-size: 14px;
          padding-left: 25px;
          font-family: $regularFont;
          width: 75%;
        }
      }
    }
    .side-headings {
      font-family: $boldFont;
      width: 85%;
    }
  }
}
#new-to-presto-table-mobile {
  display: none;
  @media only screen and (max-width: $mobileView) {
    display: table;
    margin-bottom: 15px;
  }
  table {
    @media only screen and (max-width: $mobileView) {
      margin-left: 16px;
      margin-right: 16px;
      width: -webkit-fill-available;
      border-collapse: collapse;
      border-bottom: 2px solid #dadada;
      margin-bottom: 20px;
    }
    tr:nth-child(even) {
      background-color: #fff;
    }
    th {
      @media only screen and (max-width: $mobileView) {
        padding-bottom: 10px;
        padding-left: 10px;
        background-color: #dfdede;
        padding-top: 10px;
        text-align: left;
      }
      p {
        @media only screen and (max-width: $mobileView) {
          margin-bottom: 0px;
          margin-top: 0px;
          font-size: 14px;
        }
      }
    }
    tr {
      .row-data-left {
        @media only screen and (max-width: $mobileView) {
          width: 32%;
          padding-top: 10px;
          font-size: 12px;
          padding-left: 10px;
          vertical-align: top;
          line-height: 1.5em;
          border-right: 1px solid #c7c6c6;
          padding-bottom: 10px;
        }
        img {
          margin-top: 20px;
        }
        p {
          font-size: 14px;
        }
        .first-row-ul {
          font-size: 14px;
          padding-left: 25px;
          font-family: $boldFont;
        }
        .other-option-lists {
          font-size: 14px;
          padding-left: 25px;
          font-family: $regularFont;
          width: 75%;
        }
      }
      .row-data-right {
        @media only screen and (max-width: $mobileView) {
          width: 68%;
          // vertical-align: top;
          line-height: 1.5em;
        }
        .first-row-ul {
          @media only screen and (max-width: $mobileView) {
            font-size: 12px;
            margin-top: 6px;
            padding-left: 25px;
            font-family: 'AvenirNextLTPro-Demi', sans-serif;
          }
          li {
            @media only screen and (max-width: $mobileView) {
              padding-left: 5px;
            }
          }
        }
        div {
          @media only screen and (max-width: $mobileView) {
            // padding-top: 8px;
          }
        }
        .other-option-lists {
          @media only screen and (max-width: $mobileView) {
            font-family: 'AvenirNextLTPro-Regular';
            font-size: 12px;
            padding-left: 25px;
            width: 70%;
          }
          li {
            @media only screen and (max-width: $mobileView) {
              padding-left: 5px;
            }
          }
        }
        .inline-paragraphs {
          @media only screen and (max-width: $mobileView) {
            font-family: $regularFont;
            width: 86%;
            margin-left: 15px;
            font-size: 12px;
            line-height: 1.5em;
          }
        }
        .inline-links {
          @media only screen and (max-width: $mobileView) {
            font-family: $boldFont;
            margin-left: 15px;
            font-size: 12px;
            line-height: 1.5em;
          }
          .para-link {
            @media only screen and (max-width: $mobileView) {
              font-family: $regularFont;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.paper-content-para {
  color: #191919;
  font-family: $regularFont;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border: 1px solid #dadada;
  background-color: white;
  // padding-left: 30px;
  // padding-right: 30px;
  // padding-top: 10px;
  // padding-bottom: 12px;
  padding: 24px 32px 32px;
  @media only screen and (max-width: $mobileView) {
    // width: 80%;
    //padding-left: 16px;
    //padding-top: 5px;
    padding: 16px 16px 24px;
  }
  p {
    font-family: $regularFont;
    @media only screen and (max-width: $mobileView) {
      // width: 105%;
      font-size: 16px;
      line-height: 24px;
      font-family: $regularFont;
    }
  }
  .arrow-icon {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 15px;
      margin-top: -25px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 170%;
      @media only screen and (max-width: $mobileView) {
        width: 280%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .arrow-icon1 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 305%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .arrow-icon2 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 220%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .arrow-icon3 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 15px;
      margin-top: -25px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 130%;
      @media only screen and (max-width: $mobileView) {
        width: 280%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.TTC-content-para {
  color: #191919;
  font-family: $regularFont;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border: 1px solid #dadada;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  @media only screen and (max-width: $mobileView) {
    width: unset;
    padding-left: 20px;
    padding-top: 5px;
    margin-top: 70px;
  }
  p {
    font-family: $regularFont;
    @media only screen and (max-width: $mobileView) {
      width: 105%;
      font-size: 19px;
      line-height: 30px;
    }
  }
  .arrow-icon {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 15px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 170%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 570%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 28px;
      }
    }
  }
  .arrow-icon3 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 15px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 130%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 440%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 28px;
      }
    }
  }
}
.paper-content-para1 {
  color: #191919;
  font-family: $regularFont;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: white;
  padding: 24px 32px 32px;
  @media only screen and (max-width: $mobileView) {
    padding: 16px 16px 24px;
    margin-top: 0;
  }
  p {
    font-family: $regularFont;
    @media only screen and (max-width: $mobileView) {
      margin-top: 0;
      font-size: 16px;
      line-height: 24px;
      font-family: $regularFont;
    }
  }
  .arrow-icon1 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 15px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 139%;
        height: 16.5px;
        width: 11.5px;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .arrow-icon2 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 280%;
      }
    }
    a {
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .arrow-icon3 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 205%;
      }
    }
    a {
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.defective-content-para1 {
  margin-top: 20px;
  color: #191919;
  font-family: $regularFont;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 20px;
  @media only screen and (max-width: $mobileView) {
    // width: 118%;
    padding-left: 20px;
    padding-top: 5px;
    margin-top: 70px;
  }
  p {
    font-family: $regularFont;
    @media only screen and (max-width: $mobileView) {
      // width: 106%;
      font-size: 19px;
      line-height: 30px;
    }
  }
  .arrow-icon3 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 205%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.setup-content-para1 {
  margin-top: 20px;
  color: #191919;
  font-family: $regularFont;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 20px;
  @media only screen and (max-width: $mobileView) {
    width: 87%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
  }
  p {
    font-family: $regularFont;
    @media only screen and (max-width: $mobileView) {
      // width: 106%;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .arrow-icon1 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        // width: 139%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .arrow-icon2 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        // width: 135%;
      }
    }
    a {
      @media only screen and (max-width: $mobileView) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.setup-content-parafrench {
  margin-top: 20px;
  color: #191919;
  font-family: $regularFont;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 20px;
  @media only screen and (max-width: $mobileView) {
    width: unset;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    margin-top: 100px;
  }
  p {
    font-family: $regularFont;
    @media only screen and (max-width: $mobileView) {
      width: 106%;
      font-size: 19px;
      line-height: 30px;
    }
  }
  .arrow-icon1 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 139%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 28px;
      }
    }
  }
  .arrow-icon2 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 135%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 28px;
      }
    }
  }
}
.register-content-para1 {
  margin-top: 20px;
  color: #191919;
  font-family: $regularFont;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 20px;
  @media only screen and (max-width: $mobileView) {
    width: 118%;
    padding-left: 20px;
    padding-top: 5px;
    margin-top: 70px;
  }
  p {
    font-family: $regularFont;
    @media only screen and (max-width: $mobileView) {
      width: 106%;
      font-size: 19px;
      line-height: 30px;
    }
  }
  .arrow-icon1 {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 101%;
    @media only screen and (max-width: $mobileView) {
      padding-left: 10px;
    }
    .instructionimage {
      padding-right: 10px;
      padding-top: 5px;
      @media only screen and (max-width: $mobileView) {
        padding-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 17px;
      @media only screen and (max-width: $mobileView) {
        width: 139%;
      }
    }
    a {
      font-family: $boldFont;
      @media only screen and (max-width: $mobileView) {
        font-size: 1.2rem;
        line-height: 28px;
      }
    }
  }
}
.note-para {
  font-family: $regularFont;
  margin-left: auto;
  margin-right: auto;
  width: 944px;
  margin-top: 48px;
  margin-bottom: 80px;
  @media only screen and (max-width: $mobileView) {
    margin-top: 32px;
    margin-bottom: 64px;
    margin-left: 16px;
    margin-right: 16px;
    width: 91%;
  }
  .para {
    margin-bottom: 32px !important;
    @media only screen and (max-width: $mobileView) {
      margin-left: 0px;
    }
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 24px;
    color: #191919;
    width: 64%;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
    }
  }
  ul {
    margin-top: 16px;
    margin-bottom: 16px;
    font-family: $boldFont;
    font-size: 16px;
    line-height: 32px;
    color: #191919;
    padding-left: 27px;
    @media only screen and (max-width: $mobileView) {
      font-size: 14px;
      line-height: 21px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    li {
      padding-left: 8px;
      @media only screen and (max-width: $mobileView) {
        padding-bottom: 8px;
      }
    }
  }
}
.Tax-credit-para {
  padding-top: 48px;
  border-top: 1px solid #d1d1d1;
  @media only screen and (max-width: $mobileView) {
    padding-top: 32px;
  }
  .content-description {
    margin: 0 auto;
    width: 944px;
    border-bottom: 1.5px solid #d1d1d1;
    padding-bottom: 48px;
    @media only screen and (max-width: $mobileView) {
      padding-bottom: 32px;
      margin-left: 22px;
      margin-right: 22px;
      width: 86%;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-family: $regularFont;
      width: 75%;
      margin-top: 0px;
      margin-bottom: 0px;
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
    }
  }
}
.commercial-para {
  border: 1px solid #e8e8e8;
  background-color: #fff;
  padding: 24px 24px 32px 24px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 24px;
    color: #191919;
    font-family: $regularFont;
  }
  ul {
    font-size: 16px;
    line-height: 24px;
    color: #191919;
    font-family: $regularFont;
    padding-left: 27px;
    margin-top: 24px;
    margin-bottom: 0px;
    li {
      padding-left: 8px;
      @media only screen and (max-width: $mobileView) {
        padding-bottom: 8px;
      }
    }
  }
}
.Bulk-presto-para {
  border: 1px solid #e8e8e8;
  background-color: #fff;
  padding: 24px 24px 32px 24px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 16px;
    padding-right: 16px;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 24px;
    color: #191919;
    font-family: $regularFont;
  }
  ul {
    font-size: 16px;
    line-height: 24px;
    color: #191919;
    font-family: $regularFont;
    padding-left: 27px;
    margin-top: 24px;
    margin-bottom: 0px;
    li {
      padding-left: 8px;
      @media only screen and (max-width: $mobileView) {
        padding-bottom: 8px;
      }
    }
  }
}
.new-presto-para {
  font-family: 'AvenirNextLTPro-Regular';
  font-size: 16px;
  line-height: 24px;
  width: 944px;
  margin: 48px auto 40px;
  padding-bottom: 48px;
  border-bottom: 1px solid #bababa;
  @media only screen and (max-width: $mobileView) {
    width: unset;
    margin: 32px 16px;
    padding-bottom: 32px;
  }
  span {
    display: block;
    margin-top: 24px;
  }
  p {
    margin-bottom: 0px;
    width: 74.5%;
    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
}
