@import '../../../assets/scss/vars';

.agencyOption {
  background-color: $white;
  border-bottom: 2px solid $secondary20;
  position: relative;

  .agencyOption-wrapper {
    padding: $spacing-1 $spacing-1 $spacing-1 $spacing-7;
    position: relative;

    @media only screen and (min-width: $mobileView) {
      display: flex;
    }
  }

  &.active {
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 4px;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $black;
    }

    .agencyOption-autorenew {
      display: block;
    }
  }

  .agencyOption-title {
    line-height: 24px;
  }

  .agencyOption-date {
    font-family: $regularFont;
  }

  .agencyOption-radio {
    position: absolute;
    left: $spacing-2;
    top: 19px;

    @media only screen and (min-width: $mobileView) {
      top: 50%;
      margin-top: -10px;
    }
  }

  .agencyOption-save {
    font-family: $regularFont;
    line-height: 24px;
  }

  .agencyOption-title-block {
    @media only screen and (min-width: $mobileView) {
      flex: 1;
      align-items: center;
      display: flex;
    }
  }

  .agencyOption-value-block {
    margin-top: $spacing-base;
    max-width: 160px;

    @media only screen and (min-width: $mobileView) {
      text-align: right;
      display: flex;
      align-items: center;
    }
  }

  .agencyOption-plan-link {
    line-height: 24px;
  }

  .agencyOption-title-logo-block {
    display: none;

    @media only screen and (min-width: $mobileView) {
      padding: 0 $spacing-1;
      display: flex;
      align-items: center;
    }
  }

  .agencyOption-title-logo {
    min-height: 1px;
    width: 64px;
  }

  .agencyOption-autorenew {
    border-top: 1px solid $secondary20;
    padding: $spacing-base 0;
    margin: 0 $spacing-1 0 $spacing-8;
    line-height: 24px;
    display: none;

    @media only screen and (max-width: $mobileView) {
      padding: $spacing-1 0;
      margin-left: $spacing-7;
    }

    label {
      margin-bottom: $spacing-2;
    }

    .dates {
      font-family: $regularFont;
      border-top: 1px solid $secondary20;
      padding: $spacing-1 0;
      line-height: 21px;

      @media only screen and (min-width: $mobileView) {
        padding: $spacing-1 0;
      }

      .description {
        padding-bottom: $spacing-1;
        font-size: 16px;

        @media only screen and (min-width: $mobileView) {
          font-size: 18px;
        }
      }

      .label {
        font-family: $boldFont;
        margin: $spacing-base 0;
        font-size: 14px;
      }

      .selectbox {
        margin-top: $spacing-1;
        @media only screen and (min-width: $mobileView) {
          width: 144px;
          display: inline-block;
          margin: 0;

          + .selectbox {
            margin-left: $spacing-1;
          }
        }
      }
    }
  }

  .agencyOption-autorenew-text {
    font-family: $regularFont;
    margin-bottom: $spacing-base;

    strong {
      font-family: $boldFont;
    }

    @media only screen and (min-width: $mobileView) {
      padding: $spacing-1 0;
      font-size: 18px;
    }
  }
}
