@import '../../assets/scss/vars';

.caslPopUp {
    @media screen and (min-width: $mobileView) {
        .modal-wrapper {
            padding: 0;

            .modal-title {
                padding: $spacing-2 $spacing-2 $spacing-1;
            }

            .button-wrapper {
                margin: $spacing-2;
            }
        }
    }
    .modal-overlay {
        z-index:2010;
    }
    .modal-wrapper{
        z-index: 2011;
    &:focus-visible{
        outline:none;
    }
}
    #modal-description {
        .caslCheckbox {
            margin-top: $spacing-2;
            padding-left: 6px;
            cursor: pointer;
        }
    }

    @media screen and (max-width: $mobileView) {
        .modal-title {
            line-height: 40px;
        }

        #modal-description {
            .caslCheckbox {
                margin-top: $spacing-2;
            }
        }

        .button-wrapper {
            margin: $spacing-2 0 $spacing-base;
        }
    }
}