@import '../../assets/scss/vars.scss';

.presto-products_accordian {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  width: 944px;
  margin-bottom: 15px;
  position: relative;
  // padding: 25px 0 25px 0px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: $mobileView) {
    margin-left: 16px;
    margin-right: 16px;
    width: unset;
  }

  button {
    text-align: left;
    width: 100%;
    padding: 25px 0;

    img {
      padding: 4px;
    }
  }

  button:focus:not(:focus-visible) {
    outline: none;
  }

  .side-icons {
    margin-right: 16px;
    margin-left: 40px;

    @media only screen and (max-width: $mobileView) {
      margin-left: 8px;
    }
  }

  .home-accordian-heading {
    display: inline-block;
    position: absolute;
    top: 22px;

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      color: #191919;
    }
  }

  .arrow-icon,
  .up {
    position: absolute;
    right: 15px;
    top: 30px;
  }

  .up {
    transform: rotate(180deg);
  }

  .home-accordian-description {
    display: flex;
    margin-top: 20px;
    border-top: 1px solid #e8e8e8;
    margin-bottom: 10px;
    padding-left: 25px;

    @media only screen and (max-width: $mobileView) {
      display: block;
      // padding-left: 0px;
      margin-bottom: 0px;
      margin-top: 10px;
      padding: 16px 17px;
    }

    img {
      width: 50%;
      height: 50%;
      margin-right: 40px;
      margin-top: 35px;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
        margin-top: 15px;
        margin-right: 0px;
      }
    }

    .presto-accordians-sub-description {
      font-family: $regularFont;
      width: 95%;
      margin-top: 35px;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: 97%;
        margin-top: 10px;
        margin-bottom: 0px;
      }

      a {
        margin-top: 20px;
        display: block;

        @media only screen and (max-width: $mobileView) {
          text-align: right;
          margin-top: 8px;
          margin-right: 5px;
        }
      }
    }
  }
}

.Accordianitem-image {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  margin-bottom: 16px;
  position: relative;

  button.accordion-heading:focus {
    outline-width: initial;
  }

  button {
    text-align: left;
    outline: none;
    width: 100%;

    padding: 32px 24px;

    @media only screen and (max-width: $mobileView) {
      padding: 24px 16px 24px 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
    }

    // border-bottom: 1px solid #313030;
  }

  .side-icons {
    margin-right: 24px;

    // margin-left: 25px;
    @media only screen and (max-width: $mobileView) {
      margin-left: 0px;
      margin-right: 16px;
      height: 100%;
      flex-basis: 45%;
      max-width: 48px;
    }
  }

  .home-accordian-heading {
    display: inline-block;
    position: absolute;
    top: 16px;
    color: black;

    @media only screen and (max-width: $mobileView) {
      margin-top: unset;
      margin-bottom: unset;
      line-height: 24px;
      top: unset;
      flex-basis: 55%;
      text-align: left;
      position: relative;
    }
  }

  button:focus:not(:focus-visible) {
    outline: none;
  }

  .arrow-icon,
  .up {
    position: absolute;
    right: 16px;
    top: 30px;

    @media only screen and (max-width: $mobileView) {
      top: 24px;
      width: 24px;
    }
  }

  .up {
    transform: rotateX(180deg);
  }

  .home-accordian-description {
    display: flex;
    border-top: 1px solid #dadada;
    //  margin-top: 24px;
    padding: 16px 24px;

    @media only screen and (max-width: $mobileView) {
      display: block;
      padding-left: 0px;
      margin-bottom: 0px;
      //  margin-top: 8px;
      padding: 8px 0px 0px 8px;
    }

    p {
      font-family: $regularFont;
      line-height: 1.5rem;
    }

    ul {
      padding-left: 30px;

      li {
        font-family: $regularFont;
        line-height: 30px;
      }
    }

    h3 {
      font-size: 22px;
      line-height: 32px;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    .accordian-table {
      margin-top: 32px;

      ~ h3 {
        @media only screen and (max-width: $mobileView) {
          font-size: 18px;
          line-height: 28px;
        }
      }

      ~ p {
        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
        }
      }

      ~ ul {
        @media only screen and (max-width: $mobileView) {
          font-size: 14px;
          line-height: 21px;
          padding-left: 24px;
        }
      }

      h3 {
        background-color: #dedede;
        margin-top: 0;
        margin-bottom: 0;
        padding: 21px 16px;
        font-size: 14px;

        @media only screen and (max-width: $mobileView) {
          font-size: 12px;
          line-height: 16px;
          padding: 8px 8px 16px;
        }
      }

      table {
        border: none !important;
        border-collapse: collapse;
        border-bottom: 2px solid #d1d1d1 !important;
      }

      tr :last-child {
        border-right: none !important;
        // @media only screen and (max-width: $mobileView) {
        //   width: 70% !important;
        // }
      }

      // tr :first-child {
      //   @media only screen and (max-width: $mobileView) {
      //     width: 30% !important;
      //   }
      // }
      tr:nth-child(even) {
        background-color: #f3f3f3;
      }

      td:nth-child(2) {
        white-space: inherit;
      }

      td {
        border-top: none !important;
        border-bottom: none !important;
        border-left: none !important;
        border-right: 1px solid #d1d1d1 !important;
        vertical-align: top;
        white-space: nowrap;

        p {
          font-family: $regularFont;
          font-size: 14px;
          line-height: 21px;
          padding: 0 16px;
          margin-top: 0px;
          margin-bottom: 0px;

          @media only screen and (max-width: $mobileView) {
            font-size: 12px;
            line-height: 21px;
            padding: 0px 8px;
            width: unset !important;
          }
        }

        p:first-child {
          padding-top: 10px;
          @media only screen and (max-width: $mobileView) {
            padding-top: 8px;
          }
        }
        p:last-child {
          padding-bottom: 10px;
          @media only screen and (max-width: $mobileView) {
            padding-bottom: 8px;
          }
        }

        .bold-heading {
          font-family: $boldFont;
          padding: 10px 16px 0px;
          margin-top: 0px;
          margin-bottom: 0px;

          @media only screen and (max-width: $mobileView) {
            font-size: 12px;
            line-height: 16px;
            padding: 8px 8px;
          }
        }
      }
    }

    .accordian-table1 {
      // padding-right: 15px;
      margin-top: 32px;

      h3 {
        background-color: #dedede;
        margin-top: 0;
        margin-bottom: 0;
        padding: 21px 16px;
        font-size: 14px;
      }

      table {
        border: none !important;
        border-collapse: collapse;
        border-bottom: 2px solid #d1d1d1 !important;
      }

      tbody {
        tr {
          vertical-align: top;

          th {
            font-family: $boldFont;
            background-color: #dedede;
            font-size: 14px;
            line-height: 21px;
            text-align: left;
            padding: 8px;
            border-right: 1px solid #d1d1d1;
          }

          td {
            font-family: $regularFont;
            font-size: 14px;
            line-height: 21px;
            padding: 10px 16px 16px 16px;
          }

          .rowname {
            font-family: $boldFont;
          }
        }
      }

      tr:nth-child(even) {
        background-color: #f3f3f3;
      }

      tr :last-child {
        border-right: none !important;
      }

      td {
        border-top: none !important;
        border-bottom: none !important;
        border-left: none !important;
        border-right: 1px solid #d1d1d1 !important;

        p {
          font-family: $regularFont;
          font-size: 14px;
        }

        .bold-heading {
          font-family: $boldFont;
          padding-left: 17px;
        }
      }
    }

    img {
      width: 50%;
      height: 50%;
      margin-right: 11.75px;

      @media only screen and (max-width: $mobileView) {
        width: 94%;
        margin-top: 15px;
      }
    }

    .presto-accordians-sub-description {
      font-family: $regularFont;
      width: 95%;
      margin-top: 35px;
      line-height: 1.5em;

      @media only screen and (max-width: $mobileView) {
        width: 85%;
        margin-top: 10px;
        margin-bottom: 0px;
      }

      a {
        margin-top: 20px;
        display: block;

        @media only screen and (max-width: $mobileView) {
          text-align: right;
          margin-top: 5px;
        }
      }
    }

    .accordian-table-links {
      margin-bottom: 10px;

      @media only screen and (max-width: $mobileView) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 24px;

        img {
          margin-top: 0px;
        }
      }
    }
  }
  @media only screen and (max-width: $mobileView) {
    &button:nth-child(5) .side-icons {
      padding-bottom: 8px;
      max-width: 32px !important;
    }
  }
}
