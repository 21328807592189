@font-face {
  font-family: 'AvenirNextLTPro-Demi';
  src: url('../fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
    url('../fonts/AvenirNextLTPro-Demi.woff') format('woff'),
    url('../fonts/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNextLTPro-Regular';
  src: url('../fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('../fonts/AvenirNextLTPro-Regular.woff') format('woff'),
    url('../fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNextLTPro-Bold';
  src: url('../fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
    url('../fonts/AvenirNextLTPro-Bold.woff') format('woff'),
    url('../fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
