@import '../../assets/scss/vars.scss';

.map-content {
    @media screen and (max-width: $mobileView) {
        padding: 0px;
    }
}

// Filter container
.search-white-box {
    height: auto;
    width: 943px;
    background-color: #FFFFFF;

    @media screen and (max-width: $mobileView) {
        width: auto;
    }

    .address-or-postal-co {
        display: block;
        color: $black;
        font-family: $proBold;
        font-size: 24px;
        line-height: 29px;
        padding-top: 32px;
        margin: 0px 0px 8px 24px;

        @media screen and (max-width: $mobileView) {
            font-size: 22px;
            line-height: 24px;
            margin: 0px 16px 16px 16px;
            padding-top: 16px;
            font-family: $proDemi;
        }
    }

    #cso-search-input {
        position: relative;

        .pac-container {
            width: auto;
            left: 0 !important;
            top: 100% !important;
        }
    }

    .search-box {
        display: flex;
        border-bottom: 1px solid $black;
        margin: 0 24px;
        background-color: $secondary10;

        @media screen and (max-width: $mobileView) {
            margin: 0 16px;
        }

        .search-box-input {
            width: 100%;
            box-sizing: border-box;

            .text-filed-input {
                width: 100%;
                border: none;
                padding: 16px;
                box-sizing: border-box;
                color: $secondary80;
                background-color: $secondary10;
                font-size: 16px;
                font-family: $proBold;
                line-height: 19px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media screen and (max-width: $mobileView) {
                    font-family: $proDemi;

                }
            }

            .text-filed-input:focus-visible {
                outline-offset: -2px;
            }

            input[type="text"].text-filed-input::-webkit-input-placeholder,
            input[type="text"].text-filed-input::-moz-placeholder,
            input[type="text"].text-filed-input:-ms-input-placeholder,
            input[type="text"].text-filed-input:-moz-placeholder,
            input[type="text"].text-filed-input::placeholder {
                font-family: $proBold;
                font-size: 16px;
                line-height: 19px !important;
                color: $secondary80;
            }
        }

        .search-box-button-group {
            display: flex;
            background-color: $secondary10;
            align-items: center;

            .tooltip-button {
                position: relative;
                padding-top: 6px;
            }

            .tooltip-button:focus-visible {
                outline: 0.25rem solid $linkOutline;
            }

            .tooltip-text {
                visibility: hidden;
                width: 129px;
                background-color: $black;
                color: $white;
                font-family: "AvenirNextLTPro-Regular";
                border-radius: 5px;
                font-size: 14px;
                letter-spacing: 0.03px;
                line-height: 16px;
                text-align: center;
                padding: 8px;
                position: absolute;
                z-index: 1;
                bottom: calc(100% + 10.5px);
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                transition: opacity 0.3s;

                @media screen and (max-width: $mobileView) {
                    width: 117px;
                    padding: 6px 4px;
                }
            }

            .tooltip-text::after {
                content: "";
                position: absolute;
                bottom: -9px;
                left: 50%;
                transform: translateX(-50%);
                border-width: 5px;
                border-style: solid;
                border-color: $black transparent transparent transparent;
            }

            .tooltip-button:hover .tooltip-text {
                visibility: visible;
                opacity: 1;
            }

            .tooltip-button:focus .tooltip-text {
                visibility: visible;
                opacity: 1;
            }

            .search-icon-button {
                //padding: 0 12px 0px 0px;

                .icon-search {
                    cursor: pointer;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    height: 32px;
                    width: 32px;
                }
            }

            .search-icon-cso {
                padding-right: 15.5px;

                @media screen and (max-width: $mobileView) {
                    padding-right: 7.5px;

                }

                .icon-search {
                    cursor: pointer;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    height: 32px;
                    width: 32px;
                }
            }

            .vertical-line {
                border: 1px solid $black;
                height: 36px;
                margin-right: 15.5px;
                margin-left: 15.5px;

                @media screen and (max-width: $mobileView) {
                    margin-right: 7.5px;
                    margin-left: 7.5px;
                }
            }


            .close-icon-button {
                padding-right: 16px;

                @media screen and (max-width: $mobileView) {
                    padding: 12px;
                }

                .icon-close {
                    cursor: pointer;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    .filter-container {
        padding: 15px 25px;

        @media only screen and (max-width: $mobileView) {
            margin: 0px;
            padding: 0px;
        }

        .tabbed-content {
            position: relative;

            @media only screen and (max-width: $mobileView) {
                position: static;
            }

            .tabs-list {
                padding: 0px;
                margin: 0px;
                display: flex;
                border-bottom: 2px solid #D1D1D1;
                gap: 10px;

                @media only screen and (max-width: $mobileView) {
                    gap: 0px;
                }

                &.mobile {
                    flex-direction: column;
                    border: none;
                }

                button.selected {
                    border-top: 2px solid #191919;
                    background-color: #F3F3F3;
                }

                button.tab:focus-visible {
                    outline: 0.25rem solid $linkOutline;
                }

                button.tab {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    line-height: 17px;
                    padding-left: 10px;
                    cursor: pointer;
                    color: $secondary80;

                    @media only screen and (max-width: $mobileView) {
                        justify-content: space-between;
                        width: 100%;
                        padding-right: 10px;

                        label {
                            padding: 12px 8px;
                        }
                    }

                    label {
                        cursor: pointer;
                        text-align: left;
                    }

                    img {

                        &.up {
                            transform: rotate(180deg);
                        }
                    }
                }

                button.tab:focus {
                    @media only screen and (max-width: $mobileView) {
                        outline-offset: -4px;
                    }
                }

                li {
                    list-style: none;
                    background-color: #FFFFFF;
                    border-top: 2px solid transparent;


                }


            }

            .tab-panel {
                margin-top: 14px;

                &.hidden {
                    display: none;
                }

                &.current {
                    display: grid;
                    gap: 10px;
                    grid-template-columns: repeat(2, minmax(130px, 320px));

                    @media only screen and (max-width: $mobileView) {
                        grid-template-columns: 1fr;
                        grid-gap: 0px;
                        margin: 0px 18px;
                    }
                }

                .filter-item {
                    display: flex;
                    gap: 8px;

                    @media only screen and (max-width: $mobileView) {
                        margin: 10px 0px;
                    }

                    .filter-checkbox {
                        box-sizing: border-box;
                        height: 16px;
                        width: 16px;
                        min-width: 16px;
                        border: 1px solid #7D7D7D;
                        border-radius: 2px;
                        margin: 0px;
                        appearance: none;
                        border-radius: 2px;
                        padding: 0px;
                        flex-shrink: 0;
                        position: relative;
                        top: 2px;
                    }

                    .filter-checkbox:checked {
                        border-color: $black;
                        background: url('../../assets/images/Selected@3x.png') -6px center no-repeat;
                        background-size: 27px;
                    }

                    .filter-label {
                        margin-right: 10px;
                        color: #191919;
                        font-family: "AvenirNextLTPro-Regular";
                        font-size: 16px;
                        line-height: 19px;
                        word-wrap: break-word;
                        overflow: auto;
                    }
                }
            }

            .tab-divider {
                border-bottom: 1px solid #E8E8E8;
            }

            .cso-filter-action-container {
                display: flex;
                flex-grow: 1;
                justify-content: space-between;

                @media only screen and (max-width: $mobileView) {
                    display: block;
                }

                .clear-all-button {
                    color: #FFFFFF;
                    font-family: $proDemi;
                    font-size: 14px;
                    line-height: 17px;
                    border-radius: 4px;
                    background-color: #7D7D7D;
                    padding: 0px 8px;
                    margin-left: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;
                    text-align: left;

                    @media only screen and (max-width: $mobileView) {
                        position: static;
                    }

                    @media only screen and (max-width: $mobileView) {
                        line-height: 17px;
                        margin: 16px 0px 8px 16px;
                        padding: 4px 10px;
                    }

                    .cso-clearall-close {
                        position: relative;
                        width: 14px;
                        height: 14px;
                    }

                    .cso-clearall-close:before,
                    .cso-clearall-close:after {
                        position: absolute;
                        content: ' ';
                        height: 14px;
                        width: 2px;
                        background-color: #fff;
                        left: 4px;
                    }

                    .cso-clearall-close:before {
                        transform: rotate(45deg);
                    }

                    .cso-clearall-close:after {
                        transform: rotate(-45deg);
                    }


                    &.enable-btn {
                        background-color: #191919;
                    }
                }
            }
        }
    }
}

.skip-map-button {
    color: $black;
    font-family: $proDemi;
    font-size: 14px;
    line-height: 17px;
    border-radius: 4px;
    padding: 0px 8px;
    border: 1px solid $black;
    text-align: left;

    @media only screen and (max-width: $mobileView) {
        padding: 4px 10px;
        margin: 16px;
    }
}

// Map
#map {
    height: 482px;
    width: 943px;
    display: inline-block;

    @media only screen and (max-width: $mobileView) {
        width: 100%;
    }
}

.pac-container {
    z-index: 100000;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
}

.pac-item {
    padding: 0.5rem;
    cursor: pointer;
}

.pac-item:hover {
    background-color: #fafafa;
}

// Accordian
.cso-station-result-container {
    height: 100%;
    width: 943px;
    background-color: #FFFFFF;
    padding-bottom: 26px;
    margin-top: -4px;
    margin-bottom: 24px;

    @media only screen and (max-width: $mobileView) {
        width: auto;
        padding-bottom: 0;
    }

    .showing-stations {
        display: block;
        padding: 16px 0 16px 23px;
        // height: 16px;
        // width: 182px;
        color: #191919;
        font-family: "AvenirNextLTPro-Demi";
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;

        @media only screen and (max-width: $mobileView) {
            width: auto;
            padding: 16px 0 16px 16px;
        }
    }

    .stations-container {
        max-height: 314px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 2px 0px;

        @media only screen and (max-width: $mobileView) {
            padding: 0px;
            max-height: 100%;
            height: 100%;
            overflow-y: revert;
            overflow-x: revert;
        }

        /*  StationDetails Desktop Styles */
        .accordion-container {
            width: 895px;
            padding: 0px 25px 0px 23px;

            @media only screen and (max-width: $mobileView) {
                width: auto;
                padding: 0;
            }

            .accordian-title-with-border {
                box-sizing: border-box;
                border: 1px solid #D1D1D1;
                background-color: #FFFFFF;
            }

            .accordian-title-without-border {
                box-sizing: border-box;
                border: none;
                background-color: #FFFFFF;

            }

            #stationContainer {

                .accordion-header:focus,
                .accordion-header:focus-visible {
                    outline: 0.25rem solid $linkOutline;
                }

                .accordion-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px;
                    cursor: pointer;
                    transition: background-color 0.2s ease-in-out;

                    @media only screen and (max-width: $mobileView) {
                        width: auto;
                    }

                    .test-class {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: baseline;
                    }

                    .label {
                        color: $black;
                        font-family: "AvenirNextLTPro-Regular";
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 17px;
                    }

                    .station-name {
                        color: #191919;
                        font-family: "AvenirNextLTPro-Demi";
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 19px;

                        p {
                            margin: 0;
                            padding: 0 0 8px 0;
                        }
                    }

                    .image-label {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        @media only screen and (max-width: $mobileView) {
                            align-items: center;
                            gap: 3px;
                        }

                        img {
                            height: 14px;
                            width: 14px;
                            margin-left: -2px;

                            @media only screen and (max-width: $mobileView) {
                                height: 24px;
                                width: 24px;
                                margin-left: -3px;
                            }
                        }
                    }

                    img {
                        height: 32px;
                        width: 32px;

                        &.up {
                            transform: rotate(180deg);
                        }
                    }

                    &.expanded {
                        padding-bottom: 8px;
                    }
                }

                .accordion-header.expanded {
                    background-color: #F3F3F3;
                }
            }

            .accordion-content {
                padding: 0px 16px 16px 0px;
                background-color: #F3F3F3;

                @media only screen and (max-width: $mobileView) {
                    padding-right: 0px;
                }

                .get-directions {
                    display: flex;
                    justify-content: flex-start;
                    margin-left: 16px;
                    align-items: center;

                    img {
                        height: 20px;
                        width: 20px;
                        //background-color: #474747;
                    }

                    a {
                        cursor: pointer;
                        color: #474747;
                        font-family: "AvenirNextLTPro-Regular";
                        font-size: 14px;
                        letter-spacing: 0;
                        text-decoration: underline;
                        text-decoration-color: #474747;
                        margin-left: 8px;
                        line-height: 24px;

                        @media only screen and (max-width: $mobileView) {
                            font-size: 16px;
                        }
                    }
                }

                ul {
                    margin: 0px auto 0px 36px;
                    width: auto;
                    border-collapse: separate;
                    border-spacing: 0 4px;
                    padding: 4px 0px 0px 0px;
                    list-style: none;

                    @media only screen and (max-width: $mobileView) {
                        margin-left: 16px;
                    }

                    li.accordion-content-location-details {
                        display: flex;
                        gap: 24px;
                        font-family: "AvenirNextLTPro-Demi";
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 17px;
                        margin-bottom: 8px;
                        word-break: break-word;
                        text-align: left;
                        vertical-align: top;

                        @media only screen and (max-width: $mobileView) {
                            margin-bottom: 10px;
                            gap: 5px;
                            flex-direction: column;
                        }

                        .loc-detail-item p {
                            color: #191919;
                            font-family: "AvenirNextLTPro-Regular";
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 17px;
                        }

                        .loc-detail-item:nth-child(1) {
                            color: #7D7D7D;
                            flex-basis: 165px;
                            flex-shrink: 0;

                            @media only screen and (max-width: $mobileView) {
                                flex-basis: auto;
                                font-size: 12px;
                                line-height: 14px;
                            }
                        }

                        .loc-detail-item:nth-child(2) {
                            color: $black;
                            font-family: 'AvenirNextLTPro-Regular';

                            a.cso-result-link-txt {
                                color: $black;
                                font-family: $regularFont;
                                cursor: pointer;
                                text-decoration: underline;
                            }

                            @media only screen and (max-width: $mobileView) {
                                flex-basis: auto;
                            }
                        }

                    }
                }
            }
        }
    }

    .stations-container::-webkit-scrollbar {
        height: 108px;
        width: 6px;
    }

    .stations-container::-webkit-scrollbar-track {
        background-color: transparent;
        /* Set the background color of the scrollbar track */
    }

    .stations-container::-webkit-scrollbar-thumb {
        background-color: #BABABA;
        border-radius: 3px;
        /* Set the background color of the scrollbar thumb (the scrollbar button) */
    }

    .stations-container::-webkit-scrollbar-button {
        display: none;
        /* Hide the scrollbar arrows */
    }

}