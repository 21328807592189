@import '../../../assets/scss/vars';

.h2-title {
  display: block;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 16px;
  margin-top: 30px;

  @media only screen and (min-width: $mobileView) {
    font-size: 22px;
  }

  &.centered {
    text-align: center;
  }
}