@import './vars.scss';

body,
html {
  margin: 0;
  font-family: $boldFont;
  overflow: auto;
  height: 100%;
  background-color: $secondary5;

  @media screen and (min-width: $mobileView) {
    min-height: 100%;
    -ms-overflow-style: scrollbar;
    overflow:visible !important;
  }

  // @media only screen and (max-width: $mobileView) {
  //   height: auto;
  // }
  // CSS specific to Android device
    @supports not (-webkit-overflow-scrolling: touch) {
      @media only screen and (max-width: $mobileView) {
  
        body,
        html {
          overflow: visible !important;
          height: auto;
        }
      }
    }
}

.visible-hidden {
  visibility: hidden;
}

.goog-te-combo {
  background: rgba(0, 0, 0, 0.3);
  //background: transparent url("../images/global.svg") 0 50%/14px no-repeat;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  border: none;

  option {
    //color: black;
  }
}

body {
  position: relative !important;
}

.smartbanner {
  position: fixed !important;
}

.smartbanner-show {
  margin-top: 0px !important;
}

a {
  color: $black;
  font-family: $boldFont;

  &:hover {
    color: $secondary80;
  }
}

a.disable-link {
  text-decoration: none;
  color: inherit !important;
  cursor: text;
}

a:focus {
  outline-color: $linkOutline;
}

button:focus {
  outline: 0.25rem solid $linkOutline;
}

body {
  &.disable-scroll {
    overflow-y: hidden !important;
  }
}

strong {
  font-family: $boldFont;
}

.bold-font {
  font-family: $boldFont;
}

.regular-font {
  font-family: $regularFont;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-family: $boldFont;
  padding: 0;
  border-radius: 0;

  &:focus {
    // outline: 0;
    // box-shadow: 0 0 0 1px $black;
  }

  &.link {
    text-decoration: underline;
  }
}

.no-focus-outline:focus-visible {
  outline: none;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: $black;
  font-size: 16px;
  background-color: $secondary5;
}

.values-grid {
  background-color: $white;

  &>div:last-child {
    border-bottom: 0;
  }

  &.block {
    padding: 8px 16px;

    @media only screen and (min-width: $mobileView) {
      padding: 16px;
    }
  }
}

h1 {
  &.block-header {
    font-size: 32px;
    text-align: center;
    font-weight: normal;
    margin: 40px 0 0 0;

    @media only screen and (min-width: $mobileView) {
      font-size: 48px;
    }
  }
}

.block-context-text {
  font-family: $regularFont;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 32px;
  color: $black;

  @media only screen and (min-width: $mobileView) {
    font-size: 18px;
  }
}

.desktopOnly {
  @media only screen and (max-width: $mobileView) {
    display: none;
  }
}

.mobileOnly {
  @media only screen and (min-width: $mobileView) {
    display: none;
  }
}

.options-block {
  margin-top: 16px;
  border: 1px solid $secondary10;
  border-bottom-color: $secondary20;

  &>div:last-child {
    border-bottom: none;
  }
}

.note-top-margin {
  margin-top: 24px;

  @media only screen and (min-width: $mobileView) {
    margin-top: 32px;
    padding: 24px;
  }
}

.help-link {
  padding-top: 9px;
  padding-bottom: 16px;
  display: block;
}

.header-subTitle {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 24px;

  @media only screen and (min-width: $mobileView) {
    margin-bottom: 0;
    position: relative;
    top: -32px;
  }
}

.checkbox-error {
  margin-left: 22px;
  margin-top: 10px;
}

.text-center {
  text-align: center;
}

.payment-text {
  margin-bottom: $spacing-1;
}

.payment-radio {
  margin-bottom: $spacing-1;
  margin-left: 4px;

  &:last-child {
    padding-bottom: $spacing-3;
    // border-bottom: 1px solid $secondary20;
  }
}

#radio-group {
  margin-bottom: $spacing-1;
  font-family: $regularFont;
}

.print-hidden {
  @media print {
    display: none;
  }
}

@media print {

  body,
  html,
  #presto-page-layout {
    height: 100% !important;
    width: 100% !important;
    overflow: visible;
  }

  .tac-modal-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    overflow: visible !important;
    height: auto;

    .tac-modal-content {
      display: none;
    }
  }

  .redeemvoucher-whole-wrapper {
    align-items: initial;

    .redeemvoucher-wrapper {
      margin: 0px 25px;
    }
  }

  .set-autoRenew--steps {
    .checkoutGrid {
      padding-bottom: 24px !important;
    }

    .checkoutGrid-header-item {
      padding: 16px;
    }
  }

  .autorenew {
    margin: 0px 60px;

    .checkoutGridItem-logo-block {
      padding-left: 16px !important;
      padding-right: 0px !important;
    }
  }

  .manage-autoRenew-container,
  .autorenew {
    .checkoutGrid {
      padding: 0px;

      .checkoutGridItem-logo-block {
        display: block;
        padding: 0 $spacing-1 0 $spacing-1;
        flex-basis: 67px;
        align-self: center;
      }

      .checkoutGridItem-data-block {
        width: auto;
      }

      .checkoutGridItem-title {
        margin-left: 0px;
      }
    }

    .autorenew-agreement-success {
      padding: 0 0 24px 84px;
    }

    .successModifier {
      justify-content: end;
    }

    .autorenew2-footer,
    .manageautorenew2-footer,
    .cancelautorenew2-footer {

      #autorenew2-printicon,
      #manageautorenew2-printicon,
      #cancelautorenew2-printicon {
        display: none;
      }
    }
  }

  .cancelAutorenew {
    .success-block-text {
      .transitPassItem-wrapper {
        padding-left: 0px;
        padding-right: 0px;

        .transitPassItem-logo-block {
          width: 64px;
          align-self: center;

          img {
            width: 64px;
          }
        }

        .transitPassItem-texts-block-wrapper {
          display: flex;
          justify-content: space-between;
        }

        .transitPassItem-bottom {
          display: flex;
          justify-content: space-between;
        }
      }

      .transitPassItem-wrapper:nth-child(2) {
        padding-left: 74px;
      }
    }
  }

  .cancelautorenew2-footer {
    #cancelautorenew2-printicon {
      display: none;
    }
  }

  .redeem-footer {
    .redeem-print {
      display: none;
    }
  }

  .cancelautorenew2-footer {
    #cancelautorenew2-printicon {
      display: none;
    }
  }

  .redeem-footer {
    .redeem-print {
      display: none;
    }
  }

  .redeem-voucher {
    .print-button {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .autoload-success-wrapper {
    .success-block-text {
      .autoload-setup {
        .inline-button-wrapper {
          &.mobileOnly {
            color: #ffffff;
            border: none;
            background-color: #191919 !important;
          }

          .inline-button {
            width: auto;
          }
        }

        .mobileOnly {
          display: none;
        }
      }
    }

    .desktopOnly {
      justify-content: space-between;
      align-items: center;

      .buttonComponent {
        margin-top: 0px;
      }
    }

    .button-wrapper {
      display: flex;
      margin-top: 16px;

      .button-wrapper-right {
        margin-left: auto;
        display: flex;
      }

      .print-button {
        display: none;
        // font-family: "AvenirNextLTPro-Demi", sans-serif;
        // font-size: 16px;
        // cursor: pointer;
        // display: block;
        // order: -1;
        // margin-right: 44px;
        // height: 24px;
        // padding: 0 0 0 32px;
        // border: none;
        // background: transparent url(/static/media/printer.e193dc58.svg) 0 center no-repeat;
      }
    }
  }
}

.hr_line {
  margin: 10px 0px;
  border-top-style: solid;
  border-top-color: $secondary20;

  &--1px {
    border-top-width: 1px;
  }
}

.stepper-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}