@import '../../assets/scss/vars.scss';

.container {
  // padding-right: 15px;
  // padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background: #fff;
  .container-paragraph {
    display: flex;
    justify-content: center;
    padding: 30px 15px 50px 15px;
    text-align: center;
    font-family: $regularFont;
    @media only screen and (max-width: $mobileView) {
      flex-direction: column;
      padding: 10px 0 50px 0;
    }
    div {
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      line-height: 1.42857143;
      text-align: right;
      font-size: 22px;
      color: #181818;
      font-weight: 600;
      margin-top: 24px;
      margin-bottom: 16px;
      justify-content: center;

      @media only screen and (max-width: $mobileView) {
        margin-top: 32px;
      }

      h3 {
        font-size: 22px;
        font-family: $regularFont;
        @media only screen and (max-width: $mobileView) {
          text-align: center;
          font-size: 2rem;
          font-weight: 400;
          margin-bottom: 25px;
          line-height: 1.42857143;
          color: #202020;
        }
      }
    }
    .perks-btn {
      padding-top: 10px;
      padding-right: 15px;
      padding-left: 15px;
      align-items: center;
      @media only screen and (max-width: $mobileView) {
        padding: 0;
        margin-top: 0;
      }
      button {
        display: inline-block;
        min-width: 170px;
        padding-top: 15px;
        padding-bottom: 15px;
        text-decoration: none;
        border-color: #000;
        background-color: #000;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        white-space: nowrap;
        cursor: pointer;
        //border: 1px solid transparent;
        border-radius: 4px;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 1.4rem;
          font-weight: 200;
          line-height: 1;
          font-family: $regularFont;
        }
      }
    }
  }
}
#report-card-button-id {
  width: 944px;
  //margin-left: 170px;
  margin-top: 48px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #f3f3f3;
  @media only screen and (max-width: $mobileView) {
    margin: 32px 16px 0;
    width: unset;
  }
  .container-paragraph {
    padding-top: 27px;
    padding-bottom: 29px;
    padding-left: 43px;
    padding-right: 43px;
    justify-content: normal;
    font-size: 22px;
    line-height: 32px;
    width: 75%;
    background-color: #fff;
    @media only screen and (max-width: $mobileView) {
      padding: 16px 16px 24px 16px;
      width: unset;
    }
    div {
      text-align: left;
      font-family: $boldFont;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
      @media only screen and (max-width: $mobileView) {
        padding-bottom: 24px;
      }
    }
    .perks-btn {
      padding-top: 5px;
      padding-right: 0px;
      padding-left: 32px;
      @media only screen and (max-width: $mobileView) {
        padding: 0px;
      }
      button {
        border-radius: 0px;
        min-width: 0px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
        font-family: $boldFont;
        line-height: 24px;
      }
    }
  }
}
// #report-card-footer-cta {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 35px;
//     }
//     h3 {
//       line-height: 33px;
//       font-family: $boldFont;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 24px;
//         margin-bottom: 0px;
//         margin-top: 15px;
//       }
//     }
//     .perks-btn {
//       @media only screen and (max-width: $mobileView) {
//         width: 88%;
//         margin-left: 22px;
//         margin-right: 22px;
//       }
//       button {
//         border-radius: 0px;
//         min-width: 0px;
//         padding-left: 20px;
//         padding-right: 20px;
//         font-size: 16px;
//         line-height: 24px;
//         font-family: $boldFont;
//         @media only screen and (max-width: $mobileView) {
//           // padding-left: 100px;
//           // padding-right: 100px;
//         }
//       }
//     }
//   }
// }
// #report-card-footer-cta-french {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 35px;
//     }
//     h3 {
//       font-family: $boldFont;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 24px;
//         margin-bottom: 0px;
//         margin-top: 15px;
//       }
//     }
//     .perks-btn {
//       @media only screen and (max-width: $mobileView) {
//         width: 88%;
//         margin-left: 22px;
//       }
//       button {
//         border-radius: 0px;
//         min-width: 0px;
//         padding-left: 20px;
//         padding-right: 20px;
//         font-size: 16px;
//         font-family: $boldFont;
//       }
//     }
//   }
// }
// #home_page_discounts_CTA {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 35px;
//     }
//     h3 {
//       font-family: $boldFont;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 24px;
//         margin-bottom: 0px;
//         margin-top: 15px;
//       }
//     }
//     .perks-btn {
//       @media only screen and (max-width: $mobileView) {
//         width: 88%;
//         margin-left: 22px;
//       }
//       button {
//         border-radius: 0px;
//         min-width: 0px;
//         padding-left: 20px;
//         padding-right: 20px;
//         font-size: 16px;
//         font-family: $boldFont;
//       }
//     }
//   }
// }
// #loading_transit_pass_CTA {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 35px;
//     }
//     h3 {
//       font-family: $boldFont;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 24px;
//         margin-bottom: 0px;
//         margin-top: 15px;
//       }
//     }
//     .perks-btn {
//       @media only screen and (max-width: $mobileView) {
//         width: fit-content;
//         margin-left: 22px;
//       }
//       button {
//         border-radius: 0px;
//         min-width: 0px;
//         padding-left: 20px;
//         padding-right: 20px;
//         font-size: 16px;
//         font-family: $boldFont;
//         @media only screen and (max-width: $mobileView) {
//           padding-left: 100px;
//           padding-right: 100px;
//         }
//       }
//     }
//   }
// }

#presto-app-footer-cta {
  margin-top: 64px;
  @media only screen and (max-width: $mobileView) {
    margin-top: 48px;
  }
}

#presto-perks-page-cta {
  margin-top: 56px;
  @media only screen and (max-width: $mobileView) {
    margin-top: 64px;
  }
}

#new_presto_devices_CTA,
#settingup_autoload_CTA,
#new-to-presto-cta,
#presto-voucher-cta,
#presto-perks-page-cta,
#presto_ticket_CTA,
#report-card-footer-cta,
#presto-app-footer-cta,
#report-card-footer-cta-french,
#loading_transit_pass_CTA,
#home_page_discounts_CTA {
  background-color: #dfdede;
  .container-paragraph {
    padding-top: 0;
    padding-bottom: 0;
    //  padding-left: 75px;
    @media only screen and (max-width: $mobileView) {
      padding-left: 0px;
      padding-bottom: 35px;
    }
    h3 {
      line-height: 33px;
      font-family: $boldFont;
      font-size: 22px;
      @media only screen and (max-width: $mobileView) {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 0px;
        margin-top: 15px;
      }
    }
    .perks-btn {
      @media only screen and (max-width: $mobileView) {
        width: fit-content;
        //margin-left: 22px;
        margin: 0 auto 24px;
      }
      button {
        border-radius: 0px;
        min-width: 0px;
        // padding-left: 20px;
        // padding-right: 20px;
        font-size: 16px;
        line-height: 24px;
        font-family: $boldFont;
        //  width: 147px;
        height: 48px;
        padding: 12px 16px;
        @media only screen and (max-width: $mobileView) {
          // padding-left: 100px;
          // padding-right: 100px;
          width: 288px;
        }
      }
    }
  }
}
// #home_page_discounts_CTA {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 40px;
//     }
//     div {
//       @media only screen and (max-width: $mobileView) {
//         padding-left: 0px;
//         padding-right: 0px;
//       }
//     }
//     h3 {
//       font-family: $boldFont;
//       line-height: 33px;
//       margin-bottom: 30px;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 22px;
//         line-height: 32px;
//         margin-bottom: 0px;
//         margin-top: 15px;
//       }
//     }
//     .perks-btn {
//       @media only screen and (max-width: $mobileView) {
//         width: 100%;
//       }
//       button {
//         border-radius: 0px;
//         min-width: 0px;
//         padding: 12px 16px;
//         font-size: 16px;
//         font-family: $boldFont;
//         line-height: 24px;
//       }
//     }
//   }
// }
// #presto-voucher-cta {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 35px;
//     }
//     h3 {
//       font-family: $boldFont;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 24px;
//         margin-bottom: 0px;
//         margin-top: 15px;
//       }
//     }
//     .perks-btn {
//       @media only screen and (max-width: $mobileView) {
//         width: fit-content;
//         margin-left: 10px;
//       }
//       button {
//         border-radius: 0px;
//         min-width: 0px;
//         padding-left: 20px;
//         padding-right: 20px;
//         font-size: 16px;
//         font-family: $boldFont;
//         @media only screen and (max-width: $mobileView) {
//           padding-left: 92px;
//           padding-right: 92px;
//         }
//       }
//     }
//   }
// }
// #presto_ticket_CTA {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 35px;
//     }
//     h3 {
//       font-family: $boldFont;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 24px;
//         margin-bottom: 0px;
//         margin-top: 15px;
//       }
//     }
//     .perks-btn {
//       @media only screen and (max-width: $mobileView) {
//         width: fit-content;
//         margin-left: 22px;
//       }
//       button {
//         border-radius: 0px;
//         min-width: 0px;
//         padding-left: 20px;
//         padding-right: 20px;
//         font-size: 16px;
//         font-family: $boldFont;
//         @media only screen and (max-width: $mobileView) {
//           padding-left: 100px;
//           padding-right: 100px;
//         }
//       }
//     }
//   }
// }
// #presto-perks-page-cta {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 35px;
//     }
//     h3 {
//       font-family: $boldFont;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 24px;
//         margin-bottom: 0px;
//         margin-top: 15px;
//       }
//     }
//     .perks-btn {
//       @media only screen and (max-width: $mobileView) {
//         width: 88%;
//         margin-left: 22px;
//       }
//       button {
//         border-radius: 0px;
//         min-width: 0px;
//         padding-left: 20px;
//         padding-right: 20px;
//         font-size: 16px;
//         font-family: $boldFont;
//       }
//     }
//   }
// }
// #new-to-presto-cta {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 35px;
//     }
//     h3 {
//       font-family: $boldFont;
//       font-size: 22px;
//       line-height: 33px;
//       @media only screen and (max-width: $mobileView) {
//         font-size: 22px;
//         line-height: 32px;
//         margin-bottom: 0px;
//         margin-top: 15px;
//       }
//     }
//     .perks-btn {
//       @media only screen and (max-width: $mobileView) {
//         width: fit-content;
//         // margin-left: 22px;
//         margin: 0 auto 24px;
//       }
//       button {
//         border-radius: 0px;
//         min-width: 0px;
//         // padding-left: 20px;
//         // padding-right: 20px;
//         font-size: 16px;
//         font-family: $boldFont;
//         line-height: 24px;
//         width: 147px;
//         height: 48px;
//         padding: 0;
//         @media only screen and (max-width: $mobileView) {
//           // padding-left: 100px;
//           // padding-right: 100px;
//           width: 288px;
//         }
//       }
//     }
//   }
// }

// #settingup_autoload_CTA {
//   background-color: #dfdede;
//   .container-paragraph {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding-left: 75px;
//     @media only screen and (max-width: $mobileView) {
//       padding-left: 0px;
//       padding-bottom: 35px;
//       div {
//         margin-top: 38px;
//         font-family: $boldFont;
//       }
//       h3 {
//         line-height: 33px;
//         font-family: $boldFont;
//         @media only screen and (max-width: $mobileView) {
//           font-size: 24px;
//           margin-bottom: 0px;
//           margin-top: 15px;
//         }
//       }
//       .perks-btn {
//         padding-bottom: 10px;
//         margin-bottom: 20px;
//         @media only screen and (max-width: $mobileView) {
//           width: fit-content;
//           margin-left: 22px;
//         }
//         button {
//           border-radius: 0px;
//           min-width: 0px;
//           padding-left: 20px;
//           padding-right: 20px;
//           font-size: 16px!important;
//           font-family: $boldFont;
//           @media only screen and (max-width: $mobileView) {
//             padding-left: 100px;
//             padding-right: 100px;
//           }
//         }
//       }
//     }
//   }
// }
