@import '../../assets/scss/vars.scss';

.targeted-main {
  width: 624px;
  height: 276px;
  box-sizing: border-box;
  border: 2px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  margin-top: 24px;
  @media only screen and (max-width: $mobileView) {
    height: auto;
    width: auto;
  }

  .top-container {
    .title-message {
      font-size: 22px;
    }
    .description-message {
      font-size: 20px;
    }
  }
  .bottom-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 48px;

    @media only screen and (max-width: $mobileView) {
      flex-direction: column;
    }

    .buttonComponent {
      margin-left: 16px;
      @media only screen and (max-width: $mobileView) {
        margin-left: 0;
        border: 0;
      }
    }
  }
}
