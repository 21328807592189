@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.deactivate-parent {
  .steps {
    margin-top: 17px;
  }

  h2 {
    @include fontSize-18;
    font-family: $regularFont;
    text-align: center;
  }

  .checkout-text {
    margin-top: 29px;
  }

  .checkout-terms-block {
    border-top: 1px solid $secondary20;
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .checkout-payment-total {
    border-top: 1px solid $secondary20;
    padding-top: 16px;
    padding-bottom: 8px;
    text-align: right;
    font-family: $boldFont;

    @media only screen and (min-width: $mobileView) {
      padding-top: 20px;
    }

    span {
      font-size: 22px;
      position: relative;
      top: 2px;

      @media only screen and (min-width: $mobileView) {
        padding-left: 24px;
      }
    }
  }

  .checkout-textInput {
    max-width: 360px;

    >div {
      margin-top: 16px;
    }
  }

  .checkout-subtext {
    font-size: 14px;
    font-family: $regularFont;
    text-align: center;
    padding: 0 0 16px;

    @media only screen and (min-width: $mobileView) {
      padding: 0 50px 16px;
    }
  }
}

.checkout-transactionInfoGrid {
  background-color: $white;
  padding: 0 16px;
  margin-top: 16px;
  font-size: 14px;

  @media only screen and (min-width: $mobileView) {
    font-size: 16px;
    padding: 0 24px;
  }

  .checkout-shippingDetails {
    padding: 16px 0;
    border-bottom: 1px solid #d1d1d1;
    display: flex;

    &>div {
      line-height: 24px;

      &:first-child {
        width: 40%;
        font-family: $regularFont;
      }

      &:last-child {
        width: 60%;
        text-align: right;
      }
    }

    p {
      margin: 0px;
    }
  }

  .checkout-transactionInfoGrid-row {
    padding: 16px 0;
    border-bottom: 1px solid $secondary20;
    display: flex;

    &:last-child {
      border: none;
    }

    &>div {
      line-height: 24px;

      &:first-child {
        width: 40%;
        font-family: $regularFont;
      }

      &:last-child {
        width: 60%;
        text-align: right;
      }
    }
  }

}

.transfer-new-print {
  display: flex !important;
  margin-top: 16px !important;

  @media only screen and (max-width: $mobileView) {
    display: none;
  }

  .icon-button {
    height: 24px;
    padding-top: 8px;
  }

  .button-wrapper-right {
    margin-left: auto;
    display: flex;
  }
}

.print-hide {
  @media only screen and (max-width: $mobileView) {
    display: none;
  }
}


.success-block {
  @media only screen and (max-width: $mobileView) {
    padding: 10px !important;
  }

  .checkoutGridItem-value-block {
    margin-top: 0px;
  }

  .checkoutGrid-info-wrapper {
    padding: 0px;
  }

  .checkoutGrid-header-item {
    @media only screen and (max-width: $mobileView) {
      &:first-child {
        margin-right: 0px !important;
      }
    }
  }
}