.mediaImage {
  max-height: 48px;
  width: 48px;

  &-big {
    max-height: 64px;
    width: 64px;
  }

  &-medium {
    max-height: 32px;
    width: 32px;
  }

  &-small {
    max-height: 26px;
    width: 26px;
  }

  &-sm {
    max-height: 26px;
    max-width: 43px;
    padding-right: 8px;
    margin-bottom: -8px;
  }
}