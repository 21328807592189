.skip-content-link {
  font-size: 26px;
  cursor: pointer;
  text-decoration: underline;
}

.skip-overlay {
  position: fixed;
  z-index: 3000;
  left: 40%;
  top: 1rem;
}

.skip-content-wrap {
  background: white;
  padding: 10px;
}
