@import '../../../assets/scss/vars';

.transactionGridItem {
  background: $white;
  border: 1px solid $secondary10;
  margin-bottom: $spacing-base;

  .transactionGridItem-head {
    padding: $spacing-1;
    position: relative;
    display: flex;
    @media only screen and (min-width: $mobileView) {
      padding: 17px 26px 15px 26px;
    }
  }

  .transactionGridItem-right {
    display: block;
    width: 100%;
    @media only screen and (min-width: $mobileView) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  .transactionGridItem-control {
    display: flex;
    align-items: flex-start;
    margin-left: $spacing-1;
    @media only screen and (min-width: $mobileView) {
      align-items: center;
    }
  }

  .transactionGridItem-title {
    line-height: 24px;
    .data-ingreen {
      color: #328633;
    }
    .data-inblack {
      color: #191919;
    }
    button.link {
      text-align: right;
      color: #191919;
      @media only screen and (max-width: $mobileView) {
        text-align: left;
      }
    }
  }

  .transactionGridItem-subTitle {
    font-family: $regularFont;
    font-size: 12px;
    line-height: 21px;
    font-style: italic;
  }

  .transactionGridItem-remove {
    font-family: $regularFont;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
  }

  .transactionGridItem-block {
    margin-bottom: $spacing-base;
    @media only screen and (min-width: $mobileView) {
      // flex: 1;
      // min-width: 110px;
      align-items: center;
      display: flex;
      margin-bottom: 0;
    }
  }

  .transactionGridItem-value-block {
    @media only screen and (min-width: $mobileView) {
      text-align: right;
      display: flex;
      align-items: center;
      align-self: flex-start;
    }
  }

  .transactionGridItem-logo-block {
    padding-right: $spacing-1;
    display: flex;
    align-items: flex-start;
    @media only screen and (min-width: $mobileView) {
      align-items: center;
    }
  }

  .transactionGridItem-logo {
    width: 64px;
  }

  .transactionGridItem-more {
    font-size: 14px;
    line-height: 21px;
    font-family: $regularFont;
    border-top: 2px solid $secondary10;
    padding: $spacing-1;
    @media only screen and (min-width: $mobileView) {
      display: flex;
      padding-bottom: $spacing-2;
    }
  }

  .transactionGridItem-more-left {
    @media only screen and (min-width: $mobileView) {
      margin-left: 59px;
      flex: 1 1;
    }
  }

  .transactionGridItem-more-right {
    @media only screen and (min-width: $mobileView) {
      text-align: right;
      margin-right: 48px;
    }
  }
}
