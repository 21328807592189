@import '../../assets/scss/vars.scss';

.image-wallet-info-main {
  background: #ffffff;

  .wallet-info-main {
    display: flex;
    flex-direction: row;
    width: 944px;
    margin: 0 auto;
    padding: 16px 16px 30px 16px;

    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
    }

    @media only screen and (max-width: $mobileView) {
      display: flex;
      flex-direction: column;
      width: auto;
      margin: 0;
      padding-bottom: 25px;
    }

    .wallet-info-main-content-block {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }

      .wallet-info-content-block {
        width: 100%;

        .wallet-info-header {
          margin-bottom: 24px;

          @media only screen and (max-width: $mobileView) {
            margin-bottom: 0;
          }

          .display-4 {
            font-family: $boldFont;
            font-size: 22px;
            line-height: 32px;
            margin: 0 auto 8px;
          }
        }

        .wallet-info-content {
          display: flex;
          flex-direction: row;
          margin: 0 auto;
          font-weight: normal;
          font-family: $regularFont;
          background: #ffffff;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
          border-radius: 15px;
          margin-bottom: 24px;
          padding: 16px 16px 0 16px;

          .wallet-info-content-heading {
            color: #191919;
            font-size:16px;
          }

          @media only screen and (max-width: $mobileView) {
            width: unset;
            margin: 0;
            background: none;
            border: none;
            border-radius: 0;
            padding: 0;
            box-shadow: none;
          }

          .wallet-info-content1 {
            display: flex;
            width: 100%;
            text-align: left;

            @media only screen and (max-width: $mobileView) {
              display: block;
              width: 775px;
              text-align: left;
              background: none;
              border: none;
              border-radius: 0;
            }

            .wallet-info-content-image {
              width: 89px;
              height: auto;

              .google-play-download-image {
                width: 70px;
                margin-top: 16px;

                @media only screen and (max-width: $mobileView) {
                  margin-bottom: 4px;
                }
              }

              a {
                font-size: 10px;
                line-height: 12px;
                font-family: 'AvenirNextLTPro-Regular';

                @media only screen and (max-width: $mobileView) {
                  margin-bottom: 0;
                  width: 72px;
                  text-align: left;
                }
              }

              .presto-image-container {
                @media only screen and (max-width: $mobileView) {
                  margin-bottom: 0;
                }
              }

              .google-play-download-container {
                @media only screen and (max-width: $mobileView) {
                  margin-bottom: 25px;
                  display:flex;
                }
              }

              .external-link-icon {
                width: 9px;
              }

              .presto-image {
                width: 70px;
              }

              @media only screen and (max-width: $mobileView) {
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }
            .gw-image{
              @media only screen and (max-width: $mobileView) {
                margin-top:-4px;
                margin-bottom:4px;
              }
            }

            .wallet-info-content-text1 {
              width: 420px;
              color: #191919;
              font-family: $regularFont;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 24px;

              @media only screen and (max-width: $mobileView) {
                width: 100%;
              }

              .wallet-info-content-heading {
                margin-top: 0;
                margin-bottom: 0;
                font-size:16px;
              }

              .wallet-info-or-text {
                text-align: left;
                font-family: $proBold;
                line-height: 19px;

                @media only screen and (max-width: $mobileView) {
                  text-align: center;
                }
              }

              p {
                margin-top: 0;
                font-family: $regularFont;

                @media only screen and (max-width: $mobileView) {
                  padding-right: 16px;
                }
              }

              a {
                font-family: $proBold;

                @media only screen and (max-width: $mobileView) {
                  font-family: $proBold;
                }
              }
            }

            .wallet-info-content-text2 {
              width: 420px;
              color: #191919;
              font-family: $regularFont;
              font-size: 16px;
              letter-spacing: 0;
              padding: 0 16px;
              line-height: 24px;

              @media only screen and (max-width: $mobileView) {
                padding: 0;
                width: auto;
              }

              .wallet-info-content-heading {
                margin-top: 0;
                margin-bottom: 0;
                font-family: $proBold;
                font-size:16px;
              }

              .google-wallet-features {
                font-size: 16px;
                line-height: 24px;
                font-family: $boldFont;
                margin-top:0;
                margin-bottom:0;

                @media only screen and (max-width: $mobileView) {
                  margin: 16px 0;
                }
              }

              p {
                margin-top: 0;
              }

              .wallet-info-features-main {
                display: flex;
                flex-direction: row;
                width: 100%;

                @media only screen and (max-width: $mobileView) {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  margin-left: 0;
                }

                .wallet-info-features {
                  display: flex;
                  flex-direction: column;
                  width: 50%;

                  @media only screen and (max-width: $mobileView) {
                    width: auto;
                  }

                  ul {
                    li {
                      display: flex;
                      align-items: center;
                      line-height: 19px;
                      gap: 10px;
                      margin-bottom: 16px;

                      @media only screen and (max-width: $mobileView) {
                        align-items: flex-start;
                      }

                      span {
                        flex: 0 0 50px;

                        @media only screen and (max-width: $mobileView) {
                          flex: 0 0 25px;
                        }
                      }
                    }

                    @media only screen and (max-width: $mobileView) {
                      margin: 0;
                    }
                  }
                }

                .wallet-info-features1 {
                  display: flex;
                  flex-direction: column;
                  width: 50%;

                  @media only screen and (max-width: $mobileView) {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                  }

                  ul {
                    @media only screen and (max-width: $mobileView) {
                      margin: 0;
                    }

                    li {
                      display: flex;
                      align-items: center;
                      line-height: 19px;
                      gap: 10px;
                      margin-bottom: 16px;

                      @media only screen and (max-width: $mobileView) {
                        align-items: flex-start;
                      }

                      span {
                        flex: 0 0 50px;

                        @media only screen and (max-width: $mobileView) {
                          flex: 0 0 25px;
                        }
                      }
                    }
                  }
                }
              }

              &.g-wallet {
                margin-left: 0;
                padding-left: 16px;

                @media only screen and (max-width: $mobileView) {
                  margin-left: 0;
                  padding-left: 0;
                }

                .wallet-info-features {
                  width: auto;
                }

                .google-wallet-features {
                  font-family: $proBold;
                }
              }

              .wallet-info-features-check {
                display: inline-block;
                transform: rotate(45deg);
                height: 22px;
                width: 11px;
                line-height: 19px;
                color: #191919;
                font-family: $regularFont;
                font-size: 16px;
                letter-spacing: 0;
                margin-left: 9px;
                margin-right: 10px;
                border-bottom: 4.5px solid #b7dd79;
                border-right: 4.5px solid #b7dd79;

                @media only screen and (max-width: $mobileView) {
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }
    }

    .wallet-info-image-block {
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: $mobileView) {
        align-items: center;
      }

      img {
        max-width: 100%;
        height: auto;

        @media only screen and (max-width: $mobileView) {
          padding: 8px 0 8px 0;
        }
      }

      p {
        color: #191919;
        font-family: $regularFont;
        font-size: 10.5px;
        letter-spacing: 0;
        line-height: 13px;
        display: inherit;

        @media only screen and (max-width: $mobileView) {
          line-height: 12px;
          font-size: 10px;
        }

        a {
          text-decoration: none;
          font-family: $regularFont;

          @media only screen and (max-width: $mobileView) {
            margin-top: -8px;
          }
        }

        img {
          height: 10px;
          width: 9.98px;
          margin-left: 5px;

          @media only screen and (max-width: $mobileView) {
            padding: 0;
          }
        }
      }
    }
  }
}