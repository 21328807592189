@import '../../assets/scss/vars';

.textareabox {
  margin-top: 8px;
  textarea {
    width: 100%;
    height: 160px;
    border: none;
    border-bottom: 1px solid $black;
    box-sizing: border-box;
    color: $secondary80;
    background-color: $secondary10;
    font-size: 16px;
    font-family: $regularFont;
    line-height: $spacing-3;

    &:focus {
      outline: none;
    }
    &:hover {
      // cursor: pointer;
      border-bottom: 1px solid $black;
      box-shadow: 0px 1px $black;
    }
  }

  &.textareabox-error {
    textarea {
      border-color: $feedbackError;
      box-shadow: 0px 0.5px $feedbackError;
    }
  }
}
