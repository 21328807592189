@import '../../../../assets/scss/vars';
@import '../../../../assets/scss/mixins';

.ttc-checkout {
  .pause-details-wrapper {
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    margin-top: 20px;

    .pause-details-header {
      padding: 18px 16px 14px;
      font-size: 22px;
      border-bottom: 1px solid #e8e8e8;
    }

    .pause-details-descr {
      font-family: 'AvenirNextLTPro-Regular';
      margin: 18px 18px 0px 18px;
      padding-bottom: 18px;
      border-bottom: 1px solid #e8e8e8;
    }

    .bold-content {
      font-weight: bold;
    }

    .pause-Details {
      padding-left: 20px;
      padding-right: 20px;

      .pause-date {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 1px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }

      .pause-deadline {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        border-bottom: 1px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }

      .update-attempts {
        font-size: 16px;
        padding: 14px 0px 14px 0px;
        // border-bottom: 1px solid #d1d1d1;
        font-family: 'AvenirNextLTPro-Regular';
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .agencyOption-wrapper {
    padding: 1rem;
    background-color: white;
    border: 1px solid #e8e8e8;
    margin-bottom: 16px;
    margin-top: 16px;

    .agencyOption-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 2px solid #e8e8e8;
      padding-left: 16px;
    }

    .agencyOption-content {
      padding-top: 12px;
      display: flex;

      .agencyOption-title-logo-block {
        flex: 0.17 1;
        padding-left: 10px;
        @media only screen and (max-width: $mobileView) {
          flex: 0;
          padding-left: 0;
        }
        img {
          @media only screen and (max-width: $mobileView) {
            display: none;
          }
        }
      }

      .agencyOption-title-block {
        flex: 1.5 2;

        .agencyOption-date {
          font-family: 'AvenirNextLTPro-Regular';
        }

        .agencyOption-subtitle-block {
          font-family: 'AvenirNextLTPro-Regular';
          font-size: 16px;
          line-height: 24px;
          padding: 16px 0px;
        }
      }
    }

    .agencyOption-Pause-content {
      padding-left: 6px;
      padding-top: 12px;
      display: flex;

      .agencyOption-title-logo-block {
        flex: 0.17 1;
        padding-left: 6px;
      }

      .agencyOption-title-block {
        flex: 1.5 2;
        font-size: 16px;
        line-height: 21px;

        .agencyOption-details {
          font-family: 'AvenirNextLTPro-Regular';
          padding-top: 5px;
          padding-bottom: 18px;
        }

        .pause-Link {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .cardBlockComponent {
    .cardBlockComponent-title {
      padding: 18px 16px 14px;
    }

    .cardBlockComponent-content {
      padding: 18px 18px;

      .checkout-payment-total {
        border-top: 1px solid #d1d1d1;
        text-align: right;
        font-family: 'AvenirNextLTPro-Demi', sans-serif;
        padding-top: 16px;

        @media only screen and (max-width: $mobileView) {
          display: flex;
          justify-content: space-between;
        }

        .payment-Amount {
          padding-right: 20px;
        }
      }

      .radio-card-Deactivate-payment {
        display: flex;
        margin-bottom: 16px;
        margin-left: 4px;

        @media only screen and (max-width: $mobileView) {
          margin-bottom: 16px;
          margin-left: 4px;
        }

        .new-card-content {
          padding-left: 10px;
          font-family: 'AvenirNextLTPro-Demi';

          .content-title {
            font-size: 16px;

            @media only screen and (max-width: $mobileView) {
              line-height: 1.6;
            }
          }

          .content-subtitle {
            margin-top: 4px;
            font-family: 'AvenirNextLTPro-Regular';

            @media only screen and (max-width: $mobileView) {
              line-height: 1.4;
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  .checkout-subtext {
    text-align: center;
    font-family: 'AvenirNextLTPro-Regular';
  }

  .ttc-Checkout-Button {
    justify-content: end;
    flex-direction: row-reverse;

    @media only screen and (max-width: $mobileView) {
      width: auto;
      padding-right: 0;
    }

    .buttonComponent:nth-child(2) {
      background: #fff;
      color: #000;
    }
  }
}

.tcc-pass-note {
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 16px;
  .note-top-margin {
    margin-top: 16px;
    p:not(:last-child) {
      margin-bottom: 25px;
    }
  }
  @media only screen and (max-width: $mobileView) {
    padding: 0;
    .note-top-margin {
      margin-top: 16px;
    }
  }
}
.card-nick-name {
  font-size: 16px;
  padding-top: 40px;
}
