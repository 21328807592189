@import '../../assets/scss/vars';

.carousel-bottom {
  a {
    cursor: pointer;
    text-decoration: underline;
    font-size: $spacing-1;
    line-height: $spacing-2;
  }

  display: flex;
  background-color: $secondary10;
  align-items: center;
  height: $spacing-4;

  &-steps {
    margin: 0 auto;

    &-circle {
      padding: 0 0 0 $spacing-base/2;

      &:first-child {
        padding: 0;
      }
    }
  }

  &-previous {
    a {
      display: none;
    }
    padding-left: $spacing-1;

    @media only screen and (min-width: $mobileView) {
      a {
        display: inline;
      }
      width: 100px;
      padding-left: $spacing-2;

      button {
        display: none;
      }
    }
  }

  &-next {
    a {
      display: none;
    }
    width: $spacing-4;

    @media only screen and (min-width: $mobileView) {
      a {
        display: inline;
      }
      width: 100px;
      text-align: right;
      padding-right: $spacing-2;

      button {
        display: none;
      }
    }
  }
}
