@import '../../assets/scss/vars.scss';

.global-notifications {
  display: flex;
  background-color: #f7efa8;
  padding-left: 36px;
  padding-top: 16px;
  border-bottom: 1px solid #ddd;
  @media only screen and (max-width: $mobileView) {
    padding-left: 10px;
  }
  img {
    margin-right: 15px;
    @media only screen and (max-width: $mobileView) {
      margin-right: 10px;
    }
  }
  .notification-para {
    font-family: 'AvenirNextLTPro-Regular';
    line-height: 1.5em;
    p {
      margin-top: 0;
      width: 98%;
      @media only screen and (max-width: $mobileView) {
        margin-right: 96%;
      }
    }
  }
  button {
    margin-right: 26px;
    margin-bottom: 32px;
    @media only screen and (max-width: $mobileView) {
      margin-right: 0px;
      margin-bottom: 148px;
    }
  }
}
