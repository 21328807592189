@import '../../assets/scss/vars.scss';

.contactless-user-card {
  border-bottom: 1px solid #dddddd;
  margin-top: 40px;
  @media screen and (max-width: $mobileView) {
    margin-top: 32px;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    // box-shadow: 0px 0px 2px 2px #e6e6e6;
    border-radius: 8px;
    @media screen and (max-width: $mobileView) {
      width: 100%;
      height: auto;
    }
  }
  .contactless-card-details {
    padding: 15px 0px;
    // text-align: center;
    margin-top: -16px;
    @media screen and (max-width: $mobileView) {
      display: flex;
    }
    .contactless-card-number {
      @media screen and (max-width: $mobileView) {
        margin-left: 4px;
      }
    }
    span {
      margin: 4px 0px;
      font-size: 12px;
    }
  }
  .mediaImageList {
    position: relative;
    .masked-pan {
      position: absolute;
      bottom: 34px;
      left: 34px;
      font-size: 14px;
      @media screen and (max-width: $mobileView) {
        bottom: 48px;
      }
    }

    .color-black {
      color: #000;
    }

    .color-white {
      color: #fff;
    }

    @media screen and (max-width: $mobileView) {
      margin: 0 auto;
    }
  }
}

.card-dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin: auto 1px;
  display: inline-block;
  position: relative;
  top: -2px;
}

.color-whitebg {
  background-color: #fff;
}

.color-blackbg {
  background-color: #000;
}
