@import '../../assets/scss/vars.scss';
.update-nick-name {
  margin: 0;
  // width: 90%;
  padding-top: 16px;
  @media only screen and (max-width: $mobileView) {
    width: 100%;
    padding-top: 32px;
  }
  .steps li:last-child.done {
    color: #191919;
  }
}

.update-nickname-edit {
  font-size: 48px;
  line-height: 72px;
  display: block;
  font-weight: bold;
  color: #191919;
  font-family: $boldFont;
  text-align: center;
  margin: 20px 0px 30px 0px;
  @media only screen and (max-width: $mobileView) {
    margin: 0px 0px 30px 0px;
    font-size: 32px;
    line-height: 48px;
  }
}

.nickname-wrapper {
  padding-top: 30px;
  border: 1px solid #d1d1d1;
  padding: 20px;
  // justify-content: center;
  // text-align: center;
  background-color: #ffffff;
  margin-bottom: 0 !important;
  .nickname-example {
    margin-bottom: 15px;
    display: flex;
    @media only screen and (max-width: $mobileView) {
      flex-direction: column;
      margin-bottom: 0px;
    }
    .example-text1,
    .example-text2 {
      p {
        margin: 0px 0px 15px 0px;
      }
    }
    .example-text1 {
      font-size: 14px;
      display: inline-block;
      color: #191919;
      font-family: $regularFont;
      flex-basis: 80%;
      line-height: 20px;
    }
    .example-text2 {
      font-size: 14px;
      float: right;
      color: #191919;
      font-family: $boldFont;
      flex-basis: 20%;
      text-align: right;
      @media only screen and (max-width: $mobileView) {
        text-align: initial;
      }
    }
  }
  .nickname {
    border-top: 1px solid #d1d1d1;
    .nickname-label {
      font-weight: bold;
      color: #191919;
      font-size: 16px;
      font-family: $regularFont;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .nickname-textwrap {
      width: 60%;
      .textbox {
        background-color: #e8e8e8;
        border: none;
        border-bottom: 2px solid #191919;
        // padding: 14px 24px 10px 16px;
        box-sizing: border-box;
        color: #474747;
        background-color: #e8e8e8;
        font-size: 16px;
        font-family: $regularFont;
        width: 60%;
        line-height: 40px;
        border-radius: 0;
        @media only screen and (max-width: $mobileView) {
          width: 100%;
        }
      }
      @media only screen and (max-width: $mobileView) {
        width: 100%;
      }
      .inlineError {
        font-size: 14px;
        color: #d63d3d;
        // background: url(/dist/presto-ui/static/media/error.32151db6.svg) no-repeat 13px 2px;
        background-size: 16px;
        padding-left: 37px;
        padding-top: 2px;
        font-family: $regularFont;
        line-height: 21px;
        margin-top: 6px;
        font-weight: bold;
        margin-bottom: 15px;
      }
    }
    .nickname-sublabel {
      color: #191919;
      font-size: 14px;
      font-family: $regularFont;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-left: 10px;
    }
  }
  .nickname-checkbox {
    border-top: 1px solid #d1d1d1;
    justify-content: center;
    #checkbox-label {
      padding-top: 20px;
    }
    .checkboxComponent-label {
      top: 2px !important;
    }
    #checkbox-label,
    #checkbox-text {
      display: inline-block;
    }
  }
}

.updatenickname-buttons {
  display: flex;
  flex-direction: row-reverse;
  padding: 20px 0px;
  @media only screen and (max-width: $mobileView) {
    float: none;
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  #cancelbutton,
  #confirmbutton {
    display: inline-block;
    padding-left: 20px;
    @media only screen and (max-width: $mobileView) {
      padding: 0px;
    }
  }
  .white firstOrder {
    border: 2px solid #000000;
  }
}
.update-nickname-step2 {
  border: 1px solid #d1d1d1;
  margin: 30px;
  padding: 18px;
  @media only screen and (max-width: $mobileView) {
    margin: 0px;
  }
  background-color: #ffffff;
  margin-bottom: 10px !important;
  border-left: 4px solid $feedbackSuccess;
  .success-message {
    background: url('../../assets/images/Success.svg') no-repeat left top;
    -webkit-print-color-adjust: exact;
    padding-bottom: 10px;
    line-height: 32px;
    border-bottom: 1px solid #191919;
    @media only screen and (max-width: $mobileView) {
      border: none;
    }
    #message {
      color: $feedbackSuccess;
      font-size: 18px;
      font-family: $boldFont;
      padding-left: 50px;
    }
  }
}

.success-description {
  padding: 25px 50px 0px 50px;
  color: #000;
  @media only screen and (max-width: $mobileView) {
    padding: 0px;
    margin-left: 50px;
  }
  p:first-child {
    font-family: 'AvenirNextLTPro-Regular';
    font-size: 14px;
    margin: 0px;
  }
}

.updated-nickname-footer {
  display: flex;
  justify-content: space-between;
  margin: 0px 30px;
  flex-direction: row-reverse;
  @media only screen and (max-width: $mobileView) {
    margin: 0px;
    display: block;
    .icon-button {
      display: none;
    }
  }
}
