@import '../../assets/scss/vars.scss';

.signin-sidebar-container {
  width: 240px;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: $mobileView) {
    width: 100%;
  }

  .signin-header-wrapper {
    background-color: #e8e8e8;
    border-right: 1px solid #181818;

    @media only screen and (max-width: $mobileView) {
      padding-left: 0;
    }
  }

  .signin-header {
    padding: 24px 0 16px 16px;
    background-color: #e8e8e8;
    font-size: 14px;
    line-height: 21px;
    // border-bottom: 2px solid #bababa;

    @media only screen and (max-width: $mobileView) {
      text-align: center;
      font-size: 20px;
    }
    p {
      margin: 0;
    }
  }

  .or-text {
    padding: 16px;
    background-color: #e8e8e8;
    // border-bottom: 2px solid #bababa;
    border-right: 1px solid #181818;
    h2 {
      display: grid;
      text-align: center;
      grid-template-columns: 1fr auto 1fr;
      gap: 1rem;
      margin: 0;
      font-size: 14px;
      line-height: 20px;
    }
    h2::before,
    h2::after {
      content: '';
      align-self: center;
      border-top: 1px solid #aeaeae;
    }
    .signin-withoutacc {
      margin: 16px 0 0 0;
      font-size: 14px;
      line-height: 21px;

      @media only screen and (max-width: $mobileView) {
        display: none;
      }
    }
  }
  .bottom-container {
    padding: 0 16px 0;
    flex: 1;
    border-right: 1px solid #181818;
    display: flex;
    align-items: flex-end;
    .bottom-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 0 40px 0;
      font-family: 'AvenirNextLTPro-Regular';
      font-size: 14px;
      line-height: 21px;

      @media only screen and (max-width: $mobileView) {
        border-top: 0;
      }

      .buttonComponent {
        width: 100%;
        background-color: #fff;
        color: #000;
        border: 2px solid #000;
      }
    }
  }

  .card-wrapper {
    padding-left: 12px;
    background-color: #fff;
    border-right: 1px solid #181818;
    border-bottom: 1px solid #bababa;
    border-top: 1px solid #bababa;

    @media only screen and (max-width: $mobileView) {
      padding-left: 0;
    }
  }

  .card-wrapper-active {
    border-left: 8px solid #000;
    border-right: 0;
    padding-left: 0;
    border-top: 1px solid #181818;
    border-bottom: 1px solid #181818;
  }
  .card-ac-selected {
    border-bottom: 1px solid #181818;
  }
  .card-ac-wrapper {
    border-top: 0;
  }
  .card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 12px 12px 14px;
    background-color: #fff;
    // border-bottom: 2px solid #bababa;
    cursor: pointer;

    @media only screen and (max-width: $mobileView) {
      padding: 12px 12px 12px 25px;
    }

    .card-left-sidebar {
      .card-header-signin {
        font-size: 16px;
        line-height: 21px;
      }
    }

    .card-image {
      margin-top: 4px;

      img {
        margin-right: 8px;
        padding-bottom: 0;
      }
      .mediaImage {
        width: 26px;
      }
    }
  }
  .card-container-active {
    border-right: 0;
  }
  .presto-account {
    padding: 27px 12px 24px 16px;
    @media only screen and (max-width: $mobileView) {
      padding: 25px 10px 25px 25px;
    }
  }
}
