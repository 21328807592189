@import '../../assets/scss/vars';

.carousel-slide {
  position: relative;
  min-height: 490px;
  display: none;

  &.show-current-step {
    display: flex;
  }

  &.has-image::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-right: 256px solid $secondary5;
    border-top: 318px solid transparent;
    width: 0;
    height: 0;
  }

  @media only screen and (min-width: $mobileView) {
    min-height: 320px;
  }

  &.mediumCyanBlue {
    background-color: $mediumCyanBlue;
  }

  &.mediumCyanGreen {
    background-color: $mediumCyanGreen;
  }

  &.mediumGreen {
    background-color: $mediumGreen;
  }

  &.mediumRed {
    background-color: $mediumRed;
  }

  &.mediumYellow {
    background-color: $mediumYellow;
  }

  &-image {
    img {
      position: absolute;
      bottom: 1px;
      right: 50%;
      transform: translateX(50%);
      width: 100%;
      max-width: 256px;

      @media only screen and (min-width: $mobileView) {
        height: 100%;
        right: 1px;
        transform: none;
      }
    }
  }

  .carousel-slide-textblock {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: $spacing-5 $spacing-2;
    position: relative;
    z-index: $zindex-carouselSlide;

    &.full-width-text {
      justify-content: center;
      @media only screen and (min-width: $mobileView) {
        padding: 0 $spacing-4;
        width: 100%;
      }
    }

    @media only screen and (min-width: $mobileView) {
      padding: 0 0 0 $spacing-4;
      width: 327px;
      justify-content: center;
    }

    &-heading {
      font-size: 22px;
      line-height: $spacing-3;
      margin-bottom: $spacing-1;

      @media only screen and (min-width: $mobileView) {
        font-size: 26px;
        line-height: $spacing-4;
      }
    }

    &-description {
      font-family: $regularFont;
      font-size: $spacing-1;
      line-height: $spacing-2;
    }
  }

  .buttonComponent {
    margin-left: auto;
    margin-right: auto;
    max-width: 162px;
    text-transform: none;

    @media only screen and (min-width: $mobileView) {
      margin-left: 0;
    }
  }
}
