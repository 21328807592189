@import '../../assets/scss/vars.scss';

.loadMyCard-wrapper {
  .add-funds-wrapper {
    background-color: #f3f3f3;

    #autoload-titleID {
      background: $secondary5;
      font-size: 16px;
      padding: 0px;
    }

    .accordion-section {
      margin-right: 0;

      .accordion-heading {
        padding: 15px;
        font-size: 22px;

        .autoload-subtext {
          margin-top: 0;
          padding-bottom: 0;
          font-family: $boldFont;
        }
      }

      .buttonComponent {
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }
  }
}

.addfunds {
  padding: 0 8px 40px 16px;
  text-align: left;
  margin-right: 5px;
  background-color: $white;

  @media only screen and (min-width: $mobileView) {
    padding: 32px 8px 40px 24px;
    margin-right: 0;
  }

  .text-field {
    margin-top: 0;
    margin-right: 0;

    @media only screen and (min-width: $mobileView) {
      margin-left: 13px;
    }
  }

  .labels .amount,
  .addfunds-option {
    display: flex;

    @media only screen and (min-width: $mobileView) {
      display: inline-flex;
    }
  }

  .amount {
    margin-bottom: $spacing-base;
    margin-top: $spacing-3;
    padding-top: $spacing-1;

    @media only screen and (min-width: $mobileView) {
      margin-top: 0;
      width: 330px;
    }
  }

  .custom-amount {
    display: none;
    visibility: hidden;

    @media only screen and (min-width: $mobileView) {
      display: inline-flex;
      visibility: visible;
    }
  }

  .custom-amount-mobile {
    margin-top: 32px;

    @media only screen and (min-width: $mobileView) {
      display: none;
      visibility: hidden;
    }
  }

  .addfunds-amounts {
    @media only screen and (min-width: $mobileView) {
      display: flex;

      .addfunds-option {
        height: 48px;
      }
    }
  }

  .addfunds-option .error-message {
    width: 220px;
  }

  .addfunds-input {
    background-color: $white;

    @media only screen and (min-width: $mobileView) {
      display: inline-flex;

      input {
        height: 48px;
        width: 245px !important;
      }
    }

    .error-message {
      width: 200px;
    }
  }

  .addfunds-option button {
    font-size: 14px;
    background-color: $white;
    padding-left: 17px;
    padding-right: 19px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 28px;
    border: 1px solid $secondary30;
    margin-right: 8px;
    font-family: $boldFont;
    border-radius: 0;
    color: inherit;

    @media only screen and (min-width: $mobileView) {
      font-size: 18px;
    }

    &.active {
      background-color: $mediumGreen;
      border: 1px solid $black;
    }
  }

  .content-error-block {
    padding: 12px 16px;

    @media only screen and (min-width: $mobileView) {
      padding: 12px 24px;
    }

    .content-error-block-title {
      font-size: 16px;
      line-height: 24px;
      background-size: 24px;
      margin-bottom: 0;
      padding-left: 34px;
    }
  }
}

#order-a-card,
#order-a-card-accordion,
#transit-pass-accordion {
  .accordion-heading {
    .autoload-subtext {
      padding: 0px;
    }
  }
}

.add-funds-warning-note {
  border: 1px solid #e8e8e8;
  margin-bottom: 25px;
  border-left: 4px solid #8bb8e8;
  padding: 15px;
  background-color: $white;

  .add-funds-warning-title {
    font-size: 18px;
  }

  .add-funds-purchase-warning {
    font-family: 'AvenirNextLTPro-Regular', sans-serif;
  }

  .add-funds-warning-buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;

    .add-funds-return {
      margin-left: 10px;
    }

    .add-funds-checkout {
      margin-left: 10px;
    }

    @media only screen and (max-width: $mobileView) {
      display: block;

      .add-funds-return {
        margin-left: unset;
      }

      .add-funds-checkout {
        margin-left: unset;
      }
    }
  }
}

#order-a-card,
#order-a-card-accordion,
#transit-pass-accordion {
  padding: 0px;

  .accordion-wrapper {
    padding: 15px 24px 24px 24px;
    width: auto;
  }

  .addfunds {
    padding: 0px;
    margin: 0px;

    @media only screen and (min-width: $mobileView) {
      padding: 0px;
    }

    .amount {
      margin: 0px;
    }

    .addfunds-input {
      margin-bottom: 30px;
    }

    .addfunds-option {
      justify-content: space-between;

      button {
        flex-basis: 33%;
      }
    }
  }

  .buttonComponent {
    margin: 0px;
  }

  .text-field.white-input input {
    width: 100%;

    @media only screen and (min-width: $mobileView) {
      width: auto;
    }
  }
}

#order-a-card,
#order-a-card-accordion,
#transit-pass-accordion {
  // margin-top: 2px;

  .accordion-heading {
    color: inherit;

    .autoload-subtext {
      text-decoration: none;
      color: inherit;
    }
  }
}
