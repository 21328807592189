@import '../../assets/scss/vars.scss';
.four-column-hello {
  background-color: white;
  //padding-left: 155px;
  //padding-right: 155px;
  //margin-top: -16px;
  grid-column-gap: 16px;
  padding-top: 24px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 22px;
    padding-right: 22px;
    margin-top: -19px;
    padding-top: 20px;
  }
  .Image-with-para-hello {
    height: 315px;
    @media only screen and (max-width: $mobileView) {
      height: auto;
    }
  }
}
.four-column-hello-french {
  background-color: white;
  margin-top: -16px;
  grid-column-gap: 15px;
  padding-top: 24px;
  @media only screen and (max-width: $mobileView) {
    padding-left: 22px;
    padding-right: 22px;
    margin-top: -19px;
    padding-top: 20px;
  }
  .Image-with-para-hello {
    height: 420px;
    @media only screen and (max-width: $mobileView) {
      height: auto;
    }
  }
}

.fourcolumn-wrapper {
  background: #fff;
}

.row {
  &.four-column-hello {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
  &.four-column-hello-french {
    width: 944px;
    margin: 0 auto;

    @media only screen and (max-width: $mobileView) {
      width: unset;
    }
  }
}
.Four-column-etickets {
  background-color: white;
  width: 944px;
  margin: 0 auto;
  margin-top: -16px;
  grid-column-gap: 15px;
  padding-top: 40px;
  @media only screen and (max-width: $mobileView) {
    width: unset;
    padding-left: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 16px;
    margin-top: -19px;
  }
  .imageparagraph2-etickets {
    height: 310px;
    @media only screen and (max-width: $mobileView) {
      height: auto;
    }
  }
}
.Four-column-etickets-french {
  background-color: white;
  width: 944px;
  margin: 0 auto;
  margin-top: -16px;
  grid-column-gap: 15px;
  padding-top: 40px;
  @media only screen and (max-width: $mobileView) {
    width: 88%;
    padding-left: 22px;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 22px;
    margin-top: -19px;
  }
}
