@import './fonts.scss';

// Primary colours
$black: #191919;
$white: #ffffff;
$txtBlue: #4284F4;

// Secondary colours
$secondary80: #474747;
$secondary60: #6e6e6e;
$secondary30: #bababa;
$secondary20: #d1d1d1;
$secondary10: #e8e8e8;
$secondary5: #f3f3f3;

// Accent colours
$mediumGreen: #b7dd79;
$mediumCyanBlue: #8bb8e8;
$mediumCyanGreen: #8fd6bd;
$mediumRed: #ff8674;
$mediumYellow: #fbdb65;

// Feedback colours
$feedbackSuccess: #328633;
$feedbackError: #d63d3d;
$feedbackWarning: #fdd835;
$feedbackWarningOption: #bb4d00;
$errorTitle: #d5011d;
$feedbackCancel: #005AC1;
$errorAmount: #d0011b;

// Link Colors
$linkOutline: #8bb8e8;
//--------------------------------------------------//
//       Mapping table for colors in Sketch         //
//--------------------------------------------------//
// Note - Sketch colours are after = sign
//
// $black = #1a1a1a & #1e1e1e & #0d0d0d
// $white =
//
// $secondary80 =
// $secondary60 = #8c8c8c
// $secondary30 = #c1c1c1
// $secondary20 =
// $secondary10 = #e7e7e7
// $secondary5  =
//
// $mediumGreen = #b7dd79
// $mediumCyanBlue = #8ab8e8
// $mediumCyanGreen =
// $mediumRed =
// $mediumYellow =
//
// $feedbackSuccess = #008923
// $feedbackError = #d0011b
// $feedbackWarning =
// $feedbackWarningOption =

//--------------------------------------------------//

$mobileView: '920px';

// Typography

$regularFont: 'AvenirNextLTPro-Regular', sans-serif;
$boldFont: 'AvenirNextLTPro-Demi', sans-serif;
$proBold: 'AvenirNextLTPro-Bold';
$proDemi: 'AvenirNextLTPro-Demi';

// Deprecated -  Use Font Mixin instead
$fontSize-base: 14px;
$fontSize-1: $fontSize-base + 2;
$fontSize-2: $fontSize-base + 4;
$fontSize-3: $fontSize-base + 6;
$fontSize-4: $fontSize-base + 8;
$fontSize-5: $fontSize-base + 10;
$fontSize-6: $fontSize-base + 12;
// End Of: Deprecated

// Don't use $fontSize and $lineHeight directly,
// use Font Mixins whenever is possible
$fontSize-12: 12px;
$fontSize-14: 14px;
$fontSize-16: 16px;
$fontSize-18: 18px;
$fontSize-20: 20px;
$fontSize-22: 22px;
$fontSize-26: 26px;
$fontSize-32: 32px;
$fontSize-48: 48px;
$fontSize-60: 60px;

$lineHeight-18: 18px;
$lineHeight-24: 24px;
$lineHeight-27: 27px;
$lineHeight-30: 30px;
$lineHeight-33: 33px;
$lineHeight-39: 39px;
$lineHeight-48: 48px;
$lineHeight-72: 72px;
$lineHeight-90: 90px;

// Margins and Paddings
$spacing-base: 8px;
$spacing-1: 2 * $spacing-base;
$spacing-2: 3 * $spacing-base;
$spacing-3: 4 * $spacing-base;
$spacing-4: 5 * $spacing-base;
$spacing-5: 6 * $spacing-base;
$spacing-6: 7 * $spacing-base;
$spacing-7: 8 * $spacing-base;
$spacing-8: 9 * $spacing-base;
$spacing-9: 10 * $spacing-base;
$spacing-10: 11 * $spacing-base;
$spacing-11: 12 * $spacing-base;
$spacing-12: 13 * $spacing-base;

// z-index list
// Keep all used z-index values in this list to improve overview of compoents which are dependent on z-axis.
// Avoid assingning custom z-index values directly in atoms, molecules or components SCSS files.
$zindex-carouselSlide: 1;
$zindex-radioInput: 1;
$zindex-stepTrackerCircle: 1;
$zindex-stepTrackerNumber: 2;
$zindex-textInputFloatingIcon: 3;

$zindex-topNavigationDropdown: 2000;
$zindex-topNavigation: 2001;

$zindex-shoppingCardWrapper: 2005;
$zindex-shoppingCartContent: 2006;

$zindex-modalBackdrop: 2010;
$zindex-modalContent: 2011;
//Chat icon (external css): 9999999;

$text-color-black: #191919;