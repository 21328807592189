@import '../../assets/scss/vars';

.collapsibleBlock {
  border: 1px solid $secondary10;
  margin-bottom: 24px;
  background-color: $white;

  .collapsibleBlock-header {
    padding: 13px 40px 13px 13px;
    position: relative;
    border-bottom: 1px solid $secondary10;

    .collapsibleBlock-actionButton {
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: right;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }

    &.mobilesOnly {
      @media only screen and (min-width: $mobileView) {
        display: none;
      }
    }
  }

  .actionIcon {
    width: 25px;
  }
}
