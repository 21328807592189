@import '../../assets/scss/vars.scss';
.loadMyCard-wrapper {
  .content-switcher {
    margin-top: $spacing-2;
    margin-bottom: $spacing-3;

    @media screen and (min-width: $mobileView) {
      margin-top: $spacing-5;
    }

    a {
      text-decoration: none;
      font-size: 22px;
      border-bottom: 5px solid $black;
      padding-bottom: 5px;
      line-height: 32px;
      display: inline-block;
      &:first-child {
        margin-right: 40px;
      }
      &.active {
        pointer-events: none;
        cursor: default;
      }
      &:focus {
        outline: none;
      }
      &.inactive {
        border: none;
        color: $secondary60;
        cursor: pointer;
      }
    }
  }
}
