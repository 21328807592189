@import '../../assets/scss/vars.scss';

.process-stepper{
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.12);
  border-radius: 20px;
  padding: 26px;
  padding-top: 16px;
  text-align: left;
  width: 24% !important;
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (max-width: $mobileView){
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.12);
    border-radius: 20px;
    padding: 15px;
    padding-top: 5px;
    text-align: left;
    width: 85% !important;
    margin: 0 auto;
    margin-bottom: 20px;
  }

}

.process-stepper-image-block{
  height: auto;
  text-align: center;
  align-self: end;
  img{
    height: 75px;
    width: auto;
  }
}

.process-stepper-content-block{
  //height: 65px;
  h2{
    font-size: 20px;
    font-family: $proDemi;
  }
  p{
    font-size: 16px;
    font-family: $regularFont;
    line-height: 21px;
    height: auto;
    @media only screen and (max-width: $mobileView){
      height: auto;
    }
  }
}

.mobile-process-step-container {
  background: #ffffff;

  .process-step-layout-sm-content-block {
    padding: 0 25px 0px 30px;
    margin-top: 14px;

    h2 {
      font-family: $proBold;
      font-size: 16px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-bottom: 5px;
      }
    }

    p {
      font-family: $regularFont;
      font-size: 16px;
      line-height: 24px;

      @media only screen and (max-width: $mobileView) {
        margin-top: 0px;
      }
    }
  }

  .process-step-layout-sm-image-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 20px 0;
    margin-top: 16px;

    p {
      padding: 0px 30px;
    }

    img {
      padding: 0 15px 0 15px;
    }
  }

  .slick-slider {
    .slick-dots {
      position: relative;
      bottom: 0;
      display: block;
      width: 100%;
      padding: 15px 0;
      margin: 0;
      list-style: none;
      text-align: center;
      background: #e8e8e8;

      li {
        height: auto;
        width: 7px;

        button {
          width: 7px;
        }
      }

      li button:before {
        font-size: 11px;
        top: 0;
      }
    }

    p {
      img {
        display: inline-block;
        margin-left: 5px;
        margin-bottom: -5px;
        padding: 0;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      width: auto;
    }

    .slick-next:before,
    .slick-prev:before {
      color: transparent;
      font-size: 22px;
    }

    //customized arrows
    .slick-arrow {
      position: absolute;
      top: 100%;
      margin: -26px 0 0;
      z-index: 10;
      font-size: 0;
      width: 22px;
      height: auto;

      &:focus {
        outline: 0.25rem solid #8bb8e8;
      }
    }

    .slick-arrow.slick-prev {
      left: 21px;
      background: url('https://img.icons8.com/ios/50/000000/long-arrow-left.png') 0 0 / 100% no-repeat;
    }

    .slick-arrow.slick-next {
      right: 21px;
      background: url('https://img.icons8.com/ios/50/000000/long-arrow-right.png') 0 0 / 100% no-repeat;
    }

    .slick-arrow.slick-disabled {
      opacity: 0.2;
    }
  }

  .process-step-one-item {
    position: relative;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 24px 0;
    margin: 0;
    list-style: none;
    text-align: center;
    background: #e8e8e8;
  }
}

.process-step-layout-sm {
  display: none;

  @media only screen and (max-width: $mobileView) {
    display: block;
    margin: 16px 16px 24px 16px;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
  }
}

.process-mobile-link {
  display: none;

  @media only screen and (max-width: $mobileView) {
    display: block;
  }
}

.process-step-layout-md {
  display: block;

  @media only screen and (max-width: $mobileView) {
    display: none;
  }
}

.process-step-main-container.four-column {
  width: 944px;
  margin: 0 auto;
  padding: 24px 0;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  gap: 24px;

  .process-step-layout {
    font-size: 16px;
    width: 22%;

    .process-step-content-block {
      margin-left: 6px;
      line-height: 24px;

      h2 {
        font-family: $proBold;
        font-size: 16px;
        line-height: 24px;
      }

      p {
        font-family: $regularFont;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.process-step-main-container.one-column {
  width: 944px;
  margin: 0 auto;
  display: block;
  flex-wrap: wrap;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 24px 0px 50px 0;

  .process-step-layout {
    font-size: 16px;
    margin: 0px 30px 0 0;

    .process-step-child-link-block {
      img {
        margin-bottom: -5px;
        margin-left: 10px;
      }

      p {
        a {
          width: 17%;
          display: inline-block;
          text-align: left;
          font-family: $regularFont;
        }
      }
    }
  }

  .process-step-layout-external-link {
    img {
      margin-left: 8px;
      height: 16px;
      width: 16px;
    }

    p {
      width: 45%;
      justify-content: flext;
      text-align: left;
      margin-bottom: 0px;
    }

    a {
      color: #191919;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      font-family: $regularFont;
      display: inline-flex;
      outline: none;

      &:focus {
        outline: 2px solid #8bb8e8;
        border-radius: 2px;
      }
    }
  }
}

@media only screen and (max-width: $mobileView) {
  .process-step-layout-external-link {
    margin: 0px 16px 24px 16px;

    img {
      height: 12px;
      width: 12px;
      margin-left: 5px;
    }

    p {
      margin: 0px;
    }

    a {
      color: #191919;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      font-family: $regularFont;
      // display: flex;
      gap: 5px;
      align-items: end;
    }
  }
}

.process-step-main-container.three-column-layout {
  width: 944px;
  margin: 0 auto;
  padding: 24px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  column-gap: 47px;

  @media only screen and (max-width: $mobileView) {
      width: auto;
      display: block;
  }
  

  .process-step-layout {
    font-size: 16px;
    width: 30%;
    // margin: 15px 15px 15px 0;

    .process-step-content-block {
      h2 {
        font-family: $proBold;
        font-size: 16px;
        line-height: 24px;
      }

      p {
        font-family: $regularFont;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.three-column-layout {
  .process-step-layout.process-step-layout-md {
    .process-step-image-block {
      img {
        width: 230px;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.one-column {
  .process-step-layout {
    .process-step-image-block {
      img {
        width: 230px;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.four-column {
  .process-step-layout.process-step-layout-md {
    .process-step-image-block {
      img {
        width: 210px;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}