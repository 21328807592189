@import '../../assets/scss/vars.scss';

.thumbnail-collection {
  background-color: #ffffff;
  margin-top: 80px;
  padding-top: 35px;
  padding-bottom: 48px;
  @media only screen and (max-width: $mobileView) {
    margin-top: 41px;
    padding-top: 25px;
    padding-bottom: 40px;
  }
  .thumbnail-collection-title-left-align {
    text-align: left;
    font-size: 32px;
    line-height: 32px;
    font-family: $boldFont;
    @media only screen and (max-width: $mobileView) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }
  .thumbnail-collection-title-center-align {
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    @media only screen and (max-width: $mobileView) {
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 16px;
    }
  }
  .transit-agencies-icons {
    width: 944px;
    text-align: center;
    float: none;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    @media only screen and (max-width: $mobileView) {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    li {
      width: 130px;
      text-align: center;
      list-style: none;
      display: inline-block;
      padding: 10px;
      min-height: 70px;
      margin: 10px 0 0 0;
      @media only screen and (max-width: $mobileView) {
        float: none;
        width: 40%;
      }
      a {
        display: inline-block;
        width: 100%;
        img {
          @media only screen and (max-width: $mobileView) {
            margin: 0 auto;
            display: block;
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
    > div {
      text-align: center;
      img {
        width: 75%;
      }
    }
  }
}
