@import '../../assets/scss/vars.scss';

.delete-account {
    font-size: 18px;

    @media only screen and (max-width: $mobileView) {
        margin-left: 10px;
        margin-right: 10px;
    }

    .cardBlockComponent p {
        font-size: 16px;
    }

    .cardBlockComponent-title {
        line-height: 32px;

        @media only screen and (max-width: $mobileView) {
            font-size: 22px;
            line-height: 24px;
        }
    }

    .cardBlockComponent-content {
        padding-top: 12px;

    }

    .step1-content-heading1 {
        font-family: $boldFont;
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 8px;
        line-height: 30px;
    }

    .step1-content-note {
        margin-top: 0;
        line-height: 21px;
        margin-bottom: 16px;

        @media only screen and (max-width: $mobileView) {
            font-size: 16px;
        }
    }

    .step1-content-heading2 {
        padding-top: 16px;
        font-size: 18px;
        font-family: $boldFont;
        margin-top: 0;
        margin-bottom: 8px;
        line-height: 30px;
    }

    .step1-sec1-subheading {
        margin-top: 0px;
        line-height: 21px;

        @media only screen and (max-width: $mobileView) {
            font-size: 16px;
        }
    }

    .ordered-first-para {
        padding-left: 20px;
        margin-bottom: 0px;
        font-size: 16px;

        @media only screen and (max-width: $mobileView) {
            padding-left: 18px;
        }
    }

    .step1-sec1-para1 {
        margin-bottom: 0px;
        line-height: 21px;

        @media only screen and (max-width: $mobileView) {
            font-size: 16px;
        }
    }

    .step1-sec1-para2,
    .step1-sec2-para1 {
        margin-top: 0px;
        line-height: 21px;

        @media only screen and (max-width: $mobileView) {
            font-size: 16px;
        }
    }

    hr {
        border-top: 1px solid #D1D1D1;
        margin: 0px;
    }

    .step1-content-heading3 {
        font-family: $boldFont;
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 30px;
    }

    .unordered-second-para {
        margin-bottom: 8px;
        list-style-type: none;
        padding-left: 0px;
    }

    .step1-disclaimer {
        margin-bottom: 32px;
        line-height: 21px;
        font-size: 12px !important;
    }

    .step1-content-heading4 {
        font-family: $boldFont;
        margin-bottom: 8px;
        font-size: 18px;
        margin-top: 0px;
        line-height: 30px;
    }

    .step1-cross-icon {
        display: flex;
        align-items: flex-start;
        line-height: 21px;

        @media only screen and (max-width: $mobileView) {
            font-size: 16px;
        }

        .step1-cross-icon-image {
            height: 25px;
            width: 25px;
        }

        .step1-content-para1 {
            margin: 0 0 8px 14px;
        }
    }

    .step1-check-icon {
        display: flex;
        align-items: flex-start;
        line-height: 21px;

        @media only screen and (max-width: $mobileView) {
            font-size: 16px;
        }

        .step1-content-para1 {
            margin: 0 0 8px 14px;
        }
    }


    .redBackground {
        background-color: #d0011b;
        border: none;

        @media only screen and (min-width: $mobileView) {
            margin-left: 8px;
        }
    }


    .step2-content {
        display: flex;

        @media only screen and (max-width: $mobileView) {
            display: block;
        }

        .step2-image-content {
            margin-top: 12px;
            margin-right: 16px;

            @media only screen and (max-width: $mobileView) {
                text-align: center;
                margin-bottom: 8px;
                margin-top: 0px;
                margin-right: 0px;
            }

            .step2-image {
                height: 56px;
            }
        }

        .step2-content-description1 {
            padding-top: 12px;
            margin-bottom: 0px;

            @media only screen and (max-width: $mobileView) {
                padding-top: 0;
            }
        }

        .step2-content-description2 {
            margin-top: 0px;
            margin-bottom: 0px;
            padding-top: 8px;

        }
    }

    .step2-btn-wrapper {
        @media only screen and (max-width: $mobileView) {
            margin-top: 16px;

            .firstOrder {
                margin-top: 8px;
            }
        }
    }

    .step3-para1 {
        text-align: center;
        padding-top: 16px;

        @media only screen and (max-width: $mobileView) {
            margin-bottom: 32px;

        }
    }

    .step3-img1 {
        text-align: center;
        margin-top: 0;
        margin-bottom: 32px;
    }
}