@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';

.emailVerification {
  &-formGrid {
    margin: 0 (-$spacing-2) $spacing-3;
  }

  &-formRow {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-width: $mobileView) {
      flex-wrap: nowrap;
    }
  }

  &-formCol {
    box-sizing: border-box;
    flex-grow: 1;
    padding: 0 $spacing-2;

    @supports (-webkit-touch-callout: none) {

      /* CSS specific to iOS devices */
      input:disabled {
        opacity: 1;
      }

      .text-field-input {
        color: text;
      }
    }

    @media only screen and (min-width: $mobileView) {
      max-width: 50%;
    }
  }

  &-newEmail {
    @include fontSize-22;
    margin: $spacing-base 0 $spacing-2;
  }

  &-successBlockContent {
    margin-top: $spacing-2;
  }
}

.updateFailure {
  font-size: 14px;
  color: $feedbackError;
  background-size: 16px;
  padding-top: 2px;
  font-family: $boldFont;
  line-height: 21px;
  margin-top: 6px;
}


.updateSuccess {
  font-size: 14px;
  color: $feedbackSuccess;
  background-size: 16px;
  padding-top: 2px;
  font-family: $boldFont;
  line-height: 21px;
  margin-top: 6px;
}

.successIcon:focus-visible {
  outline: 0.25rem solid #8bb8e8;
}