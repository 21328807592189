@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.deactivate-new-card-block {
  margin-top: 16px;
  width: 39rem;
  background-color: white;
  padding: 20px 0px 20px 0px;

  @media only screen and (max-width: $mobileView) {
    width: auto;
    margin-left: auto;
  }

  .card-header {
    font-size: 18px;
    padding: 0px 20px 21px 20px;
    border-bottom: 2px solid #d1d1d1;
  }

  .card-content {
    padding-left: 20px;
    padding-right: 20px;

    .card-Number {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-nick-name {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-balance {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      border-bottom: 2px solid #d1d1d1;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;
    }

    .card-transitPass {
      font-size: 16px;
      padding: 14px 0px 14px 0px;
      font-family: 'AvenirNextLTPro-Regular';
      display: flex;
      justify-content: space-between;

      b {
        flex-basis: 55%;
      }
    }
  }
}

.deactivate-parent {
  .Deactivated-Button {
    justify-content: end;
    flex-direction: row-reverse;
    // margin-top: 16px !important;
    @media only screen and (max-width: $mobileView) {
      width: auto;
      padding-right: 0;
    }

    .buttonComponent:nth-child(2) {
      background: #fff;
      color: #000;
    }
  }
  .checkoutgrid-margin-bottom {
    margin-bottom: 16px;
    .checkoutGrid-wrapper {
      .checkoutGrid {
        .checkoutGridItem {
          .checkoutGridItem-content {
            .checkoutGridItem-logo-block {
              img {
                top: 0;
                align-self: auto;
              }
            }
          }
        }
      }
    }
  }
}
